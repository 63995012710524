<template>
  <div class="content-right">
    <div class="content-title">应用提交</div>
    <div class="content">
      <p class="content-big-title">1.应用提交流程</p>
      <p class="content-small-title">
        <img src="@/assets/img/tj.png" alt />
      </p>
      <p class="content-big-title">2.详细说明</p>
      <p class="content-small-title">
        1）在开放平台
        <span class="span2">成功注册为开发者</span
        >后，便可以进行应用发布。在步步高家教机开发者平台，点击首页选择-管理中心或发布应用
        <img
          src="https://common-pic-dn.eebbk.net/common-pic/2019/09/18/144748906_449be34e03a6443c.png"
          alt
          class="img"
        />
      </p>

      <p class="content-small-title">
        2）选择您要上传的应用apk文件
        ，上传后将自动解析包名，以及应用名称。如下图：
        <img src="@/assets/img/xx2.png" alt class="img" />
      </p>

      <p class="content-small-title">3）上传版权证明资料及附加测试说明</p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span
        >软件版权证明：请上传软著扫描件，如果无软著请上传1-3个其他市场后台上架截图供审
      </p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span>
        特殊类证书：如应用为特殊行业，需上传特殊类证书，例如：金融类、直播类
      </p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span
        >附加测试说明：填写应用测试中遗留的问题，及手机测试配置的说明与提供相关的测试账号
        <img src="@/assets/img/sm2.png" alt class="img" />
      </p>
      <p class="content-small-title">4）完善应用信息</p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span
        >icon：要求与安装包中图标一致。尺寸：要求长等于宽，不低于256*256，不超过512*512，角不超过48PX，小于50K；
      </p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span
        >截图：请上传3-5张截图（尺寸大小保持一致、横屏或竖屏保持一致），支持JPG、PNG格式。截图尺寸要求：尺寸为竖图480*800，格式为jpg/png，每张图片尺寸一致，单张图片不超过2MB。请去除截图中的顶部状态栏的通知图标，图片中不得使用其他品牌的手机作为边框或宣传图；
        <img src="@/assets/img/sm1.png" alt class="img" />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    .span2 {
      text-decoration: underline;
      color: skyblue;
    }
    .span3 {
      display: inline-block;
      margin-right: 20px;
      width: 10px;
      height: 10px;
      background-color: #000;
      border-radius: 50%;
    }
  }
}
</style>
