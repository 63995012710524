<template>
  <div class="content-right">
    <div class="content-title">应用资质审核规范</div>
    <div class="content">
      <p class="content-big-title">一、基础资质</p>
      <div class="table">
        <table border="1">
          <thead>
            <tr>
              <th><strong>分类</strong></th>
              <th><strong>资质说明</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>特殊类应用</td>
              <td>1.计算机软件著作权登记证书</td>
            </tr>
            <tr>
              <td>非特殊类应用</td>
              <td>
                2.计算机软件著作权登记证书或<span
                  class="span2"
                  @click="$goToBlank('check_promise')"
                  >承诺函</span
                >（二选一）
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="content-big-title">二、游戏版权、版号要求</p>
      <p style="line-height: 160%;">
        游戏开发者需按照版权、版号要求提交下列文件，同时，为培育积极健康、向上向善的网络文化氛围，给用户提供清朗的游戏环境。
      </p>
      <div class="table">
        <table border="1">
          <tbody>
            <tr style="text-align: center;">
              <td colSpan="1" rowSpan="1" width="12%"><strong>分类</strong></td>
              <td colSpan="1" rowSpan="1" width="42%"><strong>版权要求</strong></td>
              <td colSpan="1" rowSpan="1" width="43%"><strong>版号要求</strong></td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="12%">游戏</td>
              <td colSpan="1" rowSpan="1" width="42%">
                <p>
                  （1）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
                </p>
                <p>（2）若上传游戏的开发者非版权著作权人，需提供版权授权书</p>
              </td>
              <td colSpan="1" rowSpan="1" width="43%">
                <p>
                  （1）《网络游戏出版物号（ISBN）核发单》或《关于同意出版运营移动网络游戏的批复》
                </p>
                <p>
                  （2）若上传游戏的开发者非版号批文中的运营单位，需提供版号运营单位的版号授权书
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="content-big-title">三、安全评估报告</p>
      <p style="line-height: 160%;">
        根据相关法律法规规定，应用属于具有舆论属性或社会动员能力的App，上架前应当提交App安全评估报告。【具体详见<span
          class="goToBlank"
          @click="$goToBlank('register_convention')"
          >《应用商店平台开发者合规公约（具有舆论属性或社会动员能力应用程序安全评估
          ）》</span
        >
        部分】。
      </p>
      <p class="content-big-title">四、应用特殊资质</p>
      <p style="line-height: 160%;">
        由于应用内容包含的业务类型繁多和审核规则不同时期的调整更新，资质说明并未详尽，如有疑问可咨询客服【open@eebbk.com】，以最终审核意见为主。
      </p>
      <div class="table">
        <table border="1">
          <thead>
            <tr>
              <th><strong>应用类型</strong></th>
              <th><strong>资质说明</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>银行</td>
              <td>
                <p>(1) 《金融许可证》</p>
                <p>(2) ICP备案或《增值电信业务经营许可证》</p>
                <p>
                  (3)《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
                </p>
              </td>
            </tr>
            <tr>
              <td rowspan="4">非特殊类应用</td>
              <td>
                1.《金融许可证》，当地政府的金融批准文证件，与有金融资质的公司签署的合作合同/协议，以上任一资质。（合作公司资质需同步提交）
              </td>
            </tr>
            <tr>
              <td>2.平台类金融产品需提供与银行及其他合作机构文件</td>
            </tr>
            <tr>
              <td>3.第三方支付平台或银行签署的资金托管协议（其一即可）</td>
            </tr>
            <tr>
              <td>
                4、需提供三家或三家以上的第三方合作单位的金融资质（资质内容参考1、2、3点）
              </td>
            </tr>

            <tr>
              <td rowspan="5">
                <p>期货</p>
                <p>证券</p>
                <p>股票</p>
              </td>
              <td>
                （1）《经营证券期货业务许可证》且经营范围须与应用实际业务相符
              </td>
            </tr>
            <tr>
              <td>（2）股票类应用需提供交易所行情授权书</td>
            </tr>
            <tr>
              <td>
                （3）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （4）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                （5）若接入第三方支付平台，请提交与第三方支付平台的合作协议和该平台合作的合同盖章页
              </td>
            </tr>

            <tr>
              <td rowspan="3">
                基金
              </td>
              <td>
                （1）《经营证券期货业务许可证》且业务范围须包含“基金”或《证券交易所经营许可证》（仅针对交易所的App）或《基金托管业务许可证》
              </td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>

            <tr>
              <td rowspan="3">
                信托、融资租赁、汽车金融、资产管理/交易、消费金融
              </td>
              <td>（1）《金融许可证》或当地政府的金融批文</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>

            <tr>
              <td rowspan="3">
                外汇
              </td>
              <td>
                （1）《经营外汇业务许可证》或外汇管理机关准许经营外汇业务批准文件
              </td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="4">
                具有支付功能的应用
              </td>
              <td>（1）《支付业务许可证》</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                （4）若接入第三方支付平台，请提交与第三方支付平台的合作协议和该平台的《支付业务许可证》
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                贵金属交易平台
              </td>
              <td>（1）贵金属交易平台会员：《贵金属交易会员证书》</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）非经国务院或国务院金融管理部门批准的贵金属交易平台，必须先征求联席会议意见，并报省级人民政府批准。（政府批文\省级以上金融办的批文\商务部的备案等证明(三选一)
              </td>
            </tr>
            <tr>
              <td rowspan="5">
                音视频
              </td>
              <td>（1）《网络文化经营许可证》</td>
            </tr>
            <tr>
              <td>（2）《信息网络传播视听节目许可证》</td>
            </tr>
            <tr>
              <td>
                （3）若涉及转载内容，需补充提供版权方出具的有效授权证明文件）
              </td>
            </tr>
            <tr>
              <td>
                （4）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （5）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="5">
                直播
              </td>
              <td>（1）《网络文化经营许可证》且带有“直播”或“表演”等字样</td>
            </tr>
            <tr>
              <td>（2）《安全评估报告》加盖公章</td>
            </tr>
            <tr>
              <td>
                （3）《安全评估报告》在全国互联网安全服务管理平台的提交结果截图
              </td>
            </tr>
            <tr>
              <td>
                （4）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （5）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="4">
                旅游
              </td>
              <td>（1）旅行社经营许可证（旅行社需提交）</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                （4）若软件跳转到第三方平台如携程，去哪儿，需提交第三方合作协议
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                航空公司、机票销售
              </td>
              <td>（1）《公共航空运输企业经营许可证》</td>
            </tr>
            <tr>
              <td>（2）《中国民用航空运输销售代理业务资格认证书》</td>
            </tr>

            <tr>
              <td rowspan="6">
                医药和医疗
              </td>
              <td>
                药品信息服务：<br />
                （1）《互联网药品信息服务资格证书》<br />
                （2）ICP备案或《增值电信业务经营许可证》<br />
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                药品销售（开发者为药品网络交易第三方平台提供者）：<br />
                （1）在省、自治区、直辖市人民政府药品监督管理部门备案凭证<br />
                （2）《互联网药品信息服务资格证书》或《互联网药品交易服务资格证》<br />
                （3）ICP备案或《增值电信业务经营许可证》<br />
                （4）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                医疗器械信息服务：<br />
                （1）《互联网药品信息服务资格证书》<br />
                （2）ICP备案或《增值电信业务经营许可证》<br />
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                医疗器械网络销售（开发者为医疗器械经营企业）：<br />
                （1）《互联网药品信息服务资格证书》<br />
                （2）《医疗器械网络销售备案凭证》或《医疗器械经营许可证》（第三类）或《第二类医疗器械经营备案证》<br />
                （3）ICP备案或《增值电信业务经营许可证》<br />
                （4）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                医疗器械网络交易（开发者为医疗器械网络交易服务第三方平台提供者）：<br />
                （1）《互联网药品信息服务资格证书》<br />
                （2）《医疗器械网络交易服务第三方平台备案凭证》<br />
                （3）ICP备案或《增值电信业务经营许可证》<br />
                （4）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td>
                包括医疗美容、医疗问诊、心理健康问诊等，需提供：<br />
                （1）《医疗机构执业许可证》/《设置医疗机构批准书》<br />
                注1：涉及线上问诊的，许可证上需注明互联网诊疗或互联网医院；<br />
                注2：涉及与医疗机构合作的，需提供合作机构的合作协议及合作方《医疗机构执业许可证》或《设置医疗机构批准书》。<br />
                （2）ICP备案或《增值电信业务经营许可证》<br />
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>

            <tr>
              <td rowspan="3">门诊预约</td>
              <td>（1）合作医院的授权书</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">体检购买</td>
              <td>（1）合作医院的授权书</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="2">运动、健康、养生</td>
              <td>（1）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （2）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>

            <tr>
              <td rowspan="4">应用授权代理</td>
              <td>以下三者缺一不可。</td>
            </tr>
            <tr>
              <td>（1）授权者营业执照</td>
            </tr>
            <tr>
              <td>
                （2）代理商营业执照
              </td>
            </tr>
            <tr>
              <td>
                （3）推广授权协议、代理合同、知识产权转让协议三者中的任意一种，需提供扫描件或是加盖公章的复印件
              </td>
            </tr>
            <tr>
              <td rowspan="3">移民中介</td>
              <td>（1）《因私出入境中介机构经营许可证》</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>

            <tr>
              <td rowspan="3">人才中介</td>
              <td>（1）《人才中介服务许可证》或《人才资源服务许可证》</td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>

            <tr>
              <td rowspan="3">新闻资讯弹窗推送新闻信息</td>
              <td>
                （1）《互联网信息服务许可证》或省、自治区、直辖市人民政府新闻办公室备案文件。
              </td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">互联网宗教信息服务</td>
              <td>
                （1）《互联网信息服务许可证》或省、自治区、直辖市人民政府新闻办公室备案文件。
              </td>
            </tr>
            <tr>
              <td>（2）ICP备案或《增值电信业务经营许可证》</td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="4">
                聊天 <br />
                社区<br />
                通讯
              </td>
              <td>
                （1）《安全评估报告》加盖公章
              </td>
            </tr>
            <tr>
              <td>
                （2）《安全评估报告》在全国互联网安全服务管理平台的提交结果截图
              </td>
            </tr>
            <tr>
              <td>
                （3）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （4）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                互联网地图服务
              </td>
              <td>
                （1）《测绘资质证书》并在专业范围中注明互联网地图服务（若涉及与测绘单位进行合作的，可提供双方合作协议（授权证明），同时需在地图显著位置标明审图号和合作单位）
              </td>
            </tr>
            <tr>
              <td>
                （2）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                网约车
              </td>
              <td>
                （1）《网络预约出租汽车经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （2）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                网约车聚合平台
              </td>
              <td>
                （1）至少一家合作方的《网络预约出租汽车经营许可证》及合作协议
              </td>
            </tr>
            <tr>
              <td>
                （2）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                加速器<br />
                浏览器<br />
                网盘/云存储等
              </td>
              <td>
                （1）《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （2）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                拍卖
              </td>
              <td>
                （1）《拍卖经营批准证书》
              </td>
            </tr>
            <tr>
              <td>
                （2）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                短信群发
              </td>
              <td>
                （1）《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （2）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                义务教育学科培训
              </td>
              <td>
                （1）省级教育行政部门备案
              </td>
            </tr>
            <tr>
              <td>
                （2）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="4">
                阅读
              </td>
              <td>
                （1）《网络出版服务许可证》或《网络文化经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （2）相关版权方授权书及授权方证明文件
              </td>
            </tr>
            <tr>
              <td>
                （3）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （4）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                实体剧本售卖
              </td>
              <td>
                （1）《网络出版服务许可证》
              </td>
            </tr>
            <tr>
              <td>
                （2）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （3）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                其他
              </td>
              <td>
                （1）ICP备案或《增值电信业务经营许可证》
              </td>
            </tr>
            <tr>
              <td>
                （2）《计算机软件著作权证书》、《APP电子版权证书》或《软件著作权认证证书》（三者选一）
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
strong{
  font-weight: 600;
}
td {
  line-height: 160%;
}
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      color: #333333;
    }
    .span1 {
      color: red;
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.table {
  margin-top: 30px;
}
table {
  width: 100%;
  border-collapse: collapse;
  th {
    height: 50px;

    font-size: 16px;
    color: #333333;
    line-height: 50px;
    text-align: left;
    padding-left: 20px;
    border: 1px;
  }
  td {
    // height: 50px;
    padding: 20px;
    padding-left: 10px;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.85);
  }
  .span2 {
    color: skyblue;
    cursor: pointer;
  }
}
</style>
