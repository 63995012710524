<template>
  <section class="big_data">
    <div class="body-wrap">
      <div class="main-body">
        <div class="url-navigation">
          <span class="back" @click="$router.back()">返回上一页</span> |
          <span
            class="current-page"
            @click="$router.replace('/appManage/service/data')"
            >数据服务</span
          >
        </div>
        <p class="top-tip">
          由于部分设备数据回传存在滞后，下载量数据存在3天左右的延时
        </p>
        <div class="data-display">
          <h3>数据总览</h3>
          <div class="table">
            <div class="table-title">
              <div>应用名</div>
              <div>
                昨日下载
                <img src="@/assets/img/service-intro.png" alt="" />
                <span>该应用昨日净下载量</span>
              </div>
              <div>
                最近7天下载
                <img src="@/assets/img/service-intro.png" alt="" />
                <span class="fix-position1">该应用近7天的净下载量</span>
              </div>
              <div>
                最近30天下载
                <img src="@/assets/img/service-intro.png" alt="" />
                <span class="fix-position2">该应用近30天的净下载量</span>
              </div>
              <div>操作</div>
            </div>
            <template v-if="serviceData.length > 0">
              <div
                v-for="item in serviceData"
                :key="item.apkPackageName"
                class="table-body"
              >
                <div>{{ item.apkName }}</div>
                <div>{{ item.dayCount }}</div>
                <div>{{ item.sevenDayCount }}</div>
                <div>{{ item.thirtyDayCount }}</div>
                <div @click="toDetail(item)">详情数据</div>
              </div>
            </template>
            <el-empty v-else></el-empty>
          </div>
          <el-pagination
            class="pagination"
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BigData',
  data() {
    return {
      userInfo: {},
      // 查询结果总数
      totalCount: 0,
      // 页数
      pageNum: 1,
      // 每页显示条数，
      pageSize: 10,
      // 当前显示数据
      serviceData: []
    };
  },
  computed: {
    // 计算当前显示数据在所有数据的开始索引 40   3    20
    startIndex() {
      return this.pageSize * (this.pageNum - 1);
    },
    endIndex() {
      if (this.pageSize * this.pageNum - 1 > this.totalCount - 1) {
        return this.totalCount - 1;
      }
      return this.pageSize * this.pageNum - 1;
    }
  },
  watch: {
    pageSize() {
      let maxPageNum = Math.ceil(this.totalCount / this.pageSize);
      if (this.pageNum > maxPageNum) {
        this.pageNum = maxPageNum;
      }
      this.getData();
    }
  },
  mounted() {
    // 权限鉴定
    this.$axios.get('/appManage/getCooperationSwitchShow').then(res => {
      if (!res.data.data[0].serviceSwitch) {
        // 无权限触发404
        this.$router.replace('/noPermisionForbiden403');
      }
    });
    // 获取存储用户accountId
    this.$message({
      message: '数据加载中...',
      duration: 0
    });
    this.$axios('/userManage/getUserInfo', {
      method: 'POST'
    })
      .then(res => {
        this.userInfo.accountId = res.data.data.accountId;
        // 获取数据
        this.$axios
          .post('/appManage/getStatisticsOverview', {
            accountId: res.data.data.accountId
          })
          .then(res => {
            this.$message.closeAll();
            this.ALLQUERYDATA = res.data.data.list;
            this.totalCount = res.data.data.totalCount;
            this.getData();
            this.$forceUpdate();
          })
          .catch(_ => {
            this.$message.closeAll();
            this.$message({
              message: '数据加载失败',
              duration: 1000
            });
          });
      })
      .catch(e => {
        this.$message.closeAll();
        this.$message({
          message: '数据加载失败',
          duration: 1000
        });
      });
  },
  methods: {
    // 改变每页显示数据条数
    handleSizeChange(newPageSize) {
      console.log(newPageSize);
      this.pageSize = newPageSize;
    },
    // 根据页码获取数据
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.getData();
    },
    // 从计算的索引替换当前显示数据
    getData() {
      this.serviceData = this.ALLQUERYDATA.slice(
        this.startIndex,
        this.endIndex + 1
      );
    },
    toDetail(apkInfo) {
      window.localStorage.setItem('currentApkDetail', JSON.stringify(apkInfo));
      this.$router.push(`/big_data_detail`);
    }
  }
};
</script>

<style lang="less" scoped>
.body-wrap {
  background: #f2f2f2;
  .main-body {
    width: 100%;
    min-height: 900px;
    position: relative;
    clear: both;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 1200px;
    margin: 0 auto;
    .url-navigation {
      font-size: 16px;
      padding-bottom: 20px;
      .back {
        color: #666;
        cursor: pointer;
      }
      .current-page {
        color: blue;
        cursor: pointer;
      }
    }
    .top-tip {
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #aaa;
    }
    .data-display {
      background: #fff;
      h3 {
        position: relative;
        font-size: 16px;
        padding-top: 29px;
        padding-left: 42px;
        padding-bottom: 35px;
        &::before {
          content: '';
          position: absolute;
          top: 32px;
          left: 31px;
          width: 4px;
          height: 16px;
          border-radius: 3px;
          background: #3cb1ff;
        }
      }
      .table {
        width: 1115px;
        margin: 0 auto;
        text-align: center;
        border-radius: 5px;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        .table-title {
          display: flex;
          font-size: 18px;
          background: #f0f4f5;
          div {
            position: relative;
            line-height: 70px;
            flex: 0 0 auto;
            width: 20%;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            img {
              vertical-align: middle;
              margin-top: -5px;
            }
            span {
              opacity: 0;
              position: absolute;
              top: -12px;
              left: 112px;
              width: 180px;
              height: 31px;
              line-height: 31px;
              font-size: 14px;
              color: #fff;
              background: url('~@/assets/img/service-intro-bg.png') no-repeat
                center;
              background-size: contain;
              transition: all 1s;
              &.fix-position1 {
                left: 127px;
              }
              &.fix-position2 {
                left: 133px;
              }
            }
            img:hover + span {
              opacity: 1;
            }
          }
        }
        .table-body {
          display: flex;
          font-size: 14px;
          div {
            display: block;
            flex: 0 0 auto;
            width: 20%;
            line-height: 49px;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            &:last-of-type {
              color: #3cb1ff;
              cursor: pointer;
              &:hover {
                color: #3991f4;
              }
            }
          }
        }
      }
      .pagination {
        width: 1115px;
        margin: 0 auto;
        padding: 21px 0 29px;
        text-align: right;
      }
    }
  }
}
</style>
