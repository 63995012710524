<template>
  <section class="login">
    <div class="main-body">
      <div class="login-box">
        <p class="login-title">开发者用户登录</p>
        <div class="input-box">
          <form>
            <div class="account-box">
              <input
                v-model="account"
                v-focus="focusAccountState"
                type="text"
                placeholder="用户名/邮箱"
                class="input input-long"
                :class="errorAcount ? 'red-line' : 'default-line'"
                maxlength="85"
                @keyup="accountInput('account')"
              />
              <!-- <span class="clear-icon" v-if="clearAccountShow" @click="clearContent('account')">X</span> -->
              <img
                v-if="clearAccountShow"
                src="@/assets/img/delete-icon.png"
                alt="删除输入信息"
                class="clear-icon"
                @click="clearContent('account')"
              />
              <div v-if="errorAcount" class="error-desc">请输入用户名</div>
            </div>
            <div class="psd-box">
              <input
                v-model="password"
                v-focus="focusPsdState"
                type="password"
                maxlength="85"
                placeholder="密码"
                class="input input-long"
                :class="errorPsd ? 'red-line' : 'default-line'"
                @keyup="accountInput('psd')"
              />
              <!-- <span class="clear-icon" v-if="clearPsdShow" @click="clearContent('psd')">X</span> -->
              <img
                v-if="clearPsdShow"
                src="@/assets/img/delete-icon.png"
                alt="删除输入信息"
                class="clear-icon"
                @click="clearContent('psd')"
              />
              <div v-if="errorPsd" class="error-desc">请输入密码</div>
            </div>
            <div class="code-box">
              <input
                v-model="code"
                v-focus="focusCodeState"
                type="text"
                placeholder="请输入验证码"
                maxlength="6"
                class="input input-short"
                :class="errorCode ? 'red-line' : 'default-line'"
                @keyup.enter="submit"
                @keyup="accountInput('code')"
              />
              <!-- <span class="clear-icon" v-if="clearCodeShow" @click="clearContent('code')">X</span> -->
              <img
                v-if="clearCodeShow"
                src="@/assets/img/delete-icon.png"
                alt="删除输入信息"
                class="clear-icon"
                @click="clearContent('code')"
              />
              <img
                :src="codeSrc"
                alt="验证码"
                class="change-code-btn"
                @click="changeCode"
              />
              <!-- <span class="change-code-btn" @click="changeCode" v-html="codeSrc"></span> -->
              <div v-if="errorCode" class="error-desc">请输入验证码</div>
            </div>
          </form>
          <div class="operate-box">
            <span
              class="checked"
              :class="{ no: unCheck }"
              @click="selectDays"
            ></span>
            <span class="auto-days">一周内登录</span>
            <a href="#" class="forget-psd" @click="$router.push('/recover')"
              >忘记密码？</a
            >
          </div>
          <div class="go-login-btn" @click="submit">立即登录</div>
          <div class="register-box" @click="register">马上注册</div>
        </div>
        <!--                 <div class="toast-box" v-if="toastShow">
                    <img src="@/assets/img/error-icon.png" alt="提示图标" class="toast-icon">
                    <span>发送成功</span>
                </div> -->
        <div v-if="toastShow1" class="toast-box1">
          <img
            src="@/assets/img/toast-icon.png"
            alt="提示图标"
            class="toast-icon"
          />
          <span>{{ toastSuccessShow }}</span>
        </div>
        <div v-show="toastShow2" class="toast-box2">
          <img
            src="@/assets/img/error-icon.png"
            alt="提示图标"
            class="toast-icon"
          />
          <span>{{ toastErrShow }}</span>
        </div>
      </div>
    </div>
    <Footer />
  </section>
</template>
<script>
/* eslint-disable no-undef */
import { ToCDB } from '@/plugins/util';
import { Md5 } from '@/plugins/md5';
export default {
  name: 'Login',
  directives: {
    focus: {
      update(el, { value }) {
        if (value) {
          el.focus();
        }
      }
    }
  },
  data() {
    return {
      account: '',
      password: '',
      code: '',
      unCheck: true,
      clearAccountShow: false,
      clearPsdShow: false,
      clearCodeShow: false,
      focusAccountState: false,
      focusPsdState: false,
      focusCodeState: false,
      errorAcount: false,
      errorPsd: false,
      errorCode: false,
      toastShow1: false,
      toastShow2: false,
      toastErrShow: '验证码输入错误！',
      toastSuccessShow: '登录成功，正在跳转！',
      redisKey: '',
      codeSrc: ''
    };
  },
  computed: {},
  mounted() {
    this.changeCode();
  },
  methods: {
    /*
      获取验证码
    */
    changeCode() {
      this.redisKey = location.hostname + new Date().getTime();
      this.codeSrc = `${this.$axios.defaults.baseURL}userManage/getImageVerifyCode?redisKey=${this.redisKey}&verifyCodeType=2`;
    },
    selectDays() {
      this.unCheck = this.unCheck ? false : true;
    },
    /*
      立即登录
    */
    submit() {
      var vm = this;
      this.errorAcount = this.account ? false : true;
      this.errorPsd = this.password ? false : true;
      this.errorCode = this.code ? false : true;
      this.$axios
        .post(`userManage/login`, {
          password: Md5.hex_md5(ToCDB($.trim(this.password))),
          redisKey: this.redisKey,
          userName: ToCDB(this.account),
          verifyCode: ToCDB(this.code),
          loginType: this.unCheck ? 0 : 1
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            this.$cookie.setCookie(
              'token',
              encodeURIComponent(data.data.token),
              this.unCheck ? 1 : 7
            );
            this.$cookie.setCookie(
              'userName',
              encodeURIComponent(data.data.userName),
              this.unCheck ? 1 : 7
            );
            this.$cookie.setCookie(
              'accountId',
              encodeURIComponent(data.data.accountId),
              this.unCheck ? 1 : 7
            );
            this.$cookie.setCookie(
              'loginType',
              this.unCheck ? 30 : 7,
              this.unCheck ? 1 : 7
            );
            vm.toastShow1 = true;
            vm.toastShow2 = false;
            if (!data.data.completeCode) {
              setTimeout(() => {
                vm.toastShow1 = false;
                vm.$router.push('/account_submit');
              }, 1000);
            } else {
              setTimeout(() => {
                vm.toastShow1 = false;
                vm.$router.push('/');
              }, 1000);
            }
          }
          if (data.stateCode === '803') {
            vm.toastShow1 = false;
            vm.toastShow2 = true;
            vm.toastErrShow = '用户名或密码不正确';
            setTimeout(() => {
              vm.toastShow2 = false;
            }, 3000);
          }
          if (data.stateCode === '104') {
            vm.toastShow1 = false;
            vm.toastShow2 = true;
            vm.toastErrShow = '验证码输入错误！';
            setTimeout(() => {
              vm.toastShow2 = false;
            }, 3000);
          }
        })
        .catch(() => {
          vm.toastShow1 = false;
          vm.toastShow2 = true;
          vm.toastErrShow = '网络错误，请稍后重试！';
          setTimeout(() => {
            vm.toastShow2 = false;
          }, 3000);
        });
      this.changeCode();
    },
    register() {
      this.$router.push('/register');
    },
    accountInput(type) {
      switch (type) {
        case 'account':
          this.focusPsdState = false;
          this.focusCodeState = false;
          if (this.account.length) {
            this.clearAccountShow = true;
            this.errorAcount = false;
          } else {
            this.clearAccountShow = false;
            this.errorAcount = true;
          }
          break;
        case 'psd':
          this.focusAccountState = false;
          this.focusCodeState = false;
          if (this.password.length) {
            this.clearPsdShow = true;
            this.errorPsd = false;
          } else {
            this.clearPsdShow = false;
            this.errorPsd = true;
          }
          break;
        case 'code':
          this.focusAccountState = false;
          this.focusPsdState = false;
          if (this.code.length) {
            this.clearCodeShow = true;
            this.errorCode = false;
          } else {
            this.clearCodeShow = false;
            this.errorCode = true;
          }
          break;
      }
    },
    clearContent(type) {
      switch (type) {
        case 'account':
          if (!this.account.length) return;
          this.account = '';
          this.clearAccountShow = false;
          this.focusAccountState = true;
          break;
        case 'psd':
          if (!this.password.length) return;
          this.password = '';
          this.clearPsdShow = false;
          this.focusPsdState = true;
          break;
        case 'code':
          if (!this.code.length) return;
          this.code = '';
          this.clearCodeShow = false;
          this.focusCodeState = true;
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  padding-top: 82px;
  .header {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    height: 82px;
    background-color: #0f1226;
    padding: 0 100px 0 74px;
    box-shadow: 0px 10px 10px 0px rgba(15, 18, 38, 0.2);
    .logo {
      cursor: pointer;
      float: left;
      color: #fff;
      span {
        position: relative;
        display: inline-block;
        vertical-align: 17px;
        font-size: 16px;
        opacity: 0.85;
        &:before {
          content: '';
          position: absolute;
          left: -8px;
          top: 6px;
          width: 1px;
          height: 12px;
          background-color: #fff;
        }
      }
    }
    .nav {
      float: left;
      color: #efefef;
      font-size: 15px;
      overflow: hidden;
      li {
        position: relative;
        margin: 0 35px;
        text-align: center;
        display: inline-block;
        line-height: 82px;
        cursor: pointer;
        transition: color 0.3s linear;
        &:hover {
          color: #3e8aff;
        }
        &.active {
          color: #3e8aff;
          &:after {
            content: '';
            position: absolute;
            left: -15%;
            bottom: 0;
            width: 130%;
            height: 4px;
            border-radius: 4px;
            background-color: #3e8aff;
          }
        }
      }
    }
    .login {
      height: 82px;
      float: right;
      .register {
        margin-top: 25px;
        height: 30px;
        display: inline-block;
        span {
          display: inline-block;
          width: 70px;
          height: 30px;
          line-height: 30px;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          transition: all 0.3s linear;
          margin-left: 20px;
        }
        span:first-child {
          color: #147cea;
          border: 1px solid rgba(20, 124, 234, 1);
          &:hover {
            background-color: #147cea;
            color: #fff;
          }
        }
        span:last-child {
          color: #fff;
          background: rgba(20, 122, 231, 1);
          &:hover {
            color: #147cea;
            background-color: #fff;
            border: 1px solid rgba(20, 124, 234, 1);
          }
        }
      }
      .user {
        line-height: 82px;
        color: #fff;
        & > div {
          display: inline-block;
          margin-left: 20px;
          cursor: pointer;
        }
        img {
          transform: scale(0.8);
          vertical-align: middle;
          transition: all 0.3s linear;
        }
        .user-detail {
          position: relative;
          .user-control {
            position: absolute;
            top: 100%;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            border-radius: 5px;
            text-align: center;
            line-height: 60px;
            box-shadow: 0 0 5px 1px #ccc;
            height: 0px;
            opacity: 0;
            width: 133px;
            transition: height 0.3s linear;
            & > div {
              cursor: pointer;
              transition: all 0.3s linear;
              &:first-child {
                position: relative;
                &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                }
              }
              &:hover {
                background-color: #000;
              }
              height: 60px;
            }
          }
          &:hover {
            .user-control {
              height: 120px;
              opacity: 1;
            }
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

.main-body {
  background: #fafafa;
  background: url('~@/assets/img/bg.jpg');
  height: 900px;
  padding-top: 118px;
  .login-box {
    background: #ffffff;
    width: 600px;
    height: 559px;
    margin: 0 auto;
    border-radius: 5px;
    form {
      margin-top: 30px;
    }
    .login-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      padding-top: 24px;
      padding-bottom: 20px;
      color: #545454;
      border-bottom: 1px solid #c7c7c7;
    }
  }
  .input-box {
    padding-top: 10px;
    text-align: center;
    .account-box,
    .psd-box {
      // margin-left: 112px;
      margin-bottom: 10px;
      position: relative;
      .clear-icon {
        position: absolute;
        left: 76%;
        top: 48%;
        cursor: pointer;
      }
    }
    .code-box {
      position: relative; // margin-left: 112px;
      .clear-icon {
        position: absolute;
        left: 49.4%;
        top: 47%;
        cursor: pointer;
      }
    }
  }
  .input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    width: 352px;
    outline: none;
    -webkit-appearance: none;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 8px;
    color: #666666;
    font-size: 14px;
    padding: 10px 36px 10px 10px;
    overflow: hidden;
    &.input-short {
      width: 190px;
    }
  }
  .red-line {
    border: 1px solid red;
  }

  .default-line {
    border: 1px solid #c7c7c7;
  }
  input:focus {
    border: 1px solid #3cb1ff;
  }
  .change-code-btn {
    cursor: pointer;
    margin-left: 20px;
    width: 140px;
    height: 40px;
    vertical-align: middle;
    border-radius: 4px;
  }
}

.operate-box {
  width: 352px;
  margin-top: 26px;
  margin-left: 126px;
  text-align: left;
  .checked {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url('~@/assets/img/checked.png');
    margin-bottom: -2px;
    cursor: pointer;
  }
  .checked.no {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url('~@/assets/img/un-check.png');
    margin-bottom: -2px;
    cursor: pointer;
  }
  .auto-days {
    display: inline-block;
    color: #3c3c3c;
    font-size: 14px;
    margin-left: 4px;
    text-align: left;
  }
  .forget-psd {
    color: #0099ff;
    font-size: 14px;
    float: right;
    text-decoration: none;
    .link-addr;
  }
}
.go-login-btn {
  background: #3cb1ff;
  width: 352px;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin-top: 26px;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 124px;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.go-login-btn:hover {
  background: #2ea5f5;
}

.register-box {
  color: #0099ff;
  text-align: right;
  margin-top: 20px;
  cursor: pointer;
  width: 352px;
  margin-left: 124px;
  .link-addr;
}

.error-desc {
  margin-left: 127px;
  color: #ff7477;
  font-size: 12px;
  position: absolute;
}

.modal-box {
  background: #e37e8d;
  color: #ffffff;
  width: 300px;
  height: 36px;
  line-height: 38px;
  text-align: center;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}

.toast-box1 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #28da99;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #dff9f0;
  margin: 20px 144px 0;
  .toast-icon {
    margin-right: 6px;
  }
}

.toast-box2 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  border: 1px solid red;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #feeaeb;
  margin: 20px 144px 0;
  .toast-icon {
    margin-right: 6px;
    margin-bottom: -2px;
  }
}

.link-addr() {
  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: #277db7;
  }
}
</style>
