<template>
  <div class="container">
    <h2>BBK帐号授权登录接入文档</h2>
    <h3>一、服务介绍</h3>
    <p>
      BBK帐号OAuth2.0授权登录让BBK帐号用户可以使用BBK帐号身份登录第三方应用，在BBK用户授权登录已接入BBK帐号OAuth2.0的第三方应用后，第三方可以获取到用户的接口调用凭证（access_token），通过access_token可以进行BBK帐号开放接口调用，从而可以实现获取BBK帐号基本开放信息。
    </p>
    <h3>二、Android服务接入步骤</h3>
    <h4>step1 引包</h4>
    <p>引入bfc-account-auth-client.aar</p>
    <h4>step2 初始化BfcAccountAuth</h4>
    <div>
      <pre>
BfcAccountAuth bfcAccountAuth = new BfcAccountAuth.Builder()
    .setDebug()            // 非必须设置，true:更多日志打印，默认false
    .setDebugUrl()         // 非必须设置，true:测试环境，默认false
    .setHost()             // 非必须设置，指定请求授权host，默认空
    .build(getApplicationContext());
            </pre
      >
      <p style="line-height: 30px">AndroidManifest.xml加入权限</p>
      <pre>
                &lt;uses-permission android:name="android.permission.ACCESS_NETWORK_STATE" /&gt;
    </pre
      >
    </div>
    <h4 id="step3">step3 绑定服务&添加响应监听</h4>
    <div>
      <pre>
bfcAccountAuth.bindService(context, callBack, connectionListener);
private final BfcAccountAuth.CallBack callBack = new BfcAccountAuth.CallBack() {
    @Override
    public void onResponse(int type, String request, String response) {
        if(TextUtils.isEmpty(response)){
            return;
        }
        switch (type){
            case 1:
                Response&lt;AuthorizeResponse&gt; authorizeResponse = new Gson().fromJson(response, Response&lt;AuthorizeResponse&gt;.class);
                if(authorizeResponse.isSuccess()){
                    // TODO get the BBKAuth code from Response and do next operation
                }else {
                    // TODO errorcode
                }
                break;
            case 2:
                Response&lt;TokenResponse&gt; tokenResponse = new Gson().fromJson(response, Response&lt;TokenResponse&gt;.class);
                if(tokenResponse.isSuccess()){
                    // TODO get the accessToken from Response and do next operation
                }else {
                    // TODO errorcode
                }
                break;
            case 3:
                Response&lt;RefreshTokenResponse&gt; refTokenResponse = new Gson().fromJson(response, Response&lt;RefreshTokenResponse&gt;.class);
                if(refTokenResponse.isSuccess()){
                    // TODO get the accessToken from Response and do next operation
                }else {
                    // TODO errorcode
                }
                break;
            case 4:
                Response&lt;OpenIdResponse&gt; openIdResponse = new Gson().fromJson(response, Response&lt;OpenIdResponse&gt;.class);
                if(openIdResponse.isSuccess()){
                    // TODO get the openId from Response
                }else {
                    // TODO errorcode
                }
                break;
            case 5:
                Response&lt;UserInfoResponse&gt; userInfo = new Gson().fromJson(response, Response&lt;UserInfoResponse&gt;.class);
                if(userInfo.isSuccess()){
                    // TODO get the user info from Response
                }else {
                    // TODO errorcode
                }
                break;
            default:
                break;
        }
    }
};

private BfcAccountAuth.ConnectionListener connectionListener = new BfcAccountAuth.ConnectionListener() {
    @Override
    public void onConnected() {
        // TODO service connected
    }

    @Override
    public void onDisconnected() {
        // TODO service disconnected
    }
};
            </pre
      >
      <p class="desc">
        上面演示代码中，Gson只做演示，SDK没有集成Gson，可以替换成您项目中的反序列化工具。
      </p>
      <p
        style="font-size: 14px; color: #000; line-height: 30px; padding-left: 0"
      >
        type值说明:
      </p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr>
          <th>常量值</th>
          <th>说明</th>
        </tr>
        <tr>
          <td>1</td>
          <td>
            <a href="#step4">授权请求</a>
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>
            <a href="#step5">获取token请求</a>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>
            <a href="#step8">刷新token请求</a>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>
            <a href="#step6">获取openId请求</a>
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>
            <a href="#step7">获取用户信息请求</a>
          </td>
        </tr>
      </table>
    </div>
    <h4 id="step4">step4 授权</h4>
    <div>
      <pre>
bfcAccountAuth.enqueue(context, new AuthorizeRequest.Builder()
    .setClientId()
    .setClientKey()
    .setClientKeyArgument()
    .setScope()
    .setState()
    .build(context)); 
            </pre
      >

      <p class="desc">
        授权返回请查看
        <a href="#step3">绑定服务&添加响应监听</a>
      </p>
      <p>数据结构：AuthorizeRequest</p>
      <p>请求授权数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>clientId</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一id</td>
        </tr>
        <tr>
          <td>clientKey</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一key</td>
        </tr>
        <tr>
          <td>clientKeyArgument</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的密钥</td>
        </tr>
        <tr>
          <td>scope</td>
          <td>String</td>
          <td>是</td>
          <td>
            调标请求用户的权限范围，请参考
            <a href="#access-list">权限列表</a>
          </td>
        </tr>
        <tr>
          <td>state</td>
          <td>String</td>
          <td>是</td>
          <td>
            用于保持请求和回调的状态，授权服务器在回调时原封不动传回，提供给客户端防止CSRF攻击
          </td>
        </tr>
      </table>

      <p>数据结构：Response</p>
      <p>授权返回数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>stateCode</td>
          <td>String</td>
          <td>是</td>
          <td>
            返回状态码，0：成功；异常状态码请查看
            <a href="#state-code">状态码列表</a>
          </td>
        </tr>
        <tr>
          <td>stateInfo</td>
          <td>String</td>
          <td>是</td>
          <td>返回描述</td>
        </tr>
        <tr>
          <td>data</td>
          <td>String</td>
          <td>否</td>
          <td>授权成功时返回，授权数据结构体</td>
        </tr>
        <tr>
          <td>code</td>
          <td>String</td>
          <td>否</td>
          <td>授权成功时返回，授权码</td>
        </tr>
        <tr>
          <td>expiresIn</td>
          <td>String</td>
          <td>否</td>
          <td>授权成功时返回，code的有效期，以秒为单位，默认10分钟</td>
        </tr>
      </table>
    </div>
    <h4 id="step5">step5 通过授权码获取accessToken</h4>
    <div>
      <pre>
bfcAccountAuth.enqueue(context, new TokenRequest.Builder()
    .setClientId()
    .setClientKey()
    .setClientKeyArgument()
    .setCode()
    .build(context));           
        </pre
      >
      <p class="desc">
        授权返回请查看
        <a href="#step3">绑定服务&添加响应监听</a>
      </p>
      <p>数据结构：TokenRequest</p>
      <p>获取accessToken数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>clientId</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一id</td>
        </tr>
        <tr>
          <td>clientKey</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一key</td>
        </tr>
        <tr>
          <td>clientKeyArgument</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的密钥</td>
        </tr>
        <tr>
          <td>code</td>
          <td>String</td>
          <td>是</td>
          <td>
            通过
            <a href="#step4">授权请求接口</a>获得的授权码code
          </td>
        </tr>
      </table>

      <p>数据结构：Response</p>
      <p>获取accessToken返回数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>stateCode</td>
          <td>String</td>
          <td>是</td>
          <td>
            返回状态码，0：成功；异常状态码请查看
            <a href="#state-code">状态码列表</a>
          </td>
        </tr>
        <tr>
          <td>stateInfo</td>
          <td>String</td>
          <td>是</td>
          <td>返回描述</td>
        </tr>
        <tr>
          <td>data</td>
          <td>String</td>
          <td>否</td>
          <td>授权成功时返回，授权数据结构体</td>
        </tr>
        <tr>
          <td>accessToken</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，Access Token</td>
        </tr>
        <tr>
          <td>expiresIn</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，Access Token的有效期，以秒为单位</td>
        </tr>
        <tr>
          <td>refreshToken</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，用于刷新Access Token 的 Refresh Token</td>
        </tr>
        <tr>
          <td>scope</td>
          <td>String</td>
          <td>否</td>
          <td>
            获取成功时返回，用户最终同意的授权范围。对应权限范围参考
            <a href="#access-list">权限列表</a>
          </td>
        </tr>
      </table>
    </div>
    <h4 id="step6">step6 通过accessToken获取openId</h4>
    <div>
      <pre>
bfcAccountAuth.enqueue(context, new OpenIdRequest.Builder()
    .setClientId()
    .setClientKey()
    .setClientKeyArgument()
    .setAccessToken()
    .build(context));
            </pre
      >
      <p class="desc">
        授权返回请查看
        <a href="#step3">绑定服务&添加响应监听</a>
      </p>
      <p>数据结构：OpenIdRequest</p>
      <p>获取openId数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>clientId</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一id</td>
        </tr>
        <tr>
          <td>clientKey</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一key</td>
        </tr>
        <tr>
          <td>clientKeyArgument</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的密钥</td>
        </tr>
        <tr>
          <td>accessToken</td>
          <td>String</td>
          <td>是</td>
          <td>
            通过
            <a href="#step5">获取token请求接口</a>获得的accessToken
          </td>
        </tr>
      </table>

      <p>数据结构：Response</p>
      <p>获取openId返回数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>stateCode</td>
          <td>String</td>
          <td>是</td>
          <td>
            返回状态码，0：成功；异常状态码请查看
            <a href="#state-code">状态码列表</a>
          </td>
        </tr>
        <tr>
          <td>stateInfo</td>
          <td>String</td>
          <td>是</td>
          <td>返回描述</td>
        </tr>
        <tr>
          <td>data</td>
          <td>String</td>
          <td>否</td>
          <td>授权成功时返回，授权数据结构体</td>
        </tr>
        <tr>
          <td>openId</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，openId</td>
        </tr>
        <tr>
          <td>unionId</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，unionId</td>
        </tr>
      </table>
    </div>
    <h4 id="step7">step7 通过accessToken获取用户基本信息</h4>
    <div>
      <pre>
bfcAccountAuth.enqueue(context, new UserInfoRequest.Builder()
    .setClientId()
    .setClientKey()
    .setClientKeyArgument()
    .setAccessToken()
    .setRequestUri()
    .build(context));  
            </pre
      >
      <p class="desc">
        授权返回请查看
        <a href="#step3">绑定服务&添加响应监听</a>
      </p>
      <p>数据结构：UserInfoRequest</p>
      <p>获取用户基本信息数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>clientId</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一id</td>
        </tr>
        <tr>
          <td>clientKey</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一key</td>
        </tr>
        <tr>
          <td>clientKeyArgument</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的密钥</td>
        </tr>
        <tr>
          <td>accessToken</td>
          <td>String</td>
          <td>是</td>
          <td>
            通过
            <a href="#step5">获取token请求接口</a>获得的accessToken
          </td>
        </tr>
        <tr>
          <td>requestUri</td>
          <td>String</td>
          <td>是</td>
          <td>
            根据
            <a href="#step5">获取token请求接口</a
            >中用户最终授权范围scope，获取对应用户基本信息，请参考
            <a href="#access-list">权限列表</a>
          </td>
        </tr>
      </table>

      <p>数据结构：Response</p>
      <p>获取用户基本信息返回数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>stateCode</td>
          <td>String</td>
          <td>是</td>
          <td>
            返回状态码，0：成功；异常状态码请查看
            <a href="#state-code">状态码列表</a>
          </td>
        </tr>
        <tr>
          <td>stateInfo</td>
          <td>String</td>
          <td>是</td>
          <td>返回描述</td>
        </tr>
        <tr>
          <td>data</td>
          <td>String</td>
          <td>否</td>
          <td>授权成功时返回，授权数据结构体</td>
        </tr>
        <tr>
          <td>nickName</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，昵称</td>
        </tr>
        <tr>
          <td>pictureUrl</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，头像</td>
        </tr>
      </table>
    </div>
    <h4 id="step8">step8 刷新 Access Token</h4>
    <div>
      <pre>
bfcAccountAuth.enqueue(context, new RefreshTokenRequest.Builder()
    .setClientId()
    .setClientKey()
    .setClientKeyArgument()
    .setRefreshToken()
    .build(context));                
            </pre
      >
      <p class="desc">
        授权返回请查看
        <a href="#step3">绑定服务&添加响应监听</a>
      </p>
      <p>数据结构：RefreshTokenRequest</p>
      <p>刷新 Access Token数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>clientId</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一id</td>
        </tr>
        <tr>
          <td>clientKey</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的唯一key</td>
        </tr>
        <tr>
          <td>clientKeyArgument</td>
          <td>String</td>
          <td>是</td>
          <td>BBK开放平台分配给开发者的密钥</td>
        </tr>
        <tr>
          <td>refreshToken</td>
          <td>String</td>
          <td>是</td>
          <td>
            通过
            <a href="#step5">获取token请求接口</a>获得的refreshToken
          </td>
        </tr>
      </table>

      <p>数据结构：Response</p>
      <p>刷新 Access Token返回数据实体类</p>
      <p>参数说明</p>
      <table border="1" cellpadding="0" cellspacing="0">
        <tr style="font-weight: bold">
          <td>字段名</td>
          <td>类型</td>
          <td>是否必须</td>
          <td>说明</td>
        </tr>
        <tr>
          <td>stateCode</td>
          <td>String</td>
          <td>是</td>
          <td>
            返回状态码，0：成功；异常状态码请查看
            <a href="#state-code">状态码列表</a>
          </td>
        </tr>
        <tr>
          <td>stateInfo</td>
          <td>String</td>
          <td>是</td>
          <td>返回描述</td>
        </tr>
        <tr>
          <td>data</td>
          <td>String</td>
          <td>否</td>
          <td>授权成功时返回，授权数据结构体</td>
        </tr>
        <tr>
          <td>accessToken</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，Access Token</td>
        </tr>
        <tr>
          <td>expiresIn</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，Access Token的有效期，以秒为单位</td>
        </tr>
        <tr>
          <td>refreshToken</td>
          <td>String</td>
          <td>否</td>
          <td>获取成功时返回，用于刷新Access Token 的 Refresh Token</td>
        </tr>
      </table>
    </div>
    <h4 id="step9">step9 解绑服务</h4>
    <div>
      <p>在使用完后请解绑服务</p>
      <pre>
bfcAccountAuth.unBindService(context);               
            </pre
      >
      <p>是否支持授权功能</p>
      <pre>
isSupport(context);                
            </pre
      >
      <p class="desc">
        true:支持，false:不支持。若不支持，需要更新个人中心版本。
      </p>
    </div>
    <h3 id="access-list">三、权限列表</h3>
    <table border="1" cellpadding="0" cellspacing="0">
      <tr>
        <th>权限范围</th>
        <th>scope</th>
        <th>requestUri</th>
      </tr>
      <tr>
        <td>获取昵称和头像</td>
        <td>BASE_USER_INFO</td>
        <td>getBaseInfo</td>
      </tr>
      <tr>
        <td>获取昵称、头像和年级</td>
        <td>USER_INFO_GRADE</td>
        <td>getGrade</td>
      </tr>
    </table>

    <h3>四、混淆</h3>
    <pre>
-keep class com.eebbk.bfc.account.auth.client.** {*;}            
        </pre
    >
    <h3 id="state-code">五、状态码列表</h3>
    <table border="1" cellpadding="0" cellspacing="0">
      <thead>
        <tr>
          <th align="left">状态码</th>
          <th align="left">含义</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left">0</td>
          <td align="left">成功</td>
        </tr>
        <tr>
          <td align="left">1</td>
          <td align="left">未知错误</td>
        </tr>
        <tr>
          <td align="left">1000</td>
          <td align="left">缺少参数 或 参数错误</td>
        </tr>
        <tr>
          <td align="left">1001</td>
          <td align="left">client_id 或 client_key 错误或不存在</td>
        </tr>
        <tr>
          <td align="left">1002</td>
          <td align="left">回调地址不合法</td>
        </tr>
        <tr>
          <td align="left">1003</td>
          <td align="left">access token过期</td>
        </tr>
        <tr>
          <td align="left">1004</td>
          <td align="left">
            refresh toke 过期 或 用户取消授权，请重新获取授权
          </td>
        </tr>
        <tr>
          <td align="left">1005</td>
          <td align="left">code过期，code是一次使用权限，且10分钟过期</td>
        </tr>
        <tr>
          <td align="left">1006</td>
          <td align="left">应用包名与clientId不匹配</td>
        </tr>
        <tr>
          <td align="left">1007</td>
          <td align="left">应用未通过审核</td>
        </tr>
        <tr>
          <td align="left">1008</td>
          <td align="left">申请的权限不存在</td>
        </tr>
        <tr>
          <td align="left">1009</td>
          <td align="left">code和clientId不匹配</td>
        </tr>
        <tr>
          <td align="left">1011</td>
          <td align="left">超出申请权限范围</td>
        </tr>
        <tr>
          <td align="left">1015</td>
          <td align="left">授权异常，请登录账号后再尝试</td>
        </tr>
        <tr>
          <td align="left">1999</td>
          <td align="left">请求个人中心数据失败</td>
        </tr>
        <tr>
          <td align="left">4000</td>
          <td align="left">请求参数异常</td>
        </tr>
        <tr>
          <td align="left">4001</td>
          <td align="left">授权失败</td>
        </tr>
        <tr>
          <td align="left">4002</td>
          <td align="left">未知请求类型</td>
        </tr>
        <tr>
          <td align="left">4003</td>
          <td align="left">未注册应用，请在BBK开发平台中注册</td>
        </tr>
        <tr>
          <td align="left">4004</td>
          <td align="left">登陆失败</td>
        </tr>
        <tr>
          <td align="left">4005</td>
          <td align="left">请检查clientId和clientKey是否正确</td>
        </tr>
        <tr>
          <td align="left">4999</td>
          <td align="left">未知错误</td>
        </tr>
        <tr>
          <td align="left">5000</td>
          <td align="left">
            当前个人中心版本不支持，需要升级到最新个人中心版本
          </td>
        </tr>
        <tr>
          <td align="left">5001</td>
          <td align="left">No network</td>
        </tr>
        <tr>
          <td align="left">5002</td>
          <td align="left">
            未绑定服务，请查看
            <a href="#step3">绑定服务</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  overflow: hidden;
  color: #999;
  & > img {
    margin: 10px 0;
    width: 100%;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
    color: #333333;
  }
  p {
    color: #666;
    font-size: 14px;
    line-height: 20px;
  }
  h3 {
    color: #333333;
  }
  h3 + p {
    padding-left: 2em;
  }
  h3 + div {
    & > p {
      padding-left: 2em;
    }
  }
  h4 {
    margin: 5px 0;
    padding-left: 1em;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: #333333;
  }
  h4 + div {
    & > p {
      padding-left: 2em;
      & + div {
        p {
          padding-left: 4em;
        }
      }
    }
  }
  h4 + p {
    padding-left: 3em;
  }
  pre {
    padding: 10px;
    font-size: 14px;
    background-color: #eee;
    color: #555;
    border-radius: 5px;
    overflow-x: auto;
  }
  .desc {
    margin: 30px 0;
    line-height: 50px;
    border-left: 10px solid #bbb;
    background-color: #eee;
    color: #333333;
    padding-left: 20px;
  }
  a {
    text-decoration: none;
    color: #108ee9;
    transition: color 0.3s ease;
    &:hover {
      color: #49a9ee;
    }
  }
  table {
    border-collapse: collapse;
    border-color: #ccc;
    th,
    td {
      padding: 10px;
      border: 1px solid;
    }
  }
}
</style>
