<template>
  <div class="main-body">
    <div class="content-area">
      <p class="content-title">完善账户信息</p>
      <div class="top-content">
        <div class="first-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">基本信息</span>
        </div>
        <!--<div class="div-1 common-style">-->
        <!--<span class="span-label">邮箱</span>-->
        <!--<span>{{userMail}}</span>-->
        <!--</div>-->
        <div class="div-2 common-style">
          <span class="span-label"
            >公司名称<span class="red-star">*</span></span
          >
          <input
            v-model="company"
            type="text"
            class="input"
            placeholder="请与营业执照保持一致"
            @blur="errCompany = company.trim() ? false : true"
          />
          <span v-if="errCompany" class="error-desc">请填写公司名称</span>
        </div>
        <div class="div-3 common-style">
          <span class="span-label"
            >显示名称<span class="red-star">*</span></span
          >
          <input
            v-model="showName"
            type="text"
            class="input"
            placeholder="您需要展示给用户的名称"
            @blur="errShowName = showName.trim() ? false : true"
          />
          <span v-if="errShowName" class="error-desc">请填写显示名称</span>
        </div>
        <div class="div-4 common-style">
          <span class="span-label">企业官网</span>
          <input v-model="website" type="text" class="input" />
        </div>
        <div class="second-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">资质信息</span>
        </div>
        <div class="div-5 common-style">
          <span class="span-label"
            >营业执照号<span class="red-star">*</span></span
          >
          <input
            v-model="businessLicense"
            type="text"
            style="width: 300px;"
            class="input"
            placeholder="15位注册号或18位社会信用代码"
            @blur="errBusinessLicense = businessLicense.trim() ? false : true"
          />
          <span v-if="errBusinessLicense" class="error-desc"
            >请填写营业执照号</span
          >
        </div>
        <div class="div-6 common-style">
          <span class="span-label">证件<span class="red-star">*</span></span>
          <span class="paper-desc"
            >营业执照或三证合一证件（关键信息必须清晰，格式支持：JPG/PNG，
            每张图片大小2M以内）</span
          >
        </div>
        <div class="div-7 common-style">
          <div
            class="upload-box"
            @mouseover="showDeleteImg('1')"
            @mouseout="isDelete1 = false"
          >
            <el-upload
              class="avatar-uploader"
              :headers="uploadHeader"
              :action="IMGUPLOADURL"
              :show-file-list="false"
              :on-success="
                res => {
                  return handleAvatarSuccess(res, '1');
                }
              "
              :before-upload="beforeAvatarUpload"
              :disabled="isUpload1"
            >
              <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <img
                v-if="isDelete1"
                src="@/assets/img/delete-img.png"
                alt=""
                class="delete-icon"
                @click="deleteImg('1')"
              />
            </el-upload>
          </div>
        </div>
        <div class="div-8 common-style">
          <span>营业执照</span>
          <!--  <span>开户许可证</span>
                        <span>税务登记证</span> -->
        </div>
        <div class="no-upload-box">
          <span v-if="errUpload1" class="span-1">请上传营业执照</span>
          <span v-if="errUpload2" class="span-2">请上传开户许可证</span>
          <span v-if="errUpload3" class="span-3">请上传税务登记证</span>
        </div>
        <div class="div-9 common-style">
          <span class="span-label"
            >法人身份信息<span class="red-star">*</span></span
          >
          <span class="paper-desc"
            >请上传企业法人本人手持身份证信息扫描件（必须与营业执照法人一致），需保证证件号码清晰可见，格式支持：JPG/PNG,每张图片大小2M以内</span
          >
        </div>
        <div class="div-10 common-style">
          <input
            v-model="identityCard"
            type="text"
            placeholder="请填写法人身份证号"
            class="input"
            @blur="checkCard"
          />
          <div v-if="errCard" class="error-div">请填写合法法人身份证号</div>
        </div>
        <div class="div-11 common-style">
          <div
            class="upload-box"
            @mouseover="showDeleteImg('4')"
            @mouseout="isDelete4 = false"
          >
            <el-upload
              class="avatar-uploader"
              :headers="uploadHeader"
              :action="IMGUPLOADURL"
              :show-file-list="false"
              :on-success="
                res => {
                  return handleAvatarSuccess(res, '4');
                }
              "
              :before-upload="beforeAvatarUpload"
              :disabled="isUpload4"
            >
              <img v-if="imageUrl4" :src="imageUrl4" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <img
                v-if="isDelete4"
                src="@/assets/img/delete-img.png"
                alt=""
                class="delete-icon"
                @click="deleteImg('4')"
              />
            </el-upload>
          </div>
          <span class="operate-desc">手持身份照正面</span>
          <div v-if="errUpload4" class="err-card">
            请上传身份证照
          </div>
          <div class="example-box">
            <div>示例</div>
            <img src="@/assets/img/example-img.png" alt="示例照片" />
            <div>证件文字和人需清晰</div>
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <div class="first-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">联系信息</span>
        </div>
        <div class="div-12 common-style">
          <span class="span-label"
            >联系人姓名<span class="red-star">*</span></span
          >
          <input
            v-model="linkman"
            type="text"
            class="input"
            placeholder="请填写真实姓名"
            @blur="checkNameInput"
          />
          <span v-if="errLinkman" class="error-desc">请填写联系人姓名</span>
        </div>
        <div class="div-13 common-style">
          <span class="span-label"
            >联系人邮箱<span class="red-star">*</span></span
          >
          <input
            v-model="contactMail"
            type="text"
            class="input"
            placeholder="请填写有效邮箱"
            @blur="checkMail"
          />
          <span v-if="errContactMail" class="error-desc">请填写有效邮箱</span>
          <div class="mail-prompt">
            （用于接受账户通知邮件，请确保邮箱真实有效）
          </div>
        </div>
        <div class="div-14 common-style">
          <span class="span-label">手机号<span class="red-star">*</span></span>
          <input
            v-model="mobilePhone"
            type="text"
            style="width: 334px;"
            class="input"
            placeholder="请填写有效手机号"
            @blur="checkMobile"
          />
          <span class="get-message-btn" @click="getPhoneCode">{{
            defaultCode
          }}</span>
          <span v-if="errMobilePhone" class="error-desc">请填写有效手机号</span>
          <div class="phone-prompt">（仅用于步步高平台在必要时联系开发者）</div>
          <input
            v-model="mobilePhoneVerifyCode"
            type="text"
            style="width: 334px;"
            class="input code-input"
            placeholder="短信验证码"
            @blur="
              errMobilePhoneVerifyCode = mobilePhoneVerifyCode.trim()
                ? false
                : true
            "
          />
          <span v-if="errMobilePhoneVerifyCode" class="error-desc"
            >请输入验证码</span
          >
        </div>
        <div class="div-15 common-style">
          <span class="span-label">QQ</span>
          <input
            v-model="qq"
            type="text"
            style="width: 334px;"
            class="input"
            placeholder="请填写QQ"
            @blur="checkQQ"
          />
          <span v-if="errQQ" class="error-desc">请填写正确的QQ</span>
        </div>
        <div class="div-16 common-style">
          <span class="span-label">联系地址</span>
          <area-cascader
            v-if="areaShow"
            v-model="provinces"
            style="display: inline-block;width:300px;"
            type="text"
            :data="$pca"
            @change="getArea"
          ></area-cascader>
          <!-- <span class="error-desc" v-if="errArea">请选择联系地址</span> -->
          <div class="full-address">
            <textarea
              id=""
              v-model="address"
              name=""
              class="text-area"
              placeholder="请填写详细地址"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-btn" @click="replenishUserInfo">提交审核</div>
  </div>
</template>

<script>
import { getCommonHeader } from '@/plugins/axios';
export default {
  name: 'AccountIndex',
  data() {
    return {
      signOutShow: false,
      imageUrl1: '',
      imageUrl2: '',
      imageUrl3: '',
      imageUrl4: '',
      isUpload1: false,
      isUpload2: false,
      isUpload3: false,
      isUpload4: false,
      isDelete1: false,
      isDelete2: false,
      isDelete3: false,
      isDelete4: false,
      areaShow: false,
      userName: '',
      userMail: '',
      company: '',
      showName: '',
      website: '',
      businessLicense: '',
      identityCard: '',
      linkman: '',
      contactMail: '',
      mobilePhone: '',
      mobilePhoneVerifyCode: '',
      qq: '',
      province: '',
      city: '',
      address: '',
      provinces: [],
      defaultCode: '获取短信验证码',
      errCompany: false,
      errShowName: false,
      errBusinessLicense: false,
      errUpload1: false,
      errUpload2: false,
      errUpload3: false,
      errUpload4: false,
      errCard: false,
      errLinkman: false,
      errContactMail: false,
      errMobilePhone: false,
      errQQ: false,
      errArea: false,
      errMobilePhoneVerifyCode: false,
      timer: null,
      uploadHeader: getCommonHeader(),

      IMGUPLOADURL: this.$axios.defaults.baseURL + 'upload/uploadImg'
    };
  },
  mounted() {
    this.userName = this.$cookie.getCookie('userName');
    this.getUserInfo();
    this.areaShow = true;
  },
  methods: {
    //检测姓名输入框是否合法
    checkNameInput() {
      let reg = /^([\u4E00-\u9FA5A-Za-z]){2,7}$/;
      if (reg.test(this.linkman)) {
        this.errLinkman = false;
      } else {
        this.errLinkman = true;
      }
    },
    //检测QQ
    checkQQ() {
      if (!this.qq) {
        this.errQQ = false;
        return;
      }
      let reg = /^[1-9][0-9]{4,11}$/;
      if (reg.test(this.qq)) {
        this.errQQ = false;
      } else {
        this.errQQ = true;
      }
    },
    //检测手机号
    checkMobile() {
      let reg = /^[1][0-9]{10}$/;
      if (reg.test(this.mobilePhone)) {
        this.errMobilePhone = false;
      } else {
        this.errMobilePhone = true;
      }
    },
    //检测邮箱是否合法
    checkMail() {
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (reg.test(this.contactMail)) {
        this.errContactMail = false;
      } else {
        this.errContactMail = true;
      }
    },
    //检测身份证
    checkCard() {
      let reg = /^(.|\n){1,30}$/;
      if (reg.test(this.identityCard)) {
        this.errCard = false;
      } else {
        this.errCard = true;
      }
    },
    getUserInfo() {
      this.$axios
        .post(`userManage/getUserInfo`, {
          object: this.$cookie.getCookie('accountId')
        })
        .then(res => {
          var data = res.data;
          this.userMail = data.data.email;
          if (data.stateCode === '0' && data.data.completeCode != 0) {
            this.$router.push('/');
          }
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
        })
        .catch(() => {});
    },
    getPhoneCode() {
      if (this.errMobilePhone || !this.mobilePhone) {
        this.$message({
          message: '请填写有效手机号码！',
          type: 'warning'
        });
        return;
      }
      if (this.defaultCode !== '获取短信验证码') {
        return;
      }
      this.defaultCode = '发送中';
      this.codeTimer();
      this.$axios
        .post(`userManage/sendPhoneCode?mobilePhone=${this.mobilePhone}`)
        .then(res => {
          var data = res.data;
          console.log(data);
          if (data.stateCode === '111') {
            this.$message.warning(data.stateInfo);
            clearInterval(this.timer);
            this.defaultCode = '获取短信验证码';
          }
        })
        .catch(() => {});
    },
    codeTimer() {
      let count = 60;
      this.timer = setInterval(() => {
        count--;
        if (count < 0) {
          clearInterval(this.timer);
          this.defaultCode = '获取短信验证码';
          return;
        }
        this.defaultCode = count + '秒';
      }, 1000);
    },
    replenishUserInfo() {
      this.errCompany = this.company.trim() ? false : true;
      this.errShowName = this.showName.trim() ? false : true;
      this.errBusinessLicense = this.businessLicense.trim() ? false : true;
      this.errUpload1 = this.imageUrl1 ? false : true;
      this.errUpload4 = this.imageUrl4 ? false : true;
      this.checkNameInput();
      this.checkCard();
      this.checkMail();
      this.checkMobile();
      this.errMobilePhoneVerifyCode = this.mobilePhoneVerifyCode.trim()
        ? false
        : true;
      if (
        this.errCompany ||
        this.errShowName ||
        this.errBusinessLicense ||
        this.errUpload1 ||
        this.errUpload4 ||
        this.errCard ||
        this.errLinkman ||
        this.errContactMail ||
        this.errMobilePhone ||
        this.errMobilePhoneVerifyCode
      ) {
        return;
      }
      if (this.qq) {
        if (this.errQQ) {
          return;
        }
      }
      this.$axios
        .post(`userManage/replenishUserInfo`, {
          accountId: this.$cookie.getCookie('accountId'),
          company: this.company,
          showName: this.showName,
          website: this.website,
          businessLicense: this.businessLicense,
          businessLicenseUrl: this.imageUrl1,
          identityCard: this.identityCard,
          identityCardUrl: this.imageUrl4,
          linkman: this.linkman,
          contactMail: this.contactMail,
          mobilePhone: this.mobilePhone,
          mobilePhoneVerifyCode: this.mobilePhoneVerifyCode,
          qq: this.qq,
          province: this.province,
          city: this.city,
          address: this.address
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            this.userMail = data.data.email;
            this.$message({
              message: '信息提交成功',
              type: 'success'
            });
            this.$router.push('/');
          } else {
            this.$router.push('/');
          }
        })
        .catch(() => {
          this.$router.push('/');
        });
    },
    handleAvatarSuccess(res, sort) {
      switch (sort) {
        case '1':
          this.imageUrl1 = res.data;
          this.isUpload1 = true;
          this.errUpload1 = false;
          break;
        case '2':
          this.imageUrl2 = res.data;
          this.isUpload2 = true;
          this.errUpload2 = false;
          break;
        case '3':
          this.imageUrl3 = res.data;
          this.isUpload3 = true;
          this.errUpload3 = false;
          break;
        case '4':
          this.imageUrl4 = res.data;
          this.isUpload4 = true;
          this.errUpload4 = false;
          break;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    showDeleteImg(sort) {
      switch (sort) {
        case '1':
          if (this.isUpload1) {
            this.isDelete1 = true;
          }
          break;
        case '2':
          if (this.isUpload2) {
            this.isDelete2 = true;
          }
          break;
        case '3':
          if (this.isUpload3) {
            this.isDelete3 = true;
          }
          break;
        case '4':
          if (this.isUpload4) {
            this.isDelete4 = true;
          }
          break;
      }
    },
    deleteImg(sort) {
      switch (sort) {
        case '1':
          this.imageUrl1 = false;
          this.isUpload1 = false;
          this.isDelete1 = false;
          break;
        case '2':
          this.imageUrl2 = false;
          this.isUpload2 = false;
          this.isDelete2 = false;
          break;
        case '3':
          this.imageUrl3 = false;
          this.isUpload3 = false;
          this.isDelete3 = false;
          break;
        case '4':
          this.imageUrl4 = false;
          this.isUpload4 = false;
          this.isDelete4 = false;
          break;
      }
    },
    getArea() {
      this.province = this.provinces[0];
      this.city = this.provinces[1];
    }
  }
};
</script>

<style lang="less" scoped>
.main-body {
  background: #f2f2f2;
  width: 100%;
  position: relative;
  margin-top: 40px;
  padding-bottom: 50px;
  .content-title {
    font-weight: bold;
    font-size: 20px;
    color: #545454;
    text-align: center;
    margin-bottom: 20px;
  }
  .content-area {
    max-width: 1177px;
    margin: 0 auto;
    .top-content,
    .bottom-content {
      width: 100%;
      height: 1051px;
      padding-top: 28px;
      padding-left: 28px;
      padding-right: 28px;
      background: #ffffff;
      border-radius: 5px;
    }
    .bottom-content {
      height: 597px;
      margin-top: 20px;
    }
    .first-line,
    .second-line {
      border-bottom: 1px solid #c7c7c7;
      padding-bottom: 14px;
    }
    .second-line {
      margin-top: 40px;
    }
    .title-bold {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      margin-left: 6px;
    }
    .vertical-line {
      margin-bottom: -2px;
    }
    .span-label {
      display: inline-block;
      width: 120px;
      color: #333333;
    }
    .common-style {
      font-size: 14px;
      color: #666666;
      margin-top: 20px;
    }
    .div-1 {
      margin-top: 30px;
    }
    .div-6,
    .div-9 {
      position: relative;
      .paper-desc {
        position: absolute;
        left: 120px;
      }
    }
    .div-7 {
      margin-left: 120px;
      margin-top: 68px;
    }
    .div-8 {
      margin-left: 120px;
      margin-top: 10px;
      span {
        display: inline-block;
        width: 180px;
        margin-right: 54px;
        font-size: 14px;
        color: #666666;
      }
    }
    .no-upload-box {
      margin-left: 120px;
      margin-top: 10px;
      position: relative;
      height: 20px;
      span {
        position: absolute;
        font-size: 14px;
        color: #ff7477;
      }
      .span-1 {
        left: 0;
      }
      .span-2 {
        left: 234px;
      }
      .span-3 {
        left: 466px;
      }
    }
    .err-card {
      font-size: 14px;
      color: #ff7477;
      margin-top: 26px;
    }
    .div-10 {
      margin-left: 120px;
      margin-bottom: 28px;
      .input {
        width: 300px;
      }
    }
    .div-11 {
      margin-left: 120px;
      position: relative;
      .operate-desc {
        position: absolute;
        left: 0;
        top: 120px;
      }
      .example-box {
        position: absolute;
        left: 390px;
        top: -74px;
      }
    }
    .mail-prompt,
    .phone-prompt {
      margin-left: 120px;
      margin-top: 2px;
    }
    .get-message-btn {
      display: inline-block;
      width: 136px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      margin-left: 14px;
      border-radius: 4px;
      background: #27da99;
      cursor: pointer;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
      -moz-transition: background 0.5s;
      -o-transition: background 0.5s;
    }
    .get-message-btn:hover {
      background: #36c792;
    }
    .code-input {
      margin-left: 120px;
      margin-top: 16px;
    }
    .full-address {
      margin-left: 120px;
      margin-top: 16px;
      .text-area {
        width: 334px;
        height: 77px;
        webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
        -webkit-appearance: none;
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        color: #666666;
        font-size: 14px;
        padding: 10px 36px 10px 10px;
        overflow: hidden;
      }
    }
  }
}

.input {
  webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  width: 600px;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  color: #666666;
  font-size: 14px;
  padding: 10px 36px 10px 10px;
  overflow: hidden;
  &.input-short {
    width: 204px;
  }
  &.input-short-1 {
    width: 144px;
    margin-left: 24px;
  }
  &.input-psd {
    width: 270px;
  }
}

input:focus {
  border: 1px solid #3cb1ff;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}

.upload-box {
  display: inline-block;
  position: relative;
  .delete-icon {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

.area-select {
  height: 40px;
  border: 1px solid #c7c7c7;
}

.submit-btn {
  background: #3cb1ff;
  width: 256px;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  margin: 22px auto 8px;
  cursor: pointer;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.submit-btn:hover {
  background: #2ea5f5;
}

.red-star {
  color: red;
  margin-left: 5px;
  display: inline-block;
}

.error-desc {
  margin-left: 14px;
  color: #ff7477;
  font-size: 14px;
}

.error-div {
  color: #ff7477;
  font-size: 14px;
  margin-top: 6px;
}
</style>
<style>
.avatar-uploader {
  width: 180px;
  margin-right: 53px;
  display: inline-block;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 110px;
  line-height: 110px;
  text-align: center;
}

.avatar {
  width: 180px;
  height: 110px;
  display: block;
}
.area-select.large {
  width: 100%;
}
.area-select .area-selected-trigger {
  line-height: 14px;
}
</style>
