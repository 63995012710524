<template>
  <section class="container">
    <div class="content-right">
      <div class="content-title">企业开发者注册</div>
      <div class="content">
        <p class="content-big-title">一、温馨提示</p>
        <p class="content-small-title">1. 只接受企业开发者进行注册</p>
        <p class="content-small-title">
          2.
          开发者认证帐号必须绑定邮箱，建议您使用企业邮箱，如使用个人邮箱，则可能因人员离职造成不便；
        </p>
        <p class="content-small-title">
          3. 公司名称及联系人信息，请如实填写，以便我们及时与您联系；
        </p>
        <p class="content-small-title">
          4. 您只有认证成功后，才可以在步步高家教机开发者平台发布您的App；
        </p>
        <p class="content-big-title">二、所需资料</p>
        <p class="content-small-title">1.营业执照（三证合一）</p>
        <p class="content-small-title">2.法人手持身份证照片</p>
        <p class="content-small-title">
          &nbsp;&nbsp;资质上传证件扫描件要求如下：
        </p>
        <p class="content-small-title">
          &nbsp;&nbsp;a)证照必须在有效期内，且红色公章清晰可见；
        </p>
        <p class="content-small-title">
          &nbsp;&nbsp;b)提交的应用跟营业执照经营范围保持一致
        </p>
        <p class="content-small-title">
          &nbsp;&nbsp;c)所有证照主体必须保持一致
        </p>
        <p class="content-small-title">
          &nbsp;&nbsp;d)关键信息必须清晰，格式支持：JPG/PNG，每张图片大小2M以内
        </p>
        <p class="content-small-title">
          &nbsp;&nbsp;e)企业法人本人手持身份证信息扫描件（必须与营业执照法人一致），
          需保证证件号码清晰可
        </p>
        <p class="content-small-title">
          示例：
          <img src="@/assets/img/example-img.png" alt />
        </p>
        <p class="content-big-title">
          三、注册流程
          <img src="@/assets/img/liuc.png" alt />
        </p>
        <p class="content-big-title">四、详细说明</p>
        <p class="content-small-title">
          1.点击注册入口
          <img src="@/assets/img/zc.png" alt class="img" />
        </p>
        <p class="content-small-title">2.填写注册信息</p>
        <p class="content-small-title">
          &nbsp;&nbsp;a)用户名需要是6-15位字母、数字、下划线的组合，例如：Jasonwen888_bbk
        </p>
        <p class="content-small-title">
          &nbsp;&nbsp;b)密码需要是8-16位大小写、数字、符号的组合，例如：Jason888code_bbk
          <img src="@/assets/img/msg.png" alt class="img" />
        </p>
        <p class="content-small-title">
          3.填写账户资料，上传企业资质，提交审核
          <img src="@/assets/img/zl.png" alt class="img" />
        </p>
        <p class="content-small-title">
          4.提交通过审核后就可以正式成为步步高开发者平台的企业开发者
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  height: 100%;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
  }
}
</style>
