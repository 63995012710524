<template>
  <div class="content-area">
    <div class="progress-box">
      <div class="progress-item">
        <img
          src="@/assets/img/progress-blue.png"
          alt="创建应用"
          class="progress-img"
        />
        {{ progressName }}
      </div>
      <div class="progress-item">
        <img
          src="@/assets/img/progress-blue.png"
          alt="提交审核"
          class="progress-img"
        />
        提交审核
      </div>
    </div>
    <template v-if="!showDetail">
      <template v-for="(item, index) in apkList">
        <div class="app-item">
          <div class="app-icon">
            <img :src="item.apkIcon" />
          </div>
          <div class="app-info">
            <div class="app-info-item">
              <span>应用名</span>
              <span>{{ item.apkName }}</span>
            </div>
            <div class="app-info-item">
              <span>包名</span>
              <span>{{ item.apkPackageName }}</span>
            </div>
            <div class="app-info-item">
              <span>版本名称</span>
              <span>{{ item.apkVersionName }}</span>
            </div>
            <div class="app-info-item">
              <span>版本号</span>
              <span>{{ item.apkVersionCode }}</span>
            </div>
          </div>
          <div class="app-status">
            {{isstatus(item.status)}}
          </div>
          <div v-if="item.status==1||item.status==4" class="app-btn" @click="preview(index)">
            查看审核资料
          </div>
          <div v-else class="app-btn warning" @click="update(item.id)">编辑与更新</div>
          <div v-if="item.status==1" class="undo-btn" @click="undo(index)">撤销审核</div>
        </div>
        <div class="divide-line"></div>
      </template>
    </template>
    <template v-if="showDetail">
      <div class="top-content">
        <div class="first-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">资质信息</span>
        </div>
        <div class="div-6 common-style">
          <span class="span-label">版权证明</span>
          <el-popover placement="right" trigger="hover">
            <img class="pop-quli-img" :src="apk.copyrightCertificate" />
            <img
              slot="reference"
              :src="apk.copyrightCertificate"
              class="span-img"
            />
          </el-popover>
        </div>
        <div class="div-6 common-style">
          <span class="span-label">特殊资质</span>
          <el-popover placement="right" trigger="hover">
            <img class="pop-quli-img" :src="apk.qualification" />
            <img slot="reference" :src="apk.qualification" class="span-img" />
          </el-popover>
        </div>
      </div>
      <div class="bottom-content">
        <div class="first-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">完善信息</span>
        </div>
        <div class="common-style">
          <span class="span-label">应用信息</span>
          <div class="apkinfo-box">
            <p>应用名称：{{ apk.apkName }}</p>
            <p>包名：{{ apk.apkPackageName }}</p>
            <p>版本名称：{{ apk.apkVersionName }}</p>
            <p>版本号：{{ apk.apkVersionCode }}</p>
            <p>大小：{{ formatSize(apk.apkSize) }}</p>
          </div>
        </div>
        <div class="div-6 common-style">
          <span class="span-label">应用icon</span>
          <img :src="apk.apkIcon" class="span-img" />
        </div>
        <div class="common-style">
          <span class="span-label">一句话描述</span>
          <span class="span-desc">{{ apk.shortIntro }}</span>
        </div>
        <div class="common-style">
          <span class="span-label">发布类型</span>
          <span class="span-desc">{{ fitStore[apk.isFit] }}</span>
        </div>
        <div class="common-style">
          <span class="span-label">应用简介</span>
          <!--<span class="span-desc">{{apk.apkIntroduce}}</span>-->
          <pre class="text-area-box">{{ apk.apkIntroduce }} </pre>
        </div>
        <div class="common-style">
          <span class="span-label">新版说明</span>
          <!--<span class="span-desc">{{apk.updateInformation}}</span>-->
          <pre class="text-area-box">{{ apk.updateInformation }} </pre>
        </div>
        <div class="div-6 common-style">
          <span class="span-label">应用截图</span>
          <el-popover
            v-for="(item, index) in apk.picUrlList"
            :key="index"
            placement="right"
            trigger="hover"
          >
            <img class="pop-jie-img" :src="item" />
            <img slot="reference" class="span-img" :src="item" />
          </el-popover>
        </div>
        <div class="common-style">
          <span class="span-label">客服电话</span>
          <span class="span-desc">{{ apk.serviceTel }}</span>
        </div>
        <div class="common-style">
          <span class="span-label">备注说明</span>
          <!--<span class="span-desc">{{apk.remark}}</span>-->
          <pre v-if="apk.remark" class="text-area-box">{{ apk.remark }} </pre>
        </div>
        <el-button class="submit-btn" type="success" @click="showDetail = false"
          >返回</el-button
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Check',
  data() {
    return {
      apkList: [],
      apk: {},
      showDetail: false,
      fitStore: ['常规发布', '适配上传', ''],
      progressName: '创建应用',
      statusList: new Map(),
    };
  },
  created() {
    this.isGetStatusList();
  },
  mounted() {
    this.getApkList();
    this.initPage();
    this.isGetStatusList();
  },
  // updated() {
  //   this.getApkList();
  //   this.isGetStatusList();
  // },
  methods: {
    preview(index) {
      this.apk = this.apkList[index];
      this.showDetail = true;
    },
    getApkList() {
      this.$axios
        .post(`appManage/getApkList`, {
          status: 1,
          showDetail: true
        })
        .then(res => {
          this.apkList = res.data.data;
        })
        .catch(() => {
          this.$alert('<strong>发生错误，请联系管理员！</strong>', '提示', {
            dangerouslyUseHTMLString: true,
            type: 'error'
          });
        });
    },
    /**
     * 初始化页面，判断页面是从哪里来的
     */
    initPage() {
      let isUpdate = this.$route.query.update;
      if (isUpdate) {
        this.progressName = '应用更新';
        this.menu[0].active = false;
        this.menu[1].active = true;
      }
    },
    //获取应用状态
    getStatusList() {
      this.$axios
        .get(`appManage/getStatusList`)
        .then(res => {
          if (res.data.stateCode === '0') {
            let _str = ''; //字符串方便SesstionStorge
            let _sz = []; //对象数组 转成 字符串数组
            res.data.data.map((item, index) => {
              this.statusList.set(item.id, item.name);
              _sz.push(JSON.stringify(item));
            });
            _str = _sz.join('@');
            //取到存到本地，其它页面方便取，减少请求
            sessionStorage.setItem('apkStatusList', _str);
            _sz = _str.split('@');
            _sz.map((item, index) => {
              item = JSON.parse(item);
            });
          }
        })
        .catch(() => {});
    },
    //判断是否获取过应用状态，没有就执行get，否则就从session里拿
    isGetStatusList() {
      let _str = sessionStorage.getItem('apkStatusList');
      if (!_str) {
        this.getStatusList();
      } else {
        let _sz = _str.split('@');
        _sz.map(item => {
          item = JSON.parse(item);
          this.statusList.set(item.id, item.name);
        });
      }
    },
    isstatus(index) {
      console.log(index);
      var item;
      // console.log(this.statusList);
      for (item of this.statusList ) {
        // console.log( item );
        if(item[0]==index){
          return item[1];
        } 
      }
    },
    // 编辑与跟新
    update(index){
      this.$router.push({
        path: '/manage_update',
        query: {
          apkId: index
        }
      });
    },
    // 点击撤销审核
    undo(obj){
      this.apk = this.apkList[obj];
      console.log(this.apk);
      this.$confirm('撤销审核后如需申请上架，需重新提交审核','确定要撤销该应用的审核吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        this.$axios.post(`/appManage/cancel/${this.apk.id}`,this.apk.id, {
          header: {
            "Content-Type": "application/json"
          }
        }
        ).then(res => {
          if(res.status == 200){
            if(res.data.stateCode==0){
              this.$alert(`撤销成功`, `${res.data.stateInfo}`, {
                confirmButtonText: '确定',
              });
              // this.getApkDetail();
              // this.$message.success("操作成功！");
              this.$message({
                type: 'success',
                message: '撤销成功!'
              });
              this.getApkList();
            }else{
              this.$message({
                type: 'warning',
                message: `撤销失败!${res.data.stateInfo}`
              });
              this.getApkList();
            };
          }else{
            this.$message({
              type: 'warning',
              message: `调用接口异常!`
            });
          }
        }).catch((error)=>{
          console.log(error)
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
  }
};
</script>

<style lang="less" scoped>
.content-area {
  max-width: 1200px;
  margin: 0 auto;
  .progress-box {
    max-width: 501px;
    margin: 0 auto;
    overflow: hidden;
    padding: 50px 0;
    .progress-item {
      float: left;
      width: 250px;
      margin-left: 1px;
      font-size: 14px;
      color: #3cbeff;
      text-align: center;
      &:first-child {
        margin-left: 0px;
      }
      img {
        width: 100%;
      }
    }
    .undone {
      color: #cccccc;
    }
  }
  .app-item {
    width: 100%;
    height: 230px;
    background: #ffffff;
    padding: 55px 40px;
    clear: both;
    .app-icon,
    .app-info,
    .app-status,
    .app-btn {
      height: 120px;
      line-height: 120px;
      float: left;
    }
    .app-icon {
      img {
        height: 100%;
        vertical-align: top;
      }
    }
    .app-info {
      width: 50%;
      line-height: 14px;
      margin-left: 30px;
      .app-info-item {
        height: 25%;
        line-height: 30px;
        span {
          color: #666666;
          &:first-child {
            display: inline-block;
            width: 80px;
            color: #333333;
          }
        }
      }
    }
    .app-status {
      font-size: 18px;
      color: #3cbeff;
    }
    .app-btn {
      float: right;
      background: #3cb1ff;
      width: 150px;
      height: 40px;
      line-height: 40px;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      margin-top: 40px;
      letter-spacing: 1px;
      cursor: pointer;
      border-radius: 4px;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
      -moz-transition: background 0.5s;
      -o-transition: background 0.5s;
      &.warning {
        background: #ffc367;
        margin-right: 5px;
      }
    }
    .undo-btn {
      height: 120px;
      line-height: 120px;
      width: 150px;
      height: 40px;
      line-height: 40px;
      color: #666666;
      font-size: 16px;
      text-align: center;
      letter-spacing: 1px;
      cursor: pointer;
      border-radius: 4px;
      border: #666666 1px solid;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
      -moz-transition: background 0.5s;
      -o-transition: background 0.5s;
      position: absolute;
      top: 145px;
      right: 40px;
    }
  }
  .apkinfo-box {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    color: #666666;
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    line-height: 25px;
  }
  .top-content,
  .bottom-content {
    width: 100%; // height: 1060px;
    padding-top: 28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 40px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 20px;
    .submit-btn {
      margin-left: 120px;
      width: 150px;
      margin-top: 40px;
    }
    .uploaded-img-box {
      width: 180px;
      height: 110px;
      display: inline-block;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      border: 1px dashed white;
      &:hover {
        border: 1px dashed #cccccc;
      }
      .thumb-img {
        width: 100%;
        height: 100%;
      }
      .delete-img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .top-content,
  .bottom-content {
    width: 100%; // height: 1060px;
    padding-top: 28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 40px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 20px;
    .submit-btn {
      margin-left: 120px;
      width: 150px;
      margin-top: 40px;
    }
    .uploaded-img-box {
      width: 180px;
      height: 110px;
      display: inline-block;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      border: 1px dashed white;
      &:hover {
        border: 1px dashed #cccccc;
      }
      .thumb-img {
        width: 100%;
        height: 100%;
      }
      .delete-img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .first-line,
  .second-line {
    border-bottom: 1px solid #c7c7c7;
    padding-bottom: 14px;
  }
  .second-line {
    margin-top: 40px;
  }
  .title-bold {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-left: 6px;
  }
  .vertical-line {
    margin-bottom: -2px;
  }
  .span-label {
    display: inline-block;
    width: 120px;
    color: #333333;
  }
  .span-img {
    height: 110px;
    width: 180px;
    vertical-align: top;
    margin-right: 20px;
  }
  .common-style {
    font-size: 14px;
    color: #666666;
    margin-top: 26px;
  }
  .div-6,
  .div-9 {
    position: relative;
    .paper-desc {
      position: absolute;
      left: 120px;
    }
  }
  .no-upload-box {
    margin-left: 120px;
    margin-top: 10px;
    position: relative;
    height: 20px;
    span {
      position: absolute;
      font-size: 14px;
      color: #ff7477;
    }
    .span-1 {
      left: 0;
    }
    .span-2 {
      left: 234px;
    }
    .span-3 {
      left: 466px;
    }
  }
  .err-card {
    font-size: 14px;
    color: #ff7477;
    margin-top: 26px;
  }
  .mail-prompt,
  .phone-prompt {
    margin-left: 120px;
    margin-top: 2px;
  }
  .code-input {
    margin-left: 120px;
    margin-top: 16px;
  }
  .full-address {
    margin-left: 120px;
    margin-top: -16px;
    .text-area {
      width: 666px;
      height: 77px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: none;
      -webkit-appearance: none;
      border: 1px solid #c7c7c7;
      border-radius: 4px;
      color: #666666;
      font-size: 14px;
      padding: 10px 36px 10px 10px;
      overflow: hidden;

      &:focus {
        border-color: #3cb1ff;
      }
    }
  }

  .pop-quli-img {
    max-width: 480px;
  }
  .pop-jie-img {
    max-width: 480px;
  }
  .divide-line {
    border-top: 1px solid #dddddd;
    margin-left: 40px;
  }
}
</style>
