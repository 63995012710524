<template>
  <div class="content-right">
    <div class="content-title">开发者帐号密码找回</div>
    <div class="content">
      <p class="content-big-title">1. 忘记注册账号或者注册邮箱？</p>
      <p class="content-small-title">
        请提供最新营业执照（三证合一或者三证一照）、法人身份正反面照片，发送到open@eebbk.com并备注公司名称和详细原因，相关工作人员会在1-3个工作日内回复您的邮件，为您找回原注册账号或注册邮箱。
      </p>

      <p class="content-big-title">
        2. 忘记账号密码怎么办？ 收不到验证码怎么办？
      </p>

      <p class="content-small-title">
        ①请登录开发者平台官网（https://dev.eebbk.com/index/login）点击【登录】-【找回密码】，输入注册邮箱进行安全验证之后重置密码即可。
      </p>
      <p class="content-small-title">
        ②如果您通过邮件方式找回密码却收不到验证码的邮件，可能是您的邮箱过滤了系统邮件，建议您前往邮箱垃圾箱或拦截邮件查看验证邮件。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      color: #333333;
    }
    .span1 {
      color: red;
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
