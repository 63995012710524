<template>
  <header class="header">
    <div class="logo" title="步步高开发者平台" @click="$router.push('/')">
      <img src="@/assets/img/logo.png" alt="步步高开发者平台" />
      <span>开发者平台</span>
    </div>
    <ul class="nav">
      <li
        v-for="item in menu"
        :key="item.path"
        :class="{ active: $route.path == item.path }"
        @click="$router.push(item.path)"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="login">
      <div v-if="!isLogin" class="register">
        <span @click="$router.push('/login')">登录</span>
        <span @click="$router.push('/register')">注册</span>
      </div>
      <div v-if="isLogin" class="user">
        <div class="user-detail">
          <span class="user-name">欢迎您，{{ userName }}</span>
          <img src="@/assets/img/header-select.png" alt="下拉图标" />
          <div class="user-control">
            <div @click="$router.push('/account')">账户设置</div>
            <div class="signout-btn" @click="signOut">退出登录</div>
          </div>
        </div>
        <div class="message" @click="$router.push('/message')">
          <img src="@/assets/img/message.png" alt="消息图标" />
          <span>消息（{{ unReadMessageNum }}）</span>
        </div>
      </div>
    </div>
    <!-- <div v-if="protocolShow" class="protocol-area">
      <div class="content-title">步步高开发者平台开发者服务协议</div>
      <agreementBox :isOverflow="1"/>
      <div class="btn-box">
        <span class="agree-btn" @click="agreeProtocalBtn">接受本协议</span>
        <span class="reject-btn" @click="closeModal">拒绝</span>
      </div>
    </div> -->
  </header>
</template>

<script>
import { mapState } from 'vuex';
import getMenu from './menu';
import agreementBox from '../../views/login/agreement.vue';
export default {
  name: 'Header',
  data() {
    return {
      unReadMessageNum: 0,
      menu: [],
      protocolShow: false,
      updataFlag: {}
    };
  },
  components: { agreementBox },
  computed: {
    ...mapState(['isLogin', 'userName'])
  },
  watch: {
    '$route.path': {
      handler: function() {
        this.menu = getMenu(this.$route.path);
      },
      immediate: true
    },
    isLogin(newVal) {
      if (newVal) {
        this.checkAccountState();
        this.getMessageCount();
      }
    }
  },
  mounted() {
    if (this.isLogin) {
      this.checkAccountState();
      this.getMessageCount();
    }
  },
  methods: {
    // closeModal() {
    //   this.protocolShow = false;
    //   this.signOut();
    // },
    // agreeProtocalBtn() {
    //   this.$axios
    //     .post(
    //       `userAgreementVersion/insertOrUpdateUserAgreementVersion`,
    //       this.updataFlag
    //     )
    //     .then(res => {
    //       console.log(res.data.data);
    //       this.protocolShow = false;
    //     });
    // },
    signOut() {
      this.$store.commit('logout');
      this.$router.push('/login');
    },
    // getNewUserAgreementVersionFlag(object) {
    //   this.$axios
    //     .post(`userAgreementVersion/getNewUserAgreementVersionFlag`, object)
    //     .then(res => {
    //       console.log(res.data.data);
    //       //1 是未同意   0是已同意
    //       if (res.data.data === 1) {
    //         this.protocolShow = true;
    //         this.updataFlag = object;
    //       }
    //     });
    // },
    // 这个屌接口不是用来获取用户信息的。是用来检测账户状态的
    // 这个屌接口不返回userName，屎
    checkAccountState() {
      this.$axios
        .post(`userManage/getUserInfo`, {
          object: this.$cookie.getCookie('accountId') || ''
        })
        .then(res => {
          var data = res.data;
          console.log(data, '登录成功了');
          if (data.stateCode === '0') {
            let isPass = data.data.auditCode === 3 ? true : false;
            this.$store.commit('setAccountPass', isPass);
            this.$store.commit('setBusinessLicense', data.data.businessLicense);
            // this.getNewUserAgreementVersionFlag(data.data);
            if (data.data.completeCode === 0) {
              // 账户未有资质
              this.$router.push('/account_submit');
            }
          }
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
        });
    },
    // 获取未读消息数量
    getMessageCount() {
      this.$axios.post(`userManage/getMessageCount`).then(res => {
        var data = res.data;
        if (data.stateCode === '0') {
          this.unReadMessageNum = data.data;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.protocol-area {
  background: #ffffff;
  color: #333333;
  width: 1175px;
  height: 750px;
  line-height: 38px;
  border-radius: 5px;
  opacity: 1;
  z-index: 99999;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  padding-left: 100px;
  padding-right: 100px;
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .close-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }

  .protocol-content {
    margin-top: 30px;
    height: 528px;
    overflow-y: scroll;
    font-size: 14px;
    padding: 0 20px;
    text-indent: 2em;
    strong {
      font-weight: 600;
    }
    .title {
      color: #333333;
    }
    .content-footer {
      text-align: right;
      margin-right: 20px;
    }
    .content {
      color: #666666;
      line-height: 24px;
      letter-spacing: 1px;
      .span-width {
        display: inline-block;
        width: 28px;
      }
    }
  }
  .btn-box {
    text-align: center;
    margin-top: 30px;
    .agree-btn {
      display: inline-block;
      width: 350px;
      height: 40px;
      line-height: 38px;
      text-align: center;
      border-radius: 4px;
      color: #ffffff;
      background: #3cb1ff;
      border: 1px solid #3cb1ff;
      cursor: pointer;
    }
    .reject-btn {
      display: inline-block;
      width: 350px;
      height: 40px;
      line-height: 38px;
      text-align: center;
      border-radius: 4px;
      color: #333333;
      background: #ffffff;
      margin-left: 30px;
      border: 1px solid #c7c7c7;
      cursor: pointer;
    }
  }
}
.header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  height: 82px;
  background-color: #0f1226;
  padding: 0 100px 0 74px;
  box-shadow: 0px 10px 10px 0px rgba(15, 18, 38, 0.2);
  .logo {
    cursor: pointer;
    float: left;
    color: #fff;
    span {
      position: relative;
      display: inline-block;
      vertical-align: 17px;
      font-size: 16px;
      opacity: 0.85;
      &:before {
        content: '';
        position: absolute;
        left: -8px;
        top: 6px;
        width: 1px;
        height: 12px;
        background-color: #fff;
      }
    }
  }
  .nav {
    float: left;
    height: 100%;
    color: #efefef;
    font-size: 15px;
    overflow: hidden;
    li {
      position: relative;
      margin: 0 35px;
      text-align: center;
      display: inline-block;
      line-height: 82px;
      cursor: pointer;
      transition: color 0.3s linear;
      &:hover {
        color: #3e8aff;
      }
      &.active {
        color: #3e8aff;
        &:after {
          content: '';
          position: absolute;
          left: -15%;
          bottom: 0;
          width: 130%;
          height: 4px;
          border-radius: 4px;
          background-color: #3e8aff;
        }
      }
    }
  }
  .login {
    height: 82px;
    float: right;
    .register {
      margin-top: 25px;
      height: 30px;
      display: inline-block;
      span {
        display: inline-block;
        width: 70px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        transition: all 0.3s linear;
        margin-left: 20px;
      }
      span:first-child {
        color: #147cea;
        border: 1px solid rgba(20, 124, 234, 1);
        &:hover {
          background-color: #147cea;
          color: #fff;
        }
      }
      span:last-child {
        color: #fff;
        background: rgba(20, 122, 231, 1);
        &:hover {
          color: #147cea;
          background-color: #fff;
          border: 1px solid rgba(20, 124, 234, 1);
        }
      }
    }
    .user {
      line-height: 82px;
      color: #fff;
      & > div {
        display: inline-block;
        margin-left: 15px;
        cursor: pointer;
      }
      img {
        transform: scale(0.8);
        vertical-align: middle;
        transition: all 0.3s linear;
      }
      .user-detail {
        position: relative;
        .user-control {
          position: absolute;
          top: 100%;
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.7);
          color: #fff;
          border-radius: 5px;
          text-align: center;
          line-height: 60px;
          box-shadow: 0 0 5px 1px #ccc;
          height: 0px;
          opacity: 0;
          width: 133px;
          transition: height 0.3s linear;
          & > div {
            cursor: pointer;
            transition: all 0.3s linear;
            &:first-child {
              position: relative;
              &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 80px;
                height: 2px;
                background-color: #fff;
              }
            }
            &:hover {
              background-color: #000;
            }
            height: 60px;
          }
        }
        &:hover {
          .user-control {
            height: 120px;
            opacity: 1;
          }
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
