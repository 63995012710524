<template>
  <div class="content-wrap">
    <div class="content">
      <div class="left-menu">
        <el-menu
          ref="menuCom"
          :default-active="currentComponent"
          class="el-menu-vertical-demo"
          :unique-opened="true"
          @select="selectMenu"
        >
          <el-submenu v-for="item in menu" :key="item.componentName" :index="item.componentName">
            <template slot="title">
              <span class="title">{{ item.name }}</span>
            </template>
            <template v-for="child in item.children">
              <el-submenu
                v-if="child.children && child.children.length > 0"
                :key="child.componentName"
                :index="child.componentName"
              >
                <template slot="title">
                  <span class="title">{{ child.name }}</span>
                </template>
                <el-menu-item
                  v-for="child2 in child.children"
                  :key="child2.componentName"
                  :index="child2.componentName"
                >{{ child2.name }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :key="child.componentName" :index="child.componentName">{{ child.name }}</el-menu-item>
            </template>
          </el-submenu>
        </el-menu>
      </div>
      <component :is="currentComponent" class="right-content-father" />
    </div>
  </div>
</template>

<script>
import menu from './menu';
import comObj from './component';
export default {
  name: 'Doc',
  components: comObj,
  data() {
    return {
      menu,
    };
  },
  computed: {
    currentComponent() {
      return this.$route.query.docComName || 'register-register';
    },
  },
  methods: {
    selectMenu(componentName) {
      this.$router.push({
        query: {
          docComName: componentName,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content-wrap {
  background: #f2f2f2;
  overflow: hidden;
  .content {
    position: relative;
    display: flex;
    width: 1200px;
    margin: 50px auto;
    .left-menu {
      position: fixed;
      width: 266px;
      height: 800px;
      overflow-y: auto;
      flex: 0 0 auto;
      background: #fff;
      border: 1px solid #ebf4fe;
    }
    .right-content {
      flex: 1;
      height: 800px;
      background: #f2f2f2;
      overflow-y: auto;
      border: 1px solid #ebf4fe;
      & > section {
        height: 100%;
      }
    }
  }
}
.title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}
.right-content-father {
  margin-left: 266px;
  height: 100%;
}
</style>
<style lang="less">
.doc {
  .left-menu {
    .el-menu {
      border-right: 0;
    }
  }
}
</style>
