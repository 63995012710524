<template>
  <div class="container">
    <h2>侵权投诉反通知指引</h2>
    <h3>1. 被投诉方向步步高家教机提交书面反通知</h3>
    <div>
      <ol>
        <li>
          被投诉方主体信息和相关材料
          <p>
            被投诉方的姓名（名称）、联系方式、地址及营业执照（单位）、身份证（个人）、相关授权证明等证明权利人主体资格的材料；
          </p>
        </li>
        <li>
          被投诉方要求
          <p>
            被投诉方要求恢复的内容或链接对应的名称和具体网络地址（或包名）；
          </p>
        </li>
        <li>
          不构成侵权的初步证明材料
          <p>被投诉方应提供不侵权的初步证明材料，具体包括：</p>
          <ul>
            <li>被投诉方拥有权利的证明材料;</li>
            <li>
              包括但不限于相关有权机构颁发的版权证书、商标权证书、专利权证书、作品首次公开发表或发行日期证明材料、创作手稿、经权威机构签发的作品创作时间戳、作品备案证书等能证明被投诉方拥有相关权利的有效权属证明；
            </li>
            <li>被投诉方提供的内容不构成侵权的证明材料：</li>
            <li>
              包括但不限于被投诉方提供的内容不构成版权、商标权或专利权等权利侵权的有效证明材料，书面不侵权理由及事实说明、生效司法文书等。
            </li>
          </ul>
        </li>
        <li>
          被投诉方保证
          <p>被投诉方应在反通知中明确保证：</p>
          <p>
            被投诉方在反通知中的陈述和提供的相关材料皆是真实、有效和合法的，并保证自行承担由此产生的全部法律责任，包括但不限于步步高家教机根据被投诉方反通知保留或者恢复有关内容而给步步高家教机造成的任何损失。
          </p>
        </li>
      </ol>
      <p style="margin-top: 20px;">
        反通知及相关证明材料准备时需注意的事项以及投寄地址详见下文注意事项中的说明。
      </p>
    </div>
    <h3>2.步步高家教机反馈</h3>
    <div>
      <p>
        步步高家教机在收到反通知后，将对反通知进行形式审查，反通知不符合前述要求的，被投诉方应在5个工作日内向步步高家教机补充提交有关证据材料或其他材料，被投诉方应予配合，被投诉方逾期未予补充完善反通知的，步步高家教机将视该反通知无效。
      </p>
      <p>
        如通知符合前述要求的，步步高家教机将根据相关法律法规之规定尽快进行处理，同时会将被投诉方提供的相关材料转送给权利人。
      </p>
    </div>
    <h3>3. 注意事项</h3>
    <ol>
      <li>
        本指引中的权利人，指拥有版权、商标权、专利权等合法权益的原始所有权人或经原始所有权人合法授权的人，包括自然人、法人或其他组织等。
      </li>
      <li>
        本指引中的被投诉方，指在步步高家教机开放平台被权利人投诉侵权的内容发布者。
      </li>
      <li>
        为了确保被投诉方提供相关材料的真实性和有效性，被投诉方的书面反通知及其他相关证明材料，原则上应提供原件，不能提供原件的，应提供复印件（在复印件上应有被投诉方的签字/签章），若材料涉外的，应按照法律的规定进行公证转递，并同时提供相应的公证转递材料。
      </li>
      <li>
        通知提交步步高家教机的方式有以下：
        <p>
          将前述全部的纸质版本材料清晰扫描后通过电子邮件发送至步步高家教机指定电子邮箱：<span
            style="font-weight:bold; color: #000;"
            >weichaonan@eebbk.com</span
          >
        </p>
      </li>
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  padding-bottom: 40px;
  color: #666;
  font-size: 14px;
  line-height: 30px;
  h2,
  h3,
  h4 {
    color: #333333;
    line-height: 60px;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 100;
  }
  p {
    color: #666;
    font-size: 14px;
  }
  p + div {
    padding-left: 2em;
  }
  h3 + p,
  h4 + p {
    text-indent: 2em;
  }
  h3 + div,
  h4 + div {
    padding-left: 2em;
  }
  h4 {
    margin: 5px 0;
    text-indent: 1em;
    font-weight: 100;
    font-size: 16px;
  }
  h3 + div,
  h4 + div {
    & > p {
      text-indent: 2em;
    }
  }
}
</style>
