<template>
  <section class="container">
    <div class="content-right">
      <div class="content-title">
        步步高应用商店第三方应用程序提供方内容守则公约
      </div>
      <div class="content">
        <p style="text-align: center;">
          <strong>更新日期：2023年7月10日 生效日期：2023年7月14日</strong>
        </p>
        <p class="content-big-title"><strong>引言</strong></p>
        <p>
          <strong
            >【广东天才星网络科技有限公司其他关联公司】（下称“我司”）自2010年上线以来，致力于为未成年人等用户提供一个和谐、安全、健康、清朗的网络环境，维护我司产品及服务社区秩序以和保障我司用户的合法权益。</strong
          >
        </p>
        <p>
          为实现该目标，我司依据并贯彻《中华人民共和国民法典》《中华人民共和国治安管理处罚法》《中华人民共和国网络安全法》《中华人民共和国未成年人保护法》《中华人民共和国预防未成年人犯罪法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》《网络信息内容生态治理规定》《互联网信息服务管理办法》《移动互联网作品程序信息服务管理规定》等相关法律法规及主管部门的管理政策有关法律原则制定了《步步高应用商店内容守则公约》（本公约）。
        </p>
        <p>
          本公约确立您作为我司开发者（定义见下）关于应用程序所提供的服务所涉信息内容安全事宜所享有的权利和负有的义务，针对开发者应遵守的应用商店其他规范，请查阅《应用审核规范》《应用资质审核规范》《步步高开发者平台开发者服务公约》。
        </p>
        <p class="content-big-title"><strong>一、说明</strong></p>
        <p>
          <span
            >1.1《步步高应用商店第三方应用程序提供方内容守则公约》（以下简称“本公约”）适用于所有在我司应用商店上发布上架的应用程序作品的开发者。</span
          >
        </p>
        <p>
          <span
            >一经开发者同意接受即形成开发者与我司间有法律约束力之文件。此后，开发者不得以未阅读本公约内容作任何形式的抗辩。开发者在应用商店注册或提供、上传、下载、转载开发者的作品将受本条款之规范。开发者注册时点击本公约下方的“我同意”按键（或类似按键）或使用我司向开发者提供的产品和服务即视为您对本公约条款的充分理解和完全接受。如果开发者不同意本公约条款，您不得使用或停止使用我司提供的产品和服务。</span
          >
        </p>
        <p>
          <span
            >如开发者不同意本公约任意内容的，开发者将不得在我司应用商店发布和传播任何作品，同时我司有权采取下架等应对处置措施。</span
          >
        </p>
        <p>
          <span
            >1.2一旦开发者接受本公约，开发者保证开发者已达法定年龄且具有完全之行为能力。如果开发者为一个公司、组织或其他法律实体的，需保证其公司或组织是根据中国境内法律所成立且持续有效的法律实体，并保证已合法获得授权在应用商店发布作品。
            如果开发者的作品包括任何开源软件，开发者保证遵守该开源软件所有适用的授权条款。</span
          >
        </p>
        <p>
          <em
            ><strong
              >1.3开发者违反本公约或与开发者应用程序用户因信息内容安全存在纠纷的，我司有权采取要求提供说明和应对方案、要求开展排查、整改、对被侵害的用户权益采取赔礼道歉、赔偿损失等救济措施、下架作品、解除合作、要求赔偿等措施，开发者违反本规范导致的纠纷由开发者自行负责，因此导致小天才遭受的损失由开发者承担赔偿责任及消除影响义务。</strong
            ></em
          >
        </p>
        <p>
          <u
            ><em
              ><strong
                >1.4经我司自行通过人工审核或智能发现或用户投诉发现系开发者原因或开发者提供的应用程序服务过程出现的内容安全问题，我司则将及时与第三方应用程序提供方沟通并反馈相应情况，并要求开发者采取前述措施外，如涉及重大信息内容安全实践的，我司有权将视情况严重程度不经同意，对相应开发者提供的作品、应用程序服务等立即采取停止展示、传播、转发、下架作品/应用程序、停止合作等处理措施。</strong
              ></em
            ></u
          >
        </p>
        <p class="content-big-title"><strong>二、定义</strong></p>
        <p>
          <span
            >2.1开发者指有效申请并经我司公司审核同意、经与我司达成合约后、以及由我司从应用商店筛选并审核后，在应用商店上发布应用程序作品的开发者，包括法人、其他组织或自然人。</span
          >
        </p>
        <p>
          <span
            >2.2步步高应用商店是一个开放性的作品发布平台，由我司所有和经营。</span
          >
        </p>
        <p>
          <span
            >2.3作品指开发者在成功注册开发者账号后，使用应用商店发布或更新的其拥有合法权利的任何信息或材料，包括但不限于服务、应用程序、数据、文件、软件、音乐、声音、图片、图像、图形、影音讯息、答案、、广告、问题、意见、建议、暗示、策略、观念、设计、想法、计划等信息内容。</span
          >
        </p>
        <p>
          <span
            >2.4用户指所有直接或间接使用开发者发布或更新至应用商店平台上的作品的用户。</span
          >
        </p>
        <p class="content-big-title">
          <strong>三、内容治理基本原则</strong>
        </p>
        <p>
          <span
            >开发者拟发布的第三方作品须提供安全健康的网络环境，内容积极向上、阳光开朗，符合社会主义核心价值观，同时必须有利于未成年人安全和身心健康。</span
          >
        </p>
        <p class="content-big-title">
          <strong>四、开发者作品内容基本要求</strong>
        </p>
        <p>
          <strong>4.1 作品内容不得违反国家法规及政策要求，具体包括：</strong>
        </p>
        <p>
          <span>(1)作品内不得含有违反宪法确定的基本原则的内容。 </span>
        </p>
        <p>
          <span>(2)作品内不得含有危害国家统一、主权和领土完整的内容。 </span>
        </p>
        <p>
          <span
            >(3)
            作品内不得含有泄露国家秘密、危害国家安全或者损害国家荣誉和利益的内容。
          </span>
        </p>
        <p>
          <span
            >(4) 作品内不得含有散布谣言，扰乱社会秩序，破坏社会稳定的内容。
          </span>
        </p>
        <p>
          <span
            >(5)
            作品内不得含有淫秽、色情、低俗、非法交易、赌博、暴力，或者教唆犯罪的内容。
          </span>
        </p>
        <p>
          <span>(6)作品内不得含有违背社会公德的内容。 </span>
        </p>
        <p>
          <span
            >(7)
            作品内不得含有煽动民族仇恨、民族或种族歧视，破坏民族团结，或者侵害民族风俗、习惯的内容。
          </span>
        </p>
        <p>
          <span>(8)作品内不得含诽谤、人身攻击或者侮辱性的内容。 </span>
        </p>
        <p>
          <span>(9)作品内不得包含具有贩卖、购买违禁物品的内容。 </span>
        </p>
        <p>
          <span
            >(10)
            儿童类作品内容需适合儿童，不得含有其他会对儿童造成干扰的内容，儿童类作品不得含有外链接、购买机会，除非其保留在受家长监控的指定区域中。
          </span>
        </p>
        <p>
          <span
            >(11)
            作品不得含有宣扬邪教和封建迷信的内容，也不得含有关于宗教、种族、性取向、性别或其他目标群体的诽谤或恶意内容。</span
          >
        </p>
        <p>
          <span>(12) 严管不良传教等包装成课程产品、文化交流等的形式存在。</span>
        </p>
        <p>
          <span
            >(13)
            作品内不得过度宣传酒精和危险物品（如毒药、爆炸物等），或鼓励未成年人消费香烟和酒精饮料。
          </span>
        </p>
        <p>
          <span>(14) 作品内容不得只是简单打包的内容聚合、页面整合。</span>
        </p>
        <p>
          <span
            >(15)
            不得提交大量内容相似的作品，或提交的作品与其他开发者作品相同或过分相似。</span
          >
        </p>
        <p>
          <span>(16) 不得提供有明确版权归属的破解版、汉化版作品。</span>
        </p>
        <p>
          <span>(17) 作品不得带有修改其他作品数据、存档等内容的功能。</span>
        </p>
        <p>
          <span
            >(18) 不得用不具有识别性的词汇来命名作品，如归纳类作品名称。</span
          >
        </p>
        <p>
          <span
            >(19)
            不得强制、误导、诱导用户点击广告，或者强制安装软件、捆绑下载。</span
          >
        </p>
        <p>
          <span
            >(20) 作品内不得含有法律、行政法规和国家规定禁止的其他内容。
          </span>
        </p>
        <p><strong>4.2 游戏类作品要求</strong></p>
        <p>
          <span
            >(1)
            游戏类作品不得含有错误价值观取向的内容，包括但不限于拜金主义、耽美等不良文化。
          </span>
        </p>
        <p>
          <span
            >(2)
            游戏类作品要严格落实实名认证和防沉迷要求，严格执行向未成年人提供网络游戏的时段时长限制，不得以任何形式向未成年人提供网络游戏账号租售交易服务。
          </span>
        </p>
        <p>
          <span
            >(3)
            游戏类作品要强化“氪金”管控，杜绝擅自变更游戏内容、违规运营游戏等行为，坚决遏制“唯金钱”“唯流量”等错误倾向。
          </span>
        </p>
        <p>
          <span
            >(4)
            游戏类作品要加强游戏直播管理，禁止出现高额打赏、未成年人打赏等情况。
          </span>
        </p>
        <p><strong>4.3广告内容要求</strong></p>
        <p>
          <span
            >(1)
            广告内容不得含有欺诈内容，不应与向用户所作的宣传或者承诺不符，如：用户实际下载的作品、跳转后的页面，与广告宣传的不一致。
          </span>
        </p>
        <p>
          <span
            >(2)
            作品内广告存在欺骗、诱导点击等形式，欺骗用户点击低质量推广广告，或广告内容含有虚假内容、违规内容等违反国家广告法行为。
          </span>
        </p>
        <p>
          <span
            >(3)
            广告内容不得包含不良信息或违法违规内容（例如：侵权、色情、恐怖暴力、反动等），不得含有违反政策法规的网址或页面的提示。
          </span>
        </p>
        <p>
          <span
            >(4)
            作品所提供的广告，需设置显著的跳转/打开的提示，点击提示以外的区域，不得直接跳转/打开其他页面。
          </span>
        </p>
        <p>
          <span
            >(5)
            作品所提供的广告，不得强制、误导、诱导用户点击，迫使用户产生非自愿的下载/跳转行为。
          </span>
        </p>
        <p>
          <span
            >(6)
            作品所提供的广告，须带有关闭功能和显著的关闭提示，不得设置关闭障碍，且作品关闭后，广告也需同时关闭。
          </span>
        </p>
        <p>
          <span
            >(7)
            含有下载功能的广告，应设置显著作品下载提示，用户点击“下载按钮”以外区域，不得自动下载非用户所自愿下载作品，或通过设置关闭障碍、关闭提示欺骗等方式，强迫用户下载。
          </span>
        </p>
        <p>
          <span
            >(8) 作品所提供的广告，不得含有强制安装软件或出现捆绑下载的行为。
          </span>
        </p>
        <p>
          <span
            >(9) 广告内容和广告投放行为，应符合法律法规、政策要求的其他内容。
          </span>
        </p>
        <p>
          <strong>4.4 关于儿童可阅读内容的特殊要求</strong>
        </p>
        <p>
          <span
            >(1)
            禁止上线网络小说类、电台类等长音频（例如：斗罗大陆、吞噬苍穹；情感电台等）。</span
          >
        </p>
        <p>
          <span
            >(2)
            禁止渲染校园恋情、校园霸凌、拜金主义、封建迷信等传达不良价值观的内容呈现。</span
          >
        </p>
        <p>
          <span
            >(3)
            禁止“蓝鲸死亡游戏”、“死亡窒息”、“心跳文学”等诱导青少年自杀倾向的内容。</span
          >
        </p>
        <p>
          <span
            >(4)
            禁止无底线追星、饭圈互撕等价值导向不良价值观内容出现，严厉打击与未成年人对于平台热搜打榜应援、明星话题刷屏、挑拨未成年人对立、对骂互喷、人肉搜索、攀比炫富等相关的内容。</span
          >
        </p>
        <p>
          <span
            >(5)
            禁止作品内出现劣迹艺人、敏感艺人的作品、信息及所有可能对未成年人产生不良影响的内容。</span
          >
        </p>
        <p>
          <span
            >(6)
            禁止传达错误价值观的内容呈现，如仅以偷、抢等传递错误价值的互动方式，例如偷好友能量碎片，出现游戏、对战、欺负（好友）等非正向理念传达的字眼。</span
          >
        </p>
        <p>
          <span
            >(7) 禁止作品素材具备诱导性，如游戏性强、诱导下载的作品命名（如 xx
            射击、 xx 大作战）、作品图标等，可结合作品核心服务及作品特点展开（如
            xx 互动英语）。</span
          >
        </p>
        <p>
          <strong>4.5.鼓励提供的作品内容</strong>
        </p>
        <p>
          <span
            >(1)
            我司鼓励网络信息内容服务平台坚持主流价值导向，优化信息推荐机制，加强版面页面生态管理，在重点环节呈现以下内容：</span
          >
        </p>
        <p>
          <span
            >(2)
            宣传习近平新时代中国特色社会主义思想，全面准确生动解读中国特色社会主义道路、理论、制度、文化的；</span
          >
        </p>
        <p>
          <span>(3)宣传党的理论路线方针政策和中央重大决策部署的；</span>
        </p>
        <p>
          <span
            >(4) 展示经济社会发展亮点，反映人民群众伟大奋斗和火热生活的；</span
          >
        </p>
        <p>
          <span
            >(5)
            弘扬社会主义核心价值观，宣传优秀道德文化和时代精神，充分展现中华民族昂扬向上精神风貌的；</span
          >
        </p>
        <p>
          <span
            >(6)
            有助于提高中华文化国际影响力，向世界展现真实立体全面的中国的；</span
          >
        </p>
        <p>
          <span>(7)适合未成年人的，有利于未成年人身心健康的优质内容；</span>
        </p>
        <p>
          <span
            >(8)
            其他讲品味讲格调讲责任、讴歌真善美、促进团结稳定等的内容。</span
          >
        </p>
        <p>
          <strong>4.6 坚决抵制违法信息。</strong
          ><span
            >开发者在提供服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利，同时禁止开发者应用程序的用户存在制作、复制、发布、传播含有以下内容的信息或从事相关行为：</span
          >
        </p>
        <p>
          <span>(1)</span
          ><span>反对宪法所确定的基本原则的、社会主义制度的。</span>
        </p>
        <p>
          <span>(2)</span
          ><span
            >危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。</span
          >
        </p>
        <p><span>(3)</span><span>损害国家荣誉和利益的。</span></p>
        <p>
          <span>(4)</span
          ><span
            >歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的。</span
          >
        </p>
        <p>
          <span>(5)</span
          ><span
            >宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的。</span
          >
        </p>
        <p>
          <span>(6)</span><span>煽动民族仇恨、民族歧视，破坏民族团结的。</span>
        </p>
        <p>
          <span>(7)</span><span>破坏国家宗教政策，宣扬邪教和封建迷信的。</span>
        </p>
        <p>
          <span>(8)</span><span>散布谣言，扰乱社会秩序，破坏社会稳定的。</span>
        </p>
        <p>
          <span>(9)</span
          ><span>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。</span>
        </p>
        <p>
          <span>(10)</span><span>侮辱或者诽谤他人，侵害他人合法权益的。</span>
        </p>
        <p>
          <span>(11)</span
          ><span>煽动非法集会、结社、游行、示威、聚众扰乱社会秩序。</span>
        </p>
        <p><span>(12)</span><span>以非法民间组织名义活动的。</span></p>
        <p>
          <span>(13)</span
          ><span
            >违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的。</span
          >
        </p>
        <p>
          <span>(14)</span
          ><span>不符合《即时通信工具公众信息服务发展管理暂行规定》规定。</span>
        </p>
        <p>
          <span>(15)</span
          ><span>相关法律法规或本协议、相关协议、规则等禁止的。</span>
        </p>
        <p>
          <strong>4.7 严格防范与抵制不良信息：</strong
          ><span
            >开发者在提供服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：</span
          >
        </p>
        <p><span>(1)</span><span>使用夸张标题，内容与标题严重不符的；</span></p>
        <p><span>(2)</span><span>炒作绯闻、丑闻、劣迹等的；</span></p>
        <p><span>(3)</span><span>不当评述自然灾害、重大事故等灾难的；</span></p>
        <p>
          <span>(4)</span><span>带有性暗示、性挑逗等易使人产生性联想的；</span>
        </p>
        <p>
          <span>(5)</span><span>展现血腥、惊悚、残忍等致人身心不适的；</span>
        </p>
        <p><span>(6)</span><span>煽动人群歧视、地域歧视等的；</span></p>
        <p><span>(7)</span><span>宣扬低俗、庸俗、媚俗内容的；</span></p>
        <p>
          <span>(8)</span
          ><span
            >可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；</span
          >
        </p>
        <p><span>(9)</span><span>其他对网络生态造成不良影响的内容。</span></p>
        <p>
          <strong>4.8严格禁止并防范其他不利于未成年人身心健康的信息。</strong
          ><span
            >开发者需重视未成年人的权益保护，应当在服务过程禁止并禁止开发者应用程序的用户制作、复制、传播、转发含有以下内容内容或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：</span
          >
        </p>
        <p><span>(1)</span><span>涉未成年人隐私的相关内容。</span></p>
        <p>
          <span>(2)</span
          ><span
            >含有胁迫、引诱、教唆未成年人参加黑社会性质组织或从事违法犯罪活动的内容，以及其他一切利用我司平台对未成年人实施违法犯罪行为的内容。</span
          >
        </p>
        <p>
          <span>(3)</span><span>影响、危害、诱导未成年人身心健康的内容。</span>
        </p>
        <p>
          <span>(4)</span
          ><span>带有性暗示、性挑逗等易使人产生性联想的信息内容。</span>
        </p>
        <p>
          <span>(5)</span
          ><span>展现血腥、惊悚、残忍等致人身心不适的信息内容。</span>
        </p>
        <p><span>(6)</span><span>宣扬低俗、庸俗、媚俗内容的信息内容。</span></p>
        <p>
          <span>(7)</span
          ><span
            >可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的信息内容。</span
          >
        </p>
        <p>
          <span>(8)</span
          ><span
            >危害未成年人健康的广告，以及利用校服、教材等素材，发布或者变相发布的商业广告。</span
          >
        </p>
        <p>
          <span>(9)</span
          ><span
            >其他影响、危害未成年人安全和身心健康的信息内容或者诱导未成年人作出不利于其安全和身心健康行为的信息内容。</span
          >
        </p>
        <p class="content-big-title"><strong>五、内容管控要求 </strong></p>
        <p><strong>5.1作品内容安全管控</strong></p>
        <p>
          <span
            >开发者拟在应用商店发布作品前，应按照本公约要求，采取人工或其他智能的管理或技术措施筛查是否存在公约列明的违法违规信息内容行为，并采取有效措施对相应内容予以删除处理。</span
          >
        </p>
        <p>
          <span
            >我司将针对开发者拟在应用商店发布的作品进行事前内容筛查，如发现本公约列明的禁止性内容，将给予不予上架的处理决定，直至纠正为止。</span
          >
        </p>
        <p>
          <strong>5.2.用户生成内容安全的持续管控</strong>
        </p>
        <p>
          <span
            >开发者须对作品内用户生成的内容需进行有效的管控，包括但不限于：</span
          >
        </p>
        <p>
          <span
            >(1)
            采用有效的技术或人工管理方法来过滤公约列明的违法违规信息内容，杜绝在作品或服务中出现违法违规不良信息内容，内部需明确内容安全负责机构，并建立多重召回机制、内容安全应急响应机制等确保内容信息安全的必要措施和和合规建设。</span
          >
        </p>
        <p>
          <span
            >(2)
            定期核验用户账号身份信息并进行用户生成内容进行巡检，同时保存有关记录。制定过滤机制对账号名称、昵称、简介、备注、标识、评论等功能及内容中的违法有害信息进行防范处置并保存有关记录。
          </span>
        </p>
        <p>
          <span
            >(3)
            作品应具备服务关闭功能，对严重违法违规的用户停止提供部分或全部服务的功能，并对。
          </span>
        </p>
        <p>
          <span
            >(4)
            如作品内有社区版块的，开发者须履行管理社区版块责任，社区版块中不得包含违反法律、法规、条例及其他规定的内容。</span
          >
        </p>
        <p>
          <strong>5.3内容违法违规投诉及反馈机制建设</strong>
        </p>
        <p>
          <span
            >开发者应针对其在应用商店发布的作品建立用户生成内容的举报机制，并于</span
          ><span>【15天/法律规定的期限】</span><span>内及时作出响应。</span>
        </p>
        <p>
          <span
            >开发者应在其所发布作品的用户服务协议、隐私政策或其他适当位置，公布针对用于在使用其作品、服务过程的内容安全要求，同时明确内容信息安全违法违规行为的投诉、举报的渠道，确保用户知悉内容违法违规的投诉、举报及相应的反馈机制。</span
          >
        </p>
        <p class="content-big-title"><strong>六、内容管理规定</strong></p>
        <p>
          <span
            >6.1开发者作品应当符合本公约要求以及应用商店的内容审核规范和相关规则。</span
          >
        </p>
        <p>
          <span
            >6.2开发者同意：其提供的作品或更新的作品或内容信息先经内部自行审核且不存在任何前述违法违规信息内容清楚后，方可在应用商店发布或上架，同时我司具有作品上架和更新的形式审查的权利，将采取人工、技术等方式进行内容安全审核。</span
          >
        </p>
        <p>
          <span
            >6.3开发者同意：作品发布后，我司有权持续该等审核。且上述审核过程中，我司有完全的权利判断是否同意发布或终止发布该作品，包括在不通知发布者的情况下，不同意发布作品，或对已经发布的作品进行删除、屏蔽等处理；同时，在开发者未及时对于作品的更新内容进行上传的情况下，我司可以自行从其他渠道搜索作品的更新内容并在应用商店进行发布，在此情况下视为开发者自行发布。</span
          >
        </p>
        <p class="content-big-title"><strong>七、附则</strong></p>
        <p>
          <span
            >7.1对于在我司平台中以造谣、诽谤等手段诋毁我司的声誉及信誉，阻碍我司内容审核履行职责的行为，我司有权不经通知依据我司本公约立即采取删除信息，停止、终止有关作品运营等处理措施。</span
          >
        </p>
        <p>
          <span
            >7.2本公约为动态文档，我们有权依照互联网发展的不同阶段，结合相关法律法规更新、产品运营需求和平台管理经验的不断丰富，出于维护用户合法权益和我司合法权益的目的，不断完善本规定。你应反复查看以便获得最新信息。</span
          >
        </p>
        <p>
          <span
            >7.3关于我司的服务，我司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。你对我司服务的使用行为必须自行承担相应风险。</span
          >
        </p>
        <p>
          <span
            >7.4本规范是在保障遵守国家法律法规、维护公序良俗，以及保护他人合法权益的基础上，为打造安全、健康、清朗、优质平台的更高目标而制定的，我司在能力范围内尽最大的努力依照有效的法律法规做出判断，但是并不保证其判断完全与司法机构、行政机关的判断一致，开发者已经理解因此产生的后果并同意自行承担。</span
          >
        </p>
        <p>
          <span
            >7.5本公约及附属文件均属于我司《步步高开发者平台开发者服务协议》的一部分，与《步步高开发者平台开发者服务协议》具有同等法律效力。您还应遵守该公约之外的适用的服务公约和规范，具体包括：</span
          >
        </p>
        <p>《应用平台开发者合规公约》</p>
        <p>《应用审核规范》</p>
        <p>《开发者帐号与应用处置原因及措施》</p>
        <p>
          <span>7.6本规定自2023年07月14日起施行。</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
strong {
  font-weight: 600;
}
em {
  font-style: italic;
}
.content-right {
  height: 100%;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  .content {
    padding-right: 30px;
  }
  p {
    line-height: 160%;
    text-indent: 2em;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 18px;
    color: #333333;
    margin-bottom: 8px;
    text-indent: 0;
    font-weight: 600;
  }
}
</style>
