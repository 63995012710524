<template>
  <div class="content-area">
    <div class="progress-box">
      <div class="progress-item">
        <img
          src="@/assets/img/progress-blue.png"
          alt="创建应用"
          class="progress-img"
        />
        应用更新
      </div>
      <div class="progress-item undone">
        <img
          src="@/assets/img/progress-gray.png"
          alt="提交审核"
          class="progress-img"
        />
        提交审核
      </div>
    </div>
    <div class="top-content">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">应用信息</span>
      </div>
      <div class="common-style">
        <span class="span-label"
          >上传APK包
          <span class="red-star">*</span>
        </span>
        <upload-slice
          btn-id="uploadBtn"
          :server-url="$axios.defaults.baseURL + 'upload/apk'"
          @uploadProgress="uploadProgress"
          @uploadSuccess="uploadSuccess"
          @uploadError="uploadError"
          @uploadStart="uploadStart"
        >
          <el-button
            id="uploadBtn"
            type="success"
            :loading="apkUploadBtn"
            style="width:100px;position: relative;padding:8px 0;"
            >浏览</el-button
          >
          <el-progress v-if="percent" :percentage="percent"></el-progress>
        </upload-slice>
        <span v-if="errArea && !isApkUploaded" class="error-desc"
          >请上传apk</span
        >
        <span
          class="paper-desc"
          style="margin-left: 120px;padding-top: 10px;display: inherit;"
          >建议您将APP内置的图标替换为高清图标，以便在家教机安装后显示清晰美观的应用图标。</span
        >
      </div>
      <div class="apkinfo-box">
        <p>包名：{{ backfillData.apkPackageName }}</p>
        <p>版本名称：{{ backfillData.apkVersionName }}</p>
        <p>版本号：{{ backfillData.apkVersionCode }}</p>
        <p>大小：{{ backfillData.apkSize | _formatSize }}</p>
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <span class="span-label"
          >版权证明
          <span class="red-star">*</span>
        </span>
        <span class="paper-desc">
          （请提供《计算机软件著作权证书》，提交版权证明可在客户端显示“官方”标签，未提交相关资质可能会影响
          您的应用上线。文件小于2M，支持格式：JPG/PNG。）
        </span>
      </div>
      <div
        class="common-style"
        style="margin-top: 36px;"
        :class="!disabledShow ? 'div-7' : ''"
      >
        <div class="upload-box" @mouseover="showDeleteImg('1')">
          <el-upload
            ref="upload1"
            class="avatar-uploader"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :on-success="
              res => {
                return handleAvatarSuccess(res, '1');
              }
            "
            :before-upload="beforeAvatarUpload"
            :disabled="isUpload1"
            accept="image/png, image/jpeg"
          >
            <img
              v-if="backfillData.imageUrl1"
              :src="backfillData.imageUrl1"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <img
              v-if="isDelete1"
              src="@/assets/img/delete-img.png"
              alt
              class="delete-icon"
              @click.stop="deleteImg('1')"
            />
          </el-upload>
          <!-- <p v-if="ishaveSexiocn1" style="line-height: 15px;color:red;display:flex;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p>             -->
        </div>
      </div>
      <div v-if="errArea && !isApkUploaded" class="error-div">
        请上传版权证明
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <span class="span-label">特殊资质</span>
        <span class="paper-desc">
          （如果您的应用属于新闻类，直播类，P2P借贷类等，请参考特殊应用资质规范提交对应的资质。文件小于2M，
          支持格式：JPG/PNG）
        </span>
      </div>
      <div class="common-style" :class="!disabledShow ? 'div-7' : ''">
        <div class="upload-box" @mouseover="showDeleteImg('2')">
          <el-upload
            ref="upload2"
            class="avatar-uploader"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :on-success="
              res => {
                return handleAvatarSuccess(res, '2');
              }
            "
            :before-upload="beforeAvatarUpload"
            :disabled="isUpload2"
            accept="image/png, image/jpeg"
          >
            <img
              v-if="backfillData.imageUrl2"
              :src="backfillData.imageUrl2"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <img
              v-if="isDelete2"
              src="@/assets/img/delete-img.png"
              alt
              class="delete-icon"
              @click.stop="deleteImg('2')"
            />
          </el-upload>
          <!-- <p v-if="ishaveSexiocn2" style="line-height: 15px;color:red;display:flex;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p> -->
        </div>
      </div>
    </div>
    <div v-show="isShow" class="fb-content top-content">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">发布类型</span>
      </div>
      <div class="common-style">
        <span class="span-label"
          >发布的类型
          <span class="red-star">*</span>
        </span>
        <el-radio v-model="radio" label="0" style="margin-right:185px;"
          >常规发布</el-radio
        >
        <el-radio v-model="radio" label="1">适配上传</el-radio>
        <el-row :gutter="20">
          <el-col :span="12" :offset="6">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :span="12" :offset="6">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
      </div>
      <div
        class="common-style"
        :class="!disabledShow ? 'div-7' : ''"
        style="margin-top:10px;color:#CCC;"
      >
        <span>指该应用版本首次上传或更新</span>
        <span style="margin-left:110px;"
          >指该应用版本针对步步高家教机某机型进行兼容适配上传</span
        >
      </div>
      <div v-if="errArea && radio === '2'" class="error-div">
        请选择发布的类型
      </div>
    </div>
    <div class="bottom-content">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">完善信息</span>
      </div>
      <div class="common-style">
        <span class="span-label"
          >应用名称
          <span class="red-star">*</span>
        </span>
        <input
          v-show="!modifyBtnShow2"
          v-model="backfillData.apkName"
          type="text"
          class="input"
          placeholder="名字最长为20个字"
        />
        <span v-show="modifyBtnShow2">{{ backfillData.apkName }}</span>
      </div>
      <div v-if="errArea && !backfillData.apkName.trim()" class="error-div">
        应用名称不能为空
      </div>
      <div
        v-if="errArea && backfillData.apkName.trim().length > 20"
        class="error-div"
      >
        名字最长为20个字
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <span class="span-label"
          >上传icon
          <span class="red-star">*</span>
        </span>
        <span class="paper-desc"
          >（支持jpg/png格式,尺寸要求长等于宽,不低于256*256,不超过512*512,圆角不超过48px,大小50k以内）</span
        >
      </div>
      <div class="common-style" :class="!disabledShow ? 'div-7' : ''">
        <div class="upload-box" @mouseover="showDeleteImg('3')">
          <el-upload
            ref="upload3"
            class="avatar-uploader"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :on-success="
              (res, file) => {
                return handleAvatarSuccess(res, '3', file);
              }
            "
            :before-upload="
              file => {
                return beforeAvatarUpload(file, 1024 * 50);
              }
            "
            :disabled="isUpload3"
            accept="image/png, image/jpeg"
          >
            <img
              v-if="backfillData.imageUrl3"
              :src="backfillData.imageUrl3"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <img
              v-if="isDelete3"
              src="@/assets/img/delete-img.png"
              alt
              class="delete-icon"
              @click.stop="deleteImg('3')"
            />
          </el-upload>
          <!-- <p v-if="ishaveSexiocn3" style="line-height: 15px;color:red;display:flex;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p> -->
        </div>
      </div>
      <div v-if="errArea && !isUpload3" class="error-div">请上传icon</div>
      <div class="common-style">
        <span class="span-label"
          >一句话描述
          <span class="red-star">*</span>
        </span>
        <input
          v-show="!modifyBtnShow2"
          v-model="backfillData.shortIntro"
          type="text"
          style="width: 666px;"
          class="input"
          placeholder="描述最长为13个字"
        />
        <span v-show="modifyBtnShow2">{{ backfillData.shortIntro }}</span>
      </div>
      <div v-if="errArea && !backfillData.shortIntro.trim()" class="error-div">
        一句话描述不能为空
      </div>
      <div
        v-if="
          errArea &&
            backfillData.shortIntro.trim() &&
            backfillData.shortIntro.trim().length > 13
        "
        class="error-div"
      >
        描述最长为13个字
      </div>
      <div class="common-style">
        <span class="span-label"
          >应用隐私政策
          <span class="red-star">*</span>
        </span>
        <input
          v-show="!modifyBtnShow2"
          v-model="backfillData.privacyPolicyUrl"
          type="text"
          style="width: 666px;"
          class="input"
          placeholder="请填写以https://开头的链接地址，300字符以内"
        />
        <span v-show="modifyBtnShow2">{{ backfillData.privacyPolicyUrl }}</span>
      </div>
      <div
        v-if="errArea && !backfillData.privacyPolicyUrl.trim()"
        class="error-div"
      >
        应用隐私政策不能为空
      </div>
      <div class="common-style">
        <span class="span-label"
          >开发商名称
          <span class="red-star">*</span>
        </span>
        <input
          v-show="!modifyBtnShow2"
          v-model="backfillData.developer"
          type="text"
          style="width: 666px;"
          class="input"
          placeholder="请输入开发商名称"
        />
        <span v-show="modifyBtnShow2">{{ backfillData.developer }}</span>
      </div>
      <div v-if="errArea && !backfillData.developer.trim()" class="error-div">
        开发商名称不能为空
      </div>
      <div
        v-if="
          errArea &&
            backfillData.developer.trim() &&
            backfillData.developer.trim().length > 50
        "
        class="error-div"
      >
        开发商名称最长为50个字
      </div>
      <div v-if="!modifyBtnShow2" class="common-style">
        <span class="span-label"
          >应用简介
          <span class="red-star">*</span>
        </span>
        <div class="full-address">
          <textarea
            v-model="backfillData.apkIntroduce"
            class="text-area"
            placeholder="应用简介最少为50个字"
          ></textarea>
        </div>
      </div>
      <div
        v-if="errArea && !backfillData.apkIntroduce.trim()"
        class="error-div"
      >
        应用简介不能为空
      </div>
      <div
        v-if="
          errArea &&
            backfillData.apkIntroduce.trim() &&
            backfillData.apkIntroduce.trim().length < 50
        "
        class="error-div"
      >
        应用简介最少为50个字
      </div>
      <div v-if="!modifyBtnShow2" class="common-style">
        <span class="span-label"
          >新版说明
          <span class="red-star">*</span>
        </span>
        <div class="full-address">
          <textarea
            v-model="backfillData.updateInformation"
            class="text-area"
            placeholder="不少于5字列出此版本的更新内容"
          ></textarea>
        </div>
      </div>
      <div
        v-if="errArea && !backfillData.updateInformation.trim()"
        class="error-div"
      >
        新版说明不能为空
      </div>
      <div
        v-if="
          errArea &&
            backfillData.updateInformation.trim() &&
            backfillData.updateInformation.trim().length < 5
        "
        class="error-div"
      >
        不少于5字列出此版本的更新内容
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <div class="common-style" style="margin-bottom: 10px">
          <span class="span-label"
            >上传截图
            <span class="red-star">*</span>
          </span>
          <el-radio
            v-model="uploadImgType"
            :label="0"
            style="margin-right:185px;"
            >竖屏</el-radio
          >
          <el-radio v-model="uploadImgType" :label="1">横屏</el-radio>
        </div>
        <span v-if="uploadImgType == 0" class="paper-desc"
          >(请上传3-5张清晰截图。尺寸为竖图768*1024，格式为jpg/png，每张图片尺寸一致，单张图片不超过2MB。)</span
        >
        <span v-else class="paper-desc"
          >(请上传3-5张清晰截图。尺寸为横图1024*768，格式为jpg/png，每张图片尺寸一致，单张图片不超过2MB。)</span
        >
      </div>
      <div class="common-style" :class="!disabledShow ? 'div-7' : ''">
        <div
          v-for="(item, index) in backfillData.fileList"
          :key="index"
          :class="imgStatus ? 'imgChange' : 'uploadedBox'"
        >
          <img class="thumb-img" :src="item.url" />
          <img
            class="delete-img"
            src="@/assets/img/delete-img.png"
            alt
            @click.stop="deleteImg('4', index)"
          />
          <!-- <p v-if="item.isShow" style="line-height: 15px;color:red;float:right;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p> -->
        </div>

        <div class="upload-box">
          <el-upload
            class="avatar-uploader"
            :style="imgStatus ? 'width:110px' : ''"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                return handleAvatarSuccess(res, '4', file);
              }
            "
            :before-upload="beforeAvatarUpload"
            :disabled="disabledUpload"
            accept="image/png, image/jpeg"
            :file-list="backfillData.fileList"
          >
            <i
              class="el-icon-plus avatar-uploader-icon"
              :style="
                imgStatus ? 'width:110px;height:180px;line-height:180px' : ''
              "
            ></i>
          </el-upload>
        </div>
      </div>
      <div v-if="errArea && !isUpload4" class="error-div">
        请至少上传3张截图
      </div>
      <div class="common-style">
        <span class="span-label">客服电话</span>
        <input
          v-show="!modifyBtnShow2"
          v-model="backfillData.serviceTel"
          maxlength="20"
          type="text"
          style="width: 666px;"
          class="input"
          placeholder
        />
      </div>
      <div v-if="!modifyBtnShow2" class="common-style">
        <span class="span-label">备注说明</span>
        <div class="full-address">
          <textarea
            v-model="backfillData.remark"
            class="text-area"
            maxlength="200"
            placeholder
          ></textarea>
        </div>
      </div>
      <div class="common-style" style="padding-left:120px;margin-top: 10px;">
        <p class="paper-desc">
          1. 如果之前未审核通过，可以在此填写说明以帮助通过。
        </p>
        <p class="paper-desc">
          2. 如应用需要注册才能使用，请提供测试账号和密码。
        </p>
      </div>
      <!-- <div class="common-style">
        <span class="span-label">联网状态<span class="red-star">*</span></span>
        <div class="full-address">
          <div>
            <el-radio
              v-model="backfillData.networkingStatus"
              :label="0"
              style="margin-right: 185px"
              >未联网</el-radio
            >
            <div>指该应用使用过程中不需要联网</div>
          </div>
          <div>
            <el-radio v-model="backfillData.networkingStatus" :label="1"
              >已联网</el-radio
            >
            <div>
              指该应用使用过程中需要联网
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <filing-form ref="filingForm" />

    <el-button class="submit-btn" type="primary" @click="submit"
      >提交审核</el-button
    >
  </div>
</template>

<script>
import { getCommonHeader } from '@/plugins/axios';
import uploadSlice from '@/components/upload';
import FilingForm from '../components/FilingForm';
export default {
  name: 'Update',
  components: {
    uploadSlice,
    FilingForm
  },
  filters: {
    _formatSize(size) {
      let unit;
      let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      while ((unit = units.shift()) && size > 1024) {
        size = size / 1024;
      }
      return (unit === 'B' ? size : size.toFixed(2)) + unit;
    }
  },
  data() {
    return {
      percent: '',
      radio: 2,
      isApkUploaded: false,
      isApkUploaded2: false, //标记是否更新上传apk
      isUpload1: false,
      isUpload2: false,
      isUpload3: false,
      isUpload4: false,
      disabledUpload: false, // 上传超过5张截图，禁止上传

      isDelete1: false,
      isDelete2: false,
      isDelete3: false,

      modifyBtnShow2: false,
      errArea: false,
      disabledShow: false,

      apkSize: 0,
      apkSizeStr: '',
      apkPackageName: '',
      apkVersionName: '',
      apkVersionCode: '',
      localPath: null,
      resoAllowed: true,
      ApkDetailData: {},
      isShow: false,
      apkUploadBtn: false,
      backfillData: {
        imageUrl1: '',
        imageUrl2: '',
        apkName: '',
        apkPackageName: '',
        apkVersionName: '',
        apkVersionCode: '',
        apkSize: '',
        imageUrl3: '',
        shortIntro: '',
        privacyPolicyUrl: '',
        developer: '',
        apkIntroduce: '',
        updateInformation: '',
        fileList: [],
        serviceTel: '',
        remark: '',
        apkDisplayType: '',
        networkingStatus: 0
      },
      oldData: {}, //旧数据，用来对比判断是否有做更改
      // 上传图片类型
      uploadImgType: '0',

      uploadHeader: getCommonHeader(),

      IMGUPLOADURL: this.$axios.defaults.baseURL + 'upload/uploadImg',
      uploadImgTypeArray: [
        [768, 1024],
        [1024, 768]
      ],
      imgStatus: true
      // ishaveSexiocn1:false,
      // ishaveSexiocn2:false,
      // ishaveSexiocn3:false
    };
  },
  computed: {
    getImgPx() {
      return this.uploadImgTypeArray[this.uploadImgType];
    }
  },
  watch: {
    uploadImgType(newvlue, lodvlue) {
      if (newvlue != lodvlue) {
        this.fileList = [];
        this.uploadTypeChange();
        this.backfillData.apkDisplayType = newvlue;
      }
    }
  },
  mounted() {
    this.getApkDetail();
  },
  methods: {
    uploadTypeChange() {
      if (this.uploadImgType == '0') {
        this.imgStatus = true;
      } else {
        this.imgStatus = false;
      }
    },
    //获取应用详情
    getApkDetail() {
      this.$axios
        .post(`/appManage/apkDetail/${this.$route.query.apkId}`)
        .then(res => {
          if (res.data.stateCode === '0') {
            this.ApkDetailData = res.data.data;
            this.dataBackfill();
            this.uploadImgType = this.ApkDetailData.apkDisplayType;
          }
        })
        .catch(() => {});
    },
    // 备案数据
    filingData(data) {
      this.backfillData.filingStatus = data.filingStatus;
      this.backfillData.filingCompanyType = data.filingCompanyType;
      this.backfillData.filingCompanyName = data.filingCompanyName;
      this.backfillData.filingCompanyBusinessLicense =
        data.filingCompanyBusinessLicense;
      this.backfillData.filingCompanyBusinessLicenseUrl =
        data.filingCompanyBusinessLicenseUrl;
      this.backfillData.cooperationAgreementUrl = data.cooperationAgreementUrl;
      this.backfillData.disclaimerLetterUrl = data.disclaimerLetterUrl;
      this.backfillData.filingCompanyCode = data.filingCompanyCode;
      this.backfillData.filingApkCode = data.filingApkCode;
      this.backfillData.apkPackageName = data.apkPackageName;
    },
    //数据回填
    dataBackfill() {
      this.backfillData.imageUrl1 = this.ApkDetailData.copyrightCertificate;
      this.backfillData.imageUrl2 = this.ApkDetailData.qualification;
      this.backfillData.apkName = this.ApkDetailData.apkName;
      this.backfillData.apkPackageName = this.ApkDetailData.apkPackageName;
      this.backfillData.apkVersionName = this.ApkDetailData.apkVersionName;
      this.backfillData.apkVersionCode = this.ApkDetailData.apkVersionCode;
      this.backfillData.apkSize = this.ApkDetailData.apkSize;
      this.backfillData.imageUrl3 = this.ApkDetailData.apkIcon;
      this.backfillData.shortIntro = this.ApkDetailData.shortIntro;
      this.backfillData.privacyPolicyUrl = this.ApkDetailData.privacyPolicyUrl;
      this.backfillData.developer = this.ApkDetailData.developer;
      this.backfillData.apkIntroduce = this.ApkDetailData.apkIntroduce;
      this.backfillData.updateInformation = this.ApkDetailData.updateInformation;
      this.backfillData.networkingStatus =
        this.ApkDetailData.networkingStatus || 0;
      this.ApkDetailData.picUrlList.map(item => {
        this.backfillData.fileList.push({ url: item, isShow: false });
      });
      this.backfillData.serviceTel = this.ApkDetailData.serviceTel;
      this.backfillData.remark = this.ApkDetailData.remark;
      this.radio = this.ApkDetailData.isFit;
      this.backfillData.apkDisplayType = this.ApkDetailData.apkDisplayType;

      this.filingData(this.ApkDetailData);

      //保存初始值
      this.oldData = JSON.parse(JSON.stringify(this.backfillData));

      this.isApkUploaded = true;
      this.isUpload1 = true;
      this.isUpload2 = this.backfillData.imageUrl2 ? true : false;
      this.isUpload3 = true;
      this.isUpload4 = true;

      // 备案信息
      const {
        filingStatus,
        filingCompanyType,
        filingCompanyName,
        filingCompanyBusinessLicense,
        filingCompanyBusinessLicenseUrl,
        cooperationAgreementUrl,
        disclaimerLetterUrl,
        filingCompanyCode,
        filingApkCode,
        apkPackageName,
        apkName
      } = this.ApkDetailData;
      this.$refs.filingForm.initData(
        {
          filingStatus,
          filingCompanyType,
          filingCompanyName,
          filingCompanyBusinessLicense,
          filingCompanyBusinessLicenseUrl,
          cooperationAgreementUrl,
          disclaimerLetterUrl,
          filingCompanyCode,
          filingApkCode,
          apkPackageName
        },
        apkName
      );
      // 重新请求备案信息
      this.$refs.filingForm.handleRefresh(Event);
    },
    // 文件上传之前
    uploadStart(file) {
      // this.apkSize = file.size;
      // this.apkSizeStr = WebUploader.Base.formatSize(file.size);
      console.log(file, 'uploadStart');
      if (file.ext !== 'apk') {
        this.$message.error('请上传apk格式文件！');
        return false;
      }
      this.isApkUploaded = false;
    },
    uploadProgress(percent) {
      this.percent = percent;
      this.apkUploadBtn = true;
    },
    uploadSuccess(res) {
      this.isApkUploaded = true;
      this.apkUploadBtn = false;
      // 对上传的apk进行判断
      if (this.backfillData.apkPackageName != res.data.apkPackageName) {
        this.$alert('<strong>该应用包名与上一个版本的不一致</strong>', '提示', {
          dangerouslyUseHTMLString: true,
          type: 'error'
        });
        return;
      }
      if (this.backfillData.apkVersionCode > res.data.apkVersionCode) {
        this.$alert('<strong>版本号不能小于上一个版本</strong>', '提示', {
          dangerouslyUseHTMLString: true,
          type: 'error'
        });
        return;
      }
      this.isApkUploaded2 = true;
      this.backfillData.apkPackageName = res.data.apkPackageName;
      this.backfillData.apkVersionName = res.data.apkVersionName;
      this.backfillData.apkVersionCode = res.data.apkVersionCode;
      this.backfillData.apkSize = res.data.apkSize;
      this.ApkDetailData.apkMd5 = res.data.apkMd5;
      this.isShow = true;
      this.radio = '2';
      this.localPath = res.data.path;
      // 获取备案信息
      this.$refs.filingForm.getFilingInfo(
        res.data.apkPackageName,
        res.data.apkName
      );
    },
    uploadError(reason) {
      this.apkUploadBtn = false;
      console.log(reason);
      this.$alert('<strong>上传出错</strong>', '提示', {
        dangerouslyUseHTMLString: true,
        type: 'error'
      });
      this.percent = null;
    },
    beforeAvatarUpload(file, limitSize = 1024 * 1024 * 2) {
      let vm = this;
      let isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      let isLt2M = file.size / limitSize < 1;
      let sizeStr = vm.formatSize(limitSize);
      if (!isJPG) {
        vm.$message.error('图片只能是 JPG和PNG 格式!');
      }
      if (!isLt2M) {
        vm.$message.error('图片大小不能超过' + sizeStr + '!');
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, sort, file) {
      // console.log(3123123)
      if (sort !== '4') this.$refs[`upload${sort}`].clearFiles();
      let vm = this;
      var fr;
      switch (sort) {
        case '1':
          this.backfillData.imageUrl1 = res.data;
          this.isUpload1 = true;
          // if(res.stateCode && res.stateCode == 1009) {
          //   this.ishaveSexiocn1 = true;
          // }
          break;
        case '2':
          this.backfillData.imageUrl2 = res.data;
          this.isUpload2 = true;
          // if(res.stateCode && res.stateCode == 1009)  {
          //   this.ishaveSexiocn2 = true;
          // }
          break;
        case '3':
          fr = new FileReader();
          fr.onload = function() {
            var img = new Image();
            img.onload = function() {
              let width = img.width;
              let height = img.height;
              if (
                width >= 256 &&
                width <= 512 &&
                height >= 256 &&
                height <= 512
              ) {
                // if(res.stateCode && res.stateCode == 1009)  {
                //   vm.ishaveSexiocn3 = true;
                // }
                vm.backfillData.imageUrl3 = res.data;
                vm.isUpload3 = true;
              } else {
                vm.$message.error('请上传合适分辨的图片!');
              }
            };
            img.src = fr.result;
          };
          fr.readAsDataURL(file.raw);
          break;
        case '4':
          fr = new FileReader();
          fr.onload = function() {
            var img = new Image();
            img.onload = function() {
              let width = img.width;
              let height = img.height;

              if (width == vm.getImgPx[0] && height == vm.getImgPx[1]) {
                let obj = { url: res.data, isShow: false };
                vm.backfillData.fileList.push(obj);
                let length = vm.backfillData.fileList.length;
                if (length >= 3) vm.isUpload4 = true;
                if (length == 5) vm.disabledUpload = true;
              } else {
                vm.$message.error('请上传合适分辨的图片!');
              }
            };
            img.src = fr.result;
          };
          fr.readAsDataURL(file.raw);
          break;
      }
    },
    deleteImg(sort, index) {
      switch (sort) {
        case '1':
          this.backfillData.imageUrl1 = '';
          this.isUpload1 = false;
          this.isDelete1 = false;
          // if(this.ishaveSexiocn1 == true) {
          //   this.ishaveSexiocn1 = false;
          // }
          break;
        case '2':
          this.backfillData.imageUrl2 = '';
          this.isUpload2 = false;
          this.isDelete2 = false;
          // if(this.ishaveSexiocn2 == true) {
          //   this.ishaveSexiocn2 = false;
          // }
          break;
        case '3':
          this.backfillData.imageUrl3 = '';
          this.isUpload3 = false;
          this.isDelete3 = false;
          // if(this.ishaveSexiocn3 == true) {
          //   this.ishaveSexiocn3 = false;
          // }
          break;
        case '4':
          this.backfillData.fileList.splice(index, 1);
          var length = this.backfillData.fileList.length;
          if (length < 5) {
            this.disabledUpload = false;
          }
          if (length < 3) {
            this.isUpload4 = false;
          }
          break;
      }
    },
    showDeleteImg(sort) {
      switch (sort) {
        case '1':
          if (this.isUpload1) {
            this.isDelete1 = true;
          }
          break;
        case '2':
          if (this.isUpload2) {
            this.isDelete2 = true;
          }
          break;
        case '3':
          if (this.isUpload3) {
            this.isDelete3 = true;
          }
          break;
      }
    },
    //对比对象数据判断是否做更改
    comparedObj() {
      for (let key in this.backfillData) {
        if (Array.isArray(this.backfillData[key])) {
          //如果是数组，判断两个数组是否相等
          if (this.backfillData[key].length === this.oldData[key].length) {
            for (let i = 0; i < this.backfillData[key].length; i++) {
              if (this.backfillData[key][i].url !== this.oldData[key][i].url) {
                return false;
              }
            }
          } else {
            return false;
          }
        } else {
          if (this.backfillData[key] !== this.oldData[key]) {
            return false;
          }
        }
      }
      return true;
    },
    submit() {
      console.log(1213);
      if (this.$sendHttpsRequest(this.backfillData.privacyPolicyUrl)) {
        this.$message.error('请填写https,禁止提交http!');
        return;
      }
      console.log(this.backfillData);
      this.errArea = true;
      // 判断必传文件是否上传
      if (
        !this.isApkUploaded ||
        !this.isUpload1 ||
        !this.isUpload3 ||
        !this.isUpload4
      ) {
        this.$message.warning('您有必填项没填，请完善必填项');
        return;
      }

      // 判断必填项是否填完
      this.backfillData.apkName = this.backfillData.apkName.trim();
      this.backfillData.shortIntro = this.backfillData.shortIntro.trim();
      this.backfillData.privacyPolicyUrl = this.backfillData.privacyPolicyUrl.trim();
      this.backfillData.developer = this.backfillData.developer.trim();
      this.backfillData.apkIntroduce = this.backfillData.apkIntroduce.trim();
      this.backfillData.updateInformation = this.backfillData.updateInformation.trim();
      this.backfillData.serviceTel = this.backfillData.serviceTel.trim();
      this.backfillData.remark = this.backfillData.remark.trim();
      if (
        !this.backfillData.apkName ||
        !this.backfillData.shortIntro ||
        !this.backfillData.apkIntroduce ||
        !this.backfillData.updateInformation ||
        this.backfillData.apkName.length > 20 ||
        this.backfillData.shortIntro.length > 13 ||
        this.backfillData.developer.length > 50 ||
        this.backfillData.apkIntroduce.trim().length < 50 ||
        this.backfillData.updateInformation.trim().length < 5 ||
        this.radio === '2'
      ) {
        this.$message.warning('您有必填项没填，请完善必填项');
        return;
      }

      // 判断备案信息是否填写
      const filingForm = this.$refs.filingForm.submitForm();
      if (!filingForm) {
        this.$message.warning('您有必填项没填，请完善必填项');
        return;
      }

      const {
        filingStatus, // 备案状态（-1单机无需备案，0-未备案，1-已备案）
        filingCompanyType, // 备案主体（0-与开发者账号主体一致、1-与开发者账号主体不一致）
        filingCompanyName, // 备案主体名称
        filingCompanyBusinessLicense, // 统一社会信用代码
        filingCompanyBusinessLicenseUrl, // 营业执照附件url
        cooperationAgreementUrl, // 合作协议url
        disclaimerLetterUrl, // 免责承诺函url
        filingCompanyCode, // 主体备案号
        filingApkCode // 服务/应用备案号
      } = filingForm;

      if (filingStatus === 0) {
        this.$message.warning(
          '您的应用未查到相关的备案信息，请完善备案信息在再行提交'
        );
        return;
      } else if (filingStatus === 1 && !filingCompanyCode) {
        this.$message.warning(
          '您的应用未查到相关的备案信息，请完善备案信息再进行提交'
        );
        return;
      }

      this.filingData(filingForm);

      if (this.comparedObj()) {
        this.$message.error('没有更改内容，无需提交');
        return;
      }
      // if (this.backfillData.shortIntro.length >= 13) {
      //   this.$message.error('描述最长为13个字');
      //   return;
      // }
      // var regu = /(http|https):\/\/\S*/;
      // var re = new RegExp(regu);
      // if (
      //   !this.backfillData.privacyPolicyUrl ||
      //   this.backfillData.privacyPolicyUrl.length >= 300 ||
      //   !re.test(this.backfillData.privacyPolicyUrl)
      // ) {
      //   this.$message.error(
      //     '请填写以http://或者https://开头的链接地址，300字符以内'
      //   );
      //   return;
      // }
      let formData = {
        apkIcon: this.backfillData.imageUrl3,
        apkId: Number(this.$route.query.apkId),
        apkIntroduce: this.backfillData.apkIntroduce,
        apkMd5: this.ApkDetailData.apkMd5,
        apkName: this.backfillData.apkName,
        apkPackageName: this.backfillData.apkPackageName,
        apkSize: this.backfillData.apkSize,
        apkVersionCode: this.backfillData.apkVersionCode,
        apkVersionName: this.backfillData.apkVersionName,
        copyrightCertificate: this.backfillData.imageUrl1,
        localPath: this.localPath,
        isFit: this.radio,
        picUrlList: this.backfillData.fileList.map(item => item.url),
        qualification: this.backfillData.imageUrl2,
        remark: this.backfillData.remark,
        serviceTel: this.backfillData.serviceTel,
        shortIntro: this.backfillData.shortIntro,
        privacyPolicyUrl: this.backfillData.privacyPolicyUrl,
        developer: this.backfillData.developer,
        updateInformation: this.backfillData.updateInformation,
        networkingStatus: this.backfillData.networkingStatus,
        apkDisplayType: this.uploadImgType,
        uploadApk: this.isApkUploaded2 ? true : null
      };

      // 备案信息
      if (filingStatus === 1 && filingCompanyType === 0) {
        formData.filingStatus = filingStatus;
        formData.filingCompanyType = filingCompanyType;
        formData.filingCompanyCode = filingCompanyCode;
        formData.filingApkCode = filingApkCode;
      } else if (filingStatus === 1 && filingCompanyType === 1) {
        formData.filingStatus = filingStatus;
        formData.filingCompanyType = filingCompanyType;
        formData.filingCompanyName = filingCompanyName.trim();
        formData.filingCompanyBusinessLicense = filingCompanyBusinessLicense.trim();
        formData.filingCompanyBusinessLicenseUrl = filingCompanyBusinessLicenseUrl;
        formData.cooperationAgreementUrl = cooperationAgreementUrl;
        formData.filingCompanyCode = filingCompanyCode;
        formData.filingApkCode = filingApkCode;
      } else if (filingStatus === -1) {
        formData.filingStatus = filingStatus;
        formData.disclaimerLetterUrl = disclaimerLetterUrl;
      }

      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios
        .post(`/appManage/updateOrPublish`, formData)
        .then(res => {
          loading.close();
          if (res.data.stateCode == 0) {
            // this.$router.push('/appManage/submited?update=true');
            this.$router.push('/app_Manage');
            // this.$router.push('/manage_submit');
          } else if (res.data.stateCode == 110) {
            this.$alert('<strong>' + res.data.data + '</strong>', '提示', {
              dangerouslyUseHTMLString: true,
              type: 'error'
            });
          } else {
            this.$alert(
              '参数不合法:<strong>' + res.data.data + '</strong>',
              '提示',
              {
                dangerouslyUseHTMLString: true,
                type: 'error'
              }
            );
          }
        })
        .catch(() => {
          loading.close();
          this.$alert('<strong>上传错误，请联系管理员！</strong>', '提示', {
            dangerouslyUseHTMLString: true,
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="less" scoped>
.content-area {
  max-width: 1200px;
  margin: 0 auto;
  .progress-box {
    max-width: 501px;
    margin: 0 auto;
    overflow: hidden;
    padding: 50px 0;
    .progress-item {
      float: left;
      width: 250px;
      margin-left: 1px;
      font-size: 14px;
      color: #3cbeff;
      text-align: center;
      &:first-child {
        margin-left: 0;
      }
      img {
        width: 100%;
      }
    }
    .undone {
      color: #cccccc;
    }
  }
}
.content-area {
  max-width: 1177px;
  margin: 0 auto;
  .submit-btn {
    width: 150px;
    margin: 0 150px 40px;
  }
  .apkinfo-box {
    font-size: 14px;
    color: #666666;
    margin-left: 120px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    margin-top: 10px;
    line-height: 25px;
  }
  .top-content,
  .bottom-content {
    width: 100%; // height: 1060px;
    padding-top: 28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 40px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 20px;
    // .submit-btn {
    //   margin-left: 120px;
    //   width: 150px;
    //   margin-top: 40px;
    // }
    .uploadedBox {
      width: 180px;
      height: 110px;
      display: inline-block;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      border: 1px dashed white;
      &:hover {
        border: 1px dashed #cccccc;
      }
      &:hover .delete-img {
        display: inline-block;
      }
      .thumb-img {
        width: 100%;
        height: 100%;
      }
      .delete-img {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
      }
    }
    .imgChange {
      width: 110px;
      height: 180px;
      display: inline-block;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      border: 1px dashed white;
      &:hover {
        border: 1px dashed #cccccc;
      }
      .thumb-img {
        width: 100%;
        height: 100%;
      }
      .delete-img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .first-line,
  .second-line {
    border-bottom: 1px solid #c7c7c7;
    padding-bottom: 14px;
  }
  .second-line {
    margin-top: 40px;
  }
  .title-bold {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-left: 6px;
  }
  .vertical-line {
    margin-bottom: -2px;
  }
  .span-label {
    display: inline-block;
    width: 120px;
    color: #333333;
  }
  .common-style {
    font-size: 14px;
    color: #666666;
    margin-top: 26px;
  }
  .div-6,
  .div-9 {
    position: relative;
    .paper-desc {
      position: absolute;
      left: 120px;
    }
  }
  .div-7 {
    margin-left: 120px;
    margin-top: 35px;
  }
  .no-upload-box {
    margin-left: 120px;
    margin-top: 10px;
    position: relative;
    height: 20px;
    span {
      position: absolute;
      font-size: 14px;
      color: #ff7477;
    }
    .span-1 {
      left: 0;
    }
    .span-2 {
      left: 234px;
    }
    .span-3 {
      left: 466px;
    }
  }
  .err-card {
    font-size: 14px;
    color: #ff7477;
    margin-top: 26px;
  }
  .mail-prompt,
  .phone-prompt {
    margin-left: 120px;
    margin-top: 2px;
  }
  .code-input {
    margin-left: 120px;
    margin-top: 16px;
  }
  .full-address {
    margin-left: 120px;
    margin-top: -16px;
    display: flex;
    .text-area {
      width: 666px;
      height: 77px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: none;
      -webkit-appearance: none;
      border: 1px solid #c7c7c7;
      border-radius: 4px;
      color: #666666;
      font-size: 14px;
      padding: 10px 36px 10px 10px;
      /*overflow: hidden;*/

      &:focus {
        border-color: #3cb1ff;
      }
    }
  }
}
.upload-slice-box {
  display: inline;
}
.input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  width: 600px;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  color: #666666;
  font-size: 14px;
  padding: 10px 36px 10px 10px;
  overflow: hidden;
  &.input-short {
    width: 204px;
  }
  &.input-short-1 {
    width: 144px;
    margin-left: 24px;
  }
  &.input-psd {
    width: 270px;
  }
}

input:focus {
  border: 1px solid #3cb1ff;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}

.upload-box {
  display: inline-block;
  position: relative;
  .delete-icon {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

.area-select {
  height: 40px;
  border: 1px solid #c7c7c7;
}

.red-star {
  color: red;
  margin-left: 5px;
  display: inline-block;
}

.error-desc {
  margin-left: 14px;
  color: #ff7477;
  font-size: 14px;
}

.error-div {
  color: #ff7477;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 120px;
}

.modify-btn-1,
.modify-btn-2 {
  background: #ffc267;
  border: 1px solid #ffc267;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.modify-btn-1:hover,
.modify-btn-2:hover {
  background: #deaa5c;
  border: 1px solid #deaa5c;
}

.cancel-btn-1,
.cancel-btn-2 {
  background: #ffffff;
  border: 1px solid #c7c7c7;
  color: #666666;
  margin-left: 24px;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.cancel-btn-1:hover,
.cancel-btn-2:hover {
  background: #f3f2f2;
  border: 1px solid #c7c7c7;
}

.red-star {
  color: red;
  margin-left: 5px;
  display: inline-block;
}
</style>
<style>
.el-radio__input.is-checked .el-radio__inner {
  border-color: #409eff;
  background: #409eff;
}
.avatar-uploader {
  width: 180px;
  margin-right: 53px;
  display: inline-block;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 110px;
  line-height: 110px;
  text-align: center;
}

.avatar {
  width: 180px;
  height: 110px;
  display: block;
}
</style>
