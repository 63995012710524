<template>
  <section class="container">
    <div class="content-right">
      <div class="content-title">应用名称和包名命名规范</div>
      <div class="content">
        <p class="content-big-title">什么是APP名称？</p>
        <p class="content-small-title">
          APP名称是一款应用产品的表达信息，显示在客户端界面供手机用户搜索和下载的重要标识，
          用户下载安装后显示在桌面上该产品图标的下面。该名称要求最能代表该产品。
        </p>

        <p class="content-small-title">
          APP名称与解析包保持一致，如被占用可使用副标题，副标题长度不超过5个字，建议填写公司简称、品牌，不可使用无关字段或其他蹭热度字段，平台将会进行审核，并拥有删除、修改的权利
        </p>

        <p class="content-small-title">请注意：</p>
        <p class="content-small-title">
          （1）一旦上传安装包APP名称和包名不能修改；
        </p>
        <p class="content-small-title">
          （2）不得使用与已上架的应用或知名应用易混淆的名称；
        </p>
        <p class="content-small-title">
          （3）
          APP安装名称字数限制在10个汉字以内（英文输入状态，1个字符为0.5个汉字）；
        </p>
        <p class="content-small-title">
          （4）中文标题，文字间不允许带有“()”，“*”，“-”，“空格”，“，”，“。”等特殊符号；
        </p>
        <p class="content-small-title">
          （5）英文标题，单词间可以有空格，如Fresh fish；
        </p>
        <p class="content-small-title">
          （6）中英混写标题，文字间不允许带有“()”，“*”，“-”，“空格”，“，”，“。”等特殊符号
          （仅针对软件APP）；
        </p>
        <p class="content-small-title">
          （7）不得含有与应用功能无关的搜索词后缀（仅针对软件APP）；如：返利网-淘宝京东折上折
          不符合规则，应改为返利网
        </p>
        <p class="content-big-title">应用名称命名规范</p>

        <p class="content-small-title">什么是包名（Package Name）？</p>
        <p class="content-small-title">
          步步高家教机开发者平台按照符合Android标准的原则设计，使用包名（Package
          Name）
          作为应用的唯一标识。即“包名必须唯一，一个包名代表一个应用，不允许两个应用使用同样的包名。
        </p>

        <p class="content-small-title">
          如果您发现您尚未发布的应用，包名和其他开发者已经发布的应用重复了，您可以通过申诉的形式认领该应用，或者改包名重新发布。具体如何认领，可联系步步高家教商务。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      color: #333333;
    }
    .span1 {
      color: red;
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
