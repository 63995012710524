<template>
  <div class="container">
    <h2>侵权投诉指引</h2>
    <h3>1. 侵权投诉流程</h3>
    <h4>1.1 通知</h4>
    <div>
      <p>
        若权利人认为第三方在步步高相关平台提供的应用程序或者其他相关内容侵犯其合法权益，可按照以下要求提交相关材料进行投诉，通知步步高：
      </p>
      <p>
        权利人应向步步高提交书面投诉通知材料，通知材料的内容应包括但不限于以下内容：
      </p>
      <ol>
        <li>
          权利人主体信息和相关材料：包括权利人的姓名（公司名称）、联系方式、地址、营业执照（单位）或者身份证（个人）、相关授权证明等证明权利人主体资格的材料；
        </li>
        <li>
          权利人要求
          <p>要求删除或者断开链接的涉嫌侵权内容名称和网络地址（或包名）；</p>
        </li>
        <li>构成侵权的初步证明材料</li>
        <p>该初步证明材料应包括：</p>
        <ol style="list-style-type: upper-roman">
          <li>
            权利人拥有权利的权属证明材料：包括但不限于相关有权机构颁发的版权证书、商标权证书、专利权证书、作品首次公开发表或发行日期证明材料、创作手稿、经权威机构签发的作品创作时间戳、作品备案证书等能证明权利人拥有相关权利的有效权属证明；
          </li>
          <li>
            被投诉者提供的内容构成侵权的证明：包括但不限于被投诉方提供的内容构成对权利人的版权、商标权或专利权等侵害的有效证明材料及书面事实说明等。
          </li>
          <li>提供侵权内容详情页面链接。</li>
        </ol>
        <li>
          权利人保证
          <p>权利人的通知材料应包含以下保证：</p>
          <ul>
            <li>
              权利人在通知中的陈述和提供的相关材料皆是真实、有效和合法的，并保证自行承担由此产生的全部法律责任，包括但不限于步步高根据权利人的通知下架有关内容而给步步高造成的任何损失。
            </li>
            <li>
              通知及相关证明材料准备时需注意的事项以及投寄地址详见下文注意事项中的说明。
            </li>
          </ul>
        </li>
      </ol>
    </div>
    <h4>1.2 步步高反馈</h4>
    <div>
      <p>
        步步高在收到通知后，将对通知进行形式审查，通知不符合前述要求的，权利人应在5个工作日内向步步高补充提交有关证据材料或其他材料，权利人应予配合，权利人逾期未予补充完善通知的，步步高将视该侵权通知无效。
      </p>
      <p>如通知符合前述要求的，步步高将根据相关法律法规之规定尽快进行处理。</p>
    </div>
    <h3>2. 注意事项</h3>
    <ol>
      <li>
        本指引中的权利人，指拥有版权、商标权、专利权等合法权益的原始所有人或经原始所有人合法授权的权利享有者，包括自然人、法人或其他组织等。
      </li>
      <li>
        为了确保投诉的真实性和有效性，权利人的书面通知及其他相关证明材料，原则上应提供原件，不能提供原件的，应提供复印件（在复印件上应有权利人的签字/签章），若材料涉外的，应按照法律的规定进行公证转递，并同时提供相应的公证转递材料。
      </li>
      <li>
        通知提交步步高的方式有以下:将前述全部的纸质版本材料清晰扫描后通过电子邮件发送至步步高指定电子邮箱：<span
          style="font-weight:bold;color: #000;"
          >weichaonan@eebbk.com</span
        >
      </li>
      <li>
        （4）若权利人已经因为被投诉人提供的内容向相关行政部门或司法机关提起行政投诉或诉讼的，请在提交通知时，将相关受理证明及提交行政部门或司法机关的证据材料一同提交给步步高，这将有利于权利人的投诉的处理。
      </li>
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  padding-bottom: 40px;
  color: #666;
  font-size: 14px;
  line-height: 30px;
  h2,
  h3,
  h4 {
    color: #333333;
    line-height: 60px;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 100;
  }
  p {
    color: #666;
    font-size: 14px;
  }
  p + div {
    padding-left: 2em;
  }
  h3 + p,
  h4 + p {
    text-indent: 2em;
  }
  h3 + div,
  h4 + div {
    padding-left: 2em;
  }
  h4 {
    margin: 5px 0;
    text-indent: 1em;
    font-weight: 100;
    font-size: 16px;
  }
  h3 + div,
  h4 + div {
    & > p {
      text-indent: 2em;
    }
  }
}
</style>
