<template>
  <div class="layout-wrapper">
    <Header v-if="isAgreement" />
    <router-view class="pad-header" />
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
      isAgreement: true
    };
  },
  components: {
    Header: () => import('./Header'),
    Footer: () => import('./Footer')
  },
  updated() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
  mounted() {
    if (
      [
        '/check_rule',
        '/check_quality',
        '/check_measure',
        '/check_promise',
        '/register_convention',
        '/register_threeConvention',
      ].includes(this.$route.fullPath)
    ) {
      this.isAgreement = false;
    }
  }
};
</script>

<style lang="less" scoped>
.pad-header {
  padding-top: 82px;
}
</style>
