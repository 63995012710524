<template>
  <div class="content-right">
    <div class="content-title">应用承诺（免责）函模板</div>
    <div class="content">
      <p class="content-big-title">承诺函</p>
      <p class="content-small-title">致步步高教育电子有限公司：</p>

      <p class="content-small-title">
        本公司承诺并确认上传至步步高家教机应用商店的应用名称：【】为我司独立研发完成，不存在任何侵犯第三方合法权益的情形，本公司拥有该产品的完整权利，包括但不限于版权、再次授权等。
      </p>

      <p class="content-small-title">
        同时，本公司保证该应用符合国家法律法规，并保证该应用不存在以下情形：（一）反对宪法确定的基本原则的；（二）危害国家统一、主权和领土完整的；（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；（五）宣扬邪教、迷信的；（六）散布谣言，扰乱社会秩序，破坏社会稳定的；（七）宣扬淫秽、赌博、暴力或者教唆犯罪的；（八）侮辱或者诽谤他人，侵害他人合法权益的；（九）危害社会公德或者民族优秀文化传统的；（十）借贷服务及类似借贷性质的服务不得以任何形式向用户收取押金、保证金及类似收款；（十一）有法律、行政法规和国家规定禁止的其他内容的。
      </p>

      <p class="content-small-title">
        本公司承诺该应用在步步高家教机应用商店上架期间，若推广应用存在违反以上保证的情形，本公司完全接受并配合贵司的处理方式，包括但不限于立即下架应用、冻结结算账户等，同时本公司愿意承担所有法律责任，并赔偿贵司因此遭受的一切损失。
      </p>
      <p class="end">企业名称：</p>
      <p class="end">日期：</p>
      <p class="end">（盖章有效）</p>
      <p class="content-big-title">承诺函（授权软件）</p>

      <p class="content-small-title">致步步高教育电子有限公司：</p>
      <p class="content-small-title">
        本公司承诺并确认上传至步步高家教机应用商店的应用软件（具体名称为【
        】，已获得该软件著作权人【
        】合法授权，本公司有权将该软件上传至步步高家教机应用商店供用户下载。我司保证本应用软件所提供的内容（包括但不限于文字、图片、视频、音频等）均已获得合法授权，有权在本应用软件中传播使用，不存在任何侵犯第三方合法权益的情形（包括但不限于商标权，肖像权，版权等）。
      </p>
      <p class="content-small-title">
        同时，本公司保证该应用软件及其所提供的内容（包括但不限于文字、图片、视频、音频等）符合国家法律法规，并保证该应用不存在以下情形：（一）反对宪法确定的基本原则的；（二）危害国家统一、主权和领土完整的；（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；（五）宣扬邪教、迷信的；（六）散布谣言，扰乱社会秩序，破坏社会稳定的；（七）宣扬淫秽、赌博、暴力或者教唆犯罪的；（八）侮辱或者诽谤他人，侵害他人合法权益的；（九）危害社会公德或者民族优秀文化传统的；（十）借贷服务及类似借贷性质的服务不得以任何形式向用户收取押金、保证金及类似收款；（十一）有法律、行政法规和国家规定禁止的其他内容的。
      </p>
      <p class="content-small-title">
        本公司承诺该应用软件在步步高家教机应用商店上架期间，若推广应用软件存在违反以上保证的情形，本公司完全接受并配合贵司的处理方式，包括但不限于立即下架应用、冻结结算账户等，同时本公司愿意承担所有法律责任，并赔偿贵司因此遭受的一切损失。
      </p>
      <p class="content-small-title">
        如果步步高家教机应用商店因为该应用软件受到来自行政或者司法部门的处理，包括但不限于被采取行政强制措施、行政处罚、赔偿损失、承担律师费、公证费、诉讼费等，我公司负责赔偿步步高家教机应用商店因此受到的全部损失，上述损失在步步高家教机应用商店被行政部门或者司法部门确定的履行期限内由我公司一次性支付给贵公司。
      </p>
      <p class="content-small-title">
        本承诺函自承诺人加盖公章之日起生效，并且此承诺不可撤回。：
      </p>
      <p class="end">企业名称：</p>
      <p class="end">日期：</p>
      <p class="end">（盖章有效）</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      color: #333333;
    }
    .span1 {
      color: red;
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-right: 40px;
  }
}
</style>
