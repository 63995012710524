<template>
  <section class="container">
    <div class="content-right">
      <div class="content-title">开发者帐号与应用处置原因及措施</div>
      <div class="content">
        <p>
          <span
            >出于对用户体验、广大开发者利益和平台生态的考虑，如果您的应用违反本应用商店平台的审核标准（包括但不限于<span
              class="goToBlank"
              @click="$goToBlank('check_rule')"
              >《应用审核规范》</span
            >和<span
              class="goToBlank"
              @click="$goToBlank('register_convention')"
              >开发者合规公约</span
            >）</span
          ><span
            >，本平台有可能会对开发者的应用进行冻结、下架以及对您的帐号进行冻结等处置。本规定适用于本应用商店平台内所有开发者及其提交的应用。</span
          >
        </p>
        <h3 class="content-big-title">
          <strong>一、应用和开发者账号处理规则</strong>
        </h3>
        <p>
          <strong>1、应用冻结和下架</strong>
        </p>
        <p>
          <span
            >应用若存在以下几种行为之一的，开发者的应用在被本平台下架的同时有可能会被冻结：
          </span>
        </p>
        <table border="1" class="table">
          <tbody>
            <tr style="text-align: center;">
              <td colSpan="1" rowSpan="1" width="86"><strong>分类</strong></td>
              <td colSpan="1" rowSpan="1" width="79">
                <strong>严重等级</strong>
              </td>
              <td colSpan="1" rowSpan="1" width="317">
                <strong>违规行为</strong>
              </td>
              <td colSpan="1" rowSpan="1" width="85">
                <strong>处置措施</strong>
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="37" width="auto">应用冻结</td>
              <td colSpan="1" rowSpan="20" width="79"><strong>一级</strong></td>
              <td colSpan="1" rowSpan="1" width="317">
                1、存在非法金钱交易或内容；
              </td>
              <td colSpan="1" rowSpan="20" width="85">
                下架应用，且永久冻结应用
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                2、涉及黄赌毒、政治、宗教性质等敏感内容，不当言论宣传，及其他法律所禁止的内容，如非法集资、非法众筹、诈骗、传销、伪造/变造/买卖国家机关公文/证件/印章等；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                3、应用内容带有诽谤、人身攻击或者侮辱个人或者团体的行为；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                4、应用中存在允许用户提交色情内容（如允许用户发布色情照片、或提供色情直播内容等）；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                5、应用具有现金或者流通货币赌博功能（如六合彩、赌球等）；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                6、应用存在反政府、反社会或者不符合主流政治、存在国家法律禁止的行为；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                7、ROOT工具或存在ROOT功能的应用；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                8、应用在使用过程中非法ROOT手机
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                9、需要获取ROOT权限后才可使用的应用
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                10、应用功能为VPN工具或应用存在VPN功能；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                11、涉嫌能够篡改手机号码和电话、短信轰炸类应用；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                12、提供“种子搜索”的搜索引擎应用；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                13、谷歌安装、谷歌框架类应用；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                14、涉嫌非法篡改应用内容；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                15、伪造、变造应用资质；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                16、应用为重新打包其他开发者的应用或破解、盗版，未获得版权所有者授权的应用；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                17、应用上线后，恶意打开服务器开关，使应用内容非法变更；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                18、应用更新后，恶意修改应用内容，且存在病毒、广告、积分墙、恶意扣费等行为；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                19、开发者在提交审核时关闭或隐藏相关应用内容，上架后通过技术手段开启或展示违法违规内容；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                20、开发者帐号下存在3次及以上侵权的恶意行为；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="3" width="79"><strong>二级</strong></td>
              <td colSpan="1" rowSpan="1" width="317">
                1、社交媒体通报的恶意应用；
              </td>
              <td colSpan="1" rowSpan="3" width="85">
                下架应用，且不限时冻结应用，限期整改
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                2、因违规导致主管部门要求下架应用；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                3、应用存在盗版、侵权行为；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="10" width="79"><strong>三级</strong></td>
              <td colSpan="1" rowSpan="1" width="317">
                1、利用虚假免费试用、特惠活动或异性聊天等诱导用户消费以及以任何方式诱导未成年人充值消费等行为；
              </td>
              <td colSpan="1" rowSpan="10" width="85">
                下架应用，且冻结应用90天，限期整改
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                2、应用带有恶意积分墙、插屏/播、悬浮窗、通知栏广告，被发现2次或以上；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                3、应用上架后，存在打开广告恶意广告开关行为，被发现2次或以上；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                4、同版本应用上传被打回2次或以上，且未按照商店审核标准修改，恶意上传；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                5、未经用户同意，自动下载并安装与用户搜索内容不一致的应用，且下架警告后无修改继续上传
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                6、未经用户同意，自动下载安装无关应用，且下架警告后无修改继续上传
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                7、引导页内容，默认捆绑未知应用，翻过引导页后自动安装
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                8、应用退出框，退出标识不明确，容易造成误点导致安装无关应用，且下架警告后无修改继续上传
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                9、应用翻页或滑动时，未经用户同意自动安装无关应用，且下架警告后无修改继续上传
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                10、下载的应用与安装的应用不一致，且下架警告后无修改继续上传，且下架警告后无修改继续上传
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="4" width="79"><strong>四级</strong></td>
              <td colSpan="1" rowSpan="1" width="317">
                1、应用付费功能异常，且验证属实，反馈后不积极配合；
              </td>
              <td colSpan="1" rowSpan="4" width="85">
                下架应用，且冻结应用30天，限期整改
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                2、应用上线后，私自打开<span
                  class="goToBlank"
                  @click="$goToBlank('check_rule')"
                  >审核标准</span
                >不允许的开关，被发现1次或以上（如售彩类应用，上线后打开售彩功能；交友类应用上线后打开诱导付费开关）
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                3、应用内存在暴露镜头、发布内容含有露骨文字，且下架警告后无修改继续上传；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="317">
                4、应用测试中或上架后，被检测为病毒应用，被发现1次或以上；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="auto"></td>
              <td colSpan="1" rowSpan="1" width="79"><strong>五级</strong></td>
              <td colSpan="1" rowSpan="1" width="317">
                应用存在其他违反<span
                  class="goToBlank"
                  @click="$goToBlank('check_rule')"
                  >《应用审核规范》</span
                >和开发者公约的行为
              </td>
              <td colSpan="1" rowSpan="1" width="85">下架应用，且限期整改</td>
            </tr>
          </tbody>
        </table>
        <p style="margin-top: 20px;">
          <strong>2、开发者帐号冻结</strong>
        </p>
        <table border="1" class="table">
          <tbody>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">开发者不当行为</td>
              <td colSpan="1" rowSpan="1" width="163">处置措施</td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">1、上传违法应用</td>
              <td colSpan="1" rowSpan="11" width="163">
                永久冻结账号，下架并冻结开发者账号下所有应用
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                2、利用账号恶意攻击开放平台的
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                3、应用开发者对应用商店已经明确有版权归属的应用私自进行破解、汉化、反编译、重新打包、换皮等二次开发后提交2次或以上，将被取消步步高家教机应用商店开发者资格；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                4、应用开发者一周内重复提交相似内容应用3款或同一开发者帐号一月内提交超过5款相似内容应用将被取消应用商店开发者资格；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                5、应用上线后开启恶意广告插件、病毒、彩票售彩等行为，且警告后无修改继续上传
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                6、营业执照经营范围与应用内容不符，且批量上传应用；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                7、不同开发者帐号，使用相同法人、营业执照批量注册（注册账号达到2个或以上）；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                8、应用上线后，恶意打开服务器开关，使应用内容非法变更；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                9、应用更新后，恶意修改应用内容，且存在病毒、广告、积分墙、恶意扣费等行为；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">10、伪造、变造资质；</td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                11、账号下应用涉嫌非法篡改应用内容
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                12、应用开发者连续多次上传问题应用（不符合审核标准），且退回后未经修改恶意上传，将推迟应用审核；此恶意行为达3次以上
              </td>
              <td colSpan="1" rowSpan="5" width="163">冻结90天</td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                13、开发者帐号下应用以虚假免费试用、特惠活动或异性聊天等诱导用户付费，或以任何方式诱导未成年人充值消费的
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                14、
                开发者帐号下应用带有恶意积分墙、插屏/播、悬浮窗、通知栏广告，被发现2次或以上
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                15、
                开发者帐号下应用上架后，存在打开广告恶意广告开关行为，被发现2次或以上；
              </td>
            </tr>
            <tr>
              <td colSpan="1" rowSpan="1" width="346">
                16、同版本应用上传被打回2次或以上，且未按照商店审核标准修改，恶意上传；
              </td>
            </tr>
          </tbody>
        </table>
        <h2 class="content-big-title">
          <strong>二、处置措施说明</strong>
        </h2>
        <p>
          <span
            >对于违反<span class="goToBlank" @click="$goToBlank('check_rule')"
              >《应用审核规范》</span
            >的应用，步步高应用商店有权单方视情节轻重调整处理措施，包括对开发者及对其应用的处理。处置措施包括但不限于如下内容：</span
          ><span style="color: black;"> &nbsp; </span>
        </p>
        <p>
          <span style="color: black;">1、</span
          ><span
            >开发者帐号冻结：停用开发者在步步高应用商店平台注册的帐号，该帐号无法上传及维护应用。</span
          >
        </p>
        <p>
          <span style="color: black;">2、</span
          ><span
            >应用冻结：应用从步步高应用商店下架，冻结期内该应用无法进行上传。</span
          >
        </p>
        <p>
          <span style="color: black;">3、</span
          ><span
            >下架：终止应用在步步高应用商店运营，并对应用执行下架动作。</span
          >
        </p>
        <p>
          <span style="color: black;">4、</span
          ><span
            >限期整改：步步高向开发者发送整改通知。开发者应在通知提及的限定期限内，对应用进行整改并提交更新版本；逾期未整改的，应用将被下架。</span
          >
        </p>
        <p>
          <span style="color: black;">5、</span><span>多次重犯阶梯处理</span
          ><span style="color: black;"
            ><br />
            &nbsp; &nbsp; &nbsp; &nbsp; a</span
          ><span
            >）应用维度：同一应用同时存在多个问题，将从严处理；针对同一问题或同一类问题多次重犯，每重犯一次会按照梯度处理加重一级。</span
          ><span style="color: black;"
            ><br />
            &nbsp; &nbsp; &nbsp; &nbsp; b</span
          ><span
            >）开发者维度：开发者名下多款应用多次出现恶意问题，会加重处理，造成开发者所有帐号冻结。</span
          >
        </p>
        <h2 class="content-big-title">
          <strong>三、处置措施执行</strong>
        </h2>
        <p>
          <span>1、通知：</span
          ><span>步步高应用商店在发现开发者或应用存在违法违规行为后，</span
          ><u><strong>会第一时间采取通知</strong></u
          ><span>整改、下架应用、冻结应用或冻结开发者帐号等处置措施，并</span
          ><u
            ><strong
              >通过开发者平台中心消息以及电子邮件、手机号等形式通知开发者。</strong
            ></u
          >
        </p>
        <p>
          <span
            >2、整改：对于未永久冻结的应用，开发者可在完成整改后重新提交更新版本，并根据通知邮件的要求提供整改声明，通过审核后重新上架。</span
          >
        </p>
        <p>
          <span
            >3、单方面处置：对于本规则未能涵盖的其他任何违法违规行为，由步步高应用商店根据其严重及影响程度，采取包括但不限于通知整改、下架以及其他步步高应用商店认为适合的处理措施。</span
          >
        </p>
        <h3 class="content-big-title">
          <strong>四、 解冻申请及申诉流程</strong>
        </h3>
        <p><strong>1、申请应用解冻</strong></p>
        <p>
          <span
            >要求：非永久冻结的应用按平台要求进行整改，且保证不再违规后，可在冻结期满后申请解冻，若再次违规，将永久冻结；
          </span>
        </p>
        <p>
          <span>申请方式：邮件申请，收件人：weichaonan@eebbk.com； </span>
        </p>
        <p>
          <span>邮件主题：申请“xx”应用 解冻；</span>
        </p>
        <p>
          <span>邮件内容： </span>
        </p>
        <p>
          <span>申请解冻应用名称、包名；</span>
        </p>
        <p>
          <span>申请解冻理由；</span>
        </p>
        <p>
          <span>申请解冻的辅助证明材料（如附件附上配合已修改的应用）；</span>
        </p>
        <p>
          <span
            >声明承诺函（主体内容需包含违规原因、整改内容，不再违规声明、公司盖章等，格式自拟）;
          </span>
        </p>
        <p>
          <span>审核处理时长：平台收到申请次日起1-3个工作日内。</span>
        </p>
        <p><strong>2、申请帐号解冻</strong></p>
        <p>
          <span
            >要求：非永久冻结的应用/开发者按平台要求进行整改，且保证不再违规后，可在冻结期满后申请解冻，若再次违规，将永久冻结；
          </span>
        </p>
        <p>
          <span>申请方式：邮件申请，收件人：weichaonan@eebbk.com； </span>
        </p>
        <p>
          <span>邮件主题：申请“xx”帐号 解冻；</span>
        </p>
        <p>
          <span>邮件内容： </span>
        </p>
        <p>
          <span>申请解冻的开发者注册邮箱、昵称、公司名；</span>
        </p>
        <p>
          <span>申请解冻理由；</span>
        </p>
        <p>
          <span
            >声明承诺函（主体内容需包含违规原因、整改内容，不再违规声明、公司盖章等，格式自拟）；</span
          >
        </p>
        <p>
          <span
            >开发者注册证明（营业执照和法人身份证正反面，此身份证应与国家企业信用信息公示系统上的法人的身份信息一致）
          </span>
        </p>
        <p>
          <span
            >审核处理时长：平台收到申请次日起15日内，请您耐心等待回复。</span
          >
        </p>
        <p><strong>3、申诉</strong></p>
        <p>
          <span
            >要求：开发者对于帐号与应用的处理措施有异议并可自证不存在违规行为，</span
          ><span
            >请根据本处联系邮件进行申诉。步步高应用商店收到申诉后，将会展开核查，一经核实，将予以相应处理。</span
          >
        </p>
        <p>
          <span>方式：邮件投诉</span
          ><span>，收件人：<strong>weichaonan@eebbk.com； </strong></span>
        </p>
        <p>
          <span>邮件主题：“xx”应用 申诉；“xx”开发者账号申诉；</span>
        </p>
        <p>
          <span>邮件内容： </span>
        </p>
        <p>
          <span>申诉应用名称、包名；申诉开发者账号信息</span>
        </p>
        <p>
          <span>申诉理由；</span>
        </p>
        <p>
          <span
            >申诉材料：①申诉函（写明申诉原因）以及凭证；②申诉<span
              class="goToBlank"
              @click="$goToBlank('check_promise')"
              >承诺函</span
            >；</span
          >
        </p>
        <p>
          <span>审核处理时长：平台收到申请次日起1-3个工作日内。</span>
        </p>
        <p>
          <span> </span>
        </p>
        <p class="content-big-title">五、投诉及举报</p>
        <p>
          <span
            >如果您在体验应用时，发现应用涉及黄赌毒等不文明信息，广告欺诈，恶意吸费、需用户填写银行帐号数据等获取用户敏感个人信息以及其他所有违反现有法律法规规定、违反本应用商店平台<span
              class="goToBlank"
              @click="$goToBlank('check_rule')"
              >《应用审核规范》</span
            >的现象，均可按照以下模版发送邮件至weichaonan@eebbk.com进行反馈。
          </span>
        </p>
        <p><strong>1、邮件模板：</strong></p>
        <p>
          <span>邮件标题：应用违规投诉</span>
        </p>
        <p>
          <span>邮件内容：</span>
        </p>
        <p>
          <span>违规应用名称</span>
        </p>
        <p>
          <span
            >应用链接地址（或违规应用在步步高应用商店的应用详情页截图）</span
          >
        </p>
        <p>
          <span>投诉原因</span>
        </p>
        <p>
          <span>应用违规事实相关截图或视频</span>
        </p>
        <p>
          <span>其他您认为需要说明的事项</span>
        </p>
        <p>
          <span>联系方式（以便步步高在您的帮助配合下依法处理）</span>
        </p>
        <p>
          <span>若您选择邮寄纸质材料的，请将以上材料邮寄发送至以下地点：</span>
        </p>
        <p>
          <span
            >地址：广东省东莞市长安镇步步高东门中路168号小天才研发中心
            法务部</span
          >
        </p>
        <p>
          <span>邮编：523851</span>
        </p>
        <p>
          <u
            ><strong
              >另外，您亦可以通过应用商店相关应用的下载页面中的“举报”按钮一键举报。
            </strong></u
          >
        </p>
        <p>
          <span
            >2、步步高承诺您的投诉为匿名投诉，不予公开您的个人信息（涉及权益侵害等特殊情形且有必要公开您的个人信息时除外）。</span
          ><span> </span>
        </p>
        <p>
          <span>3、</span><span></span
          ><span
            >您需要保证投诉内容及事实的真实性，否则，您可能要承担虚假举报的侵权、违法风险。</span
          >
        </p>
        <p class="content-big-title">
          六、生效及解释
        </p>
        <p>
          <span>1、本规则自发布之日起生效。 &nbsp; &nbsp; </span>
        </p>
        <p>
          <span
            >2、帐号与应用的处理的相关信息可能会通过开发者在应用商店平台注册时填写的电子邮箱或者手机号或其他合理的方式进行通知，开发者需确保所提供的电子邮箱、手机号等信息是真实、有效的，并且未设置过滤规则，否则因此造成的一切责任由开发者自行承担。</span
          >
        </p>
        <p>
          <span
            >3、
            应用商店平台有权在必要时调整本规则，无须另行通知开发者，新的规则一旦公开即有效替代原来的规则，并对应用商店平台应用市场、开发者产生约束力，请开发者时常关注应用商店平台网站。</span
          >
        </p>
        <p>
          <span
            >4、本规则由本应用商店平台拟定，如您有任何疑问，请通过电子邮箱weichaonan@eebbk.com咨询。</span
          >
        </p>
        <p><br /></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
strong {
  font-weight: 600;
}
.table {
  margin-top: 30px;
}
table {
  width: 100%;
  border-collapse: collapse;
  th {
    height: 50px;

    font-size: 16px;
    color: #333333;
    line-height: 50px;
    text-align: left;
    padding-left: 20px;
    border: 1px;
  }
  td {
    // height: 50px;
    padding: 20px;
    padding-left: 10px;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.85);
  }
  .span2 {
    color: skyblue;
  }
}
.content-right {
  height: 100%;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  .content {
    padding-right: 30px;
  }
  p {
    line-height: 160%;
    text-indent: 2em;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 18px;
    color: #333333;
    margin-bottom: 8px;
    text-indent: 0;
    font-weight: 600;
  }
}
</style>
