<template>
  <section class="message">
    <div class="main-body">
      <div class="content-area">
        <div class="table-head">
          <span class="head-status">状态</span>
          <span class="head-sender">发件人</span>
          <span class="head-title">标题</span>
          <span class="head-time">发布时间</span>
        </div>
        <div v-if="!totalCount" class="no-message-desc">暂无消息</div>
        <div
          v-for="(item, index) in messageList"
          v-if="totalCount"
          class="table-body"
          @click="showReplayContent(index, item.state, item.newsId)"
        >
          <div class="body-status body-item">
            <span
              class="checked"
              :class="{ no: !allOn }"
              :data-newsId="item.newsId"
              @click="selectMessage($event)"
            ></span>
            <img
              v-if="item.state === 1 ? false : true"
              src="@/assets/img/news-read.png"
              alt="已读图标"
              class="read-icon"
            />
            <img
              v-if="item.state === 1 ? true : false"
              src="@/assets/img/news-no-read.png"
              alt="未读图标"
              class="read-icon"
            />
          </div>
          <div
            class="body-sender"
            :class="item.state === 1 ? 'un-read-desc' : ''"
          >
            {{ item.sendUser }}
          </div>
          <div
            class="body-title"
            :class="item.state === 1 ? 'un-read-desc' : ''"
          >
            {{ item.headline }}
          </div>
          <div class="body-time">
            <span class="time-span">{{
              formatDate(new Date(item.publishTime), 'yyyy-MM-dd')
            }}</span>
            <img
              :id="'newsId' + item.newsId"
              src="@/assets/img/drop-down-icon.png"
              alt="下拉"
              class="drop-down-icon"
            />
          </div>
          <div
            v-show="showList.length && showList[index].isShow"
            class="select-content"
          >
            <div v-html="item.content"></div>
          </div>
        </div>
        <div v-if="totalCount" class="bottom-box">
          <div class="left-box">
            <span
              class="checked"
              :class="{ no: !allOn }"
              @click="getAllOn()"
            ></span>
            <span class="select-all-span" @click="allSelect">全选</span>
            <span class="delete-span" @click="clickDelete">删除</span>
          </div>
          <div class="pagination-box">
            <span class="span-1">共有{{ totalCount }}条记录</span>
            <img
              src="@/assets/img/previous-icon.png"
              alt="上一页"
              class="previous-icon"
              @click="getMoreMessageList(page - 1)"
            />
            <span class="span-2">{{ page }}</span>
            <span class="span-3">/</span>
            <span class="span-4">{{ pageNum }}</span>
            <img
              src="@/assets/img/next-icon.png"
              alt="下一页"
              class="next-icon"
              @click="getMoreMessageList(page + 1)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="modalShow" class="modal-box"></div>
    <div v-if="modalShow" class="delete-box">
      <p class="delete-desc">删除后无法在查看信息，确定删除吗？</p>
      <div class="btn-box">
        <span class="submit-btn" @click="deleteMessage">确定</span>
        <span class="cancel-btn" @click="modalShow = false">取消</span>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: 'Message',
  data() {
    return {
      unCheck: true,
      deleteList: [],
      allOn: false,
      userName: '',
      signOutShow: false,
      loginShow: false,
      noLoginShow: true,
      messageList: [],
      modalShow: false,
      showList: [],
      page: 1,
      pageNum: 1,
      totalCount: null
    };
  },
  mounted() {
    this.getUserInfo();
    this.getNewsList();
  },
  methods: {
    allSelect() {
      this.allOn = this.allOn ? false : true;
    },
    showReplayContent(index, state, newsId) {
      if (this.showList[index].isShow) {
        $('#newsId' + newsId).removeClass('rotate');
      } else {
        $('#newsId' + newsId).addClass('rotate');
      }
      this.showList[index].isShow = this.showList[index].isShow ? false : true;

      if (state === 1) {
        this.consumeMessage(newsId);
      }
    },
    clickDelete() {
      if (this.deleteList.length) {
        this.modalShow = true;
      }
    },
    deleteMessage() {
      this.$axios
        .post(`userManage/delMessage`, {
          accountId: this.$cookie.getCookie('accountId'),
          newsList: this.deleteList
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            location.reload();
          }
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
        })
        .catch(() => {});
    },
    consumeMessage(newsId) {
      this.$axios
        .post(`userManage/consumeMessage`, {
          accountId: this.$cookie.getCookie('accountId'),
          newsId: newsId
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            this.getNewsList();
            this.getMessageCount();
          }
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
        })
        .catch(() => {});
    },
    selectMessage(event) {
      let target = event.target;
      if (target.classList.contains('no')) {
        target.classList.remove('no');
        this.deleteList.push(target.dataset.newsid);
      } else {
        target.classList.add('no');
        let index = this.deleteList.indexOf(target.dataset.newsid);
        this.deleteList.splice(index, 1);
      }
    },
    getMoreMessageList(page) {
      if (page > this.pageNum || page < 1) {
        return;
      }
      this.page = page;
      this.getNewsList();
    },
    /*
        获取用户消息数据
         */
    getNewsList() {
      this.$axios
        .post(`userManage/getNewsList`, {
          accountId: this.$cookie.getCookie('accountId'),
          pi: this.page,
          ps: 10
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            this.totalCount = data.data.totalCount;
            this.messageList = data.data && data.data.list;
            this.pageNum = Math.ceil(data.data.totalCount / 10);
            for (var i = 0; i < data.data.list.length; i++) {
              this.showList.push({
                id: i + 1,
                isShow: false
              });
            }
          }
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
        })
        .catch(() => {});
    },
    getAllOn() {
      if (this.allOn) {
        this.allOn = false;
        this.deleteList = [];
        return;
      }
      for (let i in this.messageList) {
        this.deleteList.push(this.messageList[i].newsId);
      }
      this.allOn = true;
    },
    getUserInfo() {
      this.$axios
        .post(`userManage/getUserInfo`, {
          object: this.$cookie.getCookie('accountId')
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            if (data.data.completeCode === 0) {
              this.$router.push('/account');
            }
            this.userMail = data.data.email;
          }
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.main-body {
  background: #f2f2f2;
  width: 100%;
  position: relative;
  padding-top: 50px;
  padding-bottom: 120px;
  min-height: 944px;
  .content-area {
    max-width: 1177px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 5px;
    .table-head {
      height: 64px;
      line-height: 64px;
      width: 100%;
      background: #e9e9e9;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .head-status {
        margin-left: 4%;
      }
      .head-status,
      .head-sender,
      .head-time {
        width: 20%;
        display: inline-block;
      }
      .head-title {
        width: 36%;
        display: inline-block;
      }
    }
    .no-message-desc {
      text-align: center;
      height: 64px;
      line-height: 64px;
      color: #666666;
      font-size: 16px;
    }
    .table-body {
      min-height: 64px;
      line-height: 68px;
      width: 100%;
      background: #ffffff;
      color: #666666;
      font-size: 14px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-top: 1px solid #f2f2f2;
      cursor: pointer;
      &:nth-child(1) {
        border-top: none;
      }
      &:hover {
        color: #3991f4;
      }
      .un-read-desc {
        color: #3991f4;
      }
      .select-content {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 4%;
        background: #f5f5f5;
        line-height: 24px;
        padding-left: 4%;
        letter-spacing: 1px;
      }

      .body-status {
        margin-left: 4%;
      }
      .body-status,
      .body-sender,
      .body-time {
        width: 20%;
        display: inline-block;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
      }
      .body-title {
        width: 36%;
        display: inline-block;
        height: 60px;
        line-height: 60px;
      }
      .uncheck-icon {
        cursor: pointer;
      }
      .read-icon {
        margin-left: 15px;
        margin-bottom: -2px;
      }
      .drop-down-icon {
        margin-left: 40px;
        transition: all 0.3s linear;
        cursor: pointer;
      }
      .rotate {
        transform: rotate(180deg);
      }
    }
  }
}

.checked {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url('~@/assets/img/message-checked.png');
  margin-bottom: -2px;
  cursor: pointer;
}

.checked.no {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url('~@/assets/img/message-uncheck.png');
  margin-bottom: -2px;
  cursor: pointer;
}

.bottom-box {
  margin-top: 20px;
  padding-left: 4%;
  .left-box {
    float: left;
    .checked.no,
    .checked {
      margin-bottom: -6px;
    }
    .select-all-span {
      margin-left: 16px;
    }
    .delete-span {
      margin-left: 30px;
      cursor: pointer;
    }
  }
  .pagination-box {
    font-size: 14px;
    color: #333333;
    float: right;
    margin-right: 30px;
    .previous-icon {
      margin-left: 45px;
      margin-right: 75px;
      cursor: pointer;
      margin-bottom: -4px;
    }
    .next-icon {
      margin-left: 75px;
      cursor: pointer;
      margin-bottom: -4px;
    }
    .span-3 {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}

.modal-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.5;
  z-index: 9999;
}

.delete-box {
  background: #ffffff;
  color: #333333;
  width: 451px;
  height: 250px;
  border-radius: 5px;
  opacity: 1;
  z-index: 99999;
  /*position: absolute;*/
  /*top: 30%;*/
  position: fixed;
  top: 0px;
  /* left: 0px; */
  /* right: 0px; */
  bottom: 0px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  padding-top: 90px;
  font-size: 14px;
  .delete-desc {
    text-align: center;
    font-size: 16px;
  }
  .btn-box {
    text-align: center;
    margin-top: 76px;
  }
  .submit-btn {
    display: inline-block;
    width: 185px;
    height: 40px;
    line-height: 40px;
    background: #3cb1ff;
    border: 1px solid #3cb1ff;
    color: #ffffff;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }
  .cancel-btn {
    display: inline-block;
    width: 185px;
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    color: #666666;
    margin-left: 20px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
