<template>
  <section class="home-wrapper">
    <section ref="bannerWrap" class="banner">
      <div ref="bannerImg" class="banner-img"></div>
      <div ref="banner" class="banner-wrap">
        <h1>
          <span>步</span>
          <span>步</span>
          <span>高</span>
          <span>家</span>
          <span>教</span>
          <span>机</span>
        </h1>
        <h2>
          <span>开</span>
          <span>发</span>
          <span>者</span>
          <span>平</span>
          <span>台</span>
        </h2>
        <p>精准聚焦学生用户，专注教育应用分发</p>
        <div class="animation-box">
          <span class="main-line1"></span>
          <span class="main-line2"></span>
          <span class="main-line3"></span>
          <span class="main-line4"></span>
          <span class="main-line5"></span>
          <i class="line1"></i>
          <i class="line2"></i>
          <i class="line3"></i>
          <i class="line4"></i>
          <i class="line5"></i>
          <img src="@/assets/img/animation0.png" class="main-box" />
          <img src="@/assets/img/animation1.png" class="box box1" />
          <img src="@/assets/img/animation2.png" class="box box2" />
          <img src="@/assets/img/animation3.png" class="box box3" />
          <img src="@/assets/img/animation4.png" class="box box4" />
          <img src="@/assets/img/animation5.png" class="box box5" />
        </div>
      </div>
    </section>

    <section class="main">
      <h2 class="need-fade-in">管理中心</h2>
      <div class="btn need-fade-in">
        <div class="upload-app" @click="goNext('/publish')">
          <img src="@/assets/img/upload-img.png" alt />
          <p>发布应用</p>
        </div>
        <div class="manage-app" @click="goNext('/manage')">
          <img src="@/assets/img/manage-img.png" alt />
          <p>管理应用</p>
        </div>
      </div>
    </section>

    <section class="about">
      <h2 class="need-fade-in">关于平台</h2>
      <div class="btn">
        <div class="need-fade-in">
          <img src="@/assets/img/about1.png" alt />
          <h4>精准的分发能力</h4>
          <p>
            深耕教育领域多年，
            <br />拥有海量学生用户
          </p>
        </div>
        <div class="need-fade-in">
          <img src="@/assets/img/about2.png" alt />
          <h4>构建教育生态</h4>
          <p>
            旨在打造优质的教育生态，
            <br />和多方教育类应用深度合作联运
          </p>
        </div>
        <div class="need-fade-in">
          <img src="@/assets/img/about3.png" alt />
          <h4>多方能力接入</h4>
          <p>
            全面开放账号、数据、支付等服务，
            <br />确保资源快速上线
          </p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import { mapState } from 'vuex';
export default {
  name: 'Home',
  data() {
    return {};
  },
  computed: {
    ...mapState(['isAccountPassCheck', 'isLogin'])
  },
  mounted() {
    // 首次调用fixBanner()解决1024的显示问题。
    this.fixBanner();
    window.onresize = this.fixBanner;
    // 这段代码用于滚动动画。
    let $needFadeIn = $('.need-fade-in');
    window.onscroll = () => {
      let scrollTop = $(document).scrollTop();
      $(this.$refs.bannerImg).css({
        backgroundPosition: 'center ' + (-scrollTop / 1000) * 200 + 'px'
      });
      $needFadeIn.each(function() {
        if (
          Math.abs($(this).offset().top - scrollTop) < 700 ||
          scrollTop > $(this).offset().top
        ) {
          $(this)
            .removeClass('fade-out-bottom')
            .addClass('fade-in-up');
        } else {
          $(this)
            .removeClass('fade-in-up')
            .addClass('fade-out-bottom');
        }
      });
    };
  },
  beforeDestroy() {
    window.onresize = null;
    window.onscroll = null;
  },
  methods: {
    //点击发布应用或者管理中心
    goNext(path) {
      if (!this.isLogin) {
        this.$alert('您未登录，请登录后再使用此功能。', '提示', {
          confirmButtonText: '前往登录'
        }).then(() => {
          this.$router.push('/login');
        });
        return;
      }
      if (!this.isAccountPassCheck) {
        this.$alert(
          '您的账号尚未通过审核，请等待审核通过后再使用此功能。',
          '提示',
          {
            confirmButtonText: '确定'
          }
        );
      } else {
        this.$router.push(path);
        // let baseUrl = this.$router.history.base;
        // window.open(baseUrl + path, '_blank');
      }
    },
    // 解决banner的自适应问题。没有自适应的单位统一布局
    fixBanner() {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.$refs.indexContainer.style.width =
          $('.header').outerWidth() + 'px';
        $(this.$refs.banner).css({
          transform: 'scale(' + $('.header').outerWidth() / 1920 + ')'
        });
        return;
      }
      $(this.$refs.banner).css({
        transform: 'scale(' + $('.header').outerWidth() / 1920 + ')'
      });
      this.$refs.bannerWrap.style.height =
        998 * (window.innerWidth / 1920) + 'px';
    }
  }
};
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  height: 998px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  color: #efefef;
  font-family: Alibaba PuHuiTi;
  .banner-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: url(https://common-pic-dn.eebbk.net/common-pic/2019/09/19/195638612_e255f3402ee9eae5.png)
      no-repeat center top;
    background-size: cover;
    background-attachment: fixed;
  }
  .banner-wrap {
    position: relative;
    z-index: 2;
    padding: 257px 0 0 363px;
    transform-origin: left top;
  }
  h1 {
    font-size: 70px;
    font-weight: normal;
    span {
      opacity: 0;
      display: inline-block;
      animation: fadeInUp ease-in 0.3s forwards;
    }
  }
  h2 {
    font-size: 70px;
    font-weight: normal;
    span {
      opacity: 0;
      display: inline-block;
      animation: fadeInUp ease-in 0.3s 0.3s forwards;
    }
  }
  p {
    opacity: 0;
    margin-top: 10px;
    animation: fadeInUp 1s 0.5s ease-in forwards;
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .animation-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    & > * {
      position: absolute;
    }
    .box {
      opacity: 0;
      animation: bannerBoxIn 0.5s linear 1s forwards;
      transform-origin: center;
    }
    & > span,
    & > i {
      background-color: #269aff;
      height: 1px;
      transform-origin: left center;
      opacity: 0;
    }
    & > span {
      animation: bannerLineTravel 1s linear 0.5s forwards;
    }
    & > i {
      animation: bannerLineTravel 1s linear 1.5s forwards;
    }
    .main-box {
      top: 369px;
      left: 1073px;
      width: 315px;
      height: 325px;
    }
    @keyframes bannerLineTravel {
      from {
        width: 0px;
        opacity: 1;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes bannerBoxIn {
      to {
        opacity: 1;
      }
    }
    .box1 {
      width: 177px;
      height: 177px;
      top: 193px;
      left: 939px;
    }
    .box2 {
      width: 191px;
      height: 191px;
      top: 156px;
      left: 1564px;
    }
    .box3 {
      width: 184px;
      height: 184px;
      top: 598px;
      left: 675px;
    }
    .box4 {
      width: 225px;
      height: 225px;
      top: 586px;
      left: 1556px;
    }
    .box5 {
      width: 136px;
      height: 136px;
      top: 802px;
      left: 1163px;
    }
    .main-line1 {
      width: 160px;
      top: 461px;
      left: 1141px;
      transform: rotate(237deg);
    }
    .main-line2 {
      width: 380px;
      top: 495px;
      left: 1299px;
      transform: rotate(-35deg);
    }
    .main-line3 {
      width: 310px;
      top: 576px;
      left: 1315px;
      transform: rotate(20deg);
    }
    .main-line4 {
      width: 320px;
      top: 565px;
      left: 1111px;
      transform: rotate(161deg);
    }
    .main-line5 {
      width: 200px;
      top: 650px;
      left: 1223px;
      transform: rotate(90deg);
    }
    .line1 {
      width: 400px;
      top: 642px;
      left: 774px;
      transform: rotate(-57deg);
    }
    .line2 {
      width: 410px;
      top: 720px;
      left: 812px;
      transform: rotate(21deg);
    }
    .line3 {
      width: 378px;
      top: 873px;
      left: 1249px;
      transform: rotate(-23deg);
    }
    .line4 {
      width: 533px;
      top: 266px;
      left: 1069px;
      transform: rotate(-2deg);
    }
    .line5 {
      width: 337px;
      top: 307px;
      left: 1669px;
      transform: rotate(91deg);
    }
  }
}

.main {
  position: relative;
  z-index: 2;
  background-color: #fff;
  overflow: hidden;
  & > * {
    opacity: 0;
  }
  h2 {
    margin-top: 80px;
    text-align: center;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(15, 18, 38, 1);
  }
  .btn {
    margin: 0 20%;
    margin-top: 50px;
    & > div {
      display: inline-block;
      width: 43%;
      cursor: pointer;
      &:hover {
        img {
          transform: translate(0, -5px);
          // box-shadow: 0 3px 3px 3px #ccc;
          box-shadow: 0px 8px 10px #cccccc;
        }
      }
      img {
        width: 100%;
        transition: all 0.2s linear;
      }
      p {
        margin-top: 5%;
        color: #2b2b2b;
        font-size: 20px;
        text-align: center;
      }
    }
    .upload-app {
      margin-right: 14%;
    }
  }
}
.fade-in-up {
  animation: fadeInUp 0.3s linear forwards;
}
.fade-out-bottom {
  animation: fadeOutBottom 0.3s linear forwards;
}
@keyframes fadeOutBottom {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
}
.about {
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding-top: 13%;
  padding-bottom: 15%;
  overflow: hidden;
  text-align: center;
  h2 {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(15, 18, 38, 1);
  }
  .btn {
    margin-top: 60px;
    & > div {
      opacity: 0;
      display: inline-block;
      text-align: center;
      vertical-align: text-top;
      width: 28%;
      height: 300px;
      &.fade-in-up:nth-of-type(1) {
        animation-delay: 0;
      }
      &.fade-in-up:nth-of-type(2) {
        animation-delay: 0.2s;
      }
      &.fade-in-up:nth-of-type(3) {
        animation-delay: 0.4s;
      }
      &.fade-out-bottom:nth-of-type(1) {
        animation-delay: 0s;
      }
      &.fade-out-bottom:nth-of-type(2) {
        animation-delay: 0s;
      }
      &.fade-out-bottom:nth-of-type(3) {
        animation-delay: 0;
      }
      img {
        width: 25%;
      }
      h4 {
        margin-top: 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(43, 43, 43, 1);
      }
      p {
        margin-top: 3px;
        font-size: 14px;
        line-height: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(185, 185, 185, 1);
      }
    }
    .margin14 {
      margin-right: 8%;
    }
  }
}
.hide {
  display: none;
}

.main-body {
  position: relative;
  z-index: 2;
  background: #ffffff;
  padding-bottom: 94px;
  .banner-box {
    .banner-img {
      width: 100%;
    }
  }
  .operate-box {
    margin-top: 35px;
    text-align: center;
    background: transparent;
    .publish-btn,
    .manage-btn {
      cursor: pointer;
      width: 400px;
      height: 250px;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: transform 0.3s ease;
      -moz-transition: transform 0.3s ease;
      -o-transition: transform 0.3s ease;
      -ms-transition: transform 0.3s ease;
      transition: transform 0.3s ease;
      &:hover {
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        -webkit-transform: translate3d(0, -4px, 0);
        -moz-transform: translate3d(0, -4px, 0);
        -o-transform: translate3d(0, -4px, 0);
        -ms-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
        -webkit-transition: transform 0.3s ease;
        -moz-transition: transform 0.3s ease;
        -o-transition: transform 0.3s ease;
        -ms-transition: transform 0.3s ease;
        transition: transform 0.3s ease; // box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.3);
        // -o-box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.3);
        // -moz-box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.3);
        // -webkit-box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.3);
        // -mo-box-shadow: 2px 2px 2px rgba(136, 136, 136, 0.3);
      }
    }
    .manage-btn {
      margin-left: 106px;
    }
  }
}
</style>
