<template>
  <div class="content-area">
    <div class="progress-box">
      <div class="progress-item">
        <img
          src="@/assets/img/progress-blue.png"
          alt="创建应用"
          class="progress-img"
        />
        创建应用
      </div>
      <div class="progress-item undone">
        <img
          src="@/assets/img/progress-gray.png"
          alt="提交审核"
          class="progress-img"
        />
        提交审核
      </div>
    </div>
    <div class="top-content">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">应用信息</span>
      </div>
      <div class="common-style">
        <span class="span-label">上传APK包<span class="red-star">*</span></span>
        <upload-slice
          btn-id="uploadBtn"
          :server-url="$axios.defaults.baseURL + 'upload/apk'"
          @uploadProgress="uploadProgress"
          @uploadSuccess="uploadSuccess"
          @uploadError="uploadError"
          @uploadStart="uploadStart"
        >
          <el-button
            id="uploadBtn"
            :loading="apkUploadBtn"
            type="success"
            class="upload-btn"
            >浏览
          </el-button>
          <el-progress v-if="percent" :percentage="percent"></el-progress>
        </upload-slice>
        <span v-if="errArea && !isApkUploaded" class="error-desc"
          >请上传apk</span
        >
        <span
          class="paper-desc"
          style="margin-left: 120px; padding-top: 10px; display: inherit"
          >建议您将APP内置的图标替换为高清图标，以便在家教机安装后显示清晰美观的应用图标。</span
        >
      </div>
      <div v-if="showApkInfo" class="apkinfo-box">
        <!--<p>包名：{{uploadApkInfo.apkName}}</p>-->
        <p>包名：{{ uploadApkInfo.apkPackageName }}</p>
        <p>版本名称：{{ uploadApkInfo.apkVersionName }}</p>
        <p>版本号：{{ uploadApkInfo.apkVersionCode }}</p>
        <p>大小：{{ formatSize(uploadApkInfo.apkSize) }}</p>
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <span class="span-label">版权证明<span class="red-star">*</span></span>
        <span class="paper-desc"
          >（请提供《计算机软件著作权证书》，提交版权证明可在客户端显示“官方”标签，未提交相关资质可能会影响
          您的应用上线。文件小于2M，支持格式：JPG/PNG。）</span
        >
      </div>
      <div
        class="common-style"
        style="margin-top: 36px"
        :class="!disabledShow ? 'div-7' : ''"
      >
        <div class="upload-box" @mouseover="showDeleteImg('1')">
          <el-upload
            class="avatar-uploader"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :show-file-list="false"
            :on-success="
              res => {
                return handleAvatarSuccess(res, '1');
              }
            "
            :before-upload="beforeAvatarUpload"
            :disabled="isUpload1"
          >
            <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <img
              v-if="isDelete1"
              src="@/assets/img/delete-img.png"
              alt=""
              class="delete-icon"
              @click.stop="deleteImg('1')"
            />
          </el-upload>
          <!-- <p v-if="ishaveSexiocn1" style="line-height: 15px;color:red;display:flex;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p>   -->
        </div>
      </div>
      <div v-if="errArea && !isApkUploaded" class="error-div">
        请上传版权证明
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <span class="span-label">特殊资质</span>
        <span class="paper-desc"
          >（如果您的应用属于新闻类，直播类，P2P借贷类等，请参考特殊应用资质规范提交对应的资质。文件小于2M，
          支持格式：JPG/PNG）</span
        >
      </div>
      <div class="common-style" :class="!disabledShow ? 'div-7' : ''">
        <div class="upload-box" @mouseover="showDeleteImg('2')">
          <el-upload
            class="avatar-uploader"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :show-file-list="false"
            :on-success="
              res => {
                return handleAvatarSuccess(res, '2');
              }
            "
            :before-upload="beforeAvatarUpload"
            :disabled="isUpload2"
          >
            <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <img
              v-if="isDelete2"
              src="@/assets/img/delete-img.png"
              alt=""
              class="delete-icon"
              @click.stop="deleteImg('2')"
            />
          </el-upload>
          <!-- <p v-if="ishaveSexiocn2" style="line-height: 15px;color:red;display:flex;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p>   -->
        </div>
      </div>
    </div>
    <!-- start -->
    <!-- 发布类型 -->
    <div class="fb-content top-content">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">发布类型</span>
      </div>
      <div class="common-style">
        <span class="span-label"
          >发布的类型<span class="red-star">*</span></span
        >
        <el-radio v-model="radio" label="0" style="margin-right: 185px"
          >常规发布</el-radio
        >
        <el-radio v-model="radio" label="1">适配上传</el-radio>

        <el-row :gutter="20">
          <el-col :span="12" :offset="6"
            ><div class="grid-content bg-purple"></div
          ></el-col>
          <el-col :span="12" :offset="6"
            ><div class="grid-content bg-purple"></div
          ></el-col>
        </el-row>
      </div>
      <div
        class="common-style"
        :class="!disabledShow ? 'div-7' : ''"
        style="margin-top: 10px; color: #ccc"
      >
        <span>指该应用版本首次上传或更新</span>
        <span style="margin-left: 110px"
          >指该应用版本针对步步高家教机某机型进行兼容适配上传</span
        >
      </div>
      <div v-if="errArea && radio === '2'" class="error-div">
        请选择发布的类型
      </div>
    </div>
    <!-- end -->

    <div class="bottom-content">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">完善信息</span>
      </div>
      <div class="common-style">
        <span class="span-label">应用名称<span class="red-star">*</span></span>
        <input
          v-show="!modifyBtnShow2"
          v-model="uploadApkInfo.apkName"
          type="text"
          class="input"
          placeholder="名字最长为20个字"
        />
        <span v-show="modifyBtnShow2">{{ uploadApkInfo.apkName }}</span>
      </div>
      <div
        v-if="
          errArea && (!uploadApkInfo.apkName || !uploadApkInfo.apkName.trim())
        "
        class="error-div"
      >
        应用名称不能为空
      </div>
      <div
        v-if="
          errArea &&
            (!uploadApkInfo.apkName || uploadApkInfo.apkName.trim().length > 20)
        "
        class="error-div"
      >
        名字最长为20个字
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <span class="span-label">上传icon<span class="red-star">*</span></span>
        <span class="paper-desc"
          >（支持jpg/png格式,尺寸要求长等于宽,不低于256*256,不超过512*512,圆角不超过48px,大小50k以内）</span
        >
      </div>
      <div class="common-style" :class="!disabledShow ? 'div-7' : ''">
        <div class="upload-box" @mouseover="showDeleteImg('3')">
          <el-upload
            class="avatar-uploader"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                return handleAvatarSuccess(res, '3', file);
              }
            "
            :before-upload="
              file => {
                return beforeAvatarUpload(file, 1024 * 50);
              }
            "
            :disabled="isUpload3"
          >
            <img v-if="imageUrl3" :src="imageUrl3" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <img
              v-if="isDelete3"
              src="@/assets/img/delete-img.png"
              alt=""
              class="delete-icon"
              @click.stop="deleteImg('3')"
            />
          </el-upload>
          <!-- <p v-if="ishaveSexiocn3" style="line-height: 15px;color:red;display:flex;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p>   -->
        </div>
      </div>
      <div v-if="errArea && !isUpload3" class="error-div">请上传icon</div>
      <div class="common-style">
        <span class="span-label"
          >一句话描述<span class="red-star">*</span></span
        >
        <input
          v-show="!modifyBtnShow2"
          v-model="formData.shortIntro"
          type="text"
          style="width: 666px"
          class="input"
          placeholder="描述最长为13个字"
        />
        <span v-show="modifyBtnShow2">{{ formData.shortIntro }}</span>
      </div>
      <div v-if="errArea && !formData.shortIntro.trim()" class="error-div">
        一句话描述不能为空
      </div>
      <div
        v-if="
          errArea &&
            formData.shortIntro.trim() &&
            formData.shortIntro.trim().length > 13
        "
        class="error-div"
      >
        描述最长为13个字
      </div>
      <div class="common-style">
        <span class="span-label"
          >开发商名称<span class="red-star">*</span></span
        >
        <input
          v-show="!modifyBtnShow2"
          v-model="formData.developer"
          type="text"
          style="width: 666px"
          class="input"
          placeholder="请输入开发商名称"
        />
        <span v-show="modifyBtnShow2">{{ formData.developer }}</span>
      </div>
      <div v-if="errArea && !formData.developer.trim()" class="error-div">
        开发商名称不能为空
      </div>
      <div
        v-if="
          errArea &&
            formData.developer.trim() &&
            formData.developer.trim().length > 50
        "
        class="error-div"
      >
        开发商名称最长为50个字
      </div>
      <div class="common-style">
        <div>
          <span class="span-label"
            >应用隐私政策<span class="red-star">*</span></span
          >
          <input
            v-show="!modifyBtnShow2"
            v-model="formData.privacyPolicyUrl"
            type="text"
            style="width: 666px"
            class="input"
            placeholder="请填写以https://开头的链接地址，300字符以内"
          />
          <span v-show="modifyBtnShow2">{{ formData.privacyPolicyUrl }}</span>
        </div>
        <div
          v-if="errArea && !formData.privacyPolicyUrl.trim()"
          class="error-div"
        >
          请填写以https://开头的链接地址，300字符以内
        </div>
        <div class="privacyPolicy">
          <p style="color:#FAAD14;margin: 30px 0;">
            应用提交时请提交隐私政策，URL链接更便于您填写和进行隐私政策内容管理，建议您选择URL形式。
          </p>
          <p>
            1.隐私政策内应告知具体信息收集主体，为便于审核人员审核，建议您在文首或文末增加公司署名；
          </p>
          <p>
            2.隐私政策应与您APP运行实际权限与个人信息使用等情况相符，且应与APP内隐私政策保持一致；
          </p>
          <!-- <p>3.为避免您的APP因用户隐私政策问题而被审核驳回，请您查阅用户隐私与功能权限审核规范；APP常见隐私问题合规指引；</p> -->
          <p style="color:#FAAD14;margin: 30px 0;">
            点击
            <i class="el-icon-zoom-in"></i> 速览错误图例，减少隐私问题被拒绝
          </p>
          <p>一、违规收集个人信息 1. 隐私政策不规范 2. 默认勾选同意政策</p>
          <p>二、违规使用个人信息</p>
          <p>三、强制、频繁、过渡索取权限，权限申请目的不明</p>
          <p>四、欺骗误导用户下载APP</p>
          <p>五、强制用户使用定向推送功能</p>
          <p>六、应用分发行为未明示相关信息</p>
        </div>
      </div>

      <div v-if="!modifyBtnShow2" class="common-style">
        <span class="span-label">应用简介<span class="red-star">*</span></span>
        <div class="full-address">
          <textarea
            v-model="formData.apkIntroduce"
            class="text-area"
            placeholder="应用简介最少为50个字"
          ></textarea>
        </div>
      </div>
      <div v-if="errArea && !formData.apkIntroduce.trim()" class="error-div">
        应用简介不能为空
      </div>
      <div
        v-if="
          errArea &&
            formData.apkIntroduce.trim() &&
            formData.apkIntroduce.trim().length < 50
        "
        class="error-div"
      >
        应用简介最少为50个字
      </div>
      <div v-if="!modifyBtnShow2" class="common-style">
        <span class="span-label">新版说明<span class="red-star">*</span></span>
        <div class="full-address">
          <textarea
            v-model="formData.updateInformation"
            class="text-area"
            placeholder="不少于5字列出此版本的更新内容"
          ></textarea>
        </div>
      </div>
      <div
        v-if="errArea && !formData.updateInformation.trim()"
        class="error-div"
      >
        新版说明不能为空
      </div>
      <div
        v-if="
          errArea &&
            formData.updateInformation.trim() &&
            formData.updateInformation.trim().length < 5
        "
        class="error-div"
      >
        不少于5字列出此版本的更新内容
      </div>
      <div v-show="!disabledShow" class="div-6 common-style">
        <div class="common-style" style="margin-bottom: 10px">
          <span class="span-label"
            >上传截图
            <span class="red-star">*</span>
          </span>
          <el-radio
            v-model="uploadImgType"
            label="0"
            style="margin-right:185px;"
            >竖屏</el-radio
          >
          <el-radio v-model="uploadImgType" label="1">横屏</el-radio>
        </div>
        <span v-if="uploadImgType == 0" class="paper-desc"
          >(请上传3-5张清晰截图。尺寸为竖图768*1024，格式为jpg/png，每张图片尺寸一致，单张图片不超过2MB。)</span
        >
        <span v-else class="paper-desc"
          >(请上传3-5张清晰截图。尺寸为横图1024*768，格式为jpg/png，每张图片尺寸一致，单张图片不超过2MB。)</span
        >
      </div>
      <div class="common-style" :class="!disabledShow ? 'div-7' : ''">
        <div
          v-for="(item, index) in fileList"
          :key="index"
          :class="imgStatus ? 'imgChange' : 'uploadedBox'"
          @mouseover="item.isShow = true"
        >
          <img class="thumb-img" :src="item.url" />
          <img
            v-if="item.isShow"
            class="delete-img"
            src="@/assets/img/delete-img.png"
            alt=""
            @click.stop="deleteImg('4', index)"
          />
          <!-- <p v-if="item.isShow" style="line-height: 15px;color:red;float:right;width: 175px;">上传失败：检测到此图片可能存在色情敏感信息</p>   -->
        </div>
        <div class="upload-box">
          <el-upload
            class="avatar-uploader"
            :style="imgStatus ? 'width:110px' : ''"
            :headers="uploadHeader"
            :action="IMGUPLOADURL"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                return handleAvatarSuccess(res, '4', file);
              }
            "
            :before-upload="beforeAvatarUpload"
            :disabled="disabledUpload"
            :file-list="fileList"
          >
            <i
              class="el-icon-plus avatar-uploader-icon"
              :style="
                imgStatus ? 'width:110px;height:180px;line-height:180px' : ''
              "
            ></i>
          </el-upload>
          <!-- <p v-if="ishaveSexiocnAll+index" style="line-height: 15px;color:red;float:right;width: 175px;"></p>  -->
        </div>
      </div>
      <div v-if="errArea && !isUpload4" class="error-div">
        请至少上传3张截图
      </div>
      <div class="common-style">
        <span class="span-label">客服电话</span>
        <input
          v-show="!modifyBtnShow2"
          v-model="formData.serviceTel"
          maxlength="20"
          type="text"
          style="width: 666px"
          class="input"
          placeholder="客服电话最长为20个字"
        />
      </div>
      <div v-if="!modifyBtnShow2" class="common-style">
        <span class="span-label">备注说明</span>
        <div class="full-address">
          <textarea
            v-model="formData.remark"
            class="text-area"
            maxlength="200"
            placeholder="备注说明最长为200个字"
          ></textarea>
        </div>
      </div>
      <div class="common-style" style="padding-left: 120px; margin-top: 10px">
        <p class="paper-desc">
          1. 如果之前未审核通过，可以在此填写说明以帮助通过。
        </p>
        <p class="paper-desc">
          2. 如应用需要注册才能使用，请提供测试账号和密码。
        </p>
      </div>

      <!-- <div class="common-style">
        <span class="span-label">联网状态<span class="red-star">*</span></span>
        <div class="full-address">
          <div>
            <el-radio
              v-model="formData.networkingStatus"
              :label="0"
              style="margin-right: 185px"
              >未联网</el-radio
            >
            <div>指该应用使用过程中不需要联网</div>
          </div>

          <div>
            <el-radio v-model="formData.networkingStatus" :label="1"
              >已联网</el-radio
            >
            <div>
              指该应用使用过程中需要联网
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <filing-form ref="filingForm" />

    <el-button class="submit-btn" type="primary" @click="submit"
      >提交审核</el-button
    >
  </div>
</template>

<script>
import { getCommonHeader } from '@/plugins/axios';
import uploadSlice from '@/components/upload';
import FilingForm from '../components/FilingForm';
export default {
  name: 'Publish',
  components: {
    uploadSlice,
    FilingForm
  },
  data() {
    return {
      percent: '',
      isNeedFit: false,

      imageUrl1: '',
      imageUrl2: '',
      imageUrl3: '',

      isApkUploaded: false,
      isUpload1: false,
      isUpload2: false,
      isUpload3: false,
      isUpload4: false,
      disabledUpload: false, // 上传超过5张截图，禁止上传
      apkUploading: false, // 记录是否开始上传apk

      isDelete1: false,
      isDelete2: false,
      isDelete3: false,

      modifyBtnShow2: false,
      errArea: false,
      disabledShow: false,

      formData: {
        apkSize: 0,
        apkSizeStr: '',
        apkName: '',
        shortIntro: '',
        developer: '',
        updateInformation: '',
        apkIntroduce: '',
        serviceTel: '',
        remark: '',
        privacyPolicyUrl: '',
        networkingStatus: 0
      },

      fileList: [
        // {
        // 	url: "https://brand-portals-file.eebbk.com/brand-website/2018/04/17/162458401_e61ef991ddf36596.png",
        // 	isShow: false,
        // },
        // {
        // 	url: "https://brand-portals-file.eebbk.com/brand-website/2018/04/17/194921062_b1697a797eb03815.png",
        // 	isShow: false,
        // },
        // {
        // 	url: "https://brand-portals-file.eebbk.com/brand-website/2018/04/17/190308048_e61ef991ddf36596.png",
        // 	isShow: false,
        // }
      ],
      resoAllowed: true,
      uploadApkInfo: {},
      showApkInfo: false,
      routeIsNext: false,
      apkUploadBtn: false,
      radio: '2',
      // 上传图片类型
      uploadImgType: '0',

      uploadHeader: getCommonHeader(),

      IMGUPLOADURL: this.$axios.defaults.baseURL + 'upload/uploadImg',
      uploadImgTypeArray: [
        [768, 1024],
        [1024, 768]
      ],
      imgStatus: true
      // ishaveSexiocn1:false,
      // ishaveSexiocn2:false,
      // ishaveSexiocn3:false,
    };
  },
  computed: {
    getImgPx() {
      return this.uploadImgTypeArray[this.uploadImgType];
    }
  },
  watch: {
    uploadImgType(newValue, oldValue) {
      this.fileList = [];
      // this.isUpload4 = false;
      console.log('uploadImgType', oldValue);
      console.log('uploadImgType', newValue);
      this.uploadTypeChange();
      // if (newValue == '0') {
      //   this.$refs.uploadImg.style.width = '110px';
      //   this.$refs.uploadImg.style.height = '180px';
      // } else {
      //   this.$refs.uploadImg.style.width = '180px';
      //   this.$refs.uploadImg.style.height = '110px';
      // }
    }
  },
  methods: {
    uploadTypeChange() {
      if (this.uploadImgType == '0') {
        this.imgStatus = true;
      } else {
        this.imgStatus = false;
      }
    },
    // 文件上传之前
    uploadStart() {
      // this.apkSize = file.size;
      // this.apkSizeStr = this.formatSize(file.size);
      this.apkUploading = true;
    },
    uploadProgress(percent) {
      this.percent = percent;
      this.apkUploadBtn = true;
    },
    uploadSuccess(res) {
      this.apkUploadBtn = false;
      if (res.stateCode == '0' && res.data.qualified == true) {
        this.isApkUploaded = true;
        this.uploadApkInfo = res.data;
        this.showApkInfo = true;
        // 获取备案信息
        this.$refs.filingForm.getFilingInfo(
          this.uploadApkInfo.apkPackageName,
          this.uploadApkInfo.apkName
        );
      } else {
        this.$alert('<strong>' + res.stateInfo + '</strong>', '提示', {
          dangerouslyUseHTMLString: true,
          type: 'error'
        });
        this.percent = null;
      }
    },
    uploadError() {
      this.apkUploadBtn = false;
      this.$alert('<strong>上传出错</strong>', '提示', {
        dangerouslyUseHTMLString: true,
        type: 'error'
      });
      this.percent = null;
    },
    beforeAvatarUpload(file, limitSize = 1024 * 1024 * 2) {
      let vm = this;
      let isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      let isLt2M = file.size / limitSize < 1;
      let sizeStr = this.formatSize(limitSize);
      if (!isJPG) {
        vm.$message.error('图片只能是 JPG和PNG 格式!');
      }
      if (!isLt2M) {
        vm.$message.error('图片大小不能超过' + sizeStr + '!');
      }

      // //判断图片分辨率
      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = function () { //让页面中的img标签的src指向读取的路径
      //   var img = new Image()
      //   img.src = reader.result
      //   console.log(img,img.width,img.height,img.complete)
      //   if (img.complete) {//如果存在浏览器缓存中
      //     if (this.uploadImgType==0) {//竪屏
      //       if(img.width>img.height){
      //         this.$notify({
      //           title: '失败',
      //           message: '上传竪屏图片高度要大于宽度分辨率!',
      //           type: 'error',
      //           duration: 3000
      //         })
      //         return false
      //       }
      //     }else{//横屏
      //       if(img.width<img.height){
      //         this.$notify({
      //           title: '失败',
      //           message: '上传横屏图片宽度要大于高度分辨率!',
      //           type: 'error',
      //           duration: 3000
      //         })
      //         return false
      //       }
      //     }
      //   } else {
      //     img.onload = function () {
      //       if (this.uploadImgType==0) {//竪屏
      //         console.log(img.width)
      //         if(img.width>img.height){
      //           this.$notify({
      //             title: '失败',
      //             message: '上传竪屏图片高度要大于宽度分辨率!',
      //             type: 'error',
      //             duration: 3000
      //           })
      //           return false
      //         }
      //       }else{//横屏
      //         if(img.width<img.height){
      //           this.$notify({
      //             title: '失败',
      //             message: '上传横屏图片宽度要大于高度分辨率!',
      //             type: 'error',
      //             duration: 3000
      //           })
      //           return false
      //         }
      //       }
      //     }
      //   }
      // }

      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, sort, file) {
      console.log(3422312334);
      let vm = this;
      var fr;
      switch (sort) {
        case '1':
          // if(res.stateCode == 1009) {
          //   this.ishaveSexiocn1 = true;
          // }
          this.imageUrl1 = res.data;
          this.isUpload1 = true;
          break;
        case '2':
          // if(res.stateCode == 1009) {
          //   this.ishaveSexiocn2 = true;
          // }
          this.imageUrl2 = res.data;
          this.isUpload2 = true;
          break;
        case '3':
          fr = new FileReader();
          fr.onload = function() {
            var img = new Image();
            img.onload = function() {
              let width = img.width;
              let height = img.height;
              if (
                width >= 256 &&
                width <= 512 &&
                height >= 256 &&
                height <= 512
              ) {
                // if(res.stateCode == 1009) {
                //   vm.ishaveSexiocn3 = true;
                // }
                vm.imageUrl3 = res.data;
                vm.isUpload3 = true;
              } else {
                vm.$message.error('请上传合适分辨的图片!');
              }
            };
            img.src = fr.result;
          };
          fr.readAsDataURL(file.raw);
          break;
        case '4':
          fr = new FileReader();
          fr.onload = function() {
            var img = new Image();
            img.onload = function() {
              let width = img.width;
              let height = img.height;
              if (
                (width == '768' || width == '1024') &&
                (height == '768' || height == '1024')
              ) {
                // let isShow = false;
                // if(res.stateCode && res.stateCode == '1009') {
                //   isShow = true;
                // }
                let obj = { url: res.data, isShow: false };
                vm.fileList.push(obj);
                let length = vm.fileList.length;
                if (length >= 3) vm.isUpload4 = true;
                if (length == 5) vm.disabledUpload = true;
              } else {
                vm.$message.error('请上传合适分辨的图片!');
              }
            };
            img.src = fr.result;
          };
          fr.readAsDataURL(file.raw);
          break;
      }
    },
    deleteImg(sort, index) {
      switch (sort) {
        case '1':
          this.imageUrl1 = false;
          this.isUpload1 = false;
          this.isDelete1 = false;
          // if(this.ishaveSexiocn1 == true) {
          //   this.ishaveSexiocn1 = false;
          // }
          break;
        case '2':
          this.imageUrl2 = false;
          this.isUpload2 = false;
          this.isDelete2 = false;
          // if(this.ishaveSexiocn2 == true) {
          //   this.ishaveSexiocn2 = false;
          // }
          break;
        case '3':
          this.imageUrl3 = false;
          this.isUpload3 = false;
          this.isDelete3 = false;
          // if(this.ishaveSexiocn3 == true) {
          //   this.ishaveSexiocn3 = false;
          // }
          break;
        case '4':
          this.fileList.splice(index, 1);
          var length = this.fileList.length;
          if (length < 5) {
            this.disabledUpload = false;
          }
          if (length < 3) {
            this.isUpload4 = false;
          }
          break;
      }
    },
    showDeleteImg(sort) {
      // if (this.modifyBtnShow1 || this.disabledShow) {
      // 	return;
      // }
      switch (sort) {
        case '1':
          if (this.isUpload1) {
            this.isDelete1 = true;
          }
          break;
        case '2':
          if (this.isUpload2) {
            this.isDelete2 = true;
          }
          break;
        case '3':
          if (this.isUpload3) {
            this.isDelete3 = true;
          }
          break;
      }
    },
    submit() {
      // var regu = /(http|https):\/\/\S*/;
      // var re = new RegExp(regu);
      if (this.$sendHttpsRequest(this.formData.privacyPolicyUrl)) {
        this.$message.error('请填写https,禁止提交http!');
        return;
      }
      this.errArea = true;
      console.log(this.radio);
      // 判断必传文件是否上传
      if (
        !this.isApkUploaded ||
        !this.isUpload1 ||
        !this.isUpload3 ||
        !this.isUpload4
      ) {
        this.$message.warning('您有必填项没填，请完善必填项');
        return;
      }

      // 判断必填项是否填完
      // alert(this.uploadApkInfo.apkName)
      // return;
      this.uploadApkInfo.apkName = this.uploadApkInfo.apkName.trim();
      this.formData.shortIntro = this.formData.shortIntro.trim();
      this.formData.developer = this.formData.developer.trim();
      this.formData.privacyPolicyUrl = this.formData.privacyPolicyUrl.trim();
      this.formData.apkIntroduce = this.formData.apkIntroduce.trim();
      this.formData.updateInformation = this.formData.updateInformation.trim();
      this.formData.serviceTel = this.formData.serviceTel.trim();
      this.formData.remark = this.formData.remark.trim();
      if (
        !this.uploadApkInfo.apkName ||
        !this.uploadApkInfo.apkName.trim() ||
        this.uploadApkInfo.apkName.trim().length > 20 ||
        !this.formData.shortIntro ||
        !this.formData.shortIntro.trim() ||
        !this.formData.apkIntroduce ||
        !this.formData.apkIntroduce.trim() ||
        // || !this.formData.updateInformation|| !this.formData.updateInformation.trim()
        !this.formData.shortIntro ||
        this.formData.shortIntro.trim().length > 13 ||
        !this.formData.apkIntroduce ||
        this.formData.apkIntroduce.trim().length < 50 ||
        !this.formData.updateInformation ||
        this.formData.updateInformation.trim().length < 5 ||
        this.radio === '2'
      ) {
        this.$message.warning('您有必填项没填，请完善必填项');
        return;
      }

      // 判断备案信息是否填写
      const filingForm = this.$refs.filingForm.submitForm();
      if (!filingForm) {
        this.$message.warning('您有必填项没填，请完善必填项');
        return;
      }

      const {
        filingStatus, // 备案状态（-1单机无需备案，0-未备案，1-已备案）
        filingCompanyType, // 备案主体（0-与开发者账号主体一致、1-与开发者账号主体不一致）
        filingCompanyName, // 备案主体名称
        filingCompanyBusinessLicense, // 统一社会信用代码
        filingCompanyBusinessLicenseUrl, // 营业执照附件url
        cooperationAgreementUrl, // 合作协议url
        disclaimerLetterUrl, // 免责承诺函url
        filingCompanyCode, // 主体备案号
        filingApkCode // 服务/应用备案号
      } = filingForm;

      if (filingStatus === 0) {
        this.$message.warning(
          '您的应用未查到相关的备案信息，请完善备案信息在再行提交'
        );
        return;
      } else if (filingStatus === 1 && !filingCompanyCode) {
        this.$message.warning(
          '您的应用未查到相关的备案信息，请完善备案信息再进行提交'
        );
        return;
      }

      // 备案信息
      if (filingStatus === 1 && filingCompanyType === 0) {
        this.formData.filingStatus = filingStatus;
        this.formData.filingCompanyType = filingCompanyType;
        this.formData.filingCompanyCode = filingCompanyCode;
        this.formData.filingApkCode = filingApkCode;
      } else if (filingStatus === 1 && filingCompanyType === 1) {
        this.formData.filingStatus = filingStatus;
        this.formData.filingCompanyType = filingCompanyType;
        this.formData.filingCompanyName = filingCompanyName.trim();
        this.formData.filingCompanyBusinessLicense = filingCompanyBusinessLicense.trim();
        this.formData.filingCompanyBusinessLicenseUrl = filingCompanyBusinessLicenseUrl;
        this.formData.cooperationAgreementUrl = cooperationAgreementUrl;
        this.formData.filingCompanyCode = filingCompanyCode;
        this.formData.filingApkCode = filingApkCode;
      } else if (filingStatus === -1) {
        this.formData.filingStatus = filingStatus;
        this.formData.disclaimerLetterUrl = disclaimerLetterUrl;
      }

      // if (
      //   !this.formData.privacyPolicyUrl || this.formData.privacyPolicyUrl.length >= 300 ||
      //   !re.test(this.formData.privacyPolicyUrl)
      // ) {
      //   this.$message.error(
      //     '应用隐私政策:请填写以http://或者https://开头的链接地址，300字符以内'
      //   );
      //   return;
      // }

      // if (this.formData.shortIntro.length >= 13) {
      //   this.$message.error('一句话描述:描述最长为13个字');
      //   return;
      // }
      // this.$message.error('错了哦，这是一条错误消息');
      // this.formData.accountId = this.getCookie("accountId");
      this.formData.copyrightCertificate = this.imageUrl1;
      this.formData.qualification = this.imageUrl2;
      this.formData.apkIcon = this.imageUrl3;
      this.formData.apkName = this.uploadApkInfo.apkName;
      this.formData.apkPackageName = this.uploadApkInfo.apkPackageName;
      this.formData.apkVersionName = this.uploadApkInfo.apkVersionName;
      this.formData.apkVersionCode = this.uploadApkInfo.apkVersionCode;
      this.formData.apkSize = this.uploadApkInfo.apkSize;
      this.formData.apkMd5 = this.uploadApkInfo.apkMd5;
      this.formData.localPath = this.uploadApkInfo.path;
      this.formData.picUrlList = this.fileList.map(item => item.url);
      this.formData.isFit = this.radio;
      this.formData.apkDisplayType = this.uploadImgType;

      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios
        .post(`appManage/publish`, this.formData)
        .then(res => {
          loading.close();
          if (res.data.stateCode == 0) {
            this.routeIsNext = true;
            this.$router.push('/app_Manage');
          } else if (res.data.stateCode == 110) {
            this.$alert('<strong>' + res.data.data + '</strong>', '提示', {
              dangerouslyUseHTMLString: true,
              type: 'error'
            });
          } else {
            this.$alert(
              '参数不合法:<strong>' + res.data.data + '</strong>',
              '提示',
              {
                dangerouslyUseHTMLString: true,
                type: 'error'
              }
            );
          }
        })
        .catch(() => {
          loading.close();
          this.$alert('<strong>上传错误，请联系管理员！</strong>', '提示', {
            dangerouslyUseHTMLString: true,
            type: 'error'
          });
        });
    },
    //判断页面信息是否有填写
    isChange() {
      if (this.apkUploading) return true;
      if (this.imageUrl1) return true;
      if (this.imageUrl2) return true;
      if (this.uploadApkInfo.apkName) return true;
      if (this.imageUrl3) return true;
      if (this.formData.shortIntro) return true;
      if (this.formData.developer) return true;
      if (this.formData.privacyPolicyUrl) return true;
      if (this.formData.apkIntroduce) return true;
      if (this.formData.updateInformation) return true;
      if (this.fileList[0]) return true;
      if (this.formData.serviceTel) return true;
      if (this.formData.remark) return true;
      return false;
    }
  },
  beforeRouteLeave(to, from, next) {
    let flag = this.isChange();
    if (flag && !this.routeIsNext) {
      this.$confirm('离开将不会保存您所做的更改, 是否继续?', '提示', {
        confirmButtonText: '离开',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          next();
        })
        .catch(() => {});
    } else {
      next();
    }
  }
};
</script>

<style lang="less" scoped>
.content-area {
  max-width: 1200px;
  margin: 0 auto;
  .privacyPolicy {
    padding: 0 120px;
    box-sizing: border-box;
    p {
      line-height: 22px;
      font-size: 14px;
    }
  }
  .progress-box {
    max-width: 501px;
    margin: 0 auto;
    overflow: hidden;
    padding: 50px 0;
    .progress-item {
      float: left;
      width: 250px;
      margin-left: 1px;
      font-size: 14px;
      color: #3cbeff;
      text-align: center;
      &:first-child {
        margin-left: 0;
      }
      img {
        width: 100%;
      }
    }
    .undone {
      color: #cccccc;
    }
  }
}
.content-area {
  max-width: 1177px;
  margin: 0 auto;
  .submit-btn {
    width: 150px;
    margin: 0 150px 40px;
  }
  .upload-btn {
    width: 100px;
    position: relative;
    padding: 8px 0;
  }
  .apkinfo-box {
    font-size: 14px;
    color: #666666;
    margin-left: 120px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    margin-top: 10px;
    line-height: 25px;
  }
  .top-content,
  .bottom-content {
    width: 100%; // height: 1060px;
    padding-top: 28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 40px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 20px;
    // .submit-btn {
    //   margin-left: 120px;
    //   width: 150px;
    //   margin-top: 40px;
    // }
    .uploadedBox {
      width: 180px;
      height: 110px;
      display: inline-block;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      border: 1px dashed white;
      &:hover {
        border: 1px dashed #cccccc;
      }
      .thumb-img {
        width: 100%;
        height: 100%;
      }
      .delete-img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .imgChange {
      width: 110px;
      height: 180px;
      display: inline-block;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      border: 1px dashed white;
      &:hover {
        border: 1px dashed #cccccc;
      }
      .thumb-img {
        width: 100%;
        height: 100%;
      }
      .delete-img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .first-line,
  .second-line {
    border-bottom: 1px solid #c7c7c7;
    padding-bottom: 14px;
  }
  .second-line {
    margin-top: 40px;
  }
  .title-bold {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-left: 6px;
  }
  .vertical-line {
    margin-bottom: -2px;
  }
  .span-label {
    display: inline-block;
    width: 120px;
    color: #333333;
  }
  .common-style {
    font-size: 14px;
    color: #666666;
    margin-top: 26px;
  }
  .div-6,
  .div-9 {
    position: relative;
    .paper-desc {
      position: absolute;
      left: 120px;
    }
  }
  .div-7 {
    margin-left: 120px;
    margin-top: 35px;
  }
  .no-upload-box {
    margin-left: 120px;
    margin-top: 10px;
    position: relative;
    height: 20px;
    span {
      position: absolute;
      font-size: 14px;
      color: #ff7477;
    }
    .span-1 {
      left: 0;
    }
    .span-2 {
      left: 234px;
    }
    .span-3 {
      left: 466px;
    }
  }
  .err-card {
    font-size: 14px;
    color: #ff7477;
    margin-top: 26px;
  }
  .mail-prompt,
  .phone-prompt {
    margin-left: 120px;
    margin-top: 2px;
  }
  .code-input {
    margin-left: 120px;
    margin-top: 16px;
  }
  .full-address {
    margin-left: 120px;
    margin-top: -16px;
    display: flex;
    .text-area {
      width: 666px;
      height: 77px;
      webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: none;
      -webkit-appearance: none;
      border: 1px solid #c7c7c7;
      border-radius: 4px;
      color: #666666;
      font-size: 14px;
      padding: 10px 36px 10px 10px;
      overflow: hidden;
      overflow-y: auto;
      &:focus {
        border-color: #3cb1ff;
      }
    }
  }
}
.upload-slice-box {
  display: inline;
}
.input {
  webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  width: 600px;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  color: #666666;
  font-size: 14px;
  padding: 10px 36px 10px 10px;
  overflow: hidden;
  &.input-short {
    width: 204px;
  }
  &.input-short-1 {
    width: 144px;
    margin-left: 24px;
  }
  &.input-psd {
    width: 270px;
  }
}

input:focus {
  border: 1px solid #3cb1ff;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}

.upload-box {
  display: inline-block;
  position: relative;
  .delete-icon {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

.area-select {
  height: 40px;
  border: 1px solid #c7c7c7;
}

.red-star {
  color: red;
  margin-left: 5px;
  display: inline-block;
}

.error-desc {
  margin-left: 14px;
  color: #ff7477;
  font-size: 14px;
}

.error-div {
  color: #ff7477;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 120px;
}

.modify-btn-1,
.modify-btn-2 {
  background: #ffc267;
  border: 1px solid #ffc267;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.modify-btn-1:hover,
.modify-btn-2:hover {
  background: #deaa5c;
  border: 1px solid #deaa5c;
}

.cancel-btn-1,
.cancel-btn-2 {
  background: #ffffff;
  border: 1px solid #c7c7c7;
  color: #666666;
  margin-left: 24px;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.cancel-btn-1:hover,
.cancel-btn-2:hover {
  background: #f3f2f2;
  border: 1px solid #c7c7c7;
}

.red-star {
  color: red;
  margin-left: 5px;
  display: inline-block;
}
</style>
<style>
.avatar-uploader {
  width: 180px;
  margin-right: 53px;
  display: inline-block;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 110px;
  line-height: 110px;
  text-align: center;
}

.avatar {
  width: 180px;
  height: 110px;
  display: block;
}

.red-line {
  border: 1px solid red;
}
</style>
