<template>
  <div class="body-wrap">
    <div class="main-body">
      <div class="url-navigation">
        <span class="back" @click="back">返回上一页</span> |
        <span class="current-page" @click="to">{{ apkInfo.apkName }}</span>
      </div>
      <p class="top-tip">
        由于部分设备数据回传存在滞后，下载量数据存在3天左右的延时
      </p>
      <div class="apk-info-wrap">
        <div class="apk-info">
          <img :src="apkInfo.apkIcon" alt />
          <div>
            <h2>{{ apkInfo.apkName }}</h2>
            <div class="detail">
              <p>
                <span>包名:</span>
                <span>{{ apkInfo.apkPackageName }}</span>
              </p>
              <p>
                <span>更新日期:</span>
                <span>{{ updateDate }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="downloads">
          <span>{{ yesterdayDownNum }}</span>
          <span>昨日净下载量</span>
        </div>
      </div>
      <div class="echart-wrap">
        <h3>下载趋势分析</h3>
        <div class="btns">
          <span
            :class="{ active: this.eChartDataType === 1 }"
            @click="getDataByDay"
            >日</span
          >
          <span
            :class="{ active: this.eChartDataType === 2 }"
            @click="getDataByWeek"
            >周</span
          >
          <span
            :class="{ active: this.eChartDataType === 3 }"
            @click="getDataByMonth"
            >月</span
          >
        </div>
        <div ref="echart" class="echart"></div>
      </div>
      <div class="download-table">
        <div class="top">
          <h3>下载分析</h3>
          <el-button class="top-btn" type="primary" @click="downloadBigData"
            >导出数据</el-button
          >
        </div>
        <div class="date-picker">
          <span
            :class="{ active: this.queryType === 1 }"
            class="animate flexnone"
            @click="queryByTimeArea(1)"
            >最近7天</span
          >
          <span
            :class="{ active: this.queryType === 2 }"
            class="animate flexnone"
            @click="queryByTimeArea(2)"
            >最近30天</span
          >
          <span class="date flexnone">开始时间：</span>
          <el-date-picker
            v-model="beginQueryTime"
            class="flexnone"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="timestamp"
          ></el-date-picker>
          <span class="date flexnone">结束时间：</span>
          <el-date-picker
            v-model="endQueryTime"
            class="flexnone"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="timestamp"
          ></el-date-picker>
          <span class="button flexnone" @click="searchDataByDate(3)">查询</span>
          <span class="box"></span>
          <span
            :class="{ active: this.showType === 1 }"
            class="animate flexnone"
            @click="changeShowType(1)"
            >表格</span
          >
          <span
            :class="{ active: this.showType === 2 }"
            class="animate flexnone"
            @click="changeShowType(2)"
            >折线图</span
          >
        </div>
        <div class="data-display">
          <div v-if="showType === 1" class="table">
            <div class="table-title">
              <div>日期</div>
              <div>
                总下载量
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="当日该应用的总下载量（含更新）"
                  placement="top"
                >
                  <img src="@/assets/img/service-intro.png" alt />
                </el-tooltip>
              </div>
              <div>
                净下载量
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="当日该应用的不含更新下载量"
                  placement="top"
                >
                  <img src="@/assets/img/service-intro.png" alt />
                </el-tooltip>
              </div>
              <div>
                更新量
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="当日该应用的更新量"
                  placement="top"
                >
                  <img src="@/assets/img/service-intro.png" alt />
                </el-tooltip>
              </div>
              <div>
                详情页净下载量
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="该时段下该应用来自详情页的不含更新下载量"
                  placement="top"
                >
                  <img src="@/assets/img/service-intro.png" alt />
                </el-tooltip>
              </div>
              <div>
                详情页转化率
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="详情页净下载量/详情页PV"
                  placement="top"
                >
                  <img src="@/assets/img/service-intro.png" alt />
                </el-tooltip>
              </div>
            </div>
            <div v-for="item in tableData" class="table-body">
              <div>{{ item.countData }}</div>
              <div>{{ item.downAllNum }}</div>
              <div>{{ item.downNum }}</div>
              <div>{{ item.updateNum }}</div>
              <div>{{ item.detailsDownNum }}</div>
              <div>{{ item.conversion }}%</div>
            </div>
          </div>
          <div v-else>
            <div ref="echarts2" class="echarts"></div>
          </div>
          <el-pagination
            class="pagination"
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'BigDataDetail',
  data() {
    return {
      // 应用信息
      apkInfo: {},
      // 当前表中数据类别 1日 2周 3月
      eChartDataType: 1,
      // 查询结果总数
      totalCount: 0,
      // 页数
      pageNum: 1,
      // 每页显示条数，
      pageSize: 10,
      // 表中显示数据
      tableData: [],
      // 查询类型
      queryType: 1,
      // 开始查询时间
      beginQueryTime: '',
      // 结束查询时间
      endQueryTime: '',
      // 昨日净下载量
      yesterdayDownload: 0,
      // 底部栏目，数据显示类型，1表格，2折线图
      showType: 1,
      // 下载量数组，提供底部折线图切换使用
      downAllNum: [],
      // 净下载量，提供底部折线图切换使用
      downNum: [],
      // 更新量，提供底部折线图切换使用
      updateNum: [],
      // 时间数组, 提供底部折线图切换使用
      timeArray: []
    };
  },
  computed: {
    updateDate() {
      let date = new Date(this.apkInfo.countDate);
      return `${date.getFullYear()}年${date.getMonth() +
        1}月${date.getDate()}日`;
    },
    yesterdayDownNum() {
      let tem = this.yesterdayDownload;
      if (!tem) {
        tem = '0';
      }
      let arrTmp =
        tem
          .toString()
          .split('')
          .reverse() || [];
      let resultArr = [];
      arrTmp.map((item, index) => {
        resultArr.push(item);
        if (index !== 0 && index % 3 === 2 && index !== arrTmp.length - 1) {
          resultArr.push(',');
        }
      });
      return resultArr.reverse().join('');
    }
  },
  watch: {
    pageSize() {
      this.pageNum = 1;
      this.queryByTimeArea(this.queryType);
    },
    endQueryTime(newVal) {
      if (newVal < this.beginQueryTime) {
        this.$message({
          message: '结束时间小于开始时间',
          type: 'error',
          duration: 2000
        });
      }
    }
  },
  mounted() {
    // 权限鉴定
    this.$axios.get('/appManage/getCooperationSwitchShow').then(res => {
      console.log(res);
      if (!res.data.data[0].serviceSwitch) {
        // 无权限触发404
        this.$router.replace('/noPermisionForbiden403');
      } else {
        // 有权限则显示数据
        this.apkInfo = JSON.parse(
          window.localStorage.getItem('currentApkDetail')
        );
        this.$axios
          .get('/appManage/getDownDetailsYesterday', {
            params: { apkPackageName: this.apkInfo.apkPackageName }
          })
          .then(res => {
            this.yesterdayDownload = res.data.data;
          });
        // 默认显示天数据
        this.getDataByDay();
        this.queryByTimeArea(1);
      }
    });
  },
  methods: {
    to() {
      this.$router.replace('/appManage/service/data/detail');
    },
    back() {
      this.$router.back();
    },
    // 获取日图表数据
    getDataByDay() {
      this.eChartDataType = 1;
      this.$axios
        .get('/appManage/getStatisticsDownDetailsDay', {
          params: { apkPackageName: this.apkInfo.apkPackageName }
        })
        .then(res => {
          let {
            time,
            downAllNum,
            downNum,
            updateNum
          } = this.formataToEchartData(res.data.data);
          this.initEcharts(time, downAllNum, downNum, updateNum);
        })
        .catch(e => {
          console.log(e);
        });
    },
    // 获取周图表数据
    getDataByWeek() {
      this.eChartDataType = 2;
      this.$axios
        .get('/appManage/getStatisticsDownDetailsWeek', {
          params: { apkPackageName: this.apkInfo.apkPackageName }
        })
        .then(res => {
          let {
            time,
            downAllNum,
            downNum,
            updateNum
          } = this.formataToEchartData(res.data.data);
          this.initEcharts(time, downAllNum, downNum, updateNum);
        })
        .catch(e => {
          console.log(e);
        });
    },
    // 获取月图表数据
    getDataByMonth() {
      this.eChartDataType = 3;
      this.$axios
        .get('/appManage/getStatisticsDownDetailsMonth', {
          params: { apkPackageName: this.apkInfo.apkPackageName }
        })
        .then(res => {
          let {
            time,
            downAllNum,
            downNum,
            updateNum
          } = this.formataToEchartData(res.data.data);
          this.initEcharts(time, downAllNum, downNum, updateNum);
        })
        .catch(e => {
          console.log(e);
        });
    },
    // 转化时间戳为年月日
    timestampToYMD(timestamp) {
      let date = new Date(timestamp);
      return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
    },
    // 将数据改造成echart需要的格式
    formataToEchartData(data) {
      let time = [],
        downAllNum = [],
        downNum = [],
        updateNum = [];
      data.map(item => {
        let timeTmp;
        if (item.countData) {
          timeTmp = item.countData;
        } else {
          timeTmp =
            this.timestampToYMD(item.countDataStart) +
            '-' +
            this.timestampToYMD(item.countDataEnd);
        }
        time.push(timeTmp);
        downAllNum.push(item.downAllNum);
        downNum.push(item.downNum);
        updateNum.push(item.updateNum);
      });
      time.reverse();
      downAllNum.reverse();
      downNum.reverse();
      updateNum.reverse();
      return {
        time,
        downAllNum,
        downNum,
        updateNum
      };
    },
    // 初始化图表
    initEcharts(time, downAllNum, downNum, updateNum) {
      let echart = echarts.init(this.$refs.echart);
      echart.setOption({
        xAxis: {
          name: '日期',
          type: 'category',
          data: time
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          data: ['下载量', '净下载量', '更新量'],
          bottom: 0
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            name: '下载量',
            type: 'line',
            // smooth: true,
            data: downAllNum
          },
          {
            name: '净下载量',
            type: 'line',
            // smooth: true,
            data: downNum
          },
          {
            name: '更新量',
            type: 'line',
            // smooth: true,
            data: updateNum
          }
        ],
        color: ['#1CAAFA', '#FF9F16', '#92D92F']
      });
    },
    // 查询按钮特殊化处理
    searchDataByDate(type) {
      if (this.beginQueryTime && this.endQueryTime) {
        this.queryType = type;
      }
      this.pageNum = 1;
      this.queryByTimeArea(this.queryType);
    },
    // 查询时间段数据  查询底部栏数据
    queryByTimeArea(type) {
      this.queryType = type;
      this.$axios
        .get('/appManage/getDownDetailsByDate', {
          params: {
            apkPackageName: this.apkInfo.apkPackageName,
            timeType: this.queryType,
            startTime: this.beginQueryTime,
            endTime: this.endQueryTime,
            pi: this.pageNum,
            ps: this.pageSize
          }
        })
        .then(res => {
          this.tableData = res.data.data.list.map(item => {
            return {
              countData: item.countData,
              detailsDownNum: item.detailsDownNum,
              downAllNum: item.downAllNum,
              downNum: item.downNum,
              updateNum: item.updateNum,
              conversion: item.conversion
            };
          });
          this.totalCount = res.data.data.totalCount;
          let {
            time,
            downAllNum,
            downNum,
            updateNum
          } = this.formataToEchartData(res.data.data.list);
          this.timeArray = time;
          this.downAllNum = downAllNum;
          this.downNum = downNum;
          this.updateNum = updateNum;
        });
    },
    // 改变显示方式
    changeShowType(type) {
      this.showType = type;
      if (this.showType === 2) {
        this.$nextTick(() => {
          this.initEcharts2();
        });
      }
    },
    // 初始化echart表2
    initEcharts2() {
      let echart = echarts.init(this.$refs.echarts2);
      echart.setOption({
        xAxis: {
          name: '日期',
          type: 'category',
          data: this.timeArray
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          data: ['下载量', '净下载量', '更新量'],
          bottom: 0
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            name: '下载量',
            type: 'line',
            // smooth: true,
            data: this.downAllNum
          },
          {
            name: '净下载量',
            type: 'line',
            // smooth: true,
            data: this.downNum
          },
          {
            name: '更新量',
            type: 'line',
            // smooth: true,
            data: this.updateNum
          }
        ],
        color: ['#1CAAFA', '#FF9F16', '#92D92F']
      });
    },
    // 改变每页显示数据条数
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize;
    },
    // 根据页码获取数据
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.queryByTimeArea(this.queryType);
    },
    // 下载导出数据
    downloadBigData() {
      this.$axios
        .get('/appManage/exportExcel', {
          params: {
            apkPackageName: this.apkInfo.apkPackageName,
            timeType: this.queryType,
            startTime: this.beginQueryTime,
            endTime: this.endQueryTime
          }
        })
        .then(res => {
          let jsonData = res.data.data.map(item => {
            return {
              countData: item.countData,
              downAllNum: item.downAllNum,
              downNum: item.downNum,
              updateNum: item.updateNum,
              detailsDownNum: item.detailsDownNum,
              conversion: item.conversion + '%'
            };
          });
          //列标题
          let str =
            '<tr><td>日期</td><td>总下载量</td><td>净下载量</td><td>更新量</td><td>详情页下载量</td><td>详情页转化率</td></tr>';
          //循环遍历，每行加入tr标签，每个单元格加td标签
          jsonData.map(item => {
            str += '<tr>';
            for (let attr in item) {
              //增加\t为了不让表格显示科学计数法或者其他格式
              str += `<td>${item[attr] + '\t'}</td>`;
            }
            str += '</tr>';
          });
          //Worksheet名
          let worksheet = 'Sheet2';
          let uri = 'data:application/vnd.ms-excel;base64,';
          //下载的表格模板数据
          let template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" 
                xmlns:x="urn:schemas-microsoft-com:office:excel" 
                xmlns="http://www.w3.org/TR/REC-html40">
                <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                    <x:Name>${worksheet}</x:Name>
                    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                    </head><body><table>${str}</table></body></html>`;
          //下载模板
          // window.location.href = uri + base64(template)
          //输出base64编码
          function base64(s) {
            return window.btoa(unescape(encodeURIComponent(s)));
          }
          var aLink = document.createElement('a');
          aLink.href = uri + base64(template);
          aLink.setAttribute('download', '[BBK开发者平台]应用下载分析数据.xls');
          aLink.click();
          aLink.style.display = 'none';
          document.body.appendChild(aLink);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 11px;
}
.checked {
  content: '';
  position: absolute;
  height: 3px;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #3cb1ff;
  border-radius: 2px;
  transition: all 0.5s;
}
.body-wrap {
  background: #f2f2f2;
  .main-body {
    width: 100%;
    min-height: 900px;
    position: relative;
    clear: both;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 16px;
      padding-left: 13px;
      padding-bottom: 30px;
      color: #333;
      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0px;
        width: 4px;
        height: 16px;
        border-radius: 3px;
        background: #3cb1ff;
      }
    }
    .url-navigation {
      font-size: 16px;
      padding-bottom: 20px;
      .back {
        color: #666;
        cursor: pointer;
      }
      .current-page {
        color: blue;
        cursor: pointer;
      }
    }
    .top-tip {
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #aaa;
    }
    .apk-info-wrap {
      display: flex;
      justify-content: space-between;
      padding: 40px 35px;
      border-radius: 5px;
      .card();
      .apk-info {
        display: flex;
        img {
          width: 120px;
          height: 120px;
          border-radius: 10px;
          margin-right: 20px;
        }
        div {
          display: flex;
          flex-direction: column;
          h2 {
            font-size: 18px;
            color: #333;
            margin-top: 24px;
            margin-bottom: 15px;
          }
          p {
            font-size: 14px;
            color: #666;
            span:first-of-type {
              color: #333;
            }
          }
        }
      }
      .downloads {
        display: flex;
        align-items: center;
        margin: 36px 49px 0 0;
        flex-direction: column;
        font-size: 14px;
        color: #666;
        span:first-of-type {
          margin-bottom: 10px;
          font-size: 22px;
          font-weight: 900;
          color: #333;
        }
      }
    }
    .echart-wrap {
      .card();
      padding: 29px;
      .btns {
        padding-left: 10px;
        display: flex;
        span {
          position: relative;
          font-size: 14px;
          color: #333;
          margin-right: 10px;
          padding: 5px;
          cursor: pointer;
          &:hover::after {
            .checked();
            right: 0;
          }
          &::after {
            .checked();
          }
          &.active::after {
            .checked();
            right: 0;
          }
        }
      }
      .echart {
        width: 100%;
        height: 400px;
      }
    }
    .download-table {
      .card();
      padding: 29px;
      .top {
        display: flex;
        justify-content: space-between;
        .top-btn {
          height: 40px;
        }
      }
      .date-picker {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;
        .animate {
          position: relative;
          font-size: 14px;
          color: #333;
          margin-right: 10px;
          padding: 5px;
          cursor: pointer;
          &:hover::after {
            .checked();
            right: 0;
          }
          &::after {
            .checked();
          }
          &.active::after {
            .checked();
            right: 0;
          }
        }
        .button {
          display: inline-block;
          margin-left: 16px;
          padding: 6px 33px;
          color: #fff;
          border-radius: 4px;
          background: #28da99;
          cursor: pointer;
        }
        .date {
          margin: 0 10px;
        }
        .box {
          flex: 99999 0 auto;
        }
        .flexnone {
          flex: 0 0 auto;
        }
      }
      .data-display {
        background: #fff;
        padding-top: 20px;
        .echarts {
          width: 100%;
          height: 400px;
        }
        .table {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          border-radius: 5px;
          border-top: 1px solid #ddd;
          border-left: 1px solid #ddd;
          .table-title {
            display: flex;
            font-size: 18px;
            background: #f0f4f5;
            div {
              position: relative;
              line-height: 70px;
              width: 571px;
              border-right: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
              img {
                vertical-align: middle;
                margin-top: -5px;
              }
            }
          }
          .table-body {
            display: flex;
            font-size: 14px;
            div {
              display: block;
              width: 571px;
              line-height: 49px;
              border-right: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
            }
          }
        }
        .pagination {
          width: 1115px;
          margin: 0 auto;
          padding: 21px 0 29px;
          text-align: right;
        }
      }
    }
  }
}
</style>
