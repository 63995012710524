<template>
  <section class="container">
    <div class="main-body">
      <div class="login-box">
        <p class="login-title">开发者用户注册</p>
        <div class="input-box">
          <form>
            <div class="account-box">
              <div>
                <span class="left-label">
                  <span class="star">*</span>
                  <span>邮箱</span>
                </span>
                <input
                  v-model="account"
                  v-focus="focusAccountState"
                  type="text"
                  placeholder="请输入正确的邮箱"
                  class="input input-long"
                  :class="errorAcount ? 'red-line' : 'default-line'"
                  maxlength="85"
                  @keyup="accountInput('account', 'up')"
                />
                <img
                  v-if="clearAccountShow"
                  src="@/assets/img/delete-icon.png"
                  alt="删除输入信息"
                  class="clear-icon"
                  @click="clearContent('account')"
                />
                <div v-if="errorAcount" class="error-desc">
                  {{ errorAccountDesc }}
                </div>
              </div>
            </div>
            <div class="code-box">
              <div>
                <span class="left-label">
                  <span class="star">*</span>
                  <span>验证码</span>
                </span>
                <input
                  v-model="code"
                  v-focus="focusCodeState"
                  type="text"
                  placeholder="请输入邮箱验证码"
                  maxlength="8"
                  class="input input-short"
                  :class="errorCode ? 'red-line' : 'default-line'"
                  @keydown="accountInput('code', 'down')"
                  @keyup="accountInput('code', 'up')"
                />
                <img
                  v-if="clearCodeShow"
                  src="@/assets/img/delete-icon.png"
                  alt="删除输入信息"
                  class="clear-icon"
                  @click="clearContent('code')"
                />
                <a class="get-code-btn" disabled="disabled" @click="getCode">{{
                  defaultCode
                }}</a>
                <div v-if="errorCode" class="error-desc">请输入验证码</div>
              </div>
            </div>
            <div class="nickname-box">
              <div>
                <span class="left-label">
                  <span class="star">*</span>
                  <span>用户名</span>
                </span>
                <input
                  v-model="nickName"
                  v-focus="focusNickNameState"
                  type="text"
                  maxlength="15"
                  placeholder="6-15位字母、数字、下划线的组合，需以字母开头"
                  class="input input-long"
                  :class="errorNickName ? 'red-line' : 'default-line'"
                  @keydown="accountInput('nickName', 'down')"
                  @keyup="accountInput('nickName', 'up')"
                />
                <img
                  v-if="clearNickNameShow"
                  src="@/assets/img/delete-icon.png"
                  alt="删除输入信息"
                  class="clear-icon"
                  @click="clearContent('nickName')"
                />
                <div v-if="errorNickName" class="error-desc">
                  {{ errorNickNameDesc }}
                </div>
              </div>
            </div>
            <div class="psd-box">
              <div>
                <span class="left-label">
                  <span class="star">*</span>
                  <span>密码</span>
                </span>
                <input
                  v-model="password"
                  v-focus="focusPsdState"
                  type="password"
                  maxlength="16"
                  placeholder="8-16位大小写字母、数字、符号组合"
                  class="input input-long"
                  :class="errorPsd ? 'red-line' : 'default-line'"
                  @keydown="accountInput('psd', 'down')"
                  @keyup="accountInput('psd', 'up')"
                />
                <img
                  v-if="clearPsdShow"
                  src="@/assets/img/delete-icon.png"
                  alt="删除输入信息"
                  class="clear-icon"
                  @click="clearContent('psd')"
                />
                <div v-if="errorPsd" class="error-desc">
                  8-16位，必须是大写或小写字母、标点、数字至少三种的组合
                </div>
              </div>
            </div>
            <div class="confirm-box">
              <div>
                <span class="left-label">
                  <span class="star">*</span>
                  <span>确认密码</span>
                </span>
                <input
                  v-model="confirmPsd"
                  v-focus="focusConfirmPsdState"
                  type="password"
                  maxlength="16"
                  placeholder="请输入确认密码"
                  class="input input-long"
                  :class="errorConfirmPsd ? 'red-line' : 'default-line'"
                  @keydown="accountInput('confirmPsd', 'down')"
                  @keyup="accountInput('confirmPsd', 'up')"
                />
                <img
                  v-if="clearConfirmPsdShow"
                  src="@/assets/img/delete-icon.png"
                  alt="删除输入信息"
                  class="clear-icon"
                  @click="clearContent('confirmPsd')"
                />
                <div v-if="errorConfirmPsd" class="error-desc">请确认密码</div>
                <div v-if="errorConfirmPsd1" class="error-desc">密码不一致</div>
              </div>
            </div>
          </form>
          <div class="go-register-btn" @click="submit">
            立即注册
          </div>
          <div class="protocol-box">
            <span class="protocol-show" @click="closeIconBtn()"
              >《步步高用户协议》</span
            >
          </div>
          <div v-if="toastShow1" class="toast-box1">
            <img
              src="@/assets/img/toast-icon.png"
              alt="提示图标"
              class="toast-icon"
            />
            <span>{{ toastSuccessShow }}</span>
          </div>
          <div v-show="toastShow2" class="toast-box2">
            <img
              src="@/assets/img/error-icon.png"
              alt="提示图标"
              class="toast-icon"
            />
            <span>{{ toastErrShow }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="protocolShow" class="modal-box"></div>
    <div v-if="protocolShow" class="protocol-area">
      <img
        src="@/assets/img/close-icon.png"
        alt="关闭"
        class="close-icon"
        @click="closeModal"
      />
      <div class="content-title">步步高开发者平台开发者服务协议</div>
      <agreementBox :isOverflow="1" />
      <div v-if="btnBoxShow" class="btn-box">
        <span class="agree-btn" @click="agreeProtocalBtn">接受注册协议</span>
        <span class="reject-btn" @click="closeModal">拒绝</span>
      </div>
    </div>
  </section>
</template>
<script>
import { ToCDB } from '@/plugins/util';
import { Md5 } from '@/plugins/md5';
import agreementBox from './agreement.vue';
export default {
  name: 'Register',
  components: { agreementBox },
  data() {
    return {
      account: '',
      code: '',
      nickName: '',
      password: '',
      confirmPsd: '',
      unCheck: true,
      clearAccountShow: false,
      clearCodeShow: false,
      clearNickNameShow: false,
      clearPsdShow: false,
      clearConfirmPsdShow: false,
      focusAccountState: false,
      focusCodeState: false,
      focusNickNameState: false,
      focusPsdState: false,
      focusConfirmPsdState: false,
      errorAcount: false,
      errorCode: false,
      errorNickName: false,
      errorPsd: false,
      errorConfirmPsd: false,
      errorConfirmPsd1: false,
      defaultCode: '获取验证码',
      protocolShow: true,
      toastShow1: false,
      toastShow2: false,
      toastErrShow: '验证码获取失败！',
      toastSuccessShow: '发送成功',
      mailReg: null,
      userNameReg: null,
      signTime: '',
      agreeProtocal: false,
      btnBoxShow: true,
      isType1: false,
      isType2: false,
      errorAccountDesc: '请输入正确的邮箱',
      errorNickNameDesc: '6-15位，字母、数字、下划线的组合，需以字母开头'
    };
  },
  mounted() {
    this.mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    this.userNameReg = /^[a-zA-Z]{1}[a-zA-Z0-9_]{5,15}$/;
  },
  methods: {
    getCode() {
      let vm = this;
      if (
        !this.mailReg.test(this.account) ||
        this.errorAccountDesc === '邮箱已被注册'
      ) {
        this.errorAcount = true;
        return;
      }
      if (this.defaultCode !== '获取验证码') {
        return;
      }
      this.defaultCode = '发送中';
      this.$axios
        .post(`userManage/sendMailCode`, {
          contactMail: this.account,
          verifyCodeType: 1
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode !== '0') {
            vm.toastShow1 = false;
            vm.toastShow2 = true;
            vm.toastErrShow = '验证码获取失败！';
            let codeTimer2 = setTimeout(function() {
              vm.toastShow2 = false;
              clearTimeout(codeTimer2);
            }, 3000);
          } else {
            this.codeTimer();
            vm.toastShow1 = true;
            vm.toastShow2 = false;
            vm.toastSuccessShow = '发送成功';
            let codeTimer1 = setTimeout(function() {
              vm.toastShow1 = false;
              clearTimeout(codeTimer1);
            }, 3000);
          }
        })
        .catch(() => {
          vm.toastShow1 = false;
          vm.toastShow2 = true;
          vm.toastErrShow = '验证码获取失败！';
          let codeTimer3 = setTimeout(function() {
            vm.toastShow2 = false;
            clearTimeout(codeTimer3);
          }, 3000);
        });
    },
    selectDays() {
      this.unCheck = this.unCheck ? false : true;
    },
    /*
        点击“步步高用户协议”
         */
    closeIconBtn() {
      this.protocolShow = true;
      this.isType1 = true;
      this.btnBoxShow = false;
    },
    /*
        关闭modal
         */
    closeModal() {
      if (this.isType1) {
        this.protocolShow = false;
        return;
      }
      if (!this.isType2) {
        this.$router.push('/');
      }
    },
    agreeProtocalBtn() {
      this.agreeProtocal = true;
      this.signTime = new Date().getTime();
      this.protocolShow = false;
    },
    /*
        点击“立即注册”按钮
         */
    submit() {
      var vm = this;
      this.isType1 = false;
      this.isType2 = true;
      this.errorAcount = this.mailReg.test(this.account) ? false : true;
      this.errorCode = this.code ? false : true;
      this.errorNickName = this.userNameReg.test(this.nickName) ? false : true;
      // this.errorPsd = this.password ? false : true;
      if (!this.password.trim()) {
        this.errorPsd = true;
      }
      this.errorConfirmPsd = this.confirmPsd ? false : true;
      if (
        !this.errorAcount &&
        !this.errorCode &&
        !this.errorNickName &&
        !this.errorPsd
      ) {
        if (this.password !== this.confirmPsd) {
          this.errorConfirmPsd1 = true;
          return;
        }
        this.isType2 = false;
        this.btnBoxShow = true;
        this.$axios
          .post(`userManage/register`, {
            contactMail: this.account,
            mailVerifyCode: this.code,
            userName: ToCDB(this.nickName),
            password: Md5.hex_md5(ToCDB(this.password.trim())),
            signTime: this.signTime
          })
          .then(res => {
            this.protocolShow = false;
            var data = res.data;
            if (data.stateCode === '0') {
              this.$cookie.setCookie(
                'token',
                encodeURIComponent(data.data.token),
                1
              );
              this.$cookie.setCookie(
                'userName',
                encodeURIComponent(data.data.userName),
                1
              );
              this.$cookie.setCookie(
                'accountId',
                encodeURIComponent(data.data.accountId),
                1
              );
              vm.toastShow1 = true;
              vm.toastShow2 = false;
              vm.toastSuccessShow = '账号注册成功！';
              setTimeout(function() {
                vm.toastShow1 = false;
                vm.$router.push('/account_submit');
              }, 1000);
            } else {
              vm.toastShow1 = false;
              vm.toastShow2 = true;
              vm.toastErrShow = '验证码错误！';
              setTimeout(function() {
                vm.toastShow2 = false;
              }, 3000);
            }
          })
          .catch(() => {
            vm.toastShow1 = false;
            vm.toastShow2 = true;
            vm.toastErrShow = '验证码错误！';
            setTimeout(function() {
              vm.toastShow2 = false;
            }, 3000);
          });
      }
    },
    // eslint-disable-next-line no-unused-vars
    accountInput(type1, type2) {
      switch (type1) {
        case 'account':
          this.focusAccountState = true;
          this.focusCodeState = false;
          this.focusNickNameState = false;
          this.focusPsdState = false;
          this.focusConfirmPsdState = false;
          if (this.account.length && this.mailReg.test(this.account)) {
            this.verifyMail();
            this.clearAccountShow = true;
            this.errorAcount = false;
          } else {
            this.clearAccountShow = false;
            this.errorAcount = true;
          }
          break;
        case 'code':
          this.focusCodeState = true;
          this.focusAccountState = false;
          this.focusNickNameState = false;
          this.focusPsdState = false;
          this.focusConfirmPsdState = false;
          if (this.code.length) {
            this.clearCodeShow = true;
            this.errorCode = false;
          } else {
            this.clearCodeShow = false;
            this.errorCode = true;
          }
          break;
        case 'nickName':
          this.focusNickNameState = true;
          this.focusAccountState = false;
          this.focusCodeState = false;
          this.focusPsdState = false;
          this.focusConfirmPsdState = false;
          if (this.nickName.length && this.userNameReg.test(this.nickName)) {
            this.verifyUserName();
            this.clearNickNameShow = true;
            this.errorNickName = false;
          } else {
            this.clearNickNameShow = false;
            this.errorNickName = true;
          }
          break;
        case 'psd':
          var psdReg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/;
          this.focusPsdState = true;
          this.focusAccountState = false;
          this.focusCodeState = false;
          this.focusNickNameState = false;
          this.focusConfirmPsdState = false;
          if (this.password.length && psdReg.test(this.password.trim())) {
            this.clearPsdShow = true;
            this.errorPsd = false;
          } else {
            this.clearPsdShow = false;
            this.errorPsd = true;
          }
          break;
        case 'confirmPsd':
          this.focusConfirmPsdState = true;
          this.focusAccountState = false;
          this.focusCodeState = false;
          this.focusNickNameState = false;
          this.focusPsdState = false;
          if (this.confirmPsd.length) {
            this.clearConfirmPsdShow = true;
            this.errorConfirmPsd = false;
          } else {
            this.clearConfirmPsdShow = false;
            this.errorConfirmPsd = true;
          }
          break;
      }
    },
    /*
        验证输入的邮箱是否存在
         */
    verifyMail() {
      this.$axios
        .post(`userManage/verifyMail?contactMail=${this.account}`)
        .then(res => {
          var data = res.data;
          if (data.stateCode === '804') {
            this.clearAccountShow = true;
            this.errorAcount = true;
            this.errorAccountDesc = '邮箱已被注册';
            return;
          } else {
            this.errorAccountDesc = '';
          }
        })
        .catch(() => {});
    },
    /*
        验证输入的用户名是否存在
         */
    verifyUserName() {
      this.$axios
        .post(`userManage/verifyUserName?userName=${this.nickName}`)
        .then(res => {
          var data = res.data;
          if (data.stateCode === '804') {
            this.clearNickNameShow = true;
            this.errorNickName = true;
            this.errorNickNameDesc = '用户名已被使用';
            return;
          }
        })
        .catch(() => {});
    },
    clearContent(type) {
      switch (type) {
        case 'account':
          if (!this.account.length) return;
          this.account = '';
          this.clearAccountShow = false;
          this.focusAccountState = true;
          break;
        case 'code':
          if (!this.code.length) return;
          this.code = '';
          this.clearCodeShow = false;
          this.focusCodeState = true;
          break;
        case 'nickName':
          if (!this.nickName.length) return;
          this.nickName = '';
          this.clearNickNameShow = false;
          this.focusNickNameState = true;
          break;
        case 'psd':
          if (!this.password.length) return;
          this.password = '';
          this.clearPsdShow = false;
          this.focusPsdState = true;
          break;
        case 'confirmPsd':
          if (!this.confirmPsd.length) return;
          this.confirmPsd = '';
          this.clearConfirmPsdShow = false;
          this.focusConfirmPsdState = true;
          break;
      }
    },
    codeTimer() {
      let count = 60;
      let timer = setInterval(() => {
        count--;
        if (count < 0) {
          clearInterval(timer);
          this.defaultCode = '获取验证码';
          return;
        }
        this.defaultCode = count + '秒';
      }, 1000);
    }
  }
};
</script>
<style lang="less" scoped>
.main-body {
  background: url('~@/assets/img/bg.jpg');
  height: 900px;
  padding-top: 118px;
  .login-box {
    background: #ffffff;
    width: 600px;
    height: 670px;
    margin: 0 auto;
    border-radius: 5px;
    .login-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      padding-top: 24px;
      padding-bottom: 20px;
      color: #545454;
      border-bottom: 1px solid #c7c7c7;
    }
  }
  .input-box {
    padding-top: 36px;
    .account-box,
    .psd-box,
    .nickname-box,
    .confirm-box {
      position: relative;
      margin-bottom: 20px; // margin-left: 112px;
      .clear-icon {
        position: absolute;
        left: 76%;
        top: 41%;
        cursor: pointer;
      }
    }
    .code-box {
      position: relative;
      margin-bottom: 20px; // margin-left: 112px;
      .clear-icon {
        position: absolute;
        left: 51.8%;
        top: 41%;
        cursor: pointer;
      }
    }
  }
  .input {
    webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    width: 352px;
    outline: none;
    -webkit-appearance: none;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #666666;
    font-size: 14px;
    padding: 10px 36px 10px 10px;
    overflow: hidden;
    &.input-short {
      width: 206px;
    }
  }
  .red-line {
    border: 1px solid red;
  }

  .default-line {
    border: 1px solid #c7c7c7;
  }
  input:focus {
    border: 1px solid #3cb1ff;
  }
  .get-code-btn {
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
    width: 124px;
    height: 40px;
    line-height: 42px;
    display: inline-block;
    vertical-align: middle;
    background: #28da99;
    border-radius: 4px;
    text-align: center;
    transition: background 0.5s;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s;
    -o-transition: background 0.5s;
  }
  .get-code-btn:hover {
    background: #36c792;
  }
}

.operate-box {
  margin-top: 18px;
  width: 352px;
  .checked {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(~@/assets/img/checked.png);
    margin-bottom: -2px;
    cursor: pointer;
  }
  .checked.no {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(~@/assets/img/un-check.png);
    margin-bottom: -2px;
    cursor: pointer;
  }
  .auto-days {
    display: inline-block;
    color: #3c3c3c;
    font-size: 14px;
    margin-left: 4px;
  }
  .forget-psd {
    color: #0099ff;
    font-size: 14px;
    float: right;
    text-decoration: none;
  }
}

.go-register-btn {
  background: #3cb1ff;
  width: 352px;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin-top: 28px;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 127px;
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.go-register-btn:hover {
  background: #2ea5f5;
}

.error-desc {
  margin-left: 127px;
  color: #ff7477;
  font-size: 12px;
  position: absolute;
}

.modal-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.5;
  z-index: 9999;
}

.protocol-area {
  background: #ffffff;
  color: #333333;
  width: 1175px;
  height: 750px;
  line-height: 38px;
  border-radius: 5px;
  opacity: 1;
  z-index: 99999;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  padding-left: 100px;
  padding-right: 100px;
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .close-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }

  .protocol-content {
    margin-top: 30px;
    height: 528px;
    overflow-y: scroll;
    font-size: 14px;
    padding: 0 20px;
    text-indent: 2em;
    strong {
      font-weight: 600;
    }
    .title {
      color: #333333;
    }
    .content-footer {
      text-align: right;
      margin-right: 20px;
    }
    .content {
      color: #666666;
      line-height: 24px;
      letter-spacing: 1px;
      .span-width {
        display: inline-block;
        width: 28px;
      }
    }
  }
  .btn-box {
    text-align: center;
    margin-top: 30px;
    .agree-btn {
      display: inline-block;
      width: 350px;
      height: 40px;
      line-height: 38px;
      text-align: center;
      border-radius: 4px;
      color: #ffffff;
      background: #3cb1ff;
      border: 1px solid #3cb1ff;
      cursor: pointer;
    }
    .reject-btn {
      display: inline-block;
      width: 350px;
      height: 40px;
      line-height: 38px;
      text-align: center;
      border-radius: 4px;
      color: #333333;
      background: #ffffff;
      margin-left: 30px;
      border: 1px solid #c7c7c7;
      cursor: pointer;
    }
  }
}

.left-label {
  display: inline-block;
  text-align: right;
  width: 110px;
  margin-right: 16px;
  font-size: 14px;
  color: #333333;
  .star {
    color: red;
    margin-right: 3px;
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}

.protocol-box {
  font-size: 14px;
  color: #333333;
  margin-top: 16px;
  margin-right: 116px;
  text-align: right;
  .protocol-show {
    color: #3cb1ff;
    cursor: pointer;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: #277db7;
    }
  }
}

.toast-box1 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #28da99;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #dff9f0;
  margin: 24px 144px 0;
  .toast-icon {
    margin-right: 6px;
  }
}

.toast-box2 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  border: 1px solid red;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #feeaeb;
  margin: 24px 144px 0;
  .toast-icon {
    margin-right: 6px;
    margin-bottom: -2px;
  }
}
</style>
