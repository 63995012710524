import menu from './menu';
const components = (() => {
  let comObj = {};

  function checkChildren(obj) {
    if (obj.children && obj.children.length > 0) {
      checkChildren(obj.children);
      return;
    }
    obj.map(item => {
      if (item.children && item.children.length > 0) {
        checkChildren(item.children);
        return;
      }
      comObj[item.componentName] = item.component;
    });
  }
  checkChildren(menu);
  return comObj;
})();

export default components;
