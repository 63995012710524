<template>
  <div class="upload-slice-box">
    <slot></slot>
    <slot name="progress"> </slot>
  </div>
</template>
<script>
export default {
  name: 'UploadSlice',
  props: {
    // 上传按钮ID
    btnId: {
      type: String,
      default: function() {
        return 'filePicker';
      }
    },
    // 多余的参数上传给服务端
    formData: {
      type: Object,
      default: function() {
        return {
          uploadFileType: 'apk',
          isEncrypt: ''
        };
      }
    },
    // 服务器地址
    serverUrl: {
      type: String,
      default: function() {
        return '';
      }
    },
    //文件后缀名限制
    // ext: {
    // 	type: String,
    // 	default: function () {
    // 		return "apk";
    // 	}
    // },
    //分片大小设置
    chunkSize: {
      type: Number,
      default: function() {
        return 1048576;
      }
    },
    //分片上传重试次数
    chunkRetry: {
      type: Number,
      default: function() {
        return 3;
      }
    },
    //是否自动上传
    auto: {
      type: Boolean,
      default: function() {
        return true;
      }
    },
    //上传次数限制
    countLimit: {
      type: Number,
      default: function() {
        return 10;
      }
    }
  },
  data() {
    return {
      uploader: null,
      gid: '',
      percent: null
    };
  },
  mounted() {
    this.initObj();
    this.initEvent();
  },
  methods: {
    // 初始化上传组件
    initObj() {
      // eslint-disable-next-line no-undef
      this.uploader = WebUploader.create({
        // swf文件路径
        //        swf: '../js/Uploader.swf',
        // 文件接收服务端。
        server: this.serverUrl,
        // 定义选择按钮
        pick: '#' + this.btnId,
        // 自动上传
        auto: true,
        // 不压缩image, 默认如果是jpeg，文件上传前会压缩一把再上传！
        resize: false,
        // 分片上传大文件
        chunked: true,
        chunkSize: this.chunkSize,
        // 重试次数
        chunkRetry: this.chunkRetry,
        // 文件上传数量
        fileNumLimit: this.countLimit,
        fileVal: 'file'
        // 限制格式
        // accept: {
        // 	title: "access",
        // 	extensions: this.ext,
        // 	mimeTypes: ".*" + this.ext,
        // }
      });
    },
    // 给组件绑定事件
    initEvent() {
      var vm = this;
      // 某个分块文件上传之前，触发多次（每块触发一次，用于携带多余参数上传给服务端）
      this.uploader.on('uploadBeforeSend', function(block, data, header) {
        // 添加额外的参数
        data.chunkSize = vm.chunkSize;
        data.gid = vm.gid;
        Object.assign(data, vm.formData);

        // 添加额外的请求头
        header.token = vm.$cookie.getCookie('token');
        header.userName = vm.$cookie.getCookie('userName');
      });
      // 文件上传之前，触发一次
      this.uploader.on('uploadStart', file => {
        // eslint-disable-next-line no-undef
        vm.gid = WebUploader.Base.guid();
        vm.$emit('uploadStart', file);
      });
      // 上传过程中
      this.uploader.on('uploadProgress', (file, percent) => {
        percent = Math.ceil(percent * 100);
        vm.percent = percent;
        vm.$emit('uploadProgress', percent, file);
      });
      // 上传成功
      this.uploader.on('uploadSuccess', (file, response) => {
        vm.$emit('uploadSuccess', response, file);
      });
      // 上传失败
      this.uploader.on('uploadError', (file, reason) => {
        vm.$emit('uploadError', reason, file);
      });
    },
    // 重置数据
    reset() {
      this.uploader.reset();
      this.percent = null;
    }
  }
};
</script>
