<template>
  <div class="content-right">
    <div class="content-title">注册常见Q&A</div>
    <div class="content">
      <p class="content-big-title">1.可以修改注册账号或者注册邮箱吗？</p>
      <p class="content-small-title">
        均不提供修改，为了防止公司人员的变动，平台建议注册时使用企业邮箱；联系人邮箱支持修改
      </p>

      <p class="content-big-title">2.个人开发者可以注册账号吗？</p>

      <p class="content-small-title">平台暂时不支持个人开发者注册</p>
      <p class="content-big-title">
        3.注册账号的审核结果表示“公司已被注册，请勿重复注册”时，怎么办？
      </p>

      <p class="content-small-title">
        由于一个公司只支持注册一个账号，若贵公司已被注册，请提供最新营业执照（三证合一或者三证一照）、开户许可证、法人身份正反面照片，发送到open@eebbk.com，并备注公司名称和详细原因，相关同事审核之后将会为您提供已注册账号的联系人邮箱
      </p>
      <p class="content-big-title">
        4.对于境外开发者，如何注册步步高家教机开发者账号？
      </p>

      <p class="content-small-title">
        非大陆地区企业开发者若无工商颁发的营业执照，需在【营业执照】栏提供商业登记证书或者相关证明材料，如有疑问，请联系步步高商务。
      </p>
      <p class="content-big-title">
        5.营业执照在国家企业信用信息公示系统查询显示“经营异常名录”，会影响审核吗？
      </p>

      <p class="content-small-title">
        会的，如果您的营业执照在国家企业信用信息公示系统查询时显示：“列入经营异常名录信息
        或 列入严重违法失信企业名单（黑名单）信息” ，该情况将不予审核通过
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  height: 100%;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
    height: 700px;
    overflow: auto;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      color: #333333;
    }
    .span1 {
      color: red;
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
