<!--
 * @Author: K0802415 k0802415@eebbk.com
 * @Date: 2022-06-23 14:35:38
 * @LastEditors: K0802415 k0802415@eebbk.com
 * @LastEditTime: 2022-08-22 21:00:51
 * @FilePath: \dev-platform\src\views\doc\check\rule.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="container">
    <div class="content-right">
      <p class="content-title" style="text-align: center;">
        <span>
          <strong>应用审核规范</strong>
        </span>
      </p>
      <p class="content-big-title"><strong>一、 原则</strong></p>
      <p><span>（一）</span><strong>合法合规原则</strong></p>
      <p>
        <span
          >应用的资质、功能、信息内容、个人信息保护、未成年人保护、应用安全、广告和付费等各环节均应符合国家相关法律法规。</span
        >
      </p>
      <p><span>（二）</span><strong>保护未成年人原则</strong></p>
      <p>
        <span
          >应用及应用运营者应当积极响应包括清朗行动在内的国家未成年人保护工作，对自身应用进行审查调整，提供适合未成年人使用的应用服务及绿色健康的互联网环境。
        </span>
      </p>
      <p><span>（三）</span><strong>内容安全健康原则</strong></p>
      <p>
        <span
          >应用须提供安全健康的网络环境，内容积极向上、阳光开朗，符合社会主义核心价值观。</span
        >
      </p>
      <p><span>（四）</span><strong>个人信息保护原则</strong></p>
      <p>
        <span
          >应用的用户个人信息处理须符合国家相关法律法规，遵循公开透明、方便管理、确保安全、细致管控、合理适度的处理原则。</span
        >
      </p>
      <p><span>（五）</span><strong>不得诱导用户沉迷原则</strong></p>
      <p>
        <span
          >应用应当注意未成年人防沉迷，应用功能设计须符合屏幕使用低粘度、轻互动的原则，避免游戏化设计，不得向未成年人提供诱导其沉迷的产品和服务，并需及时修改可能造成未成年人沉迷的内容、功能或者规则。</span
        >
      </p>
      <p class="content-big-title"><strong>二、 应用资质</strong></p>
      <p>
        <span
          >1)
          根据应用程序分类不同，需要提供的资质各不相同【资质审核规范详细内容参照<span
            class="goToBlank"
            @click="$goToBlank('check_quality')"
            >《应用资质审核规范》</span
          >】；</span
        >
      </p>
      <p>
        <span
          >2)
          不允许提交PS等方式合成、制作的假证件，否则可能造成终止合作或开发者帐号冻结，开发者应赔偿因此给本公司造成的所有损失；</span
        >
      </p>
      <p>
        <span
          >3）具有舆论属性或者社会动员能力应用程序没有提交安全评估的，不予通过（具体参照
          <span class="goToBlank" @click="$goToBlank('register_convention')"
            >《应用商店平台开发者合规公约（具有舆论属性或者社会动员能力应用程序安全评估）》</span
          >部分）；</span
        >
      </p>
      <p>
        <span
          >4)
          开发者保证：其提供的所有信息，包括但不限于其公司名称、法定代表人姓名、联系电话、电子邮箱、资质等相关资料的真实性、准确性和完整性。
        </span>
      </p>
      <p><span> </span></p>
      <p class="content-big-title">
        <span>三、</span><strong>应用内容</strong>
      </p>
      <p>
        <strong
          >应用内容需符合《中华人民共和国未成年人保护法》《中华人民共和国预防未成年人犯罪法》《中华人民共和国广告法》《未成年人节目管理规定》《互联网视听节目服务管理规定》等法律法规要求以及本应用商店平台<span
            class="goToBlank"
            @click="$goToBlank('register_threeConvention')"
            >《步步高应用商店第三方应用程序提供方内容守则公约》</span
          >（下称“《内容安全公约》”）。</strong
        >
      </p>
      <p><span>（一）</span><strong>应用设计</strong></p>
      <p>
        <span
          >1)
          禁止开发者重复提交结构、功能、内容相似的应用，重复提交的应用将被驳回或下架。情节严重的，开发者账号会被冻结；
        </span>
      </p>
      <p>
        <span
          >2)
          禁止将已有应用内的某一内容独立作为新应用上传，包括但不限于如下情况：影视剧集、图书单行本、单一主题、单一游戏攻略、单一壁纸、单一锁屏、单一旅游攻略、游戏攻略类应用等；
        </span>
      </p>
      <p>
        <span
          >3)
          应用程序不允许依赖于跳转网页或者第三方应用程序获取其内容及功能；</span
        >
      </p>
      <p>
        <span>4) 禁止提供有明确版权归属的应用破解版、汉化版；</span>
      </p>
      <p>
        <span>5) 禁止带有修改其他应用数据、存档等内容的功能；</span>
      </p>
      <p>
        <span
          >6）应用内容是简单打包的网站、套用模板，或没有持续使用价值整合的，不予通过；
        </span>
      </p>
      <p>
        <span>7）应用内容不完整，部分功能待开发，不予通过；</span>
      </p>
      <p>
        <span
          >8）当前手电筒、计算器、闹钟等小工具应用数量较多，如果您的此类应用没有重大创新或亮点，新提交的该类应用将不予通过；</span
        >
      </p>
      <p>
        <span
          >9）应用功能、界面和应用商店中已收录应用非常类似或完全雷同，不予通过；</span
        >
      </p>
      <p><span> </span></p>
      <p>
        <span> </span>
      </p>
      <p><span>（二）</span><strong>应用名称</strong></p>
      <p>
        <span>1) </span
        ><span
          >应用程序名称不得超过10个汉字字符或20个英文字符，不得含有破折号、空格、星号等特殊符号；</span
        >
      </p>
      <p><span>2) </span><span>不得带有他人产品或品牌名称； </span></p>
      <p>
        <span>3) </span
        ><u
          >应用名不能与步步高应用商店中已存在应用重名；若拥有相应名称对应类别的商标权，请联系客服或者发送邮件到open@eebbk.com进行申诉；</u
        >
      </p>
      <p>
        <span>4) </span
        ><span
          >不允许用不具有识别性的词汇来命名应用，如泛词、归纳词、电话、邮件等；
        </span>
      </p>
      <p>
        <span>5) </span
        ><span>不允许应用内名称、安装到桌面的名称与平台创建的名称不一致；</span>
      </p>
      <p>
        <span>6) </span
        ><span
          >应用名称不得含有政治敏感、色情、赌博、暴力血腥、恐怖内容及国家法律法规禁止的其他违法内容；
        </span>
      </p>
      <p>
        <span>7) </span
        ><span
          >应用名称不得混有商业化用语、其他热门应用名称、流行用语、关键词堆砌、“国家级”、“最高级”等新广告法明令禁止以及其他与应用功能无关的词语；
        </span>
      </p>
      <p><span>8) 应用名称</span><span>不能带有官方、美女、正版字样；</span></p>
      <p>
        <span>9) </span
        ><span
          >非政务机构应用不能带有政府设定的综合服务管理机构的常用号码代称、简称、缩写等表达；
        </span>
      </p>
      <p>
        <span>10) </span
        ><span
          >名称中不能带有或使用社交应用（如微信、QQ）帐号或群聊的二维码、条形码、号码；
        </span>
      </p>
      <p>
        <span>11) </span
        ><span
          >如应用名称涉及侵权，本平台有权在不提前知会开发者的情况下进行修改；</span
        >
      </p>
      <p>
        <span> </span>
      </p>
      <p><span>（三）</span><strong>应用图标</strong></p>
      <p>
        <span>1) 不允许应用的图标和已上架应用的图标高度相似或者完全相同；</span>
      </p>
      <p>
        <span>2) 不允许应用上架时上传的图标和安装到设备上的图标不一致；</span>
      </p>
      <p>
        <span
          >3)
          不允许应用图标存在非法/侵权内容、蹭热搜行为（侵犯图标、版权或著作权等）；
        </span>
      </p>
      <p>
        <span>4) 不允许应用图标使用真人美女头像和失德艺人头像； </span>
      </p>
      <p>
        <span>5) 不允许应用图标含有“官方”“正版”“热门”等字样；</span>
      </p>
      <p>
        <span
          >6)
          图标不得使用党徽、国徽等素材作为图标，如需使用请提供相关政府机构证明；
        </span>
      </p>
      <p>
        <span
          >7) 不允许使用过于裸露的动漫或出现女性胸/臀/大腿身体部位的资源；
        </span>
      </p>
      <p>
        <span>8）应用图标不允许与其他品牌的标志、图片和文字相似。</span>
      </p>
      <p><span> </span></p>
      <p><span>（四）</span><strong>应用截图</strong></p>
      <p>
        <span>1) 应用截图和应用实际的功能毫无关联，不予通过；</span>
      </p>
      <p>
        <span
          >2)
          应用截图中存在重复、黑边、压缩、拉伸、模糊、显示不完整，不予通过；</span
        >
      </p>
      <p>
        <span
          >3)
          应用截图存在色情、性暗示、暴力、恐怖、儿童不宜、低俗、非法等不良信息和违法有害信息或者侵权内容，不予通过；
        </span>
      </p>
      <p>
        <span>4) 应用截图含有其他平台或者厂商标识，不予通过；</span>
      </p>
      <p>
        <span
          >5)
          应用截图不能带有或使用社交应用（如微信、qq）帐号或群的二维码、条形码、号码。
        </span>
      </p>
      <p>
        <span> </span>
      </p>
      <p><span>（五）</span><strong>应用介绍</strong></p>
      <p>
        <span
          >1)
          应用介绍均不能含有违法有害或不良内容，或者其他低俗敏感信息以及广告法不允许的其他词汇；
        </span>
      </p>
      <p>
        <span>2) 禁止使用虚假性及误导性内容欺骗、诱导用户下载；</span>
      </p>
      <p>
        <span>3) 应用描述中不允许包含其他应用市场的名称/专属活动；</span>
      </p>
      <p>
        <span
          >4) 应用更新日志中不允许包含其他应用市场名称、介绍、引述等内容；</span
        >
      </p>
      <p>
        <span
          >5)
          应用介绍或更新日志中介绍的功能在应用实际使用时不具备或不一致，不予通过；</span
        >
      </p>
      <p>
        <span
          >6)
          应用功能仅供部分用户使用，比如限制用户的地域或仅供组织内部使用等，请在应用介绍内说明具体限制范围；
        </span>
      </p>
      <p>
        <span> </span>
      </p>
      <p><span> </span></p>
      <p><span>（六）</span><strong>不当内容</strong></p>
      <p>
        <span>1) 禁止含有反对宪法所确定的基本原则的内容；</span>
      </p>
      <p>
        <span
          >2)
          禁止含有危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的内容；
        </span>
      </p>
      <p>
        <span>3) 禁止含有损害国家荣誉和利益的内容；</span>
      </p>
      <p>
        <span>4) 禁止含有煽动民族仇恨、民族歧视，破坏民族团结的内容；</span>
      </p>
      <p>
        <span>5) 禁止含有破坏国家宗教政策、宣扬邪教和封建迷信的内容；</span>
      </p>
      <p>
        <span>6) 禁止含有散布谣言、扰乱社会秩破坏社会稳定的内容；</span>
      </p>
      <p>
        <span>7) 禁止含有侮辱或者诽谤他人，侵害他人合法权益的内容；</span>
      </p>
      <p>
        <span>8) 禁止含有法律、行政法规禁止的其他内容；</span>
      </p>
      <p>
        <span
          >9) 禁止强制、误导、诱导用户点击广告，或者强制安装软件、捆绑下载；
        </span>
      </p>
      <p>
        <span
          >10)
          禁止含有或传播暴露、情色、低俗内容，包括但不限于应用信息、内容及广告存在低俗、性暗示、涉黄的资源（包括但不限于文字、图片、漫画、音视频等）、涉黄网站名称、涉黄网址链接以及诱导用户进行线下性交易的内容等；
        </span>
      </p>
      <p>
        <span
          >11)
          禁止含有或传播暴力或其他危险活动，包括但不限于描绘对人类或动物遭到杀害、残害、酷刑、虐待的写实暴力行为或暴力威胁，以及自残、自杀、绝食或其他可能导致严重伤亡的行为；
        </span>
      </p>
      <p>
        <span
          >12)
          禁止含有具有攻击性、不顾及他人感受、令人不安、惹人厌恶或低俗不堪的内容；
        </span>
      </p>
      <p>
        <span>13) 严管不良传教等包装成课程产品、文化交流等的形式存在；</span>
      </p>
      <p>
        <span
          >14)
          禁止传达错误价值观的内容呈现，如仅以偷、抢等传递错误价值的互动方式，例-偷好友能量碎片；如出现游戏、对战、欺负（好友）等非正向理念传达的字眼；
        </span>
      </p>
      <p>
        <span
          >15)
          禁止渲染校园恋情、校园霸凌、拜金主义、封建迷信等传达不良价值观的内容呈现；
        </span>
      </p>
      <p>
        <span
          >16)
          禁止“蓝鲸死亡游戏”、“死亡窒息”、“心跳文学”等诱导青少年自杀倾向的内容；
        </span>
      </p>
      <p>
        <span
          >17)
          禁止无底线追星、饭圈互撕等价值导向不良价值观内容出现，严厉打击与未成年人对于平台热搜打榜应援、明星话题刷屏、挑拨未成年人对立、对骂互喷、人肉搜索、攀比炫富等相关的内容；
        </span>
      </p>
      <p>
        <span
          >18)
          禁止应用内出现劣迹艺人、敏感艺人、失德艺人的作品、信息及所有可能对未成年人产生不良影响的内容；
        </span>
      </p>
      <p>
        <span> </span>
      </p>
      <p><span>（七）</span><strong>用户生成内容</strong></p>
      <p>
        <span
          >1)
          应对用户生成的内容进行有效管控，包括但不限于：核验用户帐号身份信息并保存有关记录；制定过滤机制对帐号名称、昵称、简介、备注、标识、信息发布、评论等功能及内容中的违法有害信息进行防范处置并保存有关记录；制定举报机制并及时作出响应；对严重违规用户帐号停止提供服务；公布联系信息，以便用户与您联系等。
        </span>
      </p>
      <p>
        <span
          >2)
          开发者需履行管理应用社区版块责任，社区版块不得含有违反法律、法规、条例及其他规定的内容。
        </span>
      </p>
      <p>
        <span> </span>
      </p>
      <p>
        <strong>（八）娱乐直播内容特殊规定</strong>
      </p>
      <p>
        <span
          >1)娱乐直播应用禁止含有付费才能使用的主要功能（含聊天、一对一视频、线上付费线下约局等交流功能）；</span
        >
      </p>
      <p>
        <span
          >2) 娱乐直播应用直播房间禁止含有斗牛、扎金花等可下注金币等内容。</span
        >
      </p>
      <p><span> </span></p>
      <p class="content-big-title"><strong>四、应用功能</strong></p>
      <p><span>（一）</span><strong>安装/运行/卸载</strong></p>
      <p>
        <span
          >1)
          应用无法正常安装、启动、卸载，进行相关设置及操作时频繁出现崩溃，或出现需借助第三方软件才可卸载的情况，不予通过；
        </span>
      </p>
      <p>
        <span>2) 应用安装后，如出现多于一个图标的情况，不予通过；</span>
      </p>
      <p>
        <span>3) 应用安装后，其功能与提交应用时填写的描述不符，不予通过；</span>
      </p>
      <p>
        <span
          >4)
          安装后图标、截图、名称、版本、分类与提交应用时填写信息不符，不予通过；
        </span>
      </p>
      <p>
        <span
          >5)
          兼容性差（固件/机型/屏幕等不适配），不适配我司主流机型的应用将会被拒绝【具体适配规范请参照</span
        ><span
          class="goToBlank"
          @click="$goToUrl('http://dev.eebbk.com/#/doc?docComName=design-rule')"
          >《商店客户端运营素材设计规范》</span
        ><span>】； </span>
      </p>
      <p>
        <span>6) 不能在应用内存在下载、安装其他应用的途径；</span>
      </p>
      <p>
        <span>7) 应用可被正常卸载，卸载后全部数据均被删除；</span>
      </p>
      <p>
        <span>8) 禁止开机自启动，禁止系统界面有弹窗；</span>
      </p>
      <p>
        <span
          >9）恶意隐藏相关功能，如设置透明图标、不显示下载或者运行记录、增加强制弹窗、虚假关闭跳链按钮，不予通过；</span
        >
      </p>
      <p>
        <span
          >10) 如不符合产品现状而随意勾选应用分类或适配信息，不予通过；</span
        >
      </p>
      <p>
        <span>3）如果应用上架版本与安装后运行版本不一致，不通过；</span>
      </p>
      <p><span> </span></p>
      <p><span>（二）</span><strong>账号功能相关</strong></p>
      <p>
        <span
          >1)
          应用需要登录，但应用内不提供注册通道，需要在测试附加说明处填写测试账号，如果没有提供，不予通过；如果涉及会员，不管是否提供注册通道，都需提供会员账号，否则不予通过。
        </span>
      </p>
      <p>
        <span
          >2) 注册账号功能不可用，审核时尝试3次都无法成功注册，不予通过；</span
        >
      </p>
      <p>
        <span
          >3)
          应用登录账号功能不可用，应用审核时尝试3次都无法成功登录，不予通过；</span
        >
      </p>
      <p>
        <span>4) 应用含必须验证身份证功能，请提供已验证通过的测试账号；</span>
      </p>
      <p>
        <span>5) 应用应提供账号注销渠道，未提供账号注销渠道可能会被驳回；</span>
      </p>
      <p>
        <span>6) 网络游戏类应用必须接入实名认证系统。</span>
      </p>
      <p>
        <span> </span>
      </p>
      <p><span>（三）</span><strong>基本功能</strong></p>
      <p>
        <span>1) 应用中内容无法正常显示或无法获取，不予通过；</span>
      </p>
      <p>
        <span>2) 应用内按钮点击无反应或点击报错，不予通过；</span>
      </p>
      <p>
        <span>3) 应用功能需要依赖于第三方应用才能实现，不予通过；</span>
      </p>
      <p>
        <span>4) 应用在安装或者运行前提示或者强制用户重启设备，不予通过；</span>
      </p>
      <p>
        <span>5) 功能不需要常驻通知栏，但是无法清除通知栏的，不予通过；</span>
      </p>
      <p>
        <span
          >6) 如应用功能存在问题，应用商店有权在应用上添加必要的风险提醒。</span
        >
      </p>
      <p>
        <span> </span>
      </p>
      <p><span>（四）</span><strong>性能相关</strong></p>
      <p>
        <span
          >1) 应用不得在使用过程中切换和滑动时界面出现明显卡顿、不流畅；</span
        >
      </p>
      <p>
        <span>2) 应用在未启用或者退出至后台后应主动释放系统资源；</span>
      </p>
      <p>
        <span>3) 应用不得存在严重耗电的行为，导致平板发热、耗电异常；</span>
      </p>
      <p>
        <span
          >4)
          应用不得存在高占用CPU及内存的行为，导致系统出现卡顿及应用程序无响应；</span
        >
      </p>
      <p>
        <span
          >5）应用不得影响设备功能，包括但不限于：安装后自动修改或强制修改系统默认配置且用户无法修改、功能键失灵、抢占锁屏等；</span
        >
      </p>
      <p>
        <span
          >6）应用不得私自启动、后台运行或已结束进程的情况下，启动系统服务（例如蓝牙、GPS等）；</span
        >
      </p>
      <p>
        <span
          >7）应用不得出现对其他应用恶意干扰、屏蔽的行为，或者含修改其他应用数据、存档等功能；</span
        >
      </p>
      <p>
        <span>9）应用不得存在bug导致硬件无法正常使用；</span>
      </p>
      <p>
        <span>10）应用不得获取ROOT权限，或在使用过程中非法ROOT系统。</span>
      </p>
      <p style="text-indent: 18pt;"></p>
      <p><span>（五）</span><strong>应用安全</strong></p>
      <p>
        <span
          >1)
          应用不得含有病毒、木马等侵害用户设备安全和数据安全的功能（包括代码等可疑行为）；</span
        >
      </p>
      <p>
        <span>2) 应用不得获取与软件无关的权限、威胁用户信息安全；</span>
      </p>
      <p>
        <span
          >3)
          应用不得滥用自己所具备的权限实施恶意行为，包括但不限于滥用设备管理权限、防卸载、防关停；
        </span>
      </p>
      <p>
        <span
          >4)
          应用不得滥用辅助服务权限获取用户的输入信息，模拟用户输入修改系统重要配置，窃取用户资金；
        </span>
      </p>
      <p>
        <span>5) 禁止应用窃取用户密码或者其他用户个人数据；</span>
      </p>
      <p>
        <span
          >6)
          应用不得通过图标或内容等方式伪装成系统应用，在用户不知情的情况下实施恶意行为。
        </span>
      </p>
      <p><span> </span></p>
      <p><span>（六）</span><span>应用捆绑</span></p>
      <p>
        <span>1）</span
        ><span
          >引导页面、注册、登录界面存在默认勾选下载其他应用，不予通过；</span
        >
      </p>
      <p>
        <span>2）</span
        ><span
          >未经用户同意，下载安装与用户搜索内容不一致的应用，不予通过；</span
        >
      </p>
      <p>
        <span>3）</span
        ><span>未经用户同意，自动下载安装无关应用，不予通过；</span>
      </p>
      <p>
        <span>4）</span
        ><span
          >应用程序退出框，退出标识不明确，容易造成误点导致安装无关应用，不予通过；</span
        >
      </p>
      <p>
        <span>5）</span
        ><span
          >应用程序翻页或滑动时，未经用户同意自动安装无关应用，不予通过。</span
        >
      </p>
      <p><span> </span></p>
      <p class="content-big-title"><strong>五、应用广告和付费</strong></p>
      <p><strong>（一）应用广告</strong></p>
      <p>
        <span>1）</span
        ><span
          >禁止强制或误导用户点击广告内容，或者强制安装软件、捆绑下载；
        </span>
      </p>
      <p><span>2）</span><span>禁止应用带含有恶意的弹窗广告行为；</span></p>
      <p>
        <span>3）</span
        ><span
          >广告不得显示如影响用户的正常操作体验，或者与应用适用人群不符合的广告内容</span
        ><span style="color: rgb(44, 62, 80); font-family: 宋体;"
          >（诸如相亲交友、一元夺宝、金融理财等）</span
        ><span>； </span>
      </p>
      <p>
        <span>4）</span
        ><span>
          广告不得包含恶意链接，造成的危害包括但不限于界面被劫持、下载恶意应用、个人信息泄漏等；
        </span>
      </p>
      <p><span>5）</span><span>禁止出现积分墙广告行为；</span></p>
      <p>
        <span>6）</span
        ><span>禁止应用含有大量游戏分发、H5小游戏、应用下载内容；</span>
      </p>
      <p>
        <span>8）应用广告不得存在模仿系统通知或警告的行为。</span>
      </p>
      <p><strong> </strong></p>
      <p><span>（二）</span><strong>应用付费</strong></p>
      <p>
        <span>1) </span
        ><span
          >应用程序应明示需付费的服务事项，明示内容需保证真实准确、醒目规范；</span
        >
      </p>
      <p>
        <span>2) </span
        ><span
          >如果未明示用户付费后只能享受部分服务，在用户付费后，应用程序其他服务进一步提示付费，应用会被下架；</span
        >
      </p>
      <p>
        <span
          >3)
          应用应根据提供的服务合理定价，价格明显高于服务价值的，不予通过；</span
        >
      </p>
      <p>
        <span>4) </span
        ><span
          >应用内标明的价格需与用户实际支付的价格一致；付费信息需以醒目的方式提示用户并经用户二次确认后方可扣费，付费后获取的服务需与承诺一致；
        </span>
      </p>
      <p>
        <span
          >5)
          如有必要，步步高设备会在用户下载前通过弹窗等方式提醒用户谨慎付费；</span
        >
      </p>
      <p>
        <span>6) </span
        ><span
          >若因未明示应用付费相关事项导致用户投诉，开发者必须按相关政策退款并承担相应责任；
        </span>
      </p>
      <p>
        <span>7) </span
        ><span>不得存在自动扣费、恶意扣费、隐形扣费、诱导付费等行为； </span>
      </p>
      <p>
        <span>8) </span
        ><span
          >应用内如提供自动续费购买项，需为用户提供持续的价值，并需在商品购买或订阅支付界面醒目位置，明示自动续费服务协议（如“会员服务条款”、“自动续费服务条款”等）及解除自动续费服务的说明和流程，不得强制续费；
        </span>
      </p>
      <p>
        <span>9) </span
        ><span
          >不得以任何形式诱导未成年人充值消费（如以虚假免费试用、特惠活动等方式）。</span
        >
      </p>
      <p>
        <span> </span>
      </p>
      <p class="content-big-title"><strong>六、个人信息保护</strong></p>
      <p>
        <span
          >1)
          应用内个人信息保护需符合《数据安全法》《个人信息保护法》《App违法违规收集使用个人信息行为认定方法》《信息安全技术
          个人信息安全规范》等法律法规相关条款以及本应用商店平台<span
            class="goToBlank"
            @click="$goToBlank('register_convention')"
            >《应用商店平台开发者合规公约（个人信息保护）》</span
          >的要求；
        </span>
      </p>
      <p>
        <span
          >2)
          不得出现未向用户提供隐私政策、儿童隐私政策及用户反馈渠道情况；</span
        >
      </p>
      <p>
        <span
          >3）未提交及时更新版本的隐私政策、儿童隐私政策和获取设备权限列表的，不予通过；</span
        >
      </p>
      <p>
        <span
          >4)
          不得未经用户授权收集、处理用户个人信息，不得超出隐私政策声明或实现功能的必要范围收集、处理个人信息；</span
        >
      </p>
      <p>
        <span>5) 不得存在频繁征求用户同意、干扰用户正常使用的情况；</span>
      </p>
      <p>
        <span
          >6）如需申请和使用设备权限时，请在提交时说明权限使用场景及用途；
        </span>
      </p>
      <p>
        <span
          >7)
          隐私自检报告中应明示开发者名称（若为关联公司请提交关联证明）、应用程序名称、应用程序安装及运行所需权限及用途、应用程序收集使用个人信息的内容、目的、方式和范围；
        </span>
      </p>
      <p>
        <span
          >8)
          不得存在默认勾选同意隐私政策，或其他默认授权（如使用“好的”“我知道了”等无法清晰表达用户同意的词语）的方式征求用户同意；
        </span>
      </p>
      <p>
        <span> </span>
      </p>
      <p class="content-big-title"><strong>七、未成年保护</strong></p>
      <p><strong>（一）内容健康</strong></p>
      <p>
        <span
          >应用内不得含有侵害未成年人合法权益或者损害未成年人身心健康的内容，如：</span
        >
      </p>
      <p>
        <span
          >1）应用内不得含有人物造型过分夸张怪异，对未成年人有不良影响的内容；</span
        >
      </p>
      <p>
        <span
          >2）应用内不得含有会对未成年人造成干扰的内容，以及不利于未成年人身心健康和树立正向价值观的不良内容；</span
        >
      </p>
      <p>
        <span
          >3）应用不得包含对未成年人实施人身攻击、侮辱、诽谤、威胁或者恶意损害形象等网络欺凌内容，亦不得以鼓动、诱导或帮助等方式，间接实施或参与网络欺凌行为；</span
        >
      </p>
      <p>
        <span
          >4）应用内不得包含与未成年人相关的色情内容，包括但不限于恋童癖、针对未成年人的不当互动、儿童性虐待等；
        </span>
      </p>
      <p>
        <span
          >5）应用内不得展示未成年人或者未成年人形象的动画、动漫人物的性行为，或让人产生性妄想的；不得提供以未成年人为形象创作的色情小说、漫画内容，严禁引诱未成年人不良交友、煽动负面情绪；
        </span>
      </p>
      <p>
        <span
          >6）面向未成年人的应用不得包含不适合未成年人的暴力、血腥或惊悚内容，以及描绘或鼓励有害、危险的活动；
        </span>
      </p>
      <p>
        <span>7）尊重未成年人隐私权，严禁侵犯未成年人个人隐私；</span>
      </p>
      <p>
        <span
          >8）提供社交或类社交功能的，应当严厉查处对未成年人进行性引诱、教唆未成年人拍摄裸露视频图片、实施网络猥亵等行为。</span
        >
      </p>
      <p>
        <span
          >9）不得直接或允许应用使用者对散布虚假信息、虚构离奇故事和低俗剧情进行炒作引流、恶意营销等，对应用内涉及炫富拜金、奢靡享乐、扭曲审美等可能对未成年人造成不良影响的内容应当严格审查并禁止发布。</span
        >
      </p>
      <p>
        <span> </span>
      </p>
      <p>
        <span>（二）防沉迷</span>
      </p>
      <p>
        <span
          >网络游戏、网络直播、网络音视频、网络社交等网络服务提供者应当针对未成年人使用其服务设置相应的时间管理、权限管理、消费管理等功能，</span
        ><span
          >应用不得出现诱导未成年人沉迷、充值付费等情形，包括但不限于：</span
        >
      </p>
      <p>
        <span>1）应用不得诱导中小学生家长使用分期贷款缴纳校外培训费用；</span>
      </p>
      <p>
        <span
          >2）应用不得向未成年人宣传或鼓动未成年人使用/贩卖酒精饮料、烟草制品、电子烟产品或管制药品。不得向未成年人宣传负面身体形象或自我形象，如描绘整形手术、减肥以及对个人外表进行的其他美容调整；</span
        >
      </p>
      <p>
        <span
          >3）社交应用如涉及与陌生人聊天、提供约会交友服务、两性或婚姻建议等内容，不得以未成年人为目标用户，如在线配对聊天、开放聊天室、秀场直播等；</span
        >
      </p>
      <p>
        <span
          >4）儿童类应用不得含有应用外链接、付费项目或其他会对儿童造成干扰的内容，除非其保留在受家长监控的指定区域中，防止儿童在父母或监护人不知情的情况下进行商业交易，或者从应用中链接至外部网站、社交网络或其他应用；</span
        >
      </p>
      <p>
        <span
          >5）应用内面向未成年人投放广告的行为应符合相关法规和行业标准的要求，不得发布医疗、药品、保健食品、医疗器械、化妆品、酒类、美容、婚恋、孕育广告以及不利于未成年人身心健康的网络游戏广告。面向儿童的应用广告不得含有劝诱儿童要求家长购买广告商品或者服务以及可能引发儿童模仿不安全行为的内容。</span
        >
      </p>
      <p>
        <span>（三）其他</span>
      </p>
      <p>
        <span
          >1）网络游戏类应用，应确保已按照国家新闻出版署要求，接入网络游戏防沉迷实名认证系统，落实教育部等《关于进一步加强预防中小学生沉迷网络游戏管理工作的通知》要求。</span
        >
      </p>
      <p>
        <span
          >2）面向未成年人的在线教育类应用不得含有网络游戏链接，以及推送广告等与教学无关的信息。</span
        >
      </p>
      <p>
        <span
          >3）严禁利用“网红儿童”牟利，严禁通过未成年人做出不雅姿势、性暗示动作吸引流量，严禁一切诱导或可能诱导未成年人采取危险行为的功能或内容；
        </span>
      </p>
      <p>
        <span
          >4）不得允许未满16周岁未成年人提供或变相提供网络主播服务，不得在应用中针对任何内容提供打赏或变相打赏的功能。
        </span>
      </p>
      <p>
        <span
          >5）不得提供网络游戏或网络游戏账号租售服务，不得以任何方式发布破解防沉迷系统的信息内容。
        </span>
      </p>
      <p>
        <span>（四）意见反馈</span>
      </p>
      <p>
        <span
          >应用内应当建立便捷、合理、有效的投诉和举报渠道，公开投诉、举报方式等信息，及时受理并处理涉及未成年人的投诉、举报。
        </span>
      </p>
      <p><span> </span></p>
      <p class="content-big-title"><strong>八、禁止欺诈行为</strong></p>
      <p>
        <span
          >1)
          如果采取后台控制的方式，先关闭特定违规模块，应用通过审核后再上线违规模块，存在欺骗上架的情况，开发者帐号将会被冻结；
        </span>
      </p>
      <p>
        <span
          >2)
          禁止采用伪造或欺骗下载以及其他任何不适当的方式操纵或者欺骗用户评论等影响商店的排名；
        </span>
      </p>
      <p>
        <span
          >3)
          禁止欺骗、伪造或者误导用户的行为，如通过刷量或其他不正当方式来提高应用排名。
        </span>
      </p>
      <p>
        <span> </span>
      </p>
      <p class="content-big-title">
        <strong>九、知识产权及其他合法权益保护</strong>
      </p>
      <p>
        <span>1）</span
        ><span
          >请确保应用及其内容具备完整权利或充分授权，不得侵犯他人知识产权（包括商标、版权、专利、商业秘密和其他专有权利）或其他合法权益</span
        ><span style="color: rgb(44, 62, 80); font-family: 宋体;"
          >，亦不得以鼓动、诱导或帮助等方式，间接实施或参与侵权行为</span
        ><span>；</span>
      </p>
      <p>
        <span>2）</span
        ><span
          >在不构成法定正当使用的情况下，未经授权不得使用他人相同或近似商标，将有可能构成商标侵权或不正当竞争；</span
        >
      </p>
      <p>
        <span>3）</span
        ><span
          >未经授权，不得使用步步高及其关联公司的商业名称、商标和其他标识。不得虚假或引人误解地宣传与步步高及其关联公司的关系，如暗示步步高及其关联公司是应用的提供商或合作商，或者以任何形式表示步步高及其关联公司认可或担保其质量或功能；</span
        >
      </p>
      <p>
        <span>4）</span
        ><span
          >不得实施混淆行为，误导用户认为应用及其内容来源于他人或与他人存在特定联系（如在任何语言下均不得使用与其他开发者应用相同</span
        ><span>/相似的外观、名称、主题等）</span><span>；</span>
      </p>
      <p><span>5）</span><span>应用不得销售或推广仿冒商品。</span></p>
      <p><strong>附：不予收录应用类型</strong></p>
      <ul class="ulBox">
        <li>
          <span
            >彩票类：非官方彩票或非法互联网体育彩票和福利彩票产品，包括但不限于彩票资讯类等产品
          </span>
        </li>
        <li>
          <span>非法VPN、翻墙、境外媒体等产品</span>
        </li>
        <li>
          <span>单一影视剧集类及图书单行本类</span>
        </li>
        <li>
          <span
            >投资理财类：非法集资类、非法众筹类、非法竞拍类，包括但不限于一元夺宝、股权众筹等
          </span>
        </li>
        <li>
          <span>应用功能为刷单、刷评、刷粉、清粉类</span>
        </li>
        <li>
          <span
            >自动抢红包类/赌博性质红包类/避雷、埋雷、控数抢红包、红包群类</span
          >
        </li>
        <li>
          <span>主功能为网赚类兼职</span>
        </li>
        <li>
          <span
            >单一的非官方游戏攻略类、非官方游戏社区类以及非官方电竞资讯类
          </span>
        </li>
        <li>
          <span>主功能为游戏代练类</span>
        </li>
        <li>
          <span>主题类、单一壁纸类、单一锁屏类</span>
        </li>
        <li>
          <span
            >主要目的为分发类（包含但不限于：应用商店、游戏中心）/主功能为H5游戏中心类（社交小游戏类除外）/破解游戏平台类/作弊器类
          </span>
        </li>
        <li>
          <span>独立插件类（硬件插件、辅助插件除外）</span>
        </li>
        <li>
          <span>色情内容类、血腥暴力类</span>
        </li>
        <li>
          <span>版权侵权类、应用侵权类</span>
        </li>
        <li>
          <span>赌博类、博彩类或是地下彩类</span>
        </li>
        <li>
          <span>支持非法金钱交易的应用</span>
        </li>
        <li>
          <span>支持手游充值的应用</span>
        </li>
        <li>
          <span>申请root权限、存在root功能、引导对终端设备进行root的</span>
        </li>
        <li>
          <span>积分墙相关应用（通过下载应用换取积分奖品或现金等）</span>
        </li>
        <li>
          <span
            >主要功能是诱导用户进行签到打卡或分享至社交媒体赚取收益的应用
          </span>
        </li>
        <li>
          <span>虚拟货币相关资源</span>
        </li>
        <li>
          <span>云养殖、云种植类（如云养牛）</span>
        </li>
        <li>
          <span>P2P类产品</span>
        </li>
        <li>
          <span>PUA类应用</span>
        </li>
        <li>
          <span>儿童小游戏内涉及“儿童邪典片”及手术类型</span>
        </li>
        <li>
          <span>种子、BT磁力类应用</span>
        </li>
        <li>
          <span>提供占卜、算命、改名改运、消灾避祸等迷信服务的应用</span>
        </li>
        <li>
          <span>带有偷拍功能的应用</span>
        </li>
        <li>
          <span>“深度伪造”类换脸应用</span>
        </li>
        <li>
          <span>分身、双开、多开类应用</span>
        </li>
        <li>
          <span>动态IP代理相关服务类应用</span>
        </li>
        <li>
          <span>支持虚拟定位类应用</span>
        </li>
        <li>
          <span>数字藏品涉及交易服务类应用</span>
        </li>
        <li>
          <span>免费WiFi类、清理类应用</span>
        </li>
        <li>
          <span>婚恋类应用</span>
        </li>
        <li>
          <span>主功能为广告推广类应用</span>
        </li>
        <li>
          <span>简单调用系统功能的应用</span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
strong {
  font-weight: 600;
}
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  p {
    line-height: 180%;
    color: #333333;
  }
  .ulBox {
    padding-left: 30px;
    li {
      list-style-type: disc;
      line-height: 160%;
    }
  }
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 18px;
    color: #000;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-medium-title {
    // margin-top: 10px;
    font-size: 16px;
    line-height: 34px;

    span {
      color: #333333;
    }
    // margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    // margin-top: 12px;
    // font-size: 14px;

    line-height: 30px;
    text-indent: 2em;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 14px;
      color: rgb(84, 80, 80);
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
