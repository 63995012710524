<template>
  <div class="container">
    <h2>违规应用举报流程</h2>
    <div>
      <p>尊敬的用户：感谢您使用步步高家教机应用商店下载安装应用程序。</p>
      <p>
        如果您在体验应用时，发现应用涉及黄赌毒等不文明信息，广告欺诈，恶意吸费、需用户填写银行帐号数据等获取用户敏感信息以及其他所有违反现有法律规定及公序良俗的现象，均可按照以下模版发送邮件至<span
          style="font-weight:bold;color: #000;"
          >weichaonan@eebbk.com</span
        >进行反馈。
      </p>
    </div>
    <h3>1. 邮件模板：</h3>
    <div>
      <p>邮件标题：应用违规投诉</p>
      <p>邮件内容：</p>
      <ol style="margin-left: 2em">
        <li>违规应用名称</li>
        <li>应用链接地址（或违规应用在步步高家教机商店的应用详情页截图）</li>
        <li>投诉原因</li>
        <li>应用违规事实相关截图或视频</li>
        <li>其他您认为需要说明的事项</li>
        <li>联系方式（以便步步高家教机在您的帮助配合下依法处理）</li>
      </ol>
      <p>若您选择邮寄纸质材料的，请将以上材料邮寄发送至以下地点：</p>
      <div>
        <p>地址：广东省东莞市长安镇步步高东门中路168号小天才研发中心 法务部</p>
        <p>邮编：523851</p>
      </div>
      <p>
        另外，您亦可以通过应用商店相关应用的下载页面中的“举报”按钮一键举报。
      </p>
    </div>
    <h3>
      2.
      步步高家教机承诺您的投诉为匿名投诉，不予公开您的个人信息（涉及权益侵害等特殊情形且有必要公开您的个人信息时除外）。
    </h3>
    <h3>
      3. 您需要保证投诉内容及事实的真实性，否则，您可能要承担虚假举报的侵权、违法风险。
    </h3>
    <h3>4. 本指引所提供的渠道不受理应用违规举报外的其它问题。</h3>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  padding-bottom: 40px;
  color: #666;
  font-size: 14px;
  line-height: 30px;
  h2,
  h3,
  h4 {
    color: #333333;
    line-height: 40px;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 100;
  }
  p {
    color: #666;
    font-size: 14px;
  }
  p + div {
    padding-left: 2em;
  }
  p + div {
    & > p {
      text-indent: 2em;
    }
  }
  h3 + p,
  h4 + p {
    text-indent: 2em;
  }
  h3 + div,
  h4 + div {
    padding-left: 2em;
  }
  h4 {
    margin: 5px 0;
    text-indent: 1em;
    font-weight: 100;
    font-size: 16px;
  }
  h2 + div,
  h3 + div,
  h4 + div {
    & > p {
      text-indent: 2em;
    }
  }
}
</style>
