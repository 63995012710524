<template>
  <div class="ff-container">
    <div class="ff-header">
      <img src="@/assets/img/vertical-line.png" class="ff-header-line" />
      <span class="ff-header-title">备案信息</span>
    </div>
    <el-form
      ref="filingValidateForm"
      :model="form"
      :label-width="labelWidth"
      :label-position="labelPosition"
      class="ff-form"
    >
      <el-form-item label="备案状态:" prop="filingStatus" required>
        <el-radio-group v-model="form.filingStatus">
          <el-radio :label="1">应用已备案</el-radio>
          <el-radio :label="0">应用未备案</el-radio>
          <el-radio :label="-1">无需备案（单机应用）</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="form.filingStatus === 1"
        label="备案主体:"
        prop="filingCompanyType"
        required
      >
        <el-radio-group v-model="form.filingCompanyType" @input="handleRefresh">
          <el-radio :label="0">与开发者账号主体一致</el-radio>
          <el-radio :label="1">与开发者账号主体不一致</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="form.filingStatus === 0" label-width="10px">
        <div class="ff-alert">
          <div class="ff-alert-header">
            <i class="el-icon-warning-outline"></i>
            <span>请尽快完成您的应用备案</span>
          </div>
          <div class="ff-alert-content">
            <div style="margin-bottom: 10px">
              我们将针对未完成备案的场景将采取包括但不限于如下的限制手段：
            </div>
            <div>
              <span class="ff-highlight">2023年12月12日起</span
              >，将限制未备案的库内应用发起 「版本更新」；
            </div>
            <div>
              <span class="ff-highlight">2024年1月4日起</span
              >，将逐步开始清理在架未备案的「存量应用」。
            </div>
          </div>
        </div>
      </el-form-item>
      <template v-if="form.filingStatus === -1">
        <el-form-item label-width="10px">
          <div class="ff-alert">
            <div class="ff-alert-header">
              <i class="el-icon-warning-outline"></i>
              <span>关于单机应用</span>
            </div>
            <div class="ff-alert-content">
              <div style="margin-bottom: 10px">
                单机应用是指在没有联网的情况下，用户可以在本地设备上使用的应用。
                <br />这些应用通常不需要与服务器或其他设备进行通信；
              </div>
              <div>
                请确认您的应用符合上述要求，我们将会在人工审核中再次核验
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="免责承诺函:"
          prop="disclaimerLetterUrl"
          :rules="{
            required: true,
            message: '请上传免责承诺函',
            trigger: 'blur'
          }"
        >
          <image-upload
            :url="form.disclaimerLetterUrl"
            :download-url="downloadFiles[1]"
            @getImage="getImage($event, 'disclaimerLetterUrl')"
          />
        </el-form-item>
      </template>
      <div v-if="showMainInfo" style="width: 70%">
        <el-form-item
          label="备案主体名称:"
          prop="filingCompanyName"
          :rules="{
            required: true,
            message: '请输入备案主体名称',
            trigger: 'blur'
          }"
        >
          <el-input
            v-model="form.filingCompanyName"
            placeholder="请填写当前应用备案主体完整名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="备案主体统一社会信用代码:"
          prop="filingCompanyBusinessLicense"
          :rules="{
            required: true,
            message: '请输入备案主体统一社会信用代码',
            trigger: 'blur'
          }"
        >
          <el-input
            v-model="form.filingCompanyBusinessLicense"
            placeholder="请填写当前应用备案主体营业执照的统一社会信用代码"
            @blur="handleRefresh($event, form.filingCompanyBusinessLicense)"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="备案主体营业执照附件:"
          prop="filingCompanyBusinessLicenseUrl"
          :rules="{
            required: true,
            message: '请上传备案主体营业执照',
            trigger: 'blur'
          }"
        >
          <image-upload
            placeholder="请上传当前应用备案主体清晰完整的营业执照附件"
            template-name="示例"
            :url="form.filingCompanyBusinessLicenseUrl"
            :show-download="false"
            @getImage="getImage($event, 'filingCompanyBusinessLicenseUrl')"
          />
        </el-form-item>
        <el-form-item
          label="备案说明:"
          prop="cooperationAgreementUrl"
          :rules="{
            required: true,
            message: '请上传备案说明',
            trigger: 'blur'
          }"
        >
          <image-upload
            placeholder="请上传当前应用备案主体与开发者账号主体的备案说明"
            template-name="备案说明协议模板"
            :url="form.cooperationAgreementUrl"
            :download-url="downloadFiles[0]"
            @getImage="getImage($event, 'cooperationAgreementUrl')"
          />
        </el-form-item>
      </div>
      <el-form-item v-show="form.filingStatus === 1" :label-width="labelWidth2">
        <el-card class="ff-info" shadow="never">
          <div slot="header" class="ff-info-header">
            <span>备案信息展示</span>
            <!-- <el-button type="text">手动录入</el-button> -->
          </div>
          <div v-loading="loading" class="ff-info-content">
            <div>
              信息来源：自动查询 |
              <el-button type="text" @click="handleRefresh"
                >刷新查询结果</el-button
              >
            </div>
            <div>
              备案状态：<span :class="textColor">{{
                status === 1 ? '已备案' : '未备案'
              }}</span>
            </div>
            <div>APP名称：{{ apkName }}</div>
            <div>主体备案号：{{ form.filingCompanyCode }}</div>
            <div>服务备案号：{{ form.filingApkCode }}</div>
          </div>
        </el-card>
        <!-- <div class="ff-info-footer">
          若您应用已备案但无查询结果，可尝试<span class="ff-highlight"
            >手动录入备案信息</span
          >，我们将会在审核环节进行人工核验
        </div> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ImageUpload from './ImageUpload';
import { debounce } from '../../plugins/util';
export default {
  name: 'FilingForm',
  components: { ImageUpload },
  data() {
    return {
      loading: false,
      status: 0,
      apkName: '',
      form: {
        filingStatus: 0, // 备案状态（-1单机无需备案，0-未备案，1-已备案）
        filingCompanyType: 0, // 备案主体（0-与开发者账号主体一致、1-与开发者账号主体不一致）
        filingCompanyName: '', // 备案主体名称
        filingCompanyBusinessLicense: '', // 统一社会信用代码
        filingCompanyBusinessLicenseUrl: '', // 营业执照附件url
        cooperationAgreementUrl: '', // 合作协议url
        disclaimerLetterUrl: '', // 免责承诺函url
        filingCompanyCode: '', // 主体备案号
        filingApkCode: '', // 服务/应用备案号
        apkPackageName: '' // 应用包名
      },
      debounce,
      downloadFiles: [
        'https://server-market-dns.eebbk.net/marketDeveloper/2024/03/08/160821072_7591d0d6c83bbbe6.docx', // 备案说明协议模板
        'https://server-market-dns.eebbk.net/marketDeveloper/2024/03/11/090952521_55bc2b064600d213.docx' // 承诺函模板
      ]
    };
  },
  computed: {
    textColor() {
      return this.status === 1 ? 'ff-color-success' : 'ff-highlight';
    },
    // 备案主体信息
    showMainInfo() {
      return this.form.filingStatus === 1 && this.form.filingCompanyType === 1;
    },
    labelWidth() {
      let width = '120px';
      if (this.form.filingStatus === 1 && this.form.filingCompanyType === 1) {
        width = '200px';
      } else {
        width = '120px';
      }
      return width;
    },
    // 表单对齐方式
    labelPosition() {
      let position = 'left';
      if (this.form.filingStatus === 1 && this.form.filingCompanyType === 1) {
        position = 'right';
      } else {
        position = 'left';
      }
      return position;
    },
    labelWidth2() {
      return this.form.filingCompanyType === 1 ? '200px' : '10px';
    }
  },
  methods: {
    // 编辑时回显数据
    initData(data, apkName) {
      this.form = data;
      this.apkName = apkName;
      this.status = data.filingStatus;
      this.defaultData();
    },
    defaultData() {
      if (!this.form.filingStatus) this.form.filingStatus = 0;
      if (!this.form.filingCompanyType) this.form.filingCompanyType = 0;
    },
    // 查询备案信息
    getFilingInfo(apkPackageName, apkName) {
      this.form.apkPackageName = apkPackageName;
      this.apkName = apkName;
      const params = {
        businessLicense: this.$store.state.businessLicense,
        apkPackageName
      };
      this.$axios
        .get(`appManage/searchFilingInfo`, { params })
        .then(res => {
          if (res.data.stateCode == 0) {
            const data = res.data.data || {};
            this.status = data.filingStatus;
            this.form = { ...this.form, ...data };
            this.defaultData();
          } else {
            this.$message.error(res.data.data);
          }
        })
        .catch(err => {
          this.$message.error(err.data.stateInfo);
        });
    },
    // 刷新查询结果
    handleRefresh(event, code) {
      this.loading = true;
      const params = {
        businessLicense: this.$store.state.businessLicense,
        apkPackageName: this.form.apkPackageName
      };
      if (code) {
        params.businessLicense = code;
      } else if (this.form.filingCompanyType === 0) {
        params.businessLicense = this.$store.state.businessLicense;
      } else {
        params.businessLicense = this.form.filingCompanyBusinessLicense
          ? this.form.filingCompanyBusinessLicense
          : '';
      }
      this.$axios
        .get(`appManage/searchFilingInfo`, { params })
        .then(res => {
          if (res.data.stateCode == 0) {
            const { filingStatus, filingCompanyCode, filingApkCode } =
              res.data.data || {};
            this.status = filingStatus;
            this.form.filingCompanyCode = filingCompanyCode;
            this.form.filingApkCode = filingApkCode;
          } else {
            this.$message.error(res.data.data);
          }
        })
        .catch(err => {
          this.$message.error(err.data.stateInfo);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 提交 表单验证
    submitForm() {
      let validate = false;
      this.$refs['filingValidateForm'].validate(valid => {
        if (valid) {
          validate = true;
          return true;
        } else {
          validate = false;
          return false;
        }
      });
      if (validate) {
        return this.form;
      } else {
        return false;
      }
    },
    // 获取上传图片url
    getImage(url, prop) {
      this.form[prop] = url;
      this.$refs['filingValidateForm'].validateField(prop);
    }
  }
};
</script>

<style lang="less" scoped>
.ff-container {
  width: 100%;
  padding-top: 28px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 40px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
}

.ff-highlight {
  color: #ff8500;
}

.ff-color-success {
  color: #008000;
}

.ff-header {
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 14px;

  &-line {
    margin-bottom: -2px;
  }
  &-title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-left: 6px;
  }
}

.ff-form {
  margin-top: 26px;
}

.ff-info {
  width: 45%;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
    }
  }

  &-footer {
    font-size: 12px;
    color: #bfbfbf;

    span {
      color: #409eff;
    }
  }
}
.ff-info ::v-deep .el-card__header {
  padding: 0 20px;
}

.ff-alert {
  width: 40%;
  padding: 10px 20px;
  background-color: #ffdfca;
  border: 1px solid #fe8c41;
  border-radius: 4px;
  line-height: 24px;

  &-header {
    font-weight: bold;

    i {
      color: #ff8500;
      margin-right: 4px;
    }
  }

  &-content {
    color: #594e47;
  }
}
</style>
