<template>
  <section class="container">
    <div class="content-right">
      <div class="content-title">应用商店平台开发者合规公约（总则）</div>
      <div class="content">
        <p><strong>【更新日期】：2023年7月10日</strong></p>
        <p><strong>【发布日期】：2023年7月14日</strong></p>
        <p><strong> </strong></p>
        <p class="content-big-title"><strong>一、目的</strong></p>
        <p>
          <span
            >鉴于广东小天才科技有限公司/步步高教育电子有限公司及其关联公司（下称</span
          ><span>“本公司”</span
          ><span
            >）产品的特殊性，开发者通过本应用商店平台（下称“平台”）上传的应用程序的目标受众群体包括未成年人，或其产品是专为未成年人设计的。为了给未成年人等用户提供一个和谐、安全、健康、清朗的网络环境、确保应用所呈现的内容均适合未成年人、保护未成年人身心健康，为了更好地保障最终用户的个人信息权益以及帮助开发者在开发、运营</span
          ><span>应用程序的过程中充分履行个人信息保护义务，</span
          ><span
            >为了加强对具有舆论属性或社会动员能力的互联网信息服务和相关新技术新应用的安全管理以及帮助开发者能更好地开展具有舆论属性或社会动员能力应用程序安全评估工作，围绕本平台开发者上传和运营应用程序的网络信息内容、个人信息保护、未成年人保护、安全评估等合规事项，根据相关法律、行政法规、部门规章等规范，制定本公约，以供开发者遵照执行。</span
          >
        </p>
        <p><span> </span></p>
        <p class="content-big-title"><strong>二、适用</strong></p>
        <p>
          <span>1.本公约适用于所有在本平台上发布应用程序的开发者。</span>
        </p>
        <p>
          <span
            >2.一经开发者同意接受即形成开发者与本平台之间有法律约束力之文件。此后，开发者不得以未阅读本公约内容作任何形式的抗辩。开发者在应用商店注册或上传开发者的应用程序将受本公约之约束。开发者注册时点击本公约下方的“我同意”按键（或类似按键）或使用本平台向开发者提供的产品和服务即视为开发者对本公约条款的充分理解和完全接受。如果开发者不同意本公约条款，不得使用或停止使用本平台的产品和服务。</span
          >
        </p>
        <p>
          <span
            >如开发者不同意本公约任意内容的，开发者将不得在本应用商店平台发布任何应用程序，同时本应用商店平台有权采取下架应用程序、冻结应用程序等处置措施。</span
          >
        </p>
        <p><span> </span></p>
        <p class="content-big-title"><strong>三、承诺与保证</strong></p>
        <p><span>1. </span><u>开发者承诺并保证：</u></p>
        <p>
          <span
            >（1）一旦开发者接受本公约，开发者保证开发者已达法定年龄且具有完全之行为能力。如果开发者为一个公司、组织或其他法律实体的，需保证其公司或组织是根据中国境内法律所成立且持续有效的法律实体，并保证已合法获得授权在应用商店发布应用程序。</span
          >
        </p>
        <p>
          <span
            >（2）如果开发者的应用程序包括任何开源软件，开发者保证遵守该开源软件所有适用的授权条款，且开发者不侵犯任何他人知识产权等合法权益。</span
          >
        </p>
        <p><span>2. </span><u>开发者承诺并保证：</u></p>
        <p>
          <span>（1）</span
          ><u
            >因开发者违反本公约或因开发者的应用程序内的用户因网络信息内容安全、开发者侵害用户个人信息、开发者未尽未成年人保护法律义务等原因，本应用商店平台有权采取要求开发者提供说明和应对方案、要求开展排查、整改、对被侵害的用户采取赔礼道歉、赔偿损失等救济措施，并对开发者采取下架应用程序、解除合作、要求赔偿等措施。开发者违反本公约导致的纠纷由开发者自行负责，因此导致本平台运营者及/或其关联公司遭受的损失由开发者承担赔偿责任及消除影响义务。</u
          >
        </p>
        <p>
          <span>（2）</span
          ><u
            >经本应用商店平台自行通过人工及技术审核发现，或经用户或第三方的投诉发现，系开发者原因或开发者提供的应用程序服务过程中出现的网络信息内容安全、侵害用户个人信息保护、未充分履行未成年人保护等问题，本平台有权依据<span
              class="goToBlank"
              @click="$goToBlank('check_rule')"
              >《应用审核规范》</span
            ><span class="goToBlank" @click="$goToBlank('check_measure')"
              >《开发者帐号与应用处置原因及措施》</span
            ></u
          ><span>《应用违规处理规定》</span
          ><u>等规范对开发者账号和应用采取相应处置措施。</u>
        </p>

        <p class="content-big-title"><strong>四、网络信息内容安全</strong></p>
        <p>
          <span
            >本公司十分重视网络信息内容生态治理，为依法落带实网络信息安全主体责任和共同营造和谐、安全、健康、清朗的网络环境，本公司特意依法制定并公开关于网络信息内容生态治理的公约，即<span
              class="goToBlank"
              @click="$goToBlank('register_threeConvention')"
              >《步步高应用商店第三方应用程序提供方内容守则公约》</span
            >
            （下称“《内容安全公约》”）。</span
          >
        </p>
        <p style="font-style:italic;font-weight: 600;">
          <em
            >该《内容安全公约》为《步步高开发者平台开发者服务协议》（下称“开发者服务协议”）以及本公约不可分割的一部分，其将明确确开发者通过本应用商店平台上传的应用程序在提供相关产品及服务过程网络信息内容生态治理的具体规定，包括但不限于违法和不良信息的有效管理及防控义务、内容安全投诉、举报响应的规则等，是开发者使用本公司平台或者其他产品、服务过程，必须事先阅读的重要文件之一。</em
          >
        </p>
        <p>
          <span
            >开发者承诺在本应用商店平台上传应用程序并提供相关产品及服务等行为遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等底线和准则，确保所提供的产品或服务过程不得出现违法和不良信息，以及其他不利于未成年人安全和身心健康的信息，以及遵守并履行《内容安全公约》以及本公约等其他适用的规定或约定的义务，同时确保具备及时防控和处置违法和不良信息以及其他不利于未成年人安全和身心健康的信息的有效机制，包括但不限于：</span
          >
        </p>
        <p>
          1、<span
            >开发者作品符合网络信息内容安全法律要求，不得制作、复制、发布含有《网络信息内容生态治理规定》第六条的违法信息、以及应当采取措施防范和抵制制作、复制、发布该规定第七条规定的不良信息以及不利于未成年人安全和身心健康以及其他《内容安全公约》明确的信息，与此同时不得为制作、复制、发布、传播含有前述违法、不良或者不利于未成年人身心健康的内容信息或从事相关行为提供便利。</span
          >
        </p>
        <p>
          <span
            >开发者应当采取人工及技术相结合的管理及技术措施有效管理其应用程序作品的内容安全以及用户生成的内容安全，有效防控其产品及服务内部以及用户使用其产品或服务过程产生的违法、不良及其他不利于未成年人安全和身心健康的信息。</span
          >
        </p>
        <p>
          2、<span
            >开发者应当依法建立内容违法违规投诉及反馈机制，确保有效处置违法、不良信息的防扩散等事项以及用户的诉求。</span
          >
        </p>
        <p>
          <span
            >3、本公司有权依法对于开发者应用程序、作品等在上线前和上线后，进行相应的内容信息安全审核管理，同时有权依据《内容安全公约》进行处置，包括视情节严重程度针对违反《内容安全公约》的开发者采取包括不经告知同意的情况下，对相应开发者提供的作品、应用程序服务等立即采取停止展示、传播、转发、下架作品/应用程序、停止合作等处理措施，涉及违法犯罪的，同时有权移交给相关机关追究法律责任。</span
          >
        </p>
        <p>
          <span>
            开发者对本公司的内容安全管理事项或处置等有异议的，可依据《开发者协议》等本公司对外发布的联系渠道（邮箱：weichaonan@eebbk.com）与本公司联系，本公司将依据有关文件进行处置。</span
          >
        </p>

        <p class="content-big-title"><strong>五、个人信息保护</strong></p>
        <p>
          <span
            >本平台特别重视终端用户的个人信息保护，对开发者处理个人信息有特别要求。开发者应当遵守本平台已经发布的
            《应用商店平台开发者合规公约（个人信息保护）》之规定。</span
          >
        </p>
        <p class="content-big-title"><strong>六、未成年人特别保护</strong></p>
        <p>
          <span
            >本平台特别重视对未成年人用户的保护，开发者应当遵守本平台已经发布的《应用商店平台开发者合规公约（未成年人特别保护）》之规定。</span
          >
        </p>

        <p class="content-big-title">
          <strong>七、具有舆论属性或社会动员能力应用程序安全评估 </strong>
        </p>
        <p>
          本平台一直重视对具有舆论属性或社会动员能力的互联网信息服务和相关新技术新应用的安全管理，开发者应当遵守本平台已经发布的《应用商店平台开发者合规公约（具有舆论属性或社会动员能力应用程序安全评估）》之规定。
        </p>

        <p class="content-big-title"><strong>八、其他</strong></p>
        <p>
          <span>1. </span
          ><span
            >本公约为动态文档，本应用商店平台有权依照互联网发展的不同阶段，结合相关法律法规更新、产品运营需求和平台管理经验的不断丰富，出于维护用户合法权益和本应用商店平台合法权益的目的，不断完善本规定。开发者应及时和反复查看以便获得最新信息。</span
          >
        </p>
        <p>
          <span>2. </span
          ><span
            >关于本应用商店平台的服务，本应用商店平台不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。开发者对本应用商店平台服务的使用行为必须自行承担相应风险。</span
          >
        </p>
        <p>
          <strong>3. </strong
          ><u style="font-weight: 600;"
            ><strong
              >本公约属于本应用商店平台《开发者服务协议》的一部分，与其具有同等法律效力，与《开发者服务协议》不一致的内容，以本公约为准。</strong
            ></u
          >
        </p>
        <p>
          <strong>4. </strong
          ><span
            >本公约自发布之日起生效。<u style="font-weight: 600;"
              ><strong
                >更新后的公约与旧版本公约不一致的内容，以最近更新的公约版本规定为准。</strong
              ></u
            ></span
          >
        </p>
        <p>
          <strong>5. </strong
          ><u style="font-weight: 600;"
            ><strong
              >【特别提醒】开发者除了应当遵守本公约内容外，还应当遵守本平台的<span
                class="goToBlank"
                @click="$goToBlank('check_rule')"
                >《应用审核规范》</span
              ><span class="goToBlank" @click="$goToBlank('check_quality')"
                >《应用资质审核规范》</span
              ><span class="goToBlank" @click="$goToBlank('check_measure')"
                >《开发者帐号与应用处置原因及措施》</span
              >之规定。本公约与<span
                class="goToBlank"
                @click="$goToBlank('check_rule')"
                >《应用审核规范》</span
              >不一致的内容或本公约未作规定的内容，开发者以<span
                class="goToBlank"
                @click="$goToBlank('check_rule')"
                >《应用审核规范》</span
              >的规定为准。
            </strong></u
          >
        </p>
        <p style="text-align: left;">
          <u><strong> </strong></u>
        </p>
        <p class="content-title">
          <strong>应用商店平台开发者合规公约（个人信息保护）</strong>
        </p>
        <h2><strong> </strong></h2>
        <p class="content-big-title">
          一、开发者处理个人信息合规要求
        </p>
        <p class="content-big-title">
          （一）收集和使用个人信息合规
        </p>
        <p>
          <span>1. 遵循合法、正当、必要原则</span>
        </p>
        <p>
          <span
            >(1)
            应用收集和使用个人信息不得违反法律、行政法规、部门规章等规范性文件；</span
          >
        </p>
        <p>
          <span
            >(2)
            应用不得暗中收集和使用个人信息，用户授权同意隐私政策前，应用程序、SDK不应收集用户的个人信息；</span
          >
        </p>
        <p>
          <span
            >(3)
            应用收集和使用个人信息须遵守数据最小化原则，采取对用户权益影响最小的方式，限于实现处理目的的最小范围，应用不得超出服务所必需的应用场景收集和使用个人信息；</span
          >
        </p>
        <p>
          <span>2. 隐私政策合规</span>
        </p>
        <p>
          <span>(1) 应用应当有独立的隐私政策； </span>
        </p>
        <p>
          <span>(2) 隐私政策应当包括以下内容： </span>
        </p>
        <p>
          <span
            >①
            隐私政策应向用户清晰明示应用（包括委托的第三方或嵌入的第三方代码、插件）收集和使用个人信息应明示收集目的、方式、范围等；
          </span>
        </p>
        <p>
          <span
            >②
            涉及敏感个人信息的应进行显著标识（如字体加粗、标星号、下划线、斜体、高亮等）。除向用户明示收集、使用的目的、方式、范围之外，还应当向用户告知处理敏感个人信息的必要性以及对个人权益的影响;
          </span>
        </p>
        <p>
          <span
            >③
            隐私政策应向用户清晰明示应用信息、开发者信息、开发者联系方式，并与开发者注册时信息保持一致，且真实有效；
          </span>
        </p>
        <p>
          <span>④ 隐私政策应当告知用户行使个人信息权利的方式和程序；</span>
        </p>
        <p>
          <span
            >⑤
            隐私政策应当便于查阅和保存，如用户进入应用主界面后不多于4次点击等操作即可访问；</span
          >
        </p>
        <p>
          <span
            >⑥
            隐私政策的文本应易于阅读，不存在如文字过小过密、颜色过淡、模糊不清，或未提供简体中文版等。</span
          >
        </p>
        <p>
          <span>3. 告知-同意合规</span>
        </p>
        <p>
          <span
            >(1)
            应用首次运行应当有隐私政策弹窗，弹窗内容应当明确提示用户阅读隐私政策等收集使用规则；
          </span>
        </p>
        <p>
          <span
            >(2)
            应用在征求用户同意隐私政策的环节，应提供明确的同意和拒绝选项，不可使用“好的”、“我知道了”等无法清晰表达用户意愿的词语；或者向用户提供主动勾选的方式时，不应设置为默认勾选/同意完成；
          </span>
        </p>
        <p>
          <span
            >(3) 敏感个人信息需采取单独弹窗等明显方式取得用户的单独同意；</span
          >
        </p>
        <p>
          <span
            >(4)
            应用收集使用个人信息的目的、方式、范围发生变化时，应以适当方式通知用户，包括更新隐私政策等收集使用规则并通过弹窗等方式提醒用户阅读；
          </span>
        </p>
        <p>
          <span
            >(5)
            应用内应支持用户的授权同意可撤回，并提供便捷的撤回操作流程（建议在进入应用主界面后4步之内完成）。</span
          >
        </p>
        <p>
          <span>4. 收集和使用个人信息环节禁止行为</span>
        </p>
        <p>
          <span
            >(1)
            应用不得将“通话记录”、“短信”、“生物特征”、“健康数据”、“行踪轨迹”等敏感个人信息用于服务改进、投放广告或营销等非业务核心功能；</span
          >
        </p>
        <p>
          <span
            >(2)
            应用使用第三方支付交易过程中，如非适用法律要求或为提供第三方支付服务所必需，不得记录用户交易类鉴权信息，或向第三方批露与用户特定交易无关的用户个人信息。</span
          >
        </p>
        <p class="content-big-title">
          （二）获取设备权限合规
        </p>
        <p>
          <span
            >1.
            应用申请的权限，都必须有明确、合理的使用场景和功能说明，禁止诱导或误导用户授权，应用使用的权限必须与申请所述的一致；</span
          >
        </p>
        <p>
          <span>2. 应用不得仅出于广告投放或数据分析的目的而请求位置权限；</span>
        </p>
        <p>
          <span>3. </span
          ><span
            >在调用设备权限前，应当如实告知用户应用程序所获取的设备权限，并通过弹窗方式获取用户的单独同意；</span
          >
        </p>
        <p>
          <span>4. </span
          ><span
            >应用程序运行中向用户索取电话、通讯录、定位、短信、录音、相机、存储、日历等权限，用户拒绝后，重新运行时，不得向用户频繁弹窗申请开启权限，影响用户正常使用；
          </span>
        </p>
        <p>
          <span>5. </span
          ><span
            >应用程序首次启动或注册、登录服务时，向用户索取电话、通讯录、定位、短信、录音、相机、存储、日历等权限，用户拒绝后，应用不得退出、关闭或无法正常使用；
          </span>
        </p>
        <p>
          <span>6. </span
          ><span
            >应用申请和使用权限须遵守权限最小化原则，不得以用户拒绝权限或者撤回同意为由，拒绝提供产品或者服务。无论用户拒绝任何权限，应用程序都需要向用户提供基础功能，不应直接退出、关闭或无法正常使用。
          </span>
        </p>
        <p>
          <span
            >7.
            经用户授权同意的权限，必须支持可撤回，并提供便捷的撤回操作流程（建议在进入应用主界面后4步之内完成）。</span
          >
        </p>
        <p>
          <span
            >8.
            经用户授权同意的权限，可通过系统设置来关闭/撤回授权同意的权限，且应用中需有单独的权限授权管理页面，用于关闭各类权限。</span
          >
        </p>
        <p class="content-big-title">
          （三）对外提供、披露个人信息合规
        </p>
        <p>
          <span>1. 禁止应用出售用户的个人信息；</span>
        </p>
        <p>
          <span>2. </span
          ><span
            >应用程序、SDK不得未向用户告知且未经用户同意，私自使用个人信息，将用户个人信息用于其提供服务之外的目的，特别是私自向其他应用或服务器发送、共享用户个人信息的行为；
          </span>
        </p>
        <p>
          <span>3. </span
          ><span
            >应用程序未向用户明示个人信息处理的目的、方式和范围，不应将OAID、IMEI、IMSI、设备MAC地址、软件安装列表、位置、联系人、通话记录、日历、短信、本机电话号码、图片、音视频等个人信息发送给第三方SDK等产品或服务；
          </span>
        </p>
        <p>
          <span>4. </span
          ><span
            >应用程序以隐私政策弹窗等形式向用户明示共享给第三方的行为，未经用户同意，不应将OAID、IMEI、IMSI、设备MAC地址、软件安装列表、位置、联系人、通话记录、日历、短信、本机电话号码、图片、音视频等个人信息发送给第三方SDK等产品或服务；
          </span>
        </p>
        <p>
          <span>5. </span
          ><span
            >应用程序以隐私政策弹窗等形式向用户明示个人信息处理的目的、方式和范围，清晰明示共享的第三方身份、目的及个人信息类型，未经用户同意，不应将OAID、IMEI、IMSI、设备MAC地址、软件安装列表、位置、联系人、通话记录、日历、短信、本机电话号码、图片、音视频等个人信息发送给第三方SDK等产品或服务；
          </span>
        </p>
        <p>
          <span
            >6.
            应用功能为处理财务信息、付款信息或身份信息的应用，非提供相应服务所必需，不得披露任何用户个人信息；</span
          >
        </p>
        <p>
          <span
            >7. 应用披露任何个人信息须在隐私政策中说明披露内容、目的和披露对象。
          </span>
        </p>
        <p class="content-big-title">（四）定向推送合规</p>
        <p>
          <span>1. </span
          ><span
            >若应用程序中存在定向推送功能，应以隐私政策弹窗等形式告知用户，将收集的用户个人信息用于定向推送、精准营销，并经用户同意；
          </span>
        </p>
        <p>
          <span>2. </span
          ><span
            >若应用程序定向推送功能使用了第三方的个人信息来源，应以隐私政策弹窗等形式向用户明示业务功能使用第三方的个人信息进行定向推送，并向用户明示第三方的个人信息来源；
          </span>
        </p>
        <p>
          <span>3. </span
          ><span
            >应用程序以隐私政策弹窗等形式明示存在定向推送功能，页面中应显著区分定向推送服务，显著方式包括但不限于：标明“个性化推荐”、“定推”、“猜你喜欢”等其他能显著区分的字样，或通过不同的栏目、版块、页面分别展示等；
          </span>
        </p>
        <p>
          <span>4. </span
          ><span
            >应用程序以隐私政策弹窗等形式明示存在定向推送功能，应在应用程序中提供退出或关闭定向推送功能的选项。
          </span>
        </p>
        <p class="content-big-title strongTitle">
          <strong>（五）保护个人信息安全的措施</strong>
        </p>
        <p>
          <span
            >1.
            应用披露个人信息的任何第三方应提供与开发者的应用程序隐私政策所述及这些准则所要求相同或等同的个人信息保护措施；</span
          >
        </p>
        <p>
          <span>2. </span
          ><span
            >应用须使用加密技术（例如通过
            HTTPS）、匿名化、去标识化等安全的方式处理用户的所有个人信息；</span
          >
        </p>
        <p>
          <span
            >3.
            应用的开发者或运营者应当就个人信息的保护制定相应的管理措施，比如设立个人信息保护负责人、管理机构以及制定个人信息安全事件应急预案等。</span
          >
        </p>
        <p class="content-big-title">
          <strong>（六）儿童个人信息保护</strong>
        </p>
        <p>
          <span
            >1.
            鉴于本平台用户大多数为儿童用户，当应用向儿童收集任何个人信息时，开发者必须遵守适用的儿童隐私保护法规；</span
          >
        </p>
        <p>
          <span
            >2.
            如应用含有危害儿童身心健康、侵害儿童个人信息安全或对儿童实施违法犯罪行为等内容，本平台有权对开发者的应用进行冻结、下架、断开相关应用服务、终止开发者与本平台运营者的协议和/或冻结您的开发者帐号，保存有关记录，并向有关部门报告；</span
          >
        </p>
        <p>
          <span>3. 收集和使用儿童个人信息的应用应制定专门的儿童隐私政策；</span>
        </p>
        <p>
          <span
            >4.
            应用在收集和使用儿童的个人信息前，需获得儿童及其父母或监护人的合法授权；</span
          >
        </p>
        <p>
          <span
            >5.
            开发者应当建立便捷、合理、有效的投诉和举报渠道，公开投诉、举报方式等信息，及时受理并处理涉及儿童个人信息的投诉、举报。</span
          >
        </p>
        <p class="content-big-title">
          <strong>（七）用户个人信息权利响应</strong>
        </p>
        <p><strong>1. 用户的相关权利</strong></p>
        <p>
          <span
            >应用需满足用户在个人信息上享有的相关权利：知情权、决定权、限制权、拒绝权、查阅权、复制权、可携带权、更正权、删除权、自动化决策权利等。包括但不限于用户可以通过账号注销、撤回隐私协议授权、关闭系统权限等方式管理个人信息。</span
          >
        </p>
        <p><strong>2. 用户权利响应</strong></p>
        <p>
          <span
            >应用应按法律规定提供更正、删除个人信息及注销用户账号功能，并及时响应用户相应操作。需人工处理的，应在承诺时限内（承诺时限不得超过15个工作日，无承诺时限的，以15个工作日为限）完成核查和处理。
          </span>
        </p>
        <p>
          <span
            >应用不应为更正、删除个人信息或注销用户账号设置不必要或不合理条件。</span
          >
        </p>
        <p><strong>3. 用户投诉与举报</strong></p>
        <p>
          <span
            >应用应建立并公布个人信息安全投诉、举报渠道，并在承诺时限内（承诺时限不得超过15个工作日，无承诺时限的，以15个工作日为限）受理并处理。</span
          >
        </p>
        <p></p>
        <p style="text-align: left;"></p>
        <p class="content-title">
          <strong>应用商店平台开发者合规公约（未成年人特别保护）</strong>
        </p>

        <p class="content-big-title">
          <span>一、网络信息内容安全</span>
        </p>
        <p>
          <span
            >应用不得含有或传播危害未成年人身心健康的内容，或利用其网络服务对未成年人实施违法犯罪行为。如：</span
          >
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >1.应用内不得含有人物造型过分夸张怪异，对未成年人有不良影响的内容；</span
          >
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >2.应用内不得含有会对未成年人造成干扰的内容，以及不利于未成年人身心健康和树立正向价值观的不良内容</span
          >
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >3.应用不得包含对未成年人实施侮辱、诽谤、威胁或者恶意损害形象等网络欺凌内容，亦不得以鼓动、诱导或帮助等方式，间接实施或参与网络欺凌行为。
          </span>
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >4.应用内不得包含与未成年人相关的色情内容，包括但不限于恋童癖、针对未成年人的不当互动、儿童性虐待等。
          </span>
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >5.应用内不得展示未成年人或者未成年人形象的动画、动漫人物的性行为，或让人产生性妄想的；不得提供以未成年人为形象创作的色情小说、漫画内容，严禁引诱未成年人不良交友、煽动负面情绪。
          </span>
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >6.面向未成年人的应用不得包含不适合未成年人的暴力、血腥或惊悚内容，以及描绘或鼓励有害、危险的活动。
          </span>
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >7.应用应尊重未成年人隐私权，严禁侵犯未成年人个人隐私，严禁实施或允许实施对未成年人人身攻击、侮辱谩骂等网络欺凌行为，严禁发布对未成年人造成不良导向的污言秽语。</span
          >
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >8.
            提供社交或类社交功能的，应当严厉查处对未成年人进行性引诱、教唆未成年人拍摄裸露视频图片、实施网络猥亵等行为。</span
          >
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >9.
            不得直接或允许应用使用者对散布虚假信息、虚构离奇故事和低俗剧情进行炒作引流、恶意营销等，对应用内涉及炫富拜金、奢靡享乐、扭曲审美等可能对未成年人造成不良影响的内容应当严格审查并禁止发布。</span
          >
        </p>
        <p>
          <span style="color: black; background-color: white;"
            >应用如含有可能引发或者诱导未成年人模仿不安全行为、实施违反社会公德行为、产生不良情绪、养成不良嗜好等影响未成年人身心健康的信息，应当在信息展示前予以显著提示。</span
          >
        </p>
        <p>
          <u style="font-weight: 600;"
            ><strong
              >【针对开发者应当遵守的网络信息内容安全其他规范，请参照本公约“第二节网络信息内容安全”部分规定】</strong
            ></u
          >
        </p>
        <p class="content-big-title">
          <span>二、</span><span>儿童个人信息保护</span>
        </p>
        <p>
          <span
            >当您的应用向不满14周岁的未成年人（儿童）收集任何个人信息时，您必须遵守适用的儿童隐私保护法规，如《个人信息保护法》《应用程序违法违规收集使用个人信息行为认定方法》《儿童个人信息网络保护规定》以及其他适用法律法规。比如，收集和使用儿童个人信息的应用应制定专门的儿童隐私政策，又比如，应用在收集和使用儿童的个人信息前，需获得父母或监护人的合法授权。</span
          >
        </p>
        <p>
          <u style="font-weight: 600;"
            ><strong
              >【针对开发者应当遵守的儿童个人信息保护其他规范，请参照本公约“第三节
              个人信息保护”相关部分规定】</strong
            ></u
          >
        </p>
        <p class="content-big-title"><span>三、防沉迷</span></p>
        <p>
          <span>1. 防沉迷管控及措施设置</span>
        </p>
        <p>
          <span
            >（1）应用应开发升级未成年人防沉迷、青少年模式等管理系统，合理设置未成年人使用服务的时间、权限等，不得以任何形式向未成年人提供诱导其沉迷的产品和服务，不得利用算法推荐服务，诱导未成年人沉迷，并需及时修改可能造成未成年人沉迷的内容、功能或者规则。</span
          >
        </p>
        <p>
          <span
            >（2）网络游戏、网络直播、网络音视频、网络社交等网络服务提供者，应当针对未成年人使用其服务设置相应的时间管理、权限管理、消费管理等功能，如针对使用时间管理，开发者应将其提供的网络服务应纳入小天才时长和权限管控的整体管理逻辑中，或者在应用内自主提供时长管控、使用时长提醒，家长权限管控功能；针对消费管理，提供付费服务的开发者，应当在应用简介中明确说明付费事项（包括收费标准、收费方式等必要内容），并且应当通过设置消费限额、监护人端消费提示及二次确认等功能，确保对未成年人用户的消费活动进行有效管理，对于开发者针对其所提供的应用内付费服务应遵守的其他条款内容，请参考<span
              class="goToBlank"
              @click="$goToBlank('check_rule')"
              >《应用审核规范》</span
            >之“应用付费”部分规定。</span
          >
        </p>
        <p>
          <span
            >2.应用不得出现诱导未成年人沉迷、充值付费等情形，包括但不限于：</span
          >
        </p>
        <p>
          <span
            >（1）应用不得诱导中小学生家长使用分期贷款缴纳校外培训费用。</span
          >
        </p>
        <p>
          <span
            >（2）应用不得向未成年人宣传或鼓动未成年人使用/贩卖酒精饮料、烟草制品、电子烟产品或管制药品。不得向未成年人宣传负面身体形象或自我形象，如描绘整形手术、减肥以及对个人外表进行的其他美容调整。</span
          >
        </p>
        <p>
          <span
            >（3）社交应用如涉及与陌生人聊天、提供约会交友服务、两性或婚姻建议等内容，不得以未成年人为目标用户，如在线配对聊天、开放聊天室、秀场直播等。</span
          >
        </p>
        <p>
          <span
            >（4）儿童类应用不得含有应用外链接、付费项目或其他会对儿童造成干扰的内容，除非其保留在受家长监控的指定区域中，防止儿童在父母或监护人不知情的情况下进行商业交易，或者从应用中链接至外部网站、社交网络或其他应用。</span
          >
        </p>
        <p>
          <span
            >（5）应用内面向未成年人投放广告的行为应符合相关法规和行业标准的要求，不得发布医疗、药品、保健食品、医疗器械、化妆品、酒类、美容、婚恋、孕育广告以及不利于未成年人身心健康的网络游戏广告。面向儿童的应用广告不得含有劝诱儿童要求家长购买广告商品或者服务，以及可能引发儿童模仿不安全行为的内容。</span
          >
        </p>
        <p>
          <span
            >（6）面向未成年人的应用不得出现游戏化设计类的内容，防止未成年人过度沉迷。</span
          >
        </p>
        <p>
          <span
            >3.网络游戏类应用开发者，应当按照国家新闻出版署要求，接入网络游戏防沉迷实名认证系统，落实<span
              class="goToBlank"
              @click="
                $goToUrl(
                  'http://www.npc.gov.cn/npc/c30834/202010/82a8f1b84350432cac03b1e382ee1744.shtml'
                )
              "
              >《未成年人保护法》</span
            ><span
              class="goToBlank"
              @click="
                $goToUrl(
                  'http://www.gov.cn/zhengce/zhengceku/2021-10/29/content_5647673.htm'
                )
              "
              >《关于进一步加强预防中小学生沉迷网络游戏管理工作的通知》</span
            >等法律法规关于使用时长、消费管控和内容限制等义务的要求。</span
          >
        </p>
        <p>
          <span
            >4.面向未成年人的在线教育类应用不得含有网络游戏链接，以及推送广告等与教学无关的信息。</span
          >
        </p>
        <p class="content-big-title">
          <span>四、意见反馈</span>
        </p>
        <p>
          <span
            >开发者应当建立便捷、合理、有效的投诉和举报渠道，公开投诉、举报方式等信息，及时受理并处理涉及未成年人的投诉、举报。</span
          >
        </p>
        <p style="text-align: left;"></p>
        <p class="content-title">
          <strong
            >应用商店平台开发者合规公约（具有舆论属性或社会动员能力应用程序安全评估
            ）</strong
          >
        </p>

        <p class="content-big-title">
          <strong>一、适用</strong>
        </p>
        <p>
          <span
            >本节规定适用于含有论坛、博客、微博客、聊天室、通讯群组、公众账号、短视频、网络直播、信息分享、小程序等内容，提供公众舆论表达渠道或者具有发动社会公众从事特定活动能力的应用程序
            ；</span
          >
        </p>
        <p class="content-big-title">
          <strong>二、安全评估</strong>
        </p>
        <p>
          <span
            >1.
            具有舆论属性或社会动员能力的应用程序开发者，应当针对信息服务和新技术新应用的合法性、落实安全措施和防控安全风险的有效性等情况，可以自行实施安全评估，也可以委托第三方安全评估机构实施。</span
          >
        </p>
        <p>
          <span
            >2.
            开发者开展安全评估，应当对信息服务和新技术新应用的合法性，落实法律、行政法规、部门规章和标准规定的安全措施的有效性，防控安全风险的有效性等情况进行全面评估，并重点评估下列内容：</span
          >
        </p>
        <p>
          <span
            >(1)
            确定与所提供服务相适应的安全管理负责人、信息审核人员或者建立安全管理机构的情况；</span
          >
        </p>
        <p>
          <span>(2) 用户真实身份核验以及注册信息留存措施；</span>
        </p>
        <p>
          <span
            >(3)
            对用户的账号、操作时间、操作类型、网络源地址和目标地址、网络源端口、客户端硬件特征等日志信息，以及用户发布信息记录的留存措施；</span
          >
        </p>
        <p>
          <span
            >(4)
            对用户账号和通讯群组名称、昵称、简介、备注、标识，信息发布、转发、评论和通讯群组等服务功能中违法有害信息的防范处置和有关记录保存措施；</span
          >
        </p>
        <p>
          <span
            >(5)
            个人信息保护以及防范违法有害信息传播扩散、社会动员功能失控风险的技术措施；</span
          >
        </p>
        <p>
          <span
            >(6)
            建立投诉、举报制度，公布投诉、举报方式等信息，及时受理并处理有关投诉和举报的情况；</span
          >
        </p>
        <p>
          <span
            >(7)
            建立为网信部门依法履行互联网信息服务监督管理职责提供技术、数据支持和协助的工作机制的情况；</span
          >
        </p>
        <p>
          <span
            >(8)
            建立为公安机关、国家安全机关依法维护国家安全和查处违法犯罪提供技术、数据支持和协助的工作机制的情况。</span
          >
        </p>
        <p>
          <span
            >3.
            经过安全评估，符合法律、行政法规、部门规章和标准的，应当形成安全评估报告。安全评估报告应当包括下列内容：</span
          >
        </p>
        <p>
          <span
            >(1)
            互联网信息服务的功能、服务范围、软硬件设施、部署位置等基本情况和相关证照获取情况；</span
          >
        </p>
        <p>
          <span>(2) 安全管理制度和技术措施落实情况及风险防控效果；</span>
        </p>
        <p>
          <span>(3) 安全评估结论；</span>
        </p>
        <p>
          <span>(4) 其他应当说明的相关情况。</span>
        </p>
        <p class="content-big-title">
          <strong>三、安全评估报告申请</strong>
        </p>
        <p>
          <span
            >经过安全评估，符合法律、行政法规、部门规章和标准的，应当形成《安全评估报告》，并通过全国互联网安全管理服务平台提交至网信部门和公安机关。开发者可以登录【</span
          ><span
            class="goToBlank"
            @click="
              $goToUrl(
                'https://www.beian.gov.cn/portal/index?spm=a2cmq.17630005.0.0.616a79fenQJ7Si'
              )
            "
            >全国互联网安全服务管理平台</span
          ><span>】，点击“安全评估报告”进行申请。</span>
        </p>
        <p class="content-big-title">
          <strong>四、安全评估报告提交审核</strong>
        </p>
        <p>
          <span
            >具有舆论属性或社会动员能力的应用程序开发者在本应用商店平台上传或进行应用管理时，开发者需上传《安全评估报告》和《在全国互联网安全管理服务平台上提交的结果截图》。</span
          >
        </p>
        <p>
          <span>上传入口：</span>
        </p>
        <p>
          <span
            >1. 在发布应用时，在开发者平台首页“发布应用” &gt; “特殊资质”
            处上传《安全评估报告》。</span
          >
        </p>
        <p class="content-big-title">
          <span>五、其他</span>
        </p>
        <p>
          <span
            >1.
            开发者需确保服务名称和单位名称与上传的应用名称及开发者名称一致。</span
          >
        </p>
        <p>
          <span>
            2.
            开发者在安全评估中发现存在安全隐患的，应当及时整改，直至消除相关安全隐患。</span
          >
        </p>
        <p><br /></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  height: 100%;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  .content {
    padding-right: 30px;
  }
  p {
    line-height: 160%;
    text-indent: 2em;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 18px;
    color: #333333;
    margin-bottom: 8px;
    text-indent: 0;
  }
  strong {
    font-weight: 600;
  }
}
</style>
