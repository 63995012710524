<template>
  <div class="content-right">
    <div class="content-title">商店客户端运营素材设计规范</div>
    <hr />
    <div class="content">
      <p class="content-big-title">一. Banner</p>
      <p class="content-small-title">
        1．图片尺寸（共3张）：826*226，1652*452，1026*226，此三张为矩形，详见banner出图规范PSD；左上角含角标，角标为活动、专题、精选，跟产品确定选用。
      </p>
      <p class="content-small-title">2.文件大小：小于200k；</p>
      <p class="content-small-title">3.文件格式：PNG；</p>
      <p class="content-small-title">
        <img src="@/assets/img/psd1.png" alt />
      </p>
      <p class="content-big-title">二. 专题封面</p>
      <p class="content-small-title">
        1.封面图片尺寸（3个）：482*214，964*428，592*262，此三张为矩形，详见banner出图规范PSD；
      </p>
      <p class="content-small-title">2.文件大小：封面小于100k</p>
      <p class="content-small-title">3.文件格式：PNG；</p>
      <p class="content-small-title" style="overflow:hidden">
        <img src="@/assets/img/psd2.png" alt class="img" />
        <!-- <img src="@/assets/img/3.png" alt=""  class="img"> -->
      </p>
      <p class="content-big-title">三. 专题图片集合</p>
      <p class="content-small-title">
        1.一级封面尺寸（2张）：200*240，400*480，矩形，带角标，图片封面不带标题，主副标题单独提供给运营产品经理；
      </p>
      <p class="content-small-title">
        2.二级封面尺寸（2张）：472*210，944*420，矩形，带角标。
      </p>
      <p class="content-small-title">3.文件格式：PNG；</p>
      <p class="content-small-title" style="overflow:hidden">
        <img src="@/assets/img/psd3.png" alt class="img" />
        <!-- <img src="@/assets/img/3.png" alt=""  class="img"> -->
      </p>
      <p class="content-big-title">四. 启动页</p>
      <p class="content-small-title">
        1.共出图6张：1280*800（横屏），800*1280（竖屏），768*1024（竖屏），1024*768（横屏），1536*2048（竖屏），2048*1536（横屏）。
      </p>
      <p class="content-small-title">2.格式模板请下载底部PSD文件。</p>
      <p class="content-small-title" style="overflow:hidden">
        <img src="@/assets/img/psd4.png" alt class="img" />
        <!-- <img src="@/assets/img/3.png" alt=""  class="img"> -->
      </p>
      <p class="content-big-title">设计规范及模板下载</p>
      <a
        href="http://common-file-dn.eebbk.net/common-file/2019/07/29/094446501_ac9aaaf3e1c8d786.rar"
        download="设计规范指导文件.rar"
        style="margin-top:20px"
      >
        <span class="span2">设计规范指导文档</span> </a
      ><br />
      <a
        href="http://common-file-dn.eebbk.net/common-file/2019/09/18/144442574_260e97bac4f7ff5d.rar"
        download="Banner PSD模板下载.rar"
        style="margin-top:12px"
      >
        <span class="span2">Banner PSD 模板下载</span> </a
      ><br />
      <a
        href="http://common-file-dn.eebbk.net/common-file/2019/09/18/144442595_c7229d591f54a12b.rar"
        download="专题封面PSD 模板下载.rar"
        style="margin-top:12px"
      >
        <span class="span2">专题封面PSD 模板下载</span> </a
      ><br />
      <a
        href="http://common-file-dn.eebbk.net/common-file/2019/09/18/144442614_e8d8c97ed2498790.rar"
        download="专题图片集合PSD 模板下载.rar"
        style="margin-top:12px"
      >
        <span class="span2">专题图片集合PSD 模板下载</span> </a
      ><br />
      <a
        href="http://common-file-dn.eebbk.net/common-file/2019/09/18/144442634_9625ceb35d6975ee.rar"
        download="启动页PSD 模板下载.rar"
        style="margin-top:12px"
      >
        <span class="span2">启动页PSD 模板下载</span> </a
      ><br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  width: 932px;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #363636;
    font-weight: bold;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .span2 {
    cursor: pointer;
    text-decoration: underline;
    color: #00bbff;
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    .img {
      float: left;
    }
    img {
      width: 100%;
      display: block;
      margin-top: 12px;
    }
  }
}
</style>
