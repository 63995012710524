<template>
  <div class="content-right">
    <div class="content-title">应用管理FAQ</div>
    <a href></a>
    <div class="content">
      <p class="content-big-title">应用上传FAQ</p>
      <p class="content-big-title">
        1. 没有《计算机软件著作权登记证书》（以下简称“软著”）怎么办？
      </p>
      <p class="content-small-title">
        1.1 建议您尽快去申请软著，或申办软著的截图，申办软著的网址链接：
      </p>
      <p class="content-small-title">
        <a
          class="span2"
          href="http://apply.ccopyright.com.cn/cpcc/column_list_bqdj.jsp"
          target="_blank"
          >http://apply.ccopyright.com.cn/cpcc/column_list_bqdj.jsp。</a
        >
      </p>
      <p class="content-small-title">
        1.2
        提供承诺函并上传在其他主流市场（如：应用宝、小米、华为）后台审核上架的截图（整屏:附带右下角系统时间）PS：特殊分类（相亲、交友、抢红包、彩票、直播等，具体特殊类别可参考：
        <span class="span2" @click.prevent="goCheckQuality"
          >应用资质审核规范</span
        >）必须要提供软著。
      </p>
      <p class="content-small-title">
        1.3
        如果企业是更换营业执照号，新的营业执照号没有软著，可以提供授权书。（注：特殊分类APP暂不接受软著授权书）
      </p>
      <p class="content-big-title">
        2.《计算机软件著作权登记证书》（以下简称“软著”）有哪些要求呢？
      </p>

      <p class="content-small-title">
        2.1 软著必须真实有效，一经发现软著造假，平台将永久冻结开发者账号；
      </p>
      <p class="content-small-title">2.2 上传的软著需字体清晰；</p>
      <p class="content-small-title">
        2.3 软著上的“著作权人”必须和开发者注册账号时填写的企业名称一致；
      </p>
      <p class="content-small-title">2.4 其他要求以实际审核情况为准。</p>

      <p class="content-big-title">3 特殊类app需要哪些资质审核？</p>

      <p class="content-small-title">
        3.1详情请见：
        <span class="span2" @click.prevent="goCheckQuality"
          >应用资质审核规范</span
        >
      </p>
      <p class="content-big-title">4. 如何修改应用名称？</p>

      <p class="content-small-title">
        由于应用名称是直接读取应用包里的名称，故无法直接进行修改。如需修改，您可以在应用包里修改应用名称，然后上传修改名称后的应用包即可
      </p>
      <p class="content-big-title">5. 上线之后如何修改图标（ICON）？</p>

      <p class="content-small-title">
        请您先在应用包里修改应用图标，然后在后台“管理中心--该应用---编辑与更新---（icon与截图处）
        上传新图标，等待审核通过即完成修改图标的操作。
      </p>
      <p class="content-small-title">
        详情见：
        <span class="span2" @click.prevent="goCheckQuality"
          >应用资质审核规范</span
        >
      </p>
      <p class="content-big-title">6.请问一个开发者账号可以上传多少个APP？</p>
      <p class="content-small-title">
        没有限制上传个数哦，但是不允许上传功能重复的APP，重复上传功能相似的应用，可能会造成账户冻结哦。
      </p>
      <p class="content-big-title">7. 可以删除应用吗？</p>
      <p class="content-small-title">
        十分抱歉，平台暂不支持删除应用或相关上传记录。如果您遇到以下场景，请按照如下操作：
      </p>
      <p class="content-small-title">
        （1）上传的应用存在Bug、传错应用、资料填写错误等，但已进入审核流程：请您联系步步高商务，我们会为您申请撤销审核；
      </p>
      <p class="content-small-title">
        （2）应用不维护了：抱歉平台暂不支持删除应用，若应用不维护可直接联系步步高商务申请下架；
      </p>
      <p class="content-small-title">
        （3）应用已上架，需要紧急下架时，请您联系步步高商务。
      </p>
      <p class="content-big-title">应用审核FAQ</p>
      <p class="content-big-title">1. 应用显示为什么一直审核中？</p>
      <p class="content-small-title">
        步步高家教机开发者平台上班期间审核周期为1-3个工作日，耐心等待。
        若超过3个工作日还未通过/打回请联系步步高商务咨询。
      </p>
      <p class="content-big-title">2. 提交后发现传错怎么办？</p>
      <p class="content-small-title">
        2.1 传错资料：步步高家教机开发者平台上班期间审核周期为1-3个工作日，
        可等待上架后立马编辑更新资料。
      </p>
      <p class="content-small-title">
        2.2 传错APK：请马上联系步步高商务说明原因，我们会联系测试人员为您打回。
      </p>
      <p class="content-big-title">3. 审核未通过怎么办？</p>
      <p class="content-small-title">
        请根据版本管理中的“修改建议”进行修改，修改完成后重新上传即可。
      </p>
      <p class="content-small-title">
        若以上回答未能解决您的问题，请您联系步步高商务
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManageFaq',
  methods: {
    goCheckQuality() {
      this.$router.push({
        query: {
          docComName: 'check-quality'
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      color: #333333;
    }
    .span1 {
      color: red;
    }
    .span2 {
      text-decoration: underline;
      color: skyblue;
    }
    .span2 {
      cursor: pointer;
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
