<template>
  <div class="ff-upload">
    <div class="ff-upload-text">
      {{ placeholder }}
      <span v-if="showDownload"
        >| 点击查看<el-button type="text" @click="handleDownload">{{
          templateName
        }}</el-button></span
      >
    </div>
    <el-upload
      class="ff-upload-main"
      :headers="uploadHeader"
      :action="action"
      :show-file-list="false"
      :on-success="handleSuccess"
      :on-progress="() => (loading = true)"
      :before-upload="beforeAvatarUpload"
      :disabled="imageUrl ? true : false"
    >
      <img v-if="imageUrl" :src="imageUrl" class="ff-upload-img" />
      <div
        v-else
        v-loading="loading"
        element-loading-text="上传中"
        element-loading-spinner="el-icon-loading"
        class="ff-upload-tip"
      >
        <i class="el-icon-plus"></i>
        <span>点击上传图片</span>
      </div>
      <img
        v-if="imageUrl"
        src="@/assets/img/delete-img.png"
        alt=""
        class="ff-upload-delete"
        @click.stop="handleDelete"
      />
    </el-upload>
    <div class="ff-upload-text">
      文件类型要求：支持.jpg .jpeg .png格式 | 文件大小要求：不超过5M
    </div>
  </div>
</template>

<script>
import { getCommonHeader } from '@/plugins/axios';
export default {
  name: 'ImageUpload',
  props: {
    url: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '单机应用需上传对应的免责承诺函'
    },
    templateName: {
      type: String,
      default: '承诺函模板'
    },
    downloadUrl: {
      type: String,
      default: ''
    },
    showDownload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      imageUrl: '',
      action: this.$axios.defaults.baseURL + 'upload/uploadImg',
      uploadHeader: getCommonHeader()
    };
  },
  watch: {
    url: {
      immediate: true,
      handler(newVal) {
        this.imageUrl = newVal;
      }
    }
  },
  methods: {
    // 上传成功
    handleSuccess(res) {
      this.imageUrl = res.data;
      this.$emit('getImage', res.data);
      this.loading = false;
    },
    // 上传之前钩子
    beforeAvatarUpload(file, limit = 1024 * 1024 * 5) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / limit < 1;
      let sizeStr = this.formatSize(limit);

      if (!isJPG) {
        this.$message.error('上传图片只能是.jpg .jpeg .png格式!');
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过${sizeStr}!`);
      }
      return isJPG && isLt2M;
    },
    // 删除
    handleDelete() {
      this.imageUrl = '';
      this.$emit('getImage', '');
    },
    handleDownload() {
      let a = document.createElement('a'); //创建一个a标签用来跳转
      a.href = this.downloadUrl;
      // a.target = '_blank';
      a.download = '模板'; //设置下载文件的文件名，不是必须
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(this.downloadUrl); //释放 url 对象内存
      document.body.removeChild(a);
    }
  }
};
</script>

<style lang="less" scoped>
.ff-upload {
  &-text {
    font-size: 12px;
    color: #8c8c8c;

    &::v-deep .el-button--text {
      font-size: 12px;
    }
  }

  &-main {
    width: 180px;
    margin-right: 53px;
    display: inline-block;

    &::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      background-color: #fafafa;
      width: 180px;
      height: 180px;
    }

    &::v-deep .el-upload:hover {
      border-color: #409eff;
    }

    &::v-deep .el-loading-mask {
      z-index: 1;
    }
  }

  &-img {
    width: 100%;
    height: 100%;
  }

  &-tip {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-size: 14px;
    }
  }

  &-delete {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}
</style>
