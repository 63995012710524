<template>
  <div class="container">
    <h2>应用评级规则</h2>
    <h3>1、资源配置背景</h3>
    <p>
      为提升家教机应用商店资源位的有效利用，保证优质应用的有效曝光，现对应用进行分级并以此为依据进行资源位配置。
    </p>
    <h3>2、应用评级规则</h3>
    <p>
      根据《应用商店应用评级表》，以应用质量和数据表现为主要标准，对各项标准加权计算出应用评分，并分为S、A、B、C四个等级，优先级依次递减；评分、定级为每周更新一次。
    </p>
    <div class="table">
      <p>应用定级</p>
      <p>S级</p>
      <p>A级</p>
      <p>B级</p>
      <p>C级</p>
    </div>
    <h3>3、资源位配置原则</h3>
    <table cellpadding="0" cellspacing="0">
      <tr>
        <th colspan="2">资源位</th>
        <th colspan="4">应用等级要求</th>
      </tr>
      <tr>
        <td rowspan="2">启动页</td>
        <td>闪屏</td>
        <td>S级</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>开机推荐</td>
        <td>S级</td>
        <td>A级</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td rowspan="4">首页</td>
        <td>Banner</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td></td>
      </tr>
      <tr>
        <td>单款推荐位</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td></td>
      </tr>
      <tr>
        <td>发现精彩</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td></td>
      </tr>
      <tr>
        <td>悬浮广告位</td>
        <td>S级</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td rowspan="3">搜索</td>
        <td>搜索结果</td>
        <td>S级</td>
        <td>A级</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>热门搜索</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td>C级</td>
      </tr>
      <tr>
        <td>大家都安装了</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td></td>
      </tr>
      <tr>
        <td>分类</td>
        <td>热门搜索</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td>C级</td>
      </tr>
      <tr>
        <td rowspan="4">排行</td>
        <td>学习榜</td>
        <td>S级</td>
        <td>A级</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>流行榜</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td>C级</td>
      </tr>
      <tr>
        <td>新品榜</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td>C级</td>
      </tr>
      <tr>
        <td>下载榜</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td>C级</td>
      </tr>
      <tr>
        <td>应用详情页</td>
        <td>装了还会装</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td>C级</td>
      </tr>
      <tr>
        <td colspan="2">聚合详情页</td>
        <td>S级</td>
        <td>A级</td>
        <td>B级</td>
        <td>C级</td>
      </tr>
      <tr>
        <td colspan="2">系统推送</td>
        <td>S级</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  padding-bottom: 40px;
  h2 {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 100;
  }
  p {
    color: #666;
    font-size: 14px;
  }
  h3 + p {
    text-indent: 2em;
  }
  h3 + div {
    & > p {
      text-indent: 2em;
    }
  }
  h4 {
    margin: 5px 0;
    text-indent: 1em;
    font-weight: 100;
    font-size: 16px;
  }
  h4 + p {
    text-indent: 3em;
  }
  .table {
    p {
      text-align: center;
      color: #000;
      line-height: 40px;
      border: 1px solid #000;
      border-bottom: 0;
      font-size: 18px;
      &:last-child {
        border-bottom: 1px solid #000;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      border: 1px solid #000;
      height: 50px;
      &:first-child {
        width: 40%;
      }
    }
    td {
      height: 50px;
      border: 1px solid #000;
      text-align: center;
      vertical-align: middle;
    }
  }
}
</style>
