// 这个本应该让后台控制的。动态路由也可，但是爷懒得弄了
const indexMenu = ['/home', '/doc', '/contact'];
const loginMenu = ['/login', '/register'];
const manageMenu = ['/publish', '/manage'];
const accountMenu = ['/account', '/ps_modify'];
const messageMenu = ['/message', '/notice'];

const menu = {
  indexMenu: [
    {
      name: '首页',
      path: '/home'
    },
    {
      name: '文档',
      path: '/doc'
    },
    {
      name: '商务合作',
      path: '/contact'
    }
  ],
  loginMenu: [],
  manageMenu: [
    {
      name: '发布应用',
      path: '/publish'
    },
    {
      name: '管理中心',
      path: '/manage'
    }
  ],
  accountMenu: [
    {
      name: '账户信息',
      path: '/account'
    },
    {
      name: '修改密码',
      path: '/ps_modify'
    }
  ],
  messageMenu: [
    {
      name: '消息',
      path: '/message'
    },
    {
      name: '公告',
      path: '/notice'
    }
  ]
};

export default path => {
  switch (true) {
    case indexMenu.indexOf(path) > -1:
      return menu.indexMenu;
    case loginMenu.indexOf(path) > -1:
      return menu.loginMenu;
    case manageMenu.indexOf(path) > -1:
      return menu.manageMenu;
    case accountMenu.indexOf(path) > -1:
      return menu.accountMenu;
    case messageMenu.indexOf(path) > -1:
      return menu.messageMenu;
    default:
      return [];
  }
};
