<template>
  <div class="content-area">
    <div class="top-content">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">商务合作</span>
      </div>
      <div class="name-box-1">
        <div class="div-1">
          <span class="span-label name">魏超男</span>
          <span>广告合作</span>
        </div>
        <div class="div-2">
          <span class="span-label">微信</span>
          <span>jinyan904</span>
        </div>
        <div class="div-3">
          <span class="span-label">邮箱</span>
          <span>weichaonan@eebbk.com</span>
        </div>
      </div>
    </div>
    <div class="bottom-content bottom">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">核心广告代理商</span>
      </div>
      <div class="body-wrap">
        <div class="l">
          <img class="l-img" src="@/assets/img/impact.png" alt />
        </div>
        <div class="r">
          <p class="bold">
            <span class="title">公司名称</span>
            <span>英派科特</span>
          </p>
          <p style="margin-top:40px">
            <span class="title">联系人姓名</span>
            <span>张龙政</span>
          </p>
          <p>
            <span class="title">联系人电话</span>
            <span>13632606040</span>
          </p>
          <p>
            <span class="title">公司地址</span>
            <span>深圳市南山区前海路振业国际商务中心1408</span>
          </p>
          <p>
            <span class="title">公司官网</span>
            <a href="http://www.impactad.cn" target="_blank"
              >http://www.impactad.cn</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-area {
  max-width: 1177px;
  margin: 0 auto;
  .top-content,
  .bottom-content {
    width: 100%;
    padding-top: 28px;
    padding-left: 28px;
    background: #ffffff;
    border-radius: 5px;
  }
  .top-content {
    height: 471px;
  }
  .bottom-content {
    .body-wrap {
      padding: 50px 0 50px 170px;
      font-size: 0;
      .l,
      .r {
        display: inline-block;
        vertical-align: top;
      }
      .l-img {
        width: 222px;
      }
      .r {
        margin-left: 100px;
        font-size: 14px;
        color: #333;
        .title {
          display: inline-block;
          width: 100px;
          text-align: left;
        }
        p {
          margin-top: 2px;
          a {
            color: #3c98ff;
          }
        }
      }
      .bold {
        font-weight: bold;
      }
    }
  }
  .bottom-content {
    margin-top: 20px;
  }
  .first-line {
    border-bottom: 1px solid #c7c7c7;
    padding-bottom: 14px;
  }
  .title-bold {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-left: 6px;
  }
  .vertical-line {
    margin-bottom: -2px;
  }
}
.top-content {
  .name-box-1,
  .name-box-2,
  .name-box-3 {
    width: 40%; // float: left;
  }

  .name-box-1,
  .name-box-2 {
    margin-top: 40px;
  }

  .name-box-1,
  .name-box-3 {
    margin-left: 170px;
  }

  .name-box-2 {
    // margin-left: 40px;
    margin-left: 170px;
  }

  .name-box-3 {
    margin-top: 50px;
  }

  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333333 !important;
  }

  .div-1 {
    margin-top: 24px;
    color: #333333;
    font-size: 14px;
  }

  .div-2,
  .div-3 {
    margin-top: 14px;
    color: #333333;
    font-size: 14px;
  }

  .span-label {
    display: inline-block;
    width: 86px;
    color: #666666;
  }
}

.bottom-content {
  .service-box {
    margin-left: 170px;
    color: #666666;
    font-size: 16px;
    .div-1 {
      margin-top: 36px;
    }
    .div-2,
    .div-3,
    .div-4 {
      margin-top: 26px;
    }
    .span-label {
      display: inline-block;
      width: 186px;
      color: #333333;
    }
    .code-label {
      vertical-align: top;
    }
    .qq-icon {
      margin-left: 30px;
      margin-bottom: -6px;
    }
  }
}
</style>
