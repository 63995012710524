<template>
  <div class="main-body">
    <div class="content-area">
      <template v-if="!isPreview">
        <div class="app-item">
          <div class="app-icon">
            <img :src="ApkDetailData.apkIcon" />
          </div>
          <div class="app-info">
            <div class="app-info-item">
              <span>应用名</span>
              <span>{{ ApkDetailData.apkName }}</span>
            </div>
            <div class="app-info-item">
              <span>包名</span>
              <span>{{ ApkDetailData.apkPackageName }}</span>
            </div>
            <div class="app-info-item">
              <span>版本名称</span>
              <span>{{ ApkDetailData.apkVersionName }}</span>
            </div>
            <div class="app-info-item">
              <span>版本号</span>
              <span>{{ ApkDetailData.apkVersionCode }}</span>
            </div>
            <div class="app-info-item">
              <span>发布类型</span>
              <span>{{ fitStore[ApkDetailData.isFit] }}</span>
            </div>
          </div>
          <div class="app-status" :class="'app-status-' + ApkDetailData.status">
            {{ ApkDetailData.statusName }}
          </div>
          <div v-if="ApkDetailData.updateStatus == 1" class="app-msg">
            <span>!</span>
            该版本不是应用最新版本，若不及时更新，可能会被合作接口的包替换
          </div>
          <template v-if="ApkDetailData.status">
            <div
              v-if="ApkDetailData.status == 1 || ApkDetailData.status == 4"
              class="app1-btn"
              @click="preview"
            >
              查看审核资料
            </div>
            <div v-else class="app-btn warning" @click="update">编辑与更新</div>
            <div
              v-if="ApkDetailData.status === 1"
              class="undo-btn"
              @click="undo(ApkDetailData.id)"
            >
              撤销审核
            </div>
          </template>
        </div>
        <div v-if="ApkDetailData.testResult" class="reason">
          <div>
            <el-table
              border
              :data="ApkDetailData.testResult"
              style="width: 100%"
            >
              <el-table-column prop="deviceModel" align="center" label="机型">
              </el-table-column>
              <el-table-column
                prop="testResult"
                align="center"
                label="测试结果"
              >
                <template slot-scope="scope">
                  {{ scope.row.testResult }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="应用含有社交、游戏性强、涉黄、广告等因素,开通娱乐权限设备用户可见!"
                    placement="bottom"
                  >
                    <i
                      v-if="scope.row.testResult === '验证发布'"
                      class="el-icon-warning"
                      style="color:#1890ff;font-size: 20px;"
                    ></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="remark" align="center" label="操作备注">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          v-if="
            ApkDetailData.result == 1 &&
              ApkDetailData.apkTestResult &&
              ApkDetailData.apkTestResult != ''
          "
          class="reason"
        >
          <pre>{{ ApkDetailData.apkTestResult }}</pre>
        </div>
        <div
          v-if="
            ApkDetailData.result == 2 &&
              ApkDetailData.auditRemark &&
              ApkDetailData.auditRemark != ''
          "
          class="reason"
        >
          <pre>{{ ApkDetailData.auditRemark }}</pre>
        </div>
      </template>
      <template v-if="isPreview">
        <div class="top-content">
          <div class="first-line">
            <img src="@/assets/img/vertical-line.png" class="vertical-line" />
            <span class="title-bold">资质信息：</span>
          </div>
          <div class="div-6 common-style">
            <span class="span-label">版权证明：</span>
            <img :src="ApkDetailData.copyrightCertificate" class="span-img" />
          </div>
          <div class="div-6 common-style">
            <span class="span-label">特殊资质：</span>
            <img :src="ApkDetailData.qualification" class="span-img" />
          </div>
        </div>
        <div class="bottom-content">
          <div class="first-line">
            <img src="@/assets/img/vertical-line.png" class="vertical-line" />
            <span class="title-bold">完善信息：</span>
          </div>
          <div class="common-style">
            <span class="span-label">应用信息：</span>
            <div class="apkinfo-box">
              <p>应用名称：{{ ApkDetailData.apkName }}</p>
              <p>包名：{{ ApkDetailData.apkPackageName }}</p>
              <p>版本名称：{{ ApkDetailData.apkVersionName }}</p>
              <p>版本号：{{ ApkDetailData.apkVersionCode }}</p>
              <p>大小：{{ formatSize(ApkDetailData.apkSize) }}</p>
            </div>
          </div>
          <div class="div-6 common-style">
            <span class="span-label">应用icon：</span>
            <img :src="ApkDetailData.apkIcon" class="span-img" />
          </div>
          <div class="common-style">
            <span class="span-label">一句话描述：</span>
            <span class="span-desc">{{ ApkDetailData.shortIntro }}</span>
          </div>
          <div class="common-style">
            <span class="span-label">应用隐私政策：</span>
            <span class="span-desc">{{ ApkDetailData.privacyPolicyUrl }}</span>
          </div>
          <div class="common-style">
            <span class="span-label">开发商名称：</span>
            <span class="span-desc">{{ ApkDetailData.developer }}</span>
          </div>
          <div class="common-style">
            <span class="span-label">发布类型：</span>
            <span class="span-desc">{{ fitStore[ApkDetailData.isFit] }}</span>
          </div>
          <div class="common-style">
            <span class="span-label">应用简介：</span>
            <p class="text-area-box">{{ ApkDetailData.apkIntroduce }}</p>
          </div>
          <div class="common-style">
            <span class="span-label">新版说明：</span>
            <p class="text-area-box">{{ ApkDetailData.updateInformation }}</p>
          </div>
          <div class="div-6 common-style">
            <span class="span-label">应用截图：</span>
            <el-popover
              v-for="(item, index) in ApkDetailData.picUrlList"
              :key="index"
              :class="imgStatus ? 'imgChange' : 'uploadedBox'"
              placement="right"
              trigger="hover"
            >
              <img class="pop-jie-img" :src="item" />
              <img
                slot="reference"
                :class="imgStatus ? 'span-imgs' : 'span-img'"
                :src="item"
              />
            </el-popover>
          </div>

          <div class="common-style">
            <span class="span-label">客服电话：</span>
            <span class="span-desc">{{ ApkDetailData.serviceTel }}</span>
          </div>
          <div class="common-style">
            <span class="span-label">备注说明：</span>
            <p v-if="ApkDetailData.remark" class="text-area-box">
              {{ ApkDetailData.remark }}
            </p>
          </div>
          <!-- <div class="common-style">
            <span class="span-label">联网状态</span>
            <span class="span-desc">{{
              ApkDetailData.networkingStatus === 0
                ? '未联网'
                : ApkDetailData.networkingStatus === 1
                ? '已联网'
                : ''
            }}</span>
          </div> -->
          <div class="common-style">
            <span class="span-label">备案状态：</span>
            <span class="span-desc">{{ filingStatusText }}</span>
          </div>
          <div v-if="ApkDetailData.filingStatus === -1" class="common-style">
            <span class="span-label">免责承诺函：</span>
            <el-popover class="uploadedBox" placement="right" trigger="hover">
              <img
                class="pop-jie-img"
                :src="ApkDetailData.disclaimerLetterUrl"
              />
              <img
                slot="reference"
                class="span-img"
                :src="ApkDetailData.disclaimerLetterUrl"
              />
            </el-popover>
          </div>
          <template v-if="ApkDetailData.filingStatus === 1">
            <div class="common-style">
              <span class="span-label">主体备案号：</span>
              <span class="span-desc">{{
                ApkDetailData.filingCompanyCode
              }}</span>
            </div>
            <div class="common-style">
              <span class="span-label">服务备案号：</span>
              <span class="span-desc">{{ ApkDetailData.filingApkCode }}</span>
            </div>
            <div class="common-style">
              <span class="span-label">备案主体：</span>
              <span class="span-desc">{{ filingCompanyTypeText }}</span>
            </div>
            <template v-if="ApkDetailData.filingCompanyType === 1">
              <div class="common-style">
                <span class="span-label">备案主体名称：</span>
                <span class="span-desc">{{
                  ApkDetailData.filingCompanyName
                }}</span>
              </div>
              <div class="common-style">
                <span class="span-label">备案主体统一社会信用代码：</span>
                <span class="span-desc">{{
                  ApkDetailData.filingCompanyBusinessLicense
                }}</span>
              </div>
              <div class="common-style">
                <span class="span-label">备案主体营业执照附件：</span>
                <el-popover
                  class="uploadedBox"
                  placement="right"
                  trigger="hover"
                >
                  <img
                    class="pop-jie-img"
                    :src="ApkDetailData.filingCompanyBusinessLicenseUrl"
                  />
                  <img
                    slot="reference"
                    class="span-img"
                    :src="ApkDetailData.filingCompanyBusinessLicenseUrl"
                  />
                </el-popover>
              </div>
              <div class="common-style">
                <span class="span-label">合作协议：</span>
                <el-popover
                  class="uploadedBox"
                  placement="right"
                  trigger="hover"
                >
                  <img
                    class="pop-jie-img"
                    :src="ApkDetailData.cooperationAgreementUrl"
                  />
                  <img
                    slot="reference"
                    class="span-img"
                    :src="ApkDetailData.cooperationAgreementUrl"
                  />
                </el-popover>
              </div>
            </template>
          </template>
          <el-button
            class="submit-btn"
            type="success"
            @click="isPreview = false"
            >返回</el-button
          >
        </div>
      </template>
    </div>
    <!-- 额外服务 -->
    <div v-if="serviceData.length > 0" class="extra-service">
      <h3>平台服务</h3>
      <div class="service-item-wrap">
        <div
          v-for="item in serviceData"
          :key="item.type"
          class="service-item"
          @click="toDataService(serviceTypeMap[item.serviceType])"
        >
          <img :src="serviceTypeMap[item.serviceType].img" alt />
          <span>{{ serviceTypeMap[item.serviceType].name }}</span>
          <img
            v-if="item.serviceSwitchShow === 0"
            class="service-new"
            src="@/assets/img/new-service.png"
            alt
          />
        </div>
        <span class="more-service-come">更多服务敬请期待</span>
      </div>
    </div>
    <!-- <el-dialog
      title="是否撤销"
      :visible.sync="abnormalBookVisible"
      :close-on-click-modal="false" 
      :close-on-press-escape="false"
      @closed="closeAbnormalBook"
      width="50%"
      >
    </el-dialog>-->
  </div>
</template>

<script>
import serviceIconImg from '../../assets/img/service_icon.png';
import serviceVideoImg from '../../assets/img/service_video.png';
export default {
  name: 'Detail',
  data() {
    return {
      isPreview: false,
      fitStore: ['常规发布', '适配上传', ''],
      ApkDetailData: {}, //应用详情数据
      statusList: new Map(),
      // 新增service数据
      serviceData: [],
      serviceTypeMap: {
        1: {
          id: 1,
          name: '数据服务',
          path: '/big_data',
          img:
            'https://common-pic-dn.eebbk.net/common-pic/2019/12/14/105109312_36052d2e333d7b39.png'
        },
        2: {
          id: 2,
          name: '详情定制',
          path: '/customize',
          img:
            'https://common-pic-dn.eebbk.net/common-pic/2019/12/14/105109332_ce3c0f824bb5f332.png'
        },
        3: {
          id: 3,
          name: '动态图标',
          path: '/service_icon',
          img: serviceIconImg
        },
        4: {
          id: 4,
          name: '视频介绍',
          path: '/service_video',
          img: serviceVideoImg
        }
      },
      imgStatus: true
    };
  },
  computed: {
    filingStatusText() {
      let text = '';
      if (this.ApkDetailData.filingStatus === 1) {
        text = '已备案';
      } else if (this.ApkDetailData.filingStatus === 0) {
        text = '未备案';
      } else if (this.ApkDetailData.filingStatus === -1) {
        text = '无需备案（单机应用）';
      } else {
        text = '';
      }
      return text;
    },
    filingCompanyTypeText() {
      let text = '';
      if (this.ApkDetailData.filingCompanyType === 0) {
        text = '与开发者账号主体一致';
      } else if (this.ApkDetailData.filingStatus === 1) {
        text = '与开发者账号主体不一致';
      } else {
        text = '';
      }
      return text;
    }
  },
  watch: {
    uploadImgType() {
      this.fileList = [];
      this.isUpload4 = false;
      this.uploadTypeChange();
    }
  },
  mounted() {
    console.log(this.$route.params, '++++++');
    this.isGetStatusList();
    this.getServiceData();
    this.getApkDetail();
  },
  methods: {
    uploadTypeChange() {
      if (this.ApkDetailData.apkDisplayType == '0') {
        this.imgStatus = true;
      } else {
        this.imgStatus = false;
      }
    },
    preview() {
      this.isPreview = !this.isPreview;
    },
    update() {
      this.$router.push({
        path: '/manage_update',
        query: {
          apkId: this.$route.params.apkId
        }
      });
    },
    //获取应用详情
    getApkDetail() {
      this.$axios
        .post(`/appManage/apkDetail/${this.$route.params.apkId}`)
        .then(res => {
          console.log(res.data);
          if (res.data.stateCode === '0') {
            this.ApkDetailData = res.data.data;
            // this.ApkDetailData.testResult = [
            //   {
            //     deviceModel: 'A5',
            //     testResult: '测试不通过',
            //     remark: '新增应用测试可见'
            //   },
            //   {
            //     deviceModel: 'A6',
            //     testResult: '验证发布',
            //     remark: '新增应用测试可见'
            //   },
            // ];
            this.uploadImgType = this.ApkDetailData.apkDisplayType;
            this.ApkDetailData.statusName = this.statusList.get(
              this.ApkDetailData.status
            );
          }
        })
        .catch(() => {});
    },
    // 点击撤销审核
    undo(obj) {
      console.log(obj);
      this.$confirm(
        '撤销审核后如需申请上架，需重新提交审核',
        '确定要撤销该应用的审核吗？',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }
      )
        .then(() => {
          this.$axios
            .post(`/appManage/cancel/${obj}`, obj, {
              header: {
                'Content-Type': 'application/json'
              }
            })
            .then(res => {
              console.log(res);
              if (res.data.stateCode == 0) {
                this.$alert(`撤销成功`, `${res.data.stateInfo}`, {
                  confirmButtonText: '确定'
                });
                this.getApkDetail();
                // this.$message.success("操作成功！");
                this.$message({
                  type: 'success',
                  message: '撤销成功!'
                });
              } else {
                this.$message({
                  type: 'warning',
                  message: `撤销失败!${res.stateInfo}`
                });
                this.getApkDetail();
              }
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    //获取应用状态
    getStatusList() {
      this.$axios
        .get(`appManage/getStatusList`)
        .then(res => {
          if (res.data.stateCode === '0') {
            let _str = ''; //字符串方便SesstionStorge
            let _sz = []; //对象数组 转成 字符串数组
            res.data.data.map(item => {
              this.statusList.set(item.id, item.name);
              _sz.push(JSON.stringify(item));
            });
            _str = _sz.join('@');
            //取到存到本地，其它页面方便取，减少请求
            sessionStorage.setItem('apkStatusList', _str);
            _sz = _str.split('@');
            _sz.map(items => {
              console.log(items);
              items = JSON.parse(items);
            });
          }
        })
        .catch(() => {});
    },
    //判断是否获取过应用状态，没有就执行get，否则就从session里拿
    isGetStatusList() {
      let _str = sessionStorage.getItem('apkStatusList');
      if (!_str) {
        this.getStatusList();
      } else {
        let _sz = _str.split('@');
        _sz.map(item => {
          item = JSON.parse(item);
          this.statusList.set(item.id, item.name);
        });
      }
    },
    // 获取服务信息
    getServiceData() {
      this.$axios.get('/appManage/getCooperationSwitchShow').then(res => {
        console.log(res);
        let data = [];
        res.data.data.map(item => {
          if (item.serviceSwitch) {
            data.push(item);
          }
        });
        this.serviceData = data;
      });
    },
    // 跳转至数据详情页面
    showDataDetail() {
      let data = {
        apkName: this.ApkDetailData.apkName,
        apkPackageName: this.ApkDetailData.apkPackageName,
        apkIcon: this.ApkDetailData.apkIcon,
        countDate: this.ApkDetailData.updateTime,
        apkDisplayType: this.ApkDetailData.apkDisplayType
      };
      this.$axios.get('/appManage/updateCooperationSwitchShow', {
        params: { serviceType: 1 }
      });
      window.localStorage.setItem('currentApkDetail', JSON.stringify(data));
      this.$router.push('/appManage/service/data/detail');
    },
    // 跳转至数据服务总览
    toDataService(item) {
      this.$axios.get('/appManage/updateCooperationSwitchShow', {
        params: { serviceType: item.id }
      });
      this.$router.push(item.path);
    }
  }
};
</script>

<style>
.pop-jie-img {
  width: 480px;
  height: 800px;
}
</style>

<style lang="less" scoped>
// 不知原先开发哪里样式乱写。 菜狗代码懒得瞧了
* {
  box-sizing: border-box;
}
.main-body {
  background: #f2f2f2;
  width: 100%;
  min-height: 900px;
  position: relative;
  .content-area {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 50px;

    .app-item {
      position: relative;
      width: 100%;
      height: 250px;
      background: #ffffff;
      padding: 55px 40px;
      clear: both;
      .app-icon,
      .app-info,
      .app-status,
      .app-btn {
        height: 120px;
        line-height: 120px;
        float: left;
      }
      .app-icon {
        img {
          width: 120px;
          height: 120px;
          vertical-align: top;
        }
      }
      .app-info {
        width: 50%;
        line-height: 14px;
        margin-left: 30px;
        .app-info-item {
          height: 25%;
          line-height: 30px;
          span {
            color: #666666;
            &:first-child {
              display: inline-block;
              width: 80px;
              color: #333333;
            }
          }
        }
      }
      .app-status {
        font-size: 18px;
        color: #3cbeff;
      }
      .app-btn {
        float: right;
        background: #3cb1ff;
        width: 150px;
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
        border-radius: 4px;
        transition: background 0.5s;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -o-transition: background 0.5s;
        margin-top: 40px;
        left: right;
        &.warning {
          background: #ffc367;
          margin-right: 5px;
        }
      }
      .app1-btn {
        background: #3cb1ff;
        width: 150px;
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
        border-radius: 4px;
        transition: background 0.5s;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -o-transition: background 0.5s;
        position: absolute;
        top: 100px;
        right: 40px;
      }
      .undo-btn {
        height: 120px;
        line-height: 120px;
        width: 150px;
        height: 40px;
        line-height: 40px;
        color: #666666;
        border: #666666 1px solid;
        font-size: 16px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
        border-radius: 4px;
        transition: background 0.5s;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -o-transition: background 0.5s;
        position: absolute;
        top: 150px;
        right: 40px;
      }
    }
    .reason {
      margin-top: 10px;
      padding-left: 40px;
      background: #ffffff;
      padding: 10px;
      line-height: 24px;
      pre {
        white-space: pre-wrap; /*css-3*/
        white-space: -moz-pre-wrap; /*Mozilla,since1999*/
        white-space: -pre-wrap; /*Opera4-6*/
        white-space: -o-pre-wrap; /*Opera7*/
        word-wrap: break-word; /*InternetExplorer5.5+*/
      }
    }
  }
  .app-msg {
    position: absolute;
    color: #ff7477;
    font-size: 14px;
    bottom: 20px;
    left: 155px;
    span {
      display: inline-block;
      text-align: center;
      width: 12px;
      height: 12px;
      line-height: 12px;
      font-size: 10px;
      border-radius: 50%;
      color: #ff7477;
      border: 1px solid #ff7477;
    }
  }
  .content-area {
    .apkinfo-box {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      color: #666666;
      border: 1px solid #cccccc;
      border-radius: 5px;
      width: 50%;
      padding: 10px;
      line-height: 25px;
    }
    .top-content,
    .bottom-content {
      width: 100%; // height: 1060px;
      padding-top: 28px;
      padding-left: 28px;
      padding-right: 28px;
      padding-bottom: 40px;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 20px;
      .submit-btn {
        margin-left: 120px;
        width: 150px;
        margin-top: 40px;
      }
      .uploadedBox {
        width: 180px;
        height: 110px;
        display: inline-block;
        position: relative;
        margin-right: 20px;
        cursor: pointer;
        border: 1px dashed white;
        &:hover {
          border: 1px dashed #cccccc;
        }
        .thumb-img {
          width: 100%;
          height: 100%;
        }
        .delete-img {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .imgChange {
        width: 110px;
        height: 180px;
        display: inline-block;
        position: relative;
        margin-right: 20px;
        cursor: pointer;
        border: 1px dashed white;
        &:hover {
          border: 1px dashed #cccccc;
        }
        .thumb-img {
          width: 100%;
          height: 100%;
        }
        .delete-img {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .first-line,
    .second-line {
      border-bottom: 1px solid #c7c7c7;
      padding-bottom: 14px;
    }
    .second-line {
      margin-top: 40px;
    }
    .title-bold {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      margin-left: 6px;
    }
    .vertical-line {
      margin-bottom: -2px;
    }
    .span-label {
      display: inline-block;
      width: 200px;
      color: #333333;
    }
    .span-imgs {
      height: 180px;
      width: 110px;
      vertical-align: top;
      margin-right: 20px;
    }
    .span-img {
      height: 110px;
      width: 180px;
      vertical-align: top;
      margin-right: 20px;
    }
    .common-style {
      font-size: 14px;
      color: #666666;
      margin-top: 26px;
    }
    .div-6,
    .div-9 {
      position: relative;
      .paper-desc {
        position: absolute;
        left: 120px;
      }
    }
    .no-upload-box {
      margin-left: 120px;
      margin-top: 10px;
      position: relative;
      height: 20px;
      span {
        position: absolute;
        font-size: 14px;
        color: #ff7477;
      }
      .span-1 {
        left: 0;
      }
      .span-2 {
        left: 234px;
      }
      .span-3 {
        left: 466px;
      }
    }
    .err-card {
      font-size: 14px;
      color: #ff7477;
      margin-top: 26px;
    }
    .mail-prompt,
    .phone-prompt {
      margin-left: 120px;
      margin-top: 2px;
    }
    .code-input {
      margin-left: 120px;
      margin-top: 16px;
    }
    .full-address {
      margin-left: 120px;
      margin-top: -16px;
      .text-area {
        width: 666px;
        height: 77px;
        webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
        -webkit-appearance: none;
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        color: #666666;
        font-size: 14px;
        padding: 10px 36px 10px 10px;
        overflow: hidden;

        &:focus {
          border-color: #3cb1ff;
        }
      }
    }
  }
  .extra-service {
    max-width: 1200px;
    margin: 11px auto 0;
    background: #fff;
    margin-bottom: 20px;
    padding: 30px 24px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    h3 {
      position: relative;
      font-size: 16px;
      padding-left: 9px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e5e5e5;
      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 4px;
        height: 16px;
        border-radius: 3px;
        background: #3cb1ff;
      }
    }
    .service-item-wrap {
      display: flex;
      .service-item {
        position: relative;
        display: flex;
        height: 140px;
        width: 230px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .service-new {
          position: absolute;
          top: 35px;
          right: 70px;
        }
        &:hover {
          img {
            transform: scale(0.8);
          }
        }
        img {
          transition: transform 0.5s;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 1px;
          height: 50px;
          margin: auto;
          background: #e5e5e5;
        }
        span {
          font-size: 14px;
        }
      }
      .more-service-come {
        display: inline-block;
        width: 140px;
        height: 140px;
        line-height: 140px;
        margin: 0 auto;
        font-size: 14px;
        color: #aaaaaa;
      }
    }
  }
}

.text-area {
  width: 666px;
  height: 100px;
  webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  color: #666666;
  font-size: 14px;
  padding: 10px 36px 10px 10px;
  margin-top: -16px;
  display: block;
  margin-left: 120px;
}
</style>
