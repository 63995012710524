<template>
  <div class="main-body">
    <div class="content-area">
      <!-- 安全验证区域 -->
      <div v-show="firstBox" class="input-box first-box">
        <div class="first-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">安全验证</span>
        </div>
        <div class="second-line">
          为了保护您的账号安全，操作前请进行安全验证
        </div>
        <div class="third-line">
          <span>验证邮箱</span>
          <span class="account-show">{{ userMail }}</span>
        </div>
        <div class="code-box">
          <span>验证码</span>
          <input
            v-model="mailBoxCode"
            type="text"
            placeholder="验证码"
            maxlength="6"
            class="input input-short-1"
          />
          <span class="get-code-btn" @click="getCode">{{ defaultCode }}</span>
          <div v-if="errCode" class="error-desc">请输入验证码</div>
        </div>
        <div class="next-step-btn" @click="submitAccountAccess">
          下一步
        </div>
        <div v-if="toastShow1" class="toast-box1">
          <img
            src="@/assets/img/toast-icon.png"
            alt="提示图标"
            class="toast-icon"
          />
          <span>发送成功</span>
        </div>
        <div v-show="toastShow2" class="toast-box">
          <img
            src="@/assets/img/error-icon.png"
            alt="提示图标"
            class="toast-icon"
          />
          <span>验证码错误</span>
        </div>
      </div>
      <!-- 重置密码区域 -->
      <div v-show="secondBox" class="input-box second-box">
        <div class="first-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">修改密码</span>
        </div>
        <div class="old-psd-box">
          <span class="psd-label">旧密码</span>
          <input
            v-model="oldPsd"
            type="password"
            autocomplete="new-password"
            placeholder="请输入旧密码"
            class="input input-psd"
            style="margin-left:38px;"
            maxlength="16"
            @keyup="accountInput('oldPsd')"
          />
          <div v-if="errOldPsd" class="error-desc">{{ oldPsdTip }}</div>
        </div>
        <div class="psd-box">
          <span class="psd-label">新密码</span>
          <input
            v-model="psd"
            type="password"
            autocomplete="new-password"
            placeholder="8-16位大小写字母、数字、符号组合"
            style="margin-left:38px;"
            class="input input-psd"
            maxlength="16"
            @keyup="accountInput('psd')"
          />
          <div v-if="errNewPsd" class="error-desc">
            8-16位，必须是大写或小写字母、标点、数字至少三种的组合
          </div>
        </div>
        <div class="confirm-psd-box">
          <span class="psd-label">确认密码</span>
          <input
            v-model="confirmPsd"
            type="password"
            autocomplete="new-password"
            placeholder="请再次输入新密码"
            maxlength="16"
            class="input input-psd"
            @keyup="accountInput('confirmPsd')"
          />
          <div v-if="errConfirmPsd" class="error-desc">密码不一致</div>
        </div>
        <div class="next-step-btn1">
          <span class="submit-btn" @click="finishVerification">确定</span>
          <span class="cancel-btn" @click="cancelModify">取消</span>
        </div>
        <div v-show="toastShow" class="toast-box2">
          <img
            src="@/assets/img/error-icon.png"
            alt="提示图标"
            class="toast-icon"
          />
          <span>密码修改失败，请刷新重试！</span>
        </div>
      </div>
      <!-- 修改成功区域 -->
      <div v-show="thirdBox" class="input-box third-box">
        <div class="first-line">
          <img src="@/assets/img/vertical-line.png" class="vertical-line" />
          <span class="title-bold">修改密码</span>
        </div>
        <div class="div-1">
          <img src="@/assets/img/finish-icon.png" alt="" />
        </div>
        <div class="div-2">
          修改成功，请牢记新密码
        </div>
        <div class="div-3">
          <span>即将跳转至登录页面</span>
          <span class="time-show">{{ countTime }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToCDB } from '@/plugins/util';
import { Md5 } from '@/plugins/md5';
export default {
  name: 'PsModify',
  data() {
    return {
      account: '',
      code: '',
      firstBox: true,
      secondBox: false,
      thirdBox: false,
      mailBoxCode: '',
      defaultCode: '获取验证码',
      toastShow: false,
      toastShow1: false,
      toastShow2: false,
      oldPsd: '',
      psd: '',
      confirmPsd: '',
      signOutShow: false,
      userName: '',
      userMail: '',
      errOldPsd: false,
      errNewPsd: false,
      errConfirmPsd: false,
      errCode: false,
      countTime: '3秒',
      loginShow: false,
      noLoginShow: true,
      unReadMessageNum: 0,
      oldPsdTip: '请输入旧密码'
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    cancelModify() {
      this.$router.push('/account');
    },
    accountInput(type) {
      switch (type) {
        case 'oldPsd':
          if (this.oldPsd.length) {
            this.errOldPsd = false;
          } else {
            this.errOldPsd = true;
          }
          break;
        case 'psd':
          var psdReg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/;
          if (this.psd.length && psdReg.test(this.psd.trim())) {
            this.errNewPsd = false;
          } else {
            this.errNewPsd = true;
          }
          break;
        case 'confirmPsd':
          if (
            this.confirmPsd.length &&
            this.confirmPsd.trim() === this.psd.trim()
          ) {
            this.errConfirmPsd = false;
          } else {
            this.errConfirmPsd = true;
          }
          break;
      }
    },
    getUserInfo() {
      this.$axios
        .post(`userManage/getUserInfo`, {
          object: this.$cookie.getCookie('accountId')
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            if (data.data.completeCode === 0) {
              this.$router.push('/account_submit');
            }
            this.userMail = data.data.email;
          }
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
        })
        .catch(() => {});
    },
    getCode() {
      var vm = this;
      if (this.defaultCode !== '获取验证码') {
        return;
      }
      this.defaultCode = '发送中';
      this.$axios
        .post(`userManage/sendMailCode`, {
          contactMail: this.userMail,
          verifyCodeType: 4
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            this.codeTimer();
            this.toastShow1 = true;
            setTimeout(function() {
              vm.toastShow1 = false;
            }, 2000);
          }
        })
        .catch(() => {});
    },
    submitAccountAccess() {
      var vm = this;
      this.errCode = this.mailBoxCode.trim() ? false : true;
      if (this.errCode) {
        return;
      }
      this.$axios
        .post(`userManage/securityVerify`, {
          contactMail: this.userMail,
          verifyCode: this.mailBoxCode,
          verifyCodeType: 4
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
          if (data.stateCode === '0') {
            this.firstBox = false;
            this.secondBox = true;
          } else {
            this.toastShow2 = true;
            setTimeout(function() {
              vm.toastShow2 = false;
            }, 2000);
          }
        })
        .catch(() => {
          this.toastShow2 = true;
          setTimeout(function() {
            vm.toastShow2 = false;
          }, 2000);
        });
    },
    finishVerification() {
      if (!this.oldPsd.trim()) {
        this.errOldPsd = true;
        this.oldPsdTip = '请输入旧密码';
      }
      if (!this.psd.trim()) {
        this.errNewPsd = true;
      }
      if (!this.confirmPsd.trim()) {
        this.errConfirmPsd = true;
      }
      if (this.errOldPsd || this.errNewPsd || this.errConfirmPsd) {
        return;
      }
      this.$axios
        .post(`userManage/updatePassword`, {
          accountId: this.$cookie.getCookie('accountId'),
          oldPassword: Md5.hex_md5(ToCDB(this.oldPsd.trim())),
          newPassword: Md5.hex_md5(ToCDB(this.psd.trim())),
          userName: this.$cookie.getCookie('userName')
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '104') {
            this.$store.commit('logout');
          }
          if (data.stateCode === '0') {
            this.secondBox = false;
            this.thirdBox = true;
            let count = 3;
            let timer = setInterval(() => {
              count--;
              if (count < 0) {
                clearInterval(timer);
                this.$store.commit('logout');
                this.$router.push('/login');
              }
              this.countTime = count + '秒';
            }, 1000);
          } else if (data.stateCode === '109') {
            //旧密码错误
            this.errOldPsd = true;
            this.oldPsdTip = '旧密码错误';
          } else {
            this.toastShow2 = true;
            setTimeout(function() {
              this.toastShow2 = false;
            }, 2000);
          }
        })
        .catch(() => {
          this.toastShow2 = true;
          setTimeout(function() {
            this.toastShow2 = false;
          }, 2000);
        });
    },
    codeTimer() {
      let count = 60;
      let timer = setInterval(() => {
        count--;
        if (count < 0) {
          clearInterval(timer);
          this.defaultCode = '获取验证码';
          return;
        }
        this.defaultCode = count + '秒';
      }, 1000);
    }
  }
};
</script>

<style lang="less" scoped>
.main-body {
  background: #f2f2f2;
  height: 900px;
  width: 100%;
  position: relative;
  padding-top: 150px;
  .content-area {
    margin: 0 auto;
    max-width: 777px;
    .input-box {
      width: 100%;
      height: 480px;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 23px;
      padding-top: 28px;
      padding-left: 28px;
      .first-line {
        border-bottom: 1px solid #c7c7c7;
        padding-bottom: 14px;
      }
      .second-line {
        color: #333333;
        font-size: 14px;
        text-align: center;
        margin-top: 50px;
      }
      .third-line {
        color: #666666;
        font-size: 14px;
        margin-top: 23px;
        margin-left: 174px;
        .account-show {
          margin-left: 24px;
        }
      }
      .title-bold {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin-left: 6px;
      }
      .vertical-line {
        margin-bottom: -2px;
      }
      .next-step-btn {
        background: #3cb1ff;
        width: 352px;
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        margin-top: 30px;
        letter-spacing: 1px;
        cursor: pointer;
        border-radius: 4px;
        margin-left: 174px;
        transition: background 0.5s;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -o-transition: background 0.5s;
      }
      .next-step-btn:hover {
        background: #2ea5f5;
      }
      .next-step-btn1 {
        margin-top: 36px;
        letter-spacing: 1px;
        margin-left: 174px;
      }
      .input {
        webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 40px;
        width: 352px;
        outline: none;
        -webkit-appearance: none;
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        margin-top: 16px;
        margin-bottom: 8px;
        color: #666666;
        font-size: 14px;
        padding: 10px 36px 10px 10px;
        overflow: hidden;
        &.input-short {
          width: 204px;
        }
        &.input-short-1 {
          width: 144px;
          margin-left: 38px;
        }
        &.input-psd {
          width: 352px;
          margin-left: 24px;
        }
      }
      .change-code-btn {
        cursor: pointer;
        margin-left: 20px;
        width: 124px;
        height: 40px;
        vertical-align: middle;
        border-radius: 4px;
      }
      .get-code-btn {
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        margin-left: 22px;
        width: 104px;
        height: 40px;
        line-height: 42px;
        display: inline-block;
        vertical-align: middle;
        background: #28da99;
        border-radius: 4px;
        text-align: center;
        transition: background 0.5s;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -o-transition: background 0.5s;
      }
      .get-code-btn:hover {
        background: #36c792;
      }
      .psd-label {
        // width: 70px;
        // display: inline-block;
        font-size: 14px;
        color: #333333; // margin-right: 8px;
        // text-align: right;
      }
      input:focus {
        border: 1px solid #3cb1ff;
      }
      .old-psd-box {
        margin-top: 20px;
      }
      .account-box,
      .code-box,
      .old-psd-box,
      .psd-box,
      .confirm-psd-box {
        margin-left: 174px;
        color: #666666;
        font-size: 14px;
        position: relative;
      }

      .old-psd-box,
      .psd-box,
      .confirm-psd-box {
        margin-bottom: 14px;
      }
      .submit-btn,
      .cancel-btn {
        display: inline-block;
        width: 204px;
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
      }
      .submit-btn {
        background: #3cb1ff;
        border: 1px solid #3cb1ff;
        transition: background 0.5s;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -o-transition: background 0.5s;
      }

      .submit-btn:hover {
        background: #2ea5f5;
      }

      .cancel-btn {
        background: #ffffff;
        border: 1px solid #c7c7c7;
        color: #666666;
        margin-left: 24px;
        transition: background 0.5s;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -o-transition: background 0.5s;
      }
      .cancel-btn:hover {
        background: #f3f2f2;
        border: 1px solid #c7c7c7;
      }
      .div-1 {
        margin-top: 96px;
        color: #333333;
        font-size: 14px;
        text-align: center;
      }
      .div-2 {
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
        color: #333333;
      }
      .div-3 {
        text-align: center;
        margin-top: 10px;
        color: #cccccc;
        font-size: 14px;
        .time-show {
          margin-left: 4px;
        }
      }
    }
  }
}

.toast-box {
  font-size: 14px;
  color: #666666;
  text-align: center;
  border-radius: 4px;
  border: 1px solid red;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #ffeaeb;
  margin: 40px 232px 0;
  .toast-icon {
    margin-right: 10px;
    margin-bottom: -2px;
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}

.error-desc {
  color: #ff7477;
  font-size: 12px;
  position: absolute;
  left: 80px;
}

.toast-box1 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #28da99;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #dff9f0;
  margin: 30px auto;
  .toast-icon {
    margin-right: 6px;
  }
}

.toast-box2 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-radius: 4px;
  border: 1px solid red;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #feeaeb;
  margin: 30px auto;
  .toast-icon {
    margin-right: 6px;
    margin-bottom: -2px;
  }
}
</style>
