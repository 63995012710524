<template>
  <div class="container">
    <h2>联运业务介绍</h2>
    <h3>一、联运业务背景</h3>
    <p>
      家教机应用商店作为用户在使用家教机时获取应用直接且唯一的渠道，开展与开发者联合运营应用，为其提供接入、活动运营等服务。
    </p>
    <h3>二、联运业务特点</h3>
    <h4>1、精准的用户分发</h4>
    <p>
      面向全国家教机终端用户，根据应用面向的对应学段用户群体进行分发，有效触达用户。
    </p>
    <h4>2、开发者服务</h4>
    <p>提供帐号、支付等产品基础能力，人工审核快速上线。</p>
    <h4>3、 联运服务</h4>
    <p>提供优质资源位、专题推荐、榜单曝光、数据调优、搜索优化、系统推送等。</p>
    <h4>4、 营销活动</h4>
    <p>针对联运应用属性和特色开展线上活动。</p>
    <h3>三、联运合作模式</h3>
    <h4>1、应用内购买</h4>
    <p>
      用户在应用商店内安装应用后，在使用应用过程中，对应用内内容或服务产生购买行为。
    </p>
    <h3>四、联运整体流程</h3>
    <img
      src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/150834071_b2f1bcac12a1a734.png"
      alt=""
    />
    <p class="desc">注：联运SDK持续开发中，即将上线，敬请期待。</p>
    <h3>五、开放资源</h3>
    <div>
      <p>当前平台资源分级：分为黄金资源和持续资源。</p>
      <p>黄金资源：曝光与导量高，提升运营峰值效果好。</p>
      <p>持续资源：曝光与导量稳定，持续提升运营平均值与转化率。</p>
    </div>
    <img
      src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/152212032_d41c9e1714958f9c.png"
      alt=""
    />
    <h3>六、资源使用示例</h3>
    <div class="example">
      <div class="example-item">
        <img
          src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/150833645_ca3872c896970bd9.png"
          alt=""
        />
        <p>闪屏</p>
      </div>
      <div class="example-item">
        <img
          src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/150833674_62756b733adaba13.png"
          alt=""
        />
        <p>首页顶部banner</p>
      </div>
      <div class="example-item">
        <img
          src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/150833722_c299c6851f396d0b.png"
          alt=""
        />
        <p>“发现精彩”主题模块</p>
      </div>
      <div class="example-item">
        <img
          src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/150833978_2ff6a116cdbb94fa.png"
          alt=""
        />
        <p>搜索页</p>
      </div>
      <div class="example-item">
        <img
          src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/150834010_778ebf586eeb89b3.png"
          alt=""
        />
        <p>悬浮广告位</p>
      </div>
      <div class="example-item">
        <img
          src="https://common-pic-dn.eebbk.net/common-pic/2019/09/05/150834041_c98c73e84ae6b473.png"
          alt=""
        />
        <p>排行榜</p>
      </div>
    </div>
    <h3>七、联运合作</h3>
    <div>
      <p>商务联系方式</p>
      <p>联系人：魏超男</p>
      <p>微信：jinyan904</p>
      <p>邮箱：weichaonan@eebbk.com</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  overflow: hidden;
  & > img {
    margin: 10px 0;
    width: 100%;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 100;
  }
  p {
    color: #666;
    font-size: 14px;
  }
  h3 + p {
    text-indent: 2em;
  }
  h3 + div {
    & > p {
      text-indent: 2em;
    }
  }
  h4 {
    margin: 5px 0;
    text-indent: 1em;
    font-weight: 100;
    font-size: 16px;
  }
  h4 + p {
    text-indent: 3em;
  }
  .desc {
    text-align: right;
  }
  .example {
    & > .example-item {
      display: inline-block;
      width: 30%;
      margin-right: 3%;
      margin-bottom: 10px;
      img {
        width: 100%;
        border-radius: 5px;
      }
      p {
        text-align: center;
        color: #000;
      }
    }
  }
}
</style>
