<template>
  <section class="manage">
    <div class="main-body">
      <!-- 额外服务 -->
      <div v-if="serviceData.length > 0" class="extra-service">
        <h3>平台服务</h3>
        <div class="service-item-wrap">
          <div
            v-for="item in serviceData"
            :key="item.type"
            class="service-item"
            @click="toDataService(serviceTypeMap[item.serviceType])"
          >
            <img :src="serviceTypeMap[item.serviceType].img" alt />
            <span>{{ serviceTypeMap[item.serviceType].name }}</span>
            <img
              v-if="item.serviceSwitchShow === 0"
              class="service-new"
              src="@/assets/img/new-service.png"
              alt
            />
          </div>
          <span class="more-service-come">更多服务敬请期待...</span>
        </div>
      </div>
      <div class="content-area">
        <div class="navigation">
          <div class="search">
            <input
              v-model="searchVal"
              placeholder="请输入完整的应用名或包名"
              class="input"
              @keyup.enter="onSearch"
            />
            <img src="@/assets/img/search-icon.png" @click="onSearch" />
          </div>
          <div class="select">
            <div class="select-wrapper">
              <span @click.stop="showOptions = !showOptions">
                {{ choosedType }}
                <i class="el-icon-arrow-down"></i>
              </span>
              <div v-if="showOptions" class="options">
                <span
                  v-for="item in options"
                  :key="item"
                  @click.stop="selectChange(item)"
                  >{{ item }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="divide-line"></div>
        <div v-if="apkList && apkList.length > 0" class="app-list">
          <template v-for="apk in apkList">
            <div :key="apk.id">
              <div class="app-item" @click="showDetail(apk.id)">
                <div class="app-icon">
                  <img :src="apk.apkIcon" />
                </div>
                <div class="app-info">
                  <div class="app-info-item">
                    <!-- <span>应用名</span> -->
                    <span style="width:200px">{{ apk.apkName }}</span>
                  </div>
                  <!-- <div class="app-info-item">
									<span>包名</span>
									<span>{{apk.apkPackageName}}</span>
                                </div>-->
                  <div class="app-info-item">
                    <span>版本名称:</span>
                    <span>{{ apk.apkVersionName }}</span>
                  </div>
                  <div class="app-info-item">
                    <span>更新时间:</span>
                    <span>{{
                      formatDate(new Date(apk.updateTime), 'yyyy-MM-dd')
                    }}</span>
                  </div>
                  <div v-if="apk.updateStatus == 1" class="app-info-item">
                    <p>
                      !
                      该版本不是应用最新版本，若不及时更新，可能会被合作接口的包替换
                    </p>
                  </div>
                </div>
                <div class="app-fit">{{ fitStore[apk.isFit] }}</div>

                <div :class="'app-status-' + apk.status" class="app-status">
                  {{ apk.statusName }}
                </div>

                <div
                  v-if="apk.status == 1||apk.status == 4"
                  class="app1-btn"
                  @click="preview"
                >
                  查看资料
                </div>
                <div v-else class="app-btn warning" @click.stop="update(apk.id)">编辑与更新</div>
                <div v-if="apk.status === 1" class="undo-btn" @click.stop="undo(apk.id)">撤销审核</div>

                <div
                  v-if="apk.subApkInfoList.length > 0"
                  class="show-more"
                  @click.stop="
                    showMore($event, apk.id, apk.subApkInfoList.length)
                  "
                >
                  展开
                  <img src="@/assets/img/drop-down-icon.png" class="down" alt />
                </div>
              </div>
              <div class="divide-line"></div>
              <div
                v-if="apk.subApkInfoList.length > 0"
                :id="'id' + apk.id"
                class="second"
              >
                <div
                  v-for="item in apk.subApkInfoList"
                  :key="item.id + item.apkPackageName"
                >
                  <div class="app-item" @click="showDetail(item.id)">
                    <div class="app-icon">
                      <img :src="item.apkIcon" />
                    </div>
                    <div class="app-info">
                      <div class="app-info-item">
                        <span style="width:200px">{{ item.apkName }}</span>
                      </div>
                      <div class="app-info-item">
                        <span>版本名称:</span>
                        <span>{{ item.apkVersionName }}</span>
                      </div>
                      <div class="app-info-item">
                        <span>更新时间:</span>
                        <span>{{
                          formatDate(new Date(item.updateTime), 'yyyy-MM-dd')
                        }}</span>
                      </div>
                      <div v-if="item.updateStatus == 1" class="app-info-item">
                        <p>
                          !
                          该版本不是应用最新版本，若不及时更新，可能会被合作接口的包替换
                        </p>
                      </div>
                    </div>
                    <div class="app-fit">{{ fitStore[item.isFit] }}</div>
                    <div
                      :class="'app-status-' + item.status"
                      class="app-status"
                    >
                      {{ statusList.get(item.status) }}
                    </div>
                    <div v-if="item.status ==1||item.status ==4" class="app1-btn" @click="preview(item.id)">
                      查看资料
                    </div>
                    <div v-else class="app-btn warning" @click.stop="update(item.id)">编辑与更新</div>
                    <div v-if="item.status == 1" class="undo-btn" @click.stop="undo(item.id)">撤销审核</div>
                  </div>
                  <div class="divide-line"></div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="no-list">没有数据</div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import serviceIconImg from '../../assets/img/service_icon.png';
import serviceVideoImg from '../../assets/img/service_video.png';
export default {
  name: 'Manage',
  data() {
    return {
      serviceTypeMap: {
        1: {
          id: 1,
          name: '数据服务',
          path: '/big_data',
          img:
            'https://common-pic-dn.eebbk.net/common-pic/2019/12/14/105109312_36052d2e333d7b39.png'
        },
        2: {
          id: 2,
          name: '详情定制',
          path: '/customize',
          img:
            'https://common-pic-dn.eebbk.net/common-pic/2019/12/14/105109332_ce3c0f824bb5f332.png'
        },
        3: {
          id: 3,
          name: '动态图标',
          path: '/service_icon',
          img: serviceIconImg
        },
        4: {
          id: 4,
          name: '视频介绍',
          path: '/service_video',
          img: serviceVideoImg
        }
      },
      searchVal: '', //搜索输入框值
      showOptions: false,
      //				choosedType: '全部',
      choosedType: '应用名',
      options: ['应用名', '包名'],
      isShow: false,
      fitStore: ['常规发布', '适配上传', ''],
      apkList: [],
      height: 0,
      statusList: new Map(),
      getApkListParams: {
        //请求列表的参数
        accountId: null,
        apkName: null,
        apkPackageName: null,
        status: null
      },
      // 新增service数据
      serviceData: []
    };
  },
  mounted() {
    this.isGetStatusList();
    this.getApkList();
    this.getServiceData();
  },
  methods: {
    preview() {
      //效果与进入详情页功能重复，不写
    },
    // 编辑与跟新
    update(index) {
      // console.log(index)
      this.$router.push({
        path: '/manage_update',
        query: {
          apkId: index
        }
      });
    },
    showDetail(apkId) {
      this.$router.push('/detail/' + apkId);
    },
    //点击搜索
    onSearch() {
      if (this.choosedType === '应用名') {
        this.getApkListParams.apkName = this.searchVal;
        this.getApkListParams.apkPackageName = '';
      } else if (this.choosedType === '包名') {
        this.getApkListParams.apkName = '';
        this.getApkListParams.apkPackageName = this.searchVal;
      }
      this.getApkList();
    },
    //下拉选项选择
    selectChange(item) {
      this.choosedType = item;
      this.showOptions = false;
      if (item === '全部') {
        this.searchVal = '';
        for (let key in this.getApkListParams) {
          this.getApkListParams[key] = null;
        }
        this.getApkList();
      }
    },
    //获取应用状态
    getStatusList() {
      this.$axios
        .get(`appManage/getStatusList`)
        .then(res => {
          if (res.data.stateCode === '0') {
            let _str = ''; //字符串方便SesstionStorge
            let _sz = []; //对象数组 转成 字符串数组
            res.data.data.map((item, index) => {
              this.statusList.set(item.id, item.name);
              _sz.push(JSON.stringify(item));
            });
            _str = _sz.join('@');
            //取到存到本地，其它页面方便取，减少请求
            sessionStorage.setItem('apkStatusList', _str);
            _sz = _str.split('@');
            _sz.map((item, index) => {
              item = JSON.parse(item);
            });
          }
        })
        .catch(() => {});
    },
    //判断是否获取过应用状态，没有就执行get，否则就从session里拿
    isGetStatusList() {
      let _str = sessionStorage.getItem('apkStatusList');
      if (!_str) {
        this.getStatusList();
      } else {
        let _sz = _str.split('@');
        _sz.map(item => {
          item = JSON.parse(item);
          this.statusList.set(item.id, item.name);
        });
      }
    },
    //获取应用列表
    getApkList() {
      this.$axios
        .post(`appManage/getApkList`, this.getApkListParams)
        .then(res => {
          if (res.data.stateCode === '0') {
            this.apkList = res.data.data;
            this.apkList.map(item => {
              item.statusName = this.statusList.get(item.status);
            });
          }
        })
        .catch(() => {});
    },
    // 点击撤销审核
    undo(obj){
      console.log(obj);
      this.$confirm('撤销审核后如需申请上架，需重新提交审核','确定要撤销该应用的审核吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        this.$axios.post(`/appManage/cancel/${obj}`,obj, {
          header: {
            "Content-Type": "application/json"
          }
        }
        ).then(res => {
          if(res.status == 200){
            if(res.data.stateCode==0){
              this.$alert(`撤销成功`, `${res.data.stateInfo}`, {
                confirmButtonText: '确定',
              });
              // this.getApkDetail();
              // this.$message.success("操作成功！");
              this.$message({
                type: 'success',
                message: '撤销成功!'
              });
              this.getApkList();
            }else{
              this.$message({
                type: 'warning',
                message: `撤销失败!${res.data.stateInfo}`
              });
              this.getApkList();
            };
          }else{
            this.$message({
              type: 'warning',
              message: `调用接口异常!`
            });
          }
        }).catch((error)=>{
          console.log(error)
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 展开
    showMore(e, id, length) {
      var element = null;
      if (e.target.children.length > 0) {
        element = $(e.target.children[0]);
      } else {
        element = $(e.target);
      }
      if (element.hasClass('rotate')) {
        element.addClass('rotate2');
        setTimeout(function() {
          $('.down')
            .removeClass('rotate')
            .removeClass('rotate2');
        }, 200);
        $('#id' + id)
          .addClass('active')
          .css('height', '0px');
      } else {
        $('.down').removeClass('rotate');
        $('.second')
          .removeClass('active')
          .css('height', '0');
        element.addClass('rotate');
        var height = length * 160 + 'px';
        $('#id' + id)
          .addClass('active')
          .css('height', height);
      }
    },
    // 获取服务信息
    getServiceData() {
      this.$axios.get('/appManage/getCooperationSwitchShow').then(res => {
        console.log(res);
        let data = [];
        res.data.data.map(item => {
          if (item.serviceSwitch) {
            data.push(item);
          }
        });
        this.serviceData = data;
      });
    },
    // 跳转至数据服务总览
    toDataService(item) {
      this.$axios.get('/appManage/updateCooperationSwitchShow', {
        params: { serviceType: item.id }
      });
      this.$router.push(item.path);
    }
  }
};
</script>

<style lang="less" scoped>
.main-body {
  background: #f2f2f2;
  width: 100%;
  min-height: 900px;
  position: relative;
  clear: both;
  padding-top: 50px;
  padding-bottom: 50px;
  .extra-service {
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 20px;
    padding: 30px 24px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    h3 {
      position: relative;
      font-size: 16px;
      padding-left: 9px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e5e5e5;
      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 4px;
        height: 16px;
        border-radius: 3px;
        background: #3cb1ff;
      }
    }
    .service-item-wrap {
      display: flex;
      .service-item {
        position: relative;
        display: flex;
        height: 140px;
        width: 230px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .service-new {
          position: absolute;
          top: 35px;
          right: 70px;
        }
        &:hover {
          img {
            transform: scale(0.8);
          }
        }
        img {
          transition: transform 0.5s;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 1px;
          height: 50px;
          margin: auto;
          background: #e5e5e5;
        }
        span {
          font-size: 14px;
        }
      }
      .more-service-come {
        display: inline-block;
        width: 140px;
        height: 140px;
        line-height: 140px;
        margin: 0 auto;
        font-size: 14px;
        color: #aaaaaa;
      }
    }
  }
  .content-area {
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    /*padding-top: 50px;*/
    /*padding-left: 40px;*/
    .divide-line {
      border-top: 1px solid #dddddd;
      margin-left: 40px;
    }
    .navigation {
      overflow: hidden;
      height: 80px;
      line-height: 80px;
      margin-left: 40px;
      .search {
        float: left;
        position: relative;
        input {
          width: 666px;
        }
        img {
          position: absolute;
          cursor: pointer;
          right: 15px;
          top: 29.5px;
        }
      }
      .select {
        float: right;
        width: 20%;
        .select-wrapper {
          cursor: pointer;
          padding-top: 22.5px;
          padding-bottom: 22.5px;
          height: 80px;
          box-sizing: border-box;
          line-height: 35px;
          // z-index: 999;
          span {
            display: block;
            width: 180px;
            position: relative;
            border-radius: 3px;
            border: 1px solid #dddddd;
            padding-left: 10px;
            i {
              position: absolute;
              top: 9.5px;
              right: 10px;
            }
          }
          .options {
            position: absolute;
            border: 1px solid #dddddd;
            z-index: 99;
            span {
              border: 0;
              border-bottom: 1px solid #dddddd;
              border-radius: 0;
              width: 178px;
              background-color: #f8f8f8;
              &:hover {
                background-color: #dfdfdf;
              }
              &:active {
                background-color: #c6c6c6;
              }
              &:last-child {
                border: 0;
              }
            }
          }
        }
        /*select{*/
        /*width: 180px;*/
        /*height: 35px;*/
        /*padding-left: 10px;*/
        /*}*/
      }
    }
    .app-list {
      cursor: pointer;
      .app-item {
        position: relative;
        min-width: 1200px;
        height: 145px;
        background: #ffffff;
        padding-top: 30px;
        clear: both;
        .app1-btn {
          background: #3cb1ff;
          width: 150px;
          height: 40px;
          line-height: 40px;
          color: #ffffff;
          font-size: 16px;
          text-align: center;
          letter-spacing: 1px;
          cursor: pointer;
          border-radius: 4px;
          transition: background 0.5s;
          -webkit-transition: background 0.5s;
          -moz-transition: background 0.5s;
          -o-transition: background 0.5s;
          position: absolute;
          top: 50px;
          right: 80px;
        }
        .undo-btn {
          height: 120px;
          line-height: 120px;
          width: 150px;
          height: 40px;
          line-height: 40px;
          color: #666666;
          border: #666666 1px solid;
          font-size: 16px;
          text-align: center;
          letter-spacing: 1px;
          cursor: pointer;
          border-radius: 4px;
          transition: background 0.5s;
          -webkit-transition: background 0.5s;
          -moz-transition: background 0.5s;
          -o-transition: background 0.5s;
          position: absolute;
          top: 100px;
          right: 80px;
          z-index: 100;
        }
        &:hover {
          background-color: #f8f8f8;
        }
        &:active {
          background-color: #eeeeee;
        }
        .app-icon,
        .app-info,
        .app-status,
        .app-btn {
          height: 85px;
          line-height: 85px;
          float: left;
        }
        .app-icon {
          img {
            height: 100%;
            vertical-align: top;
          }
          margin-left: 40px;
        }
        .app-info {
          line-height: 14px;
          margin-left: 30px;
          width: 420px;
          font-size: 14px;
          .app-info-item {
            height: 25%;
            line-height: 21.25px;
            margin-top: 5px;
            p {
              color: #ff7477;
              font-size: 10px;
            }
            span {
              color: #666666;
              &:first-child {
                display: inline-block;
                width: 80px;
                color: #333333;
              }
            }
          }
        }
        .app-status {
          width: 160px;
          height: 90px;
          font-size: 18px;
          color: #3cbeff;
          text-align: left;
          margin-top: 10px;
          position: absolute;
          top: 30px;
          right: 300px;
        }
        .app-fit {
          width: 160px;
          height: 90px;
          font-size: 16px;
          display: inline-block;
          color: #666666;
          text-align: left;
          line-height: 90px;
          position: relative;
          top: 5px;
          left: -100px;
        }
        .app-msg {
          color: #ff7477;
          font-size: 10px;
          float: left;
          padding-top: 40px;
          margin-left: 30px;
          span {
            display: inline-block;
            text-align: center;
            width: 12px;
            height: 12px;
            line-height: 12px;
            font-size: 10px;
            border-radius: 50%;
            color: #ff7477;
            border: 1px solid #ff7477;
          }
        }
        .app-btn {
          background: #3cb1ff;
          position: absolute;
          top: 20px;
          right: 80px;
          width: 150px;
          height: 40px;
          line-height: 40px;
          color: #ffffff;
          font-size: 16px;
          text-align: center;
          letter-spacing: 1px;
          cursor: pointer;
          border-radius: 4px;
          transition: background 0.5s;
          -webkit-transition: background 0.5s;
          -moz-transition: background 0.5s;
          -o-transition: background 0.5s;
          margin-top: 40px;
          left: right;
          &.warning {
            background: #ffc367;
            margin-right: 5px;
          }
        }
      }
    }
    .no-list {
      text-align: center;
      height: 64px;
      line-height: 64px;
      color: #666666;
      font-size: 16px;
    }
  }
}

.input:focus {
  border: 1px solid #3cb1ff;
}

.input {
  border: 0;
  outline: none;
  &::-webkit-input-placeholder {
    color: #aaaaaa;
    font-size: 14px;
  }
  &:-moz-placeholder {
    color: #aaaaaa;
    font-size: 14px;
  }
  &::-moz-placeholder {
    color: #aaaaaa;
    font-size: 14px;
  }
  &:-ms-input-placeholder {
    color: #aaaaaa;
    font-size: 14px;
  }
}

.input {
  border-radius: 20px;
  border: 1px solid #dddddd;
  height: 40px;
  padding-left: 20px;
}
.show-more {
  position: absolute;
  right: 0;
  font-size: 16px;
  color: #333333;
  bottom: 10px;
  right: 10px;
  margin-left: 0;
  img {
    margin-left: 10px;
  }
}
.main-body .content-area .app-list .second {
  height: 0px;
  overflow: hidden;

  transition: height 0.2s linear;
  background: #f8f8f8;
  .app-item {
    padding-top: 14px;
    min-width: 1200px;
    background: #f8f8f8;
  }
}
.show-more:hover {
  color: #3cbeff;
}

.active {
  margin-left: 0 !important;
}
.rotate {
  animation: rotate180 0.2s linear both;
}
@keyframes rotate180 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.rotate2 {
  animation: rotate360 0.2s linear both;
}
@keyframes rotate360 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
