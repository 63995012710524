<template>
  <div class="container">
    <h2>帐号与APP冻结原因及解冻申请流程</h2>
    <p>
      出于对用户体验、广大开发者利益和平台生态的考虑，如果问题严重，我们有可能会对开发者的资源进行冻结和帐号冻结，如果您的资源或者帐号被冻结，想申请解冻，请参阅如下内容：
    </p>
    <h3>一、资源冻结与帐号冻结的规则</h3>
    <h4>1、APP冻结</h4>
    <div>
      <p>
        资源若存在以下几种行为之一的，开发者的资源在被我们下架的同时有可能会被冻结：
      </p>
      <table cellspacing="0">
        <thead>
          <tr>
            <th style="width: 3%;">分类</th>
            <th style="width: 3%;">严重等级</th>
            <th>违规行为</th>
            <th style="width: 10%">冻结时限</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="35">资源冻结</td>
            <th rowspan="18">一级</th>
            <td>1、存在非法金钱交易或内容；</td>
            <td rowspan="18">永久冻结</td>
          </tr>
          <tr>
            <td>
              2、涉及黄赌毒、政治、宗教性质等敏感内容，或违反国家法律法规；
            </td>
          </tr>
          <tr>
            <td>3、应用内容带有诽谤、人身攻击或者侮辱个人或者团体的行为；</td>
          </tr>
          <tr>
            <td>
              4、应用中存在允许用户提交色情内容（如允许用户发布色情照片、或提供色情直播内容等；
            </td>
          </tr>
          <tr>
            <td>
              5、应用具有现金或者流通货币赌博功能（如六合彩、赌球等APP）；
            </td>
          </tr>
          <tr>
            <td>
              6、APP存在反政府、反社会或者不符合主流政治、存在国家法律禁止的行为；
            </td>
          </tr>
          <tr>
            <td>7、ROOT工具或存在ROOT功能的应用；</td>
          </tr>
          <tr>
            <td>8、应用在使用过程中非法ROOT手机</td>
          </tr>
          <tr>
            <td>9、需要获取ROOT权限后才可使用的应用</td>
          </tr>
          <tr>
            <td>10、APP功能为VPN工具或应用存在VPN功能；</td>
          </tr>
          <tr>
            <td>11、涉嫌能够篡改手机号码和电话、短信轰炸类应用；</td>
          </tr>
          <tr>
            <td>12、提供“种子搜索”的搜索引擎资源；</td>
          </tr>
          <tr>
            <td>13、谷歌安装、谷歌框架类应用；</td>
          </tr>
          <tr>
            <td>14、涉嫌非法篡改APP内容；</td>
          </tr>
          <tr>
            <td>15、伪造APP资质；</td>
          </tr>
          <tr>
            <td>
              16、APP为重新打包其他开发者的应用或破解、盗版，未获得版权所有者授权的应用；
            </td>
          </tr>
          <tr>
            <td>17、APP上线后，恶意打开服务器开关，使APP内容非法变更；</td>
          </tr>
          <tr>
            <td>
              18、APP更新后，恶意修改应用内容，且存在病毒、广告、积分墙、恶意扣费等行为；
            </td>
          </tr>
          <tr>
            <th rowspan="3">二级</th>
            <td>1、主管部门要求下架资源；</td>
            <td rowspan="3">不限时解冻（根据相关部门条例执行）</td>
          </tr>
          <tr>
            <td>2、社交媒体通报的恶意应用；</td>
          </tr>
          <tr>
            <td>3、APP存在盗版、侵权行为；</td>
          </tr>
          <tr>
            <th rowspan="10">三级</th>
            <td>1、利用不法行为诱导用户付费等恶意行为；</td>
            <td rowspan="10">冻结90天</td>
          </tr>
          <tr>
            <td>
              2、APP带有恶意积分墙、插屏/播、悬浮窗、通知栏广告，被发现2次或以上；
            </td>
          </tr>
          <tr>
            <td>
              3、应用上架后，存在打开广告恶意广告开关行为，被发现2次或以上；
            </td>
          </tr>
          <tr>
            <td>
              4、同版本APP上传被打回2次或以上，且未按照商店审核标准修改，恶意上传；
            </td>
          </tr>
          <tr>
            <td>
              5、未经用户同意，自动下载并安装与用户搜索内容不一致的应用，且下架警告后无修改继续上传
            </td>
          </tr>
          <tr>
            <td>
              6、未经用户同意，自动下载安装无关应用，且下架警告后无修改继续上传
            </td>
          </tr>
          <tr>
            <td>7、引导页内容，默认捆绑未知应用，翻过引导页后自动安装</td>
          </tr>
          <tr>
            <td>
              8、APP退出框，退出标识不明确，容易造成误点导致安装无关应用，且下架警告后无修改继续上传
            </td>
          </tr>
          <tr>
            <td>
              9、APP翻页或滑动时，未经用户同意自动安装无关应用，且下架警告后无修改继续上传
            </td>
          </tr>
          <tr>
            <td>
              10、下载的应用与安装的应用不一致，且下架警告后无修改继续上传，且下架警告后无修改继续上传
            </td>
          </tr>
          <tr>
            <th rowspan="4">四级</th>
            <td>1、APP付费功能异常，且验证属实，反馈后不积极配合；</td>
            <td rowspan="4">冻结30天</td>
          </tr>
          <tr>
            <td>
              2、资源上线后，私自打开审核标准不允许的开关，被发现2次或以上（如售彩类资源，上线后打开售彩功能；交友类资源上线后打开诱导付费开关）
            </td>
          </tr>
          <tr>
            <td>
              3、APP内存在暴露镜头、发布内容含有露骨文字，且下架警告后无修改继续上传；
            </td>
          </tr>
          <tr>
            <td>4、应用测试中或上架后，被检测为病毒资源，被发现2次或以上；</td>
          </tr>
          <tr>
            <td colspan="4">
              注：非永久冻结的APP在按要求进行整改，且保证不再违规后，可在冻结期满后申请解冻，若再次违规，将永久冻结
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>2、开发者帐号冻结</h4>
    <div>
      <table cellspacing="0">
        <tbody>
          <tr>
            <td rowspan="16" style="width: 3%;">开发者冻结</td>
            <td rowspan="16" style="width: 3%;">开发者不当行为条款</td>
            <td>1、上传违法资源</td>
            <td rowspan="11" style="width: 10%">永久冻结</td>
          </tr>
          <tr>
            <td>2、利用账号恶意攻击步步高家教机开放平台的</td>
          </tr>
          <tr>
            <td>
              3、APP开发者对步步高家教机应用商店已经明确有版权归属的APP私自进行破解、汉化、反编译、重新打包、换皮等二次开发后提交2次或以上，APP将被驳回且将被取消步步高家教机应用商店开发者资格；
            </td>
          </tr>
          <tr>
            <td>
              4、APP开发者一周内重复提交相似内容APP3款或同一开发者帐号一月内提交超过5款相似内容APP将被取消步步高家教机应用商店开发者资格；
            </td>
          </tr>
          <tr>
            <td>
              5、应用上线后开启恶意广告插件、病毒、彩票售彩等行为，且警告后无修改继续上传
            </td>
          </tr>
          <tr>
            <td>6、营业执照经营范围与APP内容不符，且批量上传应用；</td>
          </tr>
          <tr>
            <td>
              7、不同开发者帐号，使用相同法人、营业执照批量注册（注册账号达到2个或以上）；
            </td>
          </tr>
          <tr>
            <td>8、APP上线后，恶意打开服务器开关，使APP内容非法变更；</td>
          </tr>
          <tr>
            <td>
              9、APP更新后，恶意修改应用内容，且存在病毒、广告、积分墙、恶意扣费等行为；
            </td>
          </tr>
          <tr>
            <td>10、伪造资质；</td>
          </tr>
          <tr>
            <td>11、账号下资源涉嫌非法篡改APP内容</td>
          </tr>
          <tr>
            <td>
              12、APP开发者连续多次上传问题应用（不符合审核标准），且退回后未经修改恶意上传，将推迟APP审核；此恶意行为达3次以上
            </td>
            <td rowspan="5">冻结90天</td>
          </tr>
          <tr>
            <td>13、开发者帐号下APP利用不法行为诱导用户付费等恶意行为</td>
          </tr>
          <tr>
            <td>
              14、
              开发者帐号下APP带有恶意积分墙、插屏/播、悬浮窗、通知栏广告，被发现2次或以上
            </td>
          </tr>
          <tr>
            <td>
              15、
              开发者帐号下应用上架后，存在打开广告恶意广告开关行为，被发现2次或以上；
            </td>
          </tr>
          <tr>
            <td>
              16、同版本APP上传被打回2次或以上，且未按照商店审核标准修改，恶意上传；
            </td>
          </tr>
          <tr>
            <td colspan="4">
              注：非永久冻结的开发者在按要求进行整改，且保证不再违规后，可在冻结期满后申请解冻，若再次违规，将永久冻结
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3>二、 解冻申请流程</h3>
    <h4>1、 申请资源解冻</h4>
    <div>
      <ol>
        <li>
          要求：非永久冻结的APP按平台要求进行整改，且保证不再违规后，可在冻结期满后申请解冻，若再次违规，将永久冻结；
        </li>
        <li>
          申请方式：邮件申请，收件人：<span
            style="font-weight:bold; color: #000;"
            >weichaonan@eebbk.com</span
          >；
        </li>
        <li>邮件主题：申请“xx”APP 解冻；</li>
        <li>
          邮件内容：
          <ul>
            <li>申请解冻app名称、包名；</li>
            <li>申请解冻理由；</li>
            <li>申请解冻的辅助证明材料（如附件附上配合已修改的app）；</li>
            <li>
              声明承诺函（主体内容需包含违规原因、整改内容，不再违规声明、公司盖章等，格式自拟）;
            </li>
          </ul>
        </li>
        <li>审核处理时长：平台收到申请次日起1-3个工作日内。</li>
      </ol>
    </div>
    <h4>2、申请帐号解冻</h4>
    <div>
      <ol>
        <li>
          要求：非永久冻结的APP/开发者按平台要求进行整改，且保证不再违规后，可在冻结期满后申请解冻，若再次违规，将永久冻结；
        </li>
        <li>
          申请方式：邮件申请，收件人：<span
            style="font-weight:bold; color: #000;"
            >weichaonan@eebbk.com</span
          >；
        </li>
        <li>邮件主题：申请“xx”帐号 解冻；</li>
        <li>
          邮件内容：
          <ul>
            <li>申请解冻的开发者注册邮箱、昵称、公司名；</li>
            <li>申请解冻理由；</li>
            <li>
              声明承诺函（主体内容需包含违规原因、整改内容，不再违规声明、公司盖章等，格式自拟）；（<a
                style="color: skyblue"
                download="账号解封申诉承诺声明承诺函.docx"
                href="https://common-file-dn.eebbk.net/common-file/2020/07/10/143306171_1877f2e39be27fd4.docx"
                >点击这里下载声明模板</a
              >）
            </li>
            <li>
              开发者注册证明（营业执照和法人身份证正反面，此身份证应与国家企业信用信息公示系统上的法人的身份信息一致）
            </li>
          </ul>
        </li>
        <li>审核处理时长：平台收到申请次日起15日内，请您耐心等待回复。</li>
      </ol>
    </div>
    <h3>三、权益说明</h3>
    <p>
      本规则由步步高家教机开放平台拟定，最终解释权归步步高家教机开放平台所有。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  padding-bottom: 40px;
  color: #666;
  font-size: 14px;
  line-height: 30px;
  h2,
  h3,
  h4 {
    color: #333333;
    line-height: 60px;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 100;
  }
  p {
    color: #666;
    font-size: 14px;
  }
  p + div {
    padding-left: 2em;
  }
  p + div {
    & > p {
      text-indent: 2em;
    }
  }
  h3 + p,
  h4 + p {
    text-indent: 2em;
  }
  h3 + div,
  h4 + div {
    padding-left: 2em;
  }
  h4 {
    margin: 5px 0;
    text-indent: 1em;
    font-weight: 100;
    font-size: 16px;
  }
  h2 + div,
  h3 + div,
  h4 + div {
    & > p {
      text-indent: 2em;
    }
  }
  table {
    border-left: 1px solid #666;
    border-top: 1px solid #666;
    th,
    td {
      border-bottom: 1px solid #666;
      border-right: 1px solid #666;
      padding: 5px;
    }
  }
}
</style>
