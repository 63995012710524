<template>
  <section class="customize">
    <div class="body-wrap">
      <div class="main-body">
        <div class="url-navigation">
          <span class="back" @click="$router.back()">返回上一页</span> |
          <span class="current-page" @click="$router.replace('/service_icon')"
            >动态图标</span
          >
        </div>
        <div class="data-display">
          <h3>
            应用列表
            <a
              style="color: #3BBFFF;margin-right: 42px;font-weight: 100;"
              href="http://common-file-dn.eebbk.net/common-file/overwrite/%E5%95%86%E5%BA%97%E8%AF%A6%E6%83%85%E9%A1%B5%E5%88%B6%E4%BD%9C%E8%A7%84%E8%8C%83.psd"
              >动态图标设计规范及PSD模板下载</a
            >
          </h3>
          <div class="table">
            <div class="table-title">
              <div>应用名</div>
              <div>应用版本</div>
              <div>定制状态</div>
              <div>审核状态</div>
              <div>操作</div>
            </div>
            <template v-if="serviceData.length > 0">
              <template v-for="(item, index) in serviceData">
                <div
                  :key="item.id"
                  class="table-body"
                  :style="{
                    cursor: item.subApkList.length > 0 ? 'pointer' : 'unset'
                  }"
                  @click="() => openVersionList(index)"
                >
                  <div>
                    <img
                      v-if="item.checkStatus == 3"
                      style="width: 50px;"
                      :src="item.dynamicIconUrl"
                      alt=""
                    />
                    {{ item.apkName }}
                    <i
                      v-if="item.subApkList.length > 0"
                      class="el-icon-arrow-down"
                    ></i>
                  </div>
                  <div>{{ item.apkVersionName }}</div>
                  <div v-html="getCusomizeStatus(item.checkStatus)"></div>
                  <div>
                    <span v-html="getCheckStatus(item.checkStatus)"></span>
                    <el-tooltip
                      v-if="item.checkStatus == 2"
                      class="item"
                      effect="dark"
                      placement="top-start"
                    >
                      <img src="@/assets/img/service-intro.png" alt="" />
                      <div slot="content" style="width: 200px;">
                        {{ item.reason }}
                      </div>
                    </el-tooltip>
                  </div>
                  <div>
                    <span
                      v-if="item.checkStatus && item.checkStatus == 3"
                      @click="openConfirmDialog(item)"
                      >撤销定制</span
                    >
                    <span v-if="item.checkStatus == 1" style="color: #ccc;"
                      >/</span
                    >
                    <span
                      v-if="
                        !item.checkStatus ||
                          item.checkStatus == 4 ||
                          item.checkStatus == 2
                      "
                      @click="openUploadDialog(item)"
                      >上传图标</span
                    >
                  </div>
                </div>
                <template v-if="item.showVersionApp">
                  <div
                    v-for="item1 in item.subApkList"
                    :key="item1.id"
                    class="table-body"
                  >
                    <div>
                      <img
                        v-if="item1.checkStatus == 3"
                        style="width: 50px;"
                        :src="item1.dynamicIconUrl"
                        alt=""
                      />
                      {{ item1.apkName }}
                    </div>
                    <div>{{ item1.apkVersionName }}</div>
                    <div v-html="getCusomizeStatus(item1.checkStatus)"></div>
                    <div>
                      <span v-html="getCheckStatus(item1.checkStatus)"></span>
                      <el-tooltip
                        v-if="item1.checkStatus == 2"
                        class="item"
                        effect="dark"
                        placement="top-start"
                      >
                        <img src="@/assets/img/service-intro.png" alt="" />
                        <div slot="content" style="width: 200px;">
                          {{ item1.reason }}
                        </div>
                      </el-tooltip>
                    </div>
                    <div>
                      <span
                        v-if="item1.checkStatus && item1.checkStatus == 3"
                        @click="openConfirmDialog(item1)"
                        >撤销定制</span
                      >
                      <span v-if="item1.checkStatus == 1" style="color: #ccc;"
                        >/</span
                      >
                      <span
                        v-if="
                          !item1.checkStatus ||
                            item1.checkStatus == 4 ||
                            item1.checkStatus == 2
                        "
                        @click="openUploadDialog(item1)"
                        >上传图标</span
                      >
                    </div>
                  </div>
                </template>
              </template>
            </template>
            <p v-else style="line-height: 100px;">
              暂无数据
            </p>
          </div>
          <el-pagination
            v-if="serviceData.length > 0"
            class="pagination"
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="'是否撤销定制' + cancelItem.apkName"
      :visible.sync="shouldShowCancelModal"
      top="30vh"
      width="30%"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="shouldShowCancelModal = false">取 消</el-button>
        <el-button type="primary" @click="shouldShowCancelModal = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="shouldShowUploadModal" top="20vh" width="30%">
      <h4 slot="title" style="text-align: center">上传图片</h4>
      <!-- <div style="display: flex;justify-content: center">
        <span class="sexTitle"  v-if="ishaveSexiocn">上传失败：检测到此图片可能存在色情敏感信息</span>
      </div> -->
      <div style="display: flex;justify-content: center">
        <div class="imgurl">
          <p style="line-height: 50px;">尺寸：512x512</p>
          <div
            class="plus-upload"
            :class="{ 'start-upload': imgStart1, 'end-upload': imgEnd1 }"
            @click="uploadImg(1)"
          >
            <span v-if="imgUrl1" @click.stop="clearImgUrl(1)">x</span>
            <img v-if="imgUrl1" class="imgurl1" :src="imgUrl1" />
            <div v-else class="plus"></div>
          </div>
          <p style="margin-top: 15px;">
            请上传清晰的应用动态图标（GIF格式）
          </p>
        </div>
      </div>
      <div slot="footer" style="text-align: center;">
        <el-button type="primary" @click="submitAddData">提交审核</el-button>
        <el-button @click="shouldShowUploadModal = false">取 消</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
export default {
  name: 'Customize',
  data() {
    return {
      userInfo: {},
      // 查询结果总数
      totalCount: 0,
      // 页数
      pageNum: 1,
      // 每页显示条数，
      pageSize: 10,
      // 当前显示数据
      serviceData: [],
      // 撤销定制modal显隐
      shouldShowCancelModal: false,
      // 需要撤销定制的当前应用
      cancelItem: {},
      // 上传图片modal
      shouldShowUploadModal: false,
      imgUrl1: '',
      imgStart1: false,
      imgEnd1: false,
      // ishaveSexiocn:false
    };
  },
  computed: {
    // 计算当前显示数据在所有数据的开始索引 40   3    20
    startIndex() {
      return this.pageSize * (this.pageNum - 1);
    },
    endIndex() {
      if (this.pageSize * this.pageNum - 1 > this.totalCount - 1) {
        return this.totalCount - 1;
      }
      return this.pageSize * this.pageNum - 1;
    }
  },
  watch: {
    pageSize() {
      let maxPageNum = Math.ceil(this.totalCount / this.pageSize);
      if (this.pageNum > maxPageNum) {
        this.pageNum = maxPageNum;
      }
      this.getData();
    }
  },
  mounted() {
    // 权限鉴定
    this.$axios.get('/appManage/getCooperationSwitchShow').then(res => {
      if (!res.data.data[2].serviceSwitch) {
        // 无权限触发404
        this.$router.replace('/noPermisionForbiden403');
      }
    });
    this.getList();
  },
  methods: {
    // 改变每页显示数据条数
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize;
    },
    // 根据页码获取数据
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.getData();
    },
    // 从计算的索引替换当前显示数据
    getData() {
      this.serviceData = this.ALLQUERYDATA.slice(
        this.startIndex,
        this.endIndex + 1
      );
    },
    // 打开撤销定制确认弹框
    openConfirmDialog(item) {
      this.$confirm('确定要撤销' + item.apkName + '的动态图标吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios
          .post('/apkDynamicIconManage/cancelAppDynamicIcon?id=' + item.id)
          .then(() => {
            this.$message({
              type: 'success',
              message: '撤销成功!'
            });
            this.getList();
          });
      });
    },
    // 打开上传modal
    openUploadDialog(item) {
      this.uploadItem = item;
      this.shouldShowUploadModal = true;
      // this.imgUrl1 =  '',
      // this.ishaveSexiocn = false;
      // this['imgEnd' + 1] = false;
    },
    // 获取详情定制文本
    getCusomizeStatus(status) {
      if (status == 3) {
        return '<span>已定制</span>';
      }
      return "<span style='color: #ccc'>未定制</span>";
    },
    // 获取审核状态文本
    getCheckStatus(status) {
      switch (status) {
        case 1:
          return "<span style='color: #3CB1FF'>审核中</span>";
        case 2:
          return "<span style='color: red'>审核不通过</span>";
        case 3:
          return "<span style='color: rgb(96, 231, 114)'>审核通过</span>";
        default:
          return "<span style='color: #ccc'>/</span>";
      }
    },
    // 提交审核
    submitAddData() {
      this.$axios
        .post('/apkDynamicIconManage/addAppDynamicIcon', {
          ...this.uploadItem,
          dynamicIconUrl: this.imgUrl1
        })
        .then(res => {
          if (res.data.stateCode == 0) {
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.shouldShowUploadModal = false;
            setTimeout(() => {
              this.getList();
            }, 500);
          } else {
            this.$message({
              message: res.data.stateInfo,
              type: 'error'
            });
          }
        });
    },
    // 获取数据
    getList() {
      // 获取数据
      this.$axios
        .get('/apkDynamicIconManage/getAppDynamicIconList', {
          params: {
            pi: 1,
            ps: 1000
          }
        })
        .then(res => {
          this.$message.closeAll();
          this.ALLQUERYDATA = res.data.data.list;
          this.totalCount = res.data.data.list.length;
          this.getData();
          this.$forceUpdate();
        })
        .catch(() => {
          this.$message.closeAll();
          this.$message({
            message: '数据加载失败',
            duration: 1000
          });
        });
    },
    // 上传图片
    uploadImg(index) {
      if (this['imgUrl' + index]) {
        return;
      }
      let self = this;
      let inputEl = document.createElement('input');
      inputEl.setAttribute('type', 'file');
      inputEl.setAttribute('accept', 'image/gif');
      inputEl.click();
      inputEl.onchange = function() {
        // 开始上传
        let file = inputEl.files[0];
        if (file.size > 3145728) {
          self.$message({
            message: '图片超过限定大小，请上传大小为3MB以内的图片',
            type: 'error'
          });
          return;
        }
        self['imgStart' + index] = true;
        if (index === 2) {
          self.getFileUrl(file);
        }
        let formData = new FormData();
        formData.append('imgType', index);
        formData.append('file', file);

        self.$axios
          .post('/apkDynamicIconManage/uploadAppDynamicIcon', formData)
          .then(res => {
            if (res.data.stateCode == 0) {
              //  if(res.data.data.stateCode && res.data.data.stateCode == 1009) {
              //   let img = new Image();
              //   self.ishaveSexiocn = true;
              //   img.src = res.data.data.path;
              //   img.onload = function() {
              //     self['imgEnd' + index] = true;
              //     self['imgStart' + index] = false;
              //     self['imgUrl' + index] = res.data.data.path;
              //     };
              //   } 
              let img = new Image();
              // img.crossOrigin = "Anonymous";
              img.src = res.data.data.path;
              img.onload = function() {
                self['imgEnd' + index] = true;
                self['imgStart' + index] = false;
                self['imgUrl' + index] = res.data.data.path;
              };
            }else {
              self['imgEnd' + index] = false;
              self['imgStart' + index] = false;
              self.$message({
                message: res.data.stateInfo,
                type: 'error'
              });
            }
          });
      };
    },
    // 删除重新上传
    clearImgUrl(index) {
      this['imgUrl' + index] = '';
      this['imgEnd' + index] = '';
      this['imgStart' + index] = '';
      // if(this.ishaveSexiocn == true) {
      //   this.ishaveSexiocn =   false;
      // }
    },
    openVersionList(index) {
      if (this.serviceData[index].subApkList.length > 0) {
        this.serviceData[index].showVersionApp = !this.serviceData[index]
          .showVersionApp;
        this.$set(this.serviceData, index, this.serviceData[index]);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.body-wrap {
  background: #f2f2f2;
  .main-body {
    width: 100%;
    min-height: 900px;
    position: relative;
    clear: both;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 1200px;
    margin: 0 auto;
    .url-navigation {
      font-size: 16px;
      padding-bottom: 20px;
      .back {
        color: #666;
        cursor: pointer;
      }
      .current-page {
        color: blue;
        cursor: pointer;
      }
    }
    .data-display {
      background: #fff;
      h3 {
        position: relative;
        font-size: 16px;
        padding-top: 29px;
        padding-left: 42px;
        padding-bottom: 35px;
        display: flex;
        justify-content: space-between;
        &::before {
          content: '';
          position: absolute;
          top: 32px;
          left: 31px;
          width: 4px;
          height: 16px;
          border-radius: 3px;
          background: #3cb1ff;
        }
      }
      .table {
        width: 1115px;
        margin: 0 auto;
        text-align: center;
        border-radius: 5px;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        box-sizing: border-box;
        * {
          box-sizing: border-box;
        }
        .table-title {
          display: flex;
          font-size: 18px;
          background: #f0f4f5;
          div {
            position: relative;
            line-height: 70px;
            flex: 0 0 auto;
            width: 20%;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
        }
        .table-body {
          display: flex;
          font-size: 14px;
          div {
            position: relative;
            display: block;
            flex: 0 0 auto;
            width: 20%;
            line-height: 70px;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            &:last-of-type {
              color: #3cb1ff;
              cursor: pointer;
              &:hover {
                color: #3991f4;
              }
            }
            img {
              vertical-align: middle;
              margin-top: -3px;
              margin-left: 3px;
            }
          }
        }
      }
      .pagination {
        width: 1115px;
        margin: 0 auto;
        padding: 21px 0 29px;
        text-align: right;
      }
    }
  }
}
.imgurl {
  text-align: center;
  .plus-upload {
    position: relative;
    margin: auto;
    width: 130px;
    height: 130px;
    border-radius: 10px;
    border: 1px dashed #ccc;
    transition: all 0.3s linear;
    text-align: center;
    cursor: pointer;
    &:hover {
      border-color: #3cb1ff;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 10%;
      right: 10%;
      width: 0%;
      height: 3px;
      border-radius: 3px;
      background: #94e594;
      transition: all 1s linear;
    }
    &.start-upload {
      &::after {
        width: 70%;
      }
    }
    &.end-upload {
      &::after {
        width: 80%;
        transition: all 0.3s linear;
      }
    }
    .plus {
      position: absolute;
      width: 100%;
      height: 100%;
      &::before {
        content: ' ';
        position: absolute;
        width: 2px;
        height: 40px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #ccc;
      }
      &::after {
        content: ' ';
        position: absolute;
        height: 2px;
        width: 40px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #ccc;
      }
    }
    .imgurl1 {
      width: 60%;
      height: 60%;
    }
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    span {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 13px;
      font-size: 12px;
      border-radius: 50%;
      border: 1px solid #ccc;
      box-sizing: border-box;
      transition: all 0.3s linear;
      cursor: pointer;
      &:hover {
        background: red;
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
.sexTitle {
  color: red;
}
</style>
