<template>
  <div class="content-right">
    <div class="content-title">应用审核FAQ</div>
    <div class="content">
      <p class="content-big-title">1、APP为什么一直显示审核中？</p>
      <p class="content-small-title">
        步步高家教机开发者平台上班期间审核周期为两个工作日，请耐心等待。
        若超过两个工作日还未通过/打回请联系在线客服咨询。
      </p>

      <p class="content-big-title">2、上传时传错了资料怎么办？</p>

      <p class="content-small-title">
        1）步步高家教机开发者平台上班期间审核周期为两个工作日，
        可等待上架后立马编辑更新资料。
      </p>
      <p class="content-small-title">
        2）可联系步步高商务撤销审核，修改资料后再重新上传。
      </p>
      <p class="content-big-title">3、上传时传错了APK怎么办？</p>

      <p class="content-small-title">
        请马上联系步步高商务说明原因，我们会联系测试人员为您打回。
      </p>
      <p class="content-big-title">4、审核未通过怎么办？</p>

      <p class="content-small-title">
        请根据审核人员的打回理由进行修改，修改完成后重新上传即可。
      </p>
      <p class="content-big-title">
        5、“一元夺宝”类或含有“一元夺宝”性质的资源为什么不能通过审核？
      </p>
      <p class="content-small-title">
        因“夺宝”类资源被国家定性为赌博软件。根据国家相关法律规定，软件商店不收录“一元夺宝”类或含有“一元夺宝”性质的资源。
      </p>
      <p class="content-big-title">
        6、应用含有售彩功能的彩票、双色球为什么审核不通过？
      </p>
      <p class="content-small-title">
        因国家政策暂未开放发互联网彩票，故商店暂不收录已开放售卖入口（可充值购买和提现）的互联网彩票类APP；
      </p>
      <p class="content-big-title">
        7、为什么不收录直播类资源含有牛牛、扎金花内容的资源？
      </p>
      <p class="content-small-title">
        因含有斗牛牛、扎金花等内容涉嫌存在赌博行为，故主、辅功能含有该内容的资源商店不收录；
      </p>
      <p class="content-big-title">
        8、抢红包、多开类、刷赞类资源为什么不能被推荐？
      </p>
      <p class="content-small-title">
        因抢红包、多开类、刷赞类应用在使用时可能出现诱导付费、微信或QQ账号被封、手机卡顿的问题，故不推荐
      </p>

      <p class="content-big-title">
        9、为什么通过转发或阅读文章、新闻、资讯，以及观看视频赚取零花钱或积分的应用不推荐？
      </p>
      <p class="content-small-title">
        因商店接到大量用户反馈，通过转发或阅读文章、新闻、资讯，以及观看视频赚取零花钱或积分的应用可能存在欺诈用户的行为，为提供给用户绿色的下载环境，故不推荐；
      </p>
      <p class="content-big-title">
        10、为什么主功能为VPN或APP含有VPN功能的资源不能通过？
      </p>
      <p class="content-small-title">
        根据国家相关法律法规，各大分发平台不能收录VPN工具或APP功能含有VPN功能的应用；
      </p>
      <p class="content-big-title">
        11、为什么需要硬件配套才能使用的资源在商店不能推荐？
      </p>
      <p class="content-small-title">
        因在测试过程中，测试人员无法找到相关硬件进行配套测试，为保护用户利益故不推荐
      </p>
      <p class="content-big-title">
        12、为什么需提供特殊证件或特殊账号才能测试的资源在商店不能推荐？
      </p>
      <p class="content-small-title">
        因测试人员根据测试账号测试时可正常进入使用，但无法确保使用特殊证件在注册过程中出现问题，为确保用户使用安全，故不作推荐
      </p>
      <p class="content-big-title">
        13、主功能需付费的应用为什么不作推荐或被打回？
      </p>
      <p class="content-small-title">
        主功能必须付费才能使用的应用，因测试人员无法对内容进行审核，故打回处理。建议在附加说明提供相关测试账号，方便审核，但原则上不推荐
      </p>

      <p class="content-big-title">
        14、婚恋交友类应用主功能需付费才能测试为什么被打回或不推荐？
      </p>
      <p class="content-small-title">
        因大部分婚恋交友类应用主功能使用美女打电话，接电话需付费的方式诱导用户付费等或通过程序伪装美女诱导聊天，诱导用户付费，严重损害用户利益，故商店不收录；
      </p>
      <p class="content-small-title">
        婚恋交友类应用非主功能付费且不存在诱导行为的应用可作收录，但原则上不推荐
      </p>
      <p class="content-big-title">
        15、桌面、主题、锁屏平台类资源为什么不推荐，且被搜索屏蔽？
      </p>
      <p class="content-small-title">
        为保证用户手机稳定，故桌面、锁屏、主题加入了搜索全称可见，防止第三方应用篡改手机桌面，只有在用户主动搜索的情况下才会出现。
      </p>
      <p class="content-big-title">
        16、为什么抓娃娃类资源分类不见以及精准搜索才能被搜索到？
      </p>
      <p class="content-small-title">
        因该类型资源用户投诉较多，为保护用户切身利益，商店不主动推荐该类型资源且只有在用户主动搜索的情况下出现。
      </p>
      <p class="content-big-title">
        17、为什么APP为主功能为返利、优惠券类资源分类不可以见，但有小部分仍被推荐？
      </p>
      <p class="content-small-title">
        1）因该类型资源用户投诉较多（马甲包、模板资源），且大部分资源功能、内容相似，为了给提供用户优质搜索环境，故被系统判定为模板类的资源将不主动推荐；
      </p>
      <p class="content-small-title">
        2）非模板类型的APP，将根据算法自行计算决定是否推荐。
      </p>
      <p class="content-big-title">
        18、为什么开发者账号下资源被判定为重复提交功能相似、相同应用？
      </p>
      <p class="content-small-title">
        在商店后台上传的资源，系统会自动判断APP账号下资源功能、内容是否类似，如存在功能、内容类似就会报出。
      </p>
      <p class="content-big-title">
        19、我的APP为独立开发，但是为什么被判定为套用模板类资源？
      </p>
      <p class="content-small-title">
        在商店后台上传的资源，系统自行判断上传APP与模板库里的APP进行对比，如果相似度较高，则会判定为模板类资源。
      </p>

      <p class="content-big-title">
        20、为什么不收含有ROOT功能或需要ROOT才能使用的应用？
      </p>
      <p class="content-small-title">
        因含有ROOT功能或需要ROOT才能使用的应用，严重影响手机性能，以及危及用户手机安全，故不收录，一经发现则永久冻结处理。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      color: #333333;
    }
    .span1 {
      color: red;
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
