<template>
  <section class="container">
    <div :class="['content-right', isOverflow == 1 ? 'isOverflow' : '']">
      <div class="content-title" v-if="isOverflow == 2">
        步步高开发者平台开发者服务协议
      </div>
      <div class="content">
        <p>
          <span>导言</span>
        </p>
        <p>
          欢迎使用步步高开发者服务平台。
        </p>
        <p>
          <span
            >为使用本平台提供的服务，您应当阅读并遵守本《步步高开发者平台开发者服务协议》。</span
          >
        </p>
        <p>
          <strong
            >请您务必审慎阅读、充分理解各条款内容，特别是限制或排除您权利、限制或免除我们责任、个人信息保护、帐号规则、法律适用和争议解决条款（包括管辖条款）以及其它以加粗、加黑和/或加下划线等显著形式提示您注意的重要条款，提示您重点阅读。除非您已阅读并接受本协议所有条款，否则请您立即停止使用我们的产品和服务。如您不同意本协议，我们将无法为您正常提供服务。</strong
          >
        </p>
        <p>
          <strong
            >请您认真阅读本协议条款并按照页面提示完成全部注册和/或登录程序，您在进行注册和/或登录程序的过程中点击“同意”按钮即表示您已阅读并同意接受本协议及其他与本协议相关协议项下全部条款的约束。如您未进行注册和登录程序，但您已使用本平台和服务行为即视为您已阅读并同意接受本协议及其他与本协议相关协议项下全部条款的约束。
          </strong>
        </p>
        <p>
          <span>1. 术语定义</span>
        </p>
        <p>
          <span
            >1.1
            开发者：指在本平台经有效注册、申请后，将其享有相应权利的各种应用接入我们开发者平台而向用户提供各种服务的自然人、法人或其他组织（简称为“您”或“开发者”）。</span
          >
        </p>
        <p>
          <span
            >1.2
            应用：指由开发者开发或开发者经权利人授权，通过本平台向用户提供各种服务的应用程序及相关服务，包括但不限于游戏类服务、工具类服务、电子商务类服务、视频或音乐类服务等现存的各种服务及今后可能出现的各种服务。
          </span>
        </p>
        <p>
          <span>1.3公司：指合法运营本平台的公司，包括。</span>
        </p>
        <p>
          <span
            >1.4
            开发者平台：指由我们所拥有、控制、经营的步步高官网（网址为：http://www.eebbk.com）下属的开发者平台子页面（网址为
            https://dev.eebbk.com/index）（以下简称为“开发者平台”或“本平台”）。
          </span>
        </p>
        <p>
          <span
            >1.5
            用户个人信息：是指用户在开发者平台中产生的与用户相关的数据，包括但不限于用户提交的数据、用户操作行为形成的数据及各类交易数据等。“用户个人信息”的所有权及其他相关权利属于我们，且是我们的商业秘密，依法属于用户享有的个人信息权益等相关权利除外。
          </span>
        </p>
        <p>
          <span
            >1.6
            开发者平台运营数据：是指用户、开发者在开发平台、应用商店以及应用中产生的所有数据，包括但不限于用户或开发者提交的数据、操作行为形成的数据及各类交易数据等。该等数据的所有权及其他相关权利属于我们，且是我们的商业秘密，依法属于用户或开发者享有的相关权利除外。
          </span>
        </p>
        <p>
          <span
            >1.7 本平台规则：是指本《开发者服务协议》以及
            <span class="goToBlank" @click="$goToBlank('check_rule')"
              >《应用审核规范》</span
            >
            <span class="goToBlank" @click="$goToBlank('check_quality')"
              >《应用资质审核规范》</span
            >
            <span class="goToBlank" @click="$goToBlank('check_measure')"
              >《开发者帐号与应用处置原因及措施》</span
            >和
            <span class="goToBlank" @click="$goToBlank('register_convention')"
              >《应用商店平台开发者合规公约》</span
            >
            等本平台其他相关协议、规定及其修改。</span
          >
        </p>
        <p>
          <span>2. 开发者的权利和义务</span>
        </p>
        <p>
          <span>2.1 账户注册</span>
        </p>
        <p>
          <span
            >2.1.1
            您应当通过本平台或我们其他指定途径注册开发者账户（以下简称“账户”）以成为开发者，开发者帐户一经注册成功，账户名不得变更，且该账户不可转让、不可赠与、不可继承等。
          </span>
        </p>
        <p>
          <span
            >2.1.2
            您注册账号时，应使用您拥有合法使用权的电子邮箱进行注册，并遵守该第三方电子邮箱服务方的相关协议、规则以及本平台规则等约束。
          </span>
        </p>
        <p>
          <span
            >2.1.3
            您不得违反本协议约定将您的账户用于其他目的。否则，我们有权随时单方限制、中止或终止向您提供本服务，且未经我们同意您不得再次使用本服务。
          </span>
        </p>
        <p>
          <span
            >2.1.4
            您注册的账户名及密码，是您登录及使用本平台的凭证。您应当做好账户名、密码以及进入和管理本服务中的各类产品与服务的口令、密码等的保密措施。因您采取的保密措施不当或您的其他行为，致使上述口令、密码等丢失或泄漏所引起的一切损失和后果，均由您自行承担。
          </span>
        </p>
        <p>
          <span
            >2.1.5
            您可以根据自己的需求，在注册本平台账号后，在该账号下添加协作者，授权其进行您指定的操作。
          </span>
        </p>
        <p>
          <span
            >2.1.6
            您保证，您在本平台上进行包括但不限于注册账号、提交相应资质材料、确认和同意本平台规则、选择具体服务类别以及进行费用结算等事项对您均有约束力，且均是您自行或您授权他人进行的行为。同时，您承担以该注册账号所作出的全部行为的法律责任。</span
          >
        </p>
        <p>
          <span
            >2.1.7
            若您发现有他人冒用或盗用您的账号及密码或任何其他未经您合法授权而使用您账号情形，您应立即以有效方式通知我们（包括但不限于提供您的身份信息和相关身份资料、相关事实情况及您的要求等）。我们收到您的有效请求并核实身份后，会根据不同情况采取相应措施。若您提供的信息不完整导致我们无法核实您的身份或我们无法判断您的需求，进而导致我们无法进行及时处理，给您带来的损失，所有责任由您应自行承担。</span
          ><strong
            >同时，您理解并同意，我们对您的请求采取措施需要合理期限，对于您通知我们以及我们根据您的有效通知采取措施之前，您所遭受的所有损失，我们不承担任何责任。</strong
          ><span> </span>
        </p>
        <p>
          <span>2.2 资质材料</span>
        </p>
        <p>
          <span
            >2.2.1
            您保证：您使用本服务、接入和运营应用已获得相关合法资质或通过了相关政府部门的审核批准；您提供的主体资质材料、应用资质等相关资质和/或证明以及其他任何文件等信息真实、准确、完整，并在该等信息发生变更后，及时进行更新；您具备履行本协议项下各项义务的行为能力；您的任何行为不违反任何对您的有约束力的法律文件的规定。否则，您应立即停止使用我们提供的相关服务，且应独自承担由此带来的一切责任及给用户、我们所造成的全部损失。</span
          >
        </p>
        <p>
          <span
            >2.2.2
            您保证：您会依法及按照本平台要求提交使用本服务所必需的真实、准确且经过您签字（或盖章）确认的主体资质材料、相关资质材料以及联系人姓名（名称）、地址、电子邮箱等相关资料。</span
          >
        </p>
        <p>
          <span
            >2.2.3
            您保证：您在开发者平台上发布的应用所提供的各种服务依法已经具有相关的合法资质或获得了有关部门的许可或批准，并会向我们提交相关资质或证明文件</span
          ><strong
            >（您提交相关应用资质时，请参照
            <span class="goToBlank" @click="$goToBlank('check_quality')"
              >《应用资质审核规范》</span
            >及其更新版本）。</strong
          >
        </p>
        <p>
          <span
            >2.2.4
            您保证：您在开发者平台上发布的应用提供各种服务的行为符合相关法律法规以及本平台规则的规定，也不会侵犯任何人的合法权益，同时，您会依照法律法规、合同约定以及我们的要求提供版权、专利权等相关证明文件。
          </span>
        </p>
        <p>
          <span
            >2.2.5
            您保证：您发布于开发者平台的应用系由您独自开发或与第三方合作开发或已获得软件权利人的完整授权，您对该应用拥有完整的处分权且不存在任何知识产权纠纷和权利行使障碍。您的应用不得使用第三人享有合法权益的知识产权，包括但不限于将他人享有知识产权和在先权利的作品等作为应用的组成元素。本平台在审查批准发布应用时，会默认您拥有该应用的所有相关合法权利。</span
          ><span><strong>任何第三方就应用或/</strong></span
          ><strong
            >和应用的组成元素向我们提出侵权等异议或投诉的，我们有权立即对您的应用采取下架、删除等处置措施并对您的账号进行处置（相关处置措施见<span
              class="goToBlank"
              @click="$goToBlank('check_measure')"
              >《开发者帐号与应用处置原因及措施》</span
            >）</strong
          ><span>。</span>
        </p>
        <p>
          <strong
            >您保证：我们或/和用户不会因为使用您提供的应用及服务而遭受第三方的侵权之诉或承担任何法律责任，我们和用户使用您的应用和服务所遭受的任何损失将由您负责全额赔偿。
          </strong>
        </p>
        <p>
          <span>2.3 服务费用</span>
        </p>
        <p>
          <span
            >2.3.1
            您因使用我们提供的相关服务（包括但不限于产品、广告推广服务等），产生的所有服务费用由您自行承担，您应按照相关协议以及本平台规则支付费用，否则，我们有权拒绝提供或/和立即停止提供相关服务。
          </span>
        </p>
        <p>
          <span>2.3.2 </span
          ><strong
            >我们可能根据实际需要对收费服务的收费标准、方式等收费内容进行修改和变更，前述收费内容修改或变更前，我们将在相应服务页面进行通知或公告。如果您不同意上述修改和变更，则应停止使用相应服务，否则，该等收费内容修改或变更后的版本发布后，您的任何继续使用行为即视为您同意上述修改和变更。</strong
          >
        </p>
        <p>
          <span>2.3.3 </span
          ><strong
            >您若对我们提供的收费标准、服务使用期限等费用结算事项的内容有异议的，应在收到费用结算事项内容的次月前5个工作日内以书面形式告知我们，我们收到您的书面告知后会进行核实，否则，视为您认可我们提供的费用结算事项的结果，双方应按照我们提供的费用结算事项的内容进行费用的结算及支付。</strong
          >
        </p>
        <p>
          <strong
            >您收到收到费用结算事项内容之日起5个工作日内，应当对无异议的部分费用进行结算和支付，否则，我们有权要求您就该迟延支付部分费用向我们支付违约金，该违约金以未付费用的千分之一按日支付。</strong
          >
        </p>
        <p>
          <span>2.4 应用要求</span>
        </p>
        <p>
          <span
            >2.4.1
            您应自行负责您的应用的创作、开发、编辑、加工、修改、测试、运营及维护等工作，并且自行承担相应的费用。
          </span>
        </p>
        <p>
          <span
            >2.4.2
            您的应用应符合相关法律法规、本平台规则以及相关技术规范和标准等，您应享有该应用的全部著作权及其他知识产权等合法权益，或您已取得相关权利人的完整授权。
          </span>
        </p>
        <p>
          <span
            >2.4.3
            您的应用应符合开发者平台对接入应用在技术、安全等方面的统一要求，以确保应用可以在开发者平台安全、稳定的运营。同时，为向用户提供优质的产品和服务，您应当在应用正式上线运营后，提供应用的持续更新，具体更新内容如下：
          </span>
        </p>
        <p>
          <span
            >（1）根据开发者平台用户的需求和用户量对应用进行优化开发，以更好地满足用户的需求，并提供运营活动和应用内容的版本更新补丁；
          </span>
        </p>
        <p>
          <span>（2）按要求对安全类异常进行反馈和修复；</span>
        </p>
        <p>
          <span
            >（3）对版本优化、BUG修补等做出不定时更新，您应提供有效的防范措施和解决方案，并应保证该等解决方案包括解决“BUG”所需的全部必要的详尽信息；
          </span>
        </p>
        <p>
          <span
            >（4）您在开发者平台的应用版本不能低于含您自己及其他第三方在内的任何合作渠道、平台或网站提供的最新版本；
          </span>
        </p>
        <p>
          <span
            >（5）防止应用、用户服务系统的服务器和技术设备免遭外挂攻击，如果一旦发现有外挂攻击，您应提供有效的防范措施和解决方案，并应保证该等解决方案包括全部必要的详尽信息以解决外挂问题。
          </span>
        </p>
        <p>
          <span>2.4.4 </span
          ><strong
            >您不得在应用内宣传与本应用无关的任何其他信息，包括但不限于广告、其他应用的产品信息和下载链接等（除非您和我们另有书面约定），也不得在应用内添加指向非我们拥有或控制的或非我们书面同意的网站链接。
          </strong>
        </p>
        <p>
          <span
            >2.4.5
            您的应用在开发者平台上在架期间，您需向用户提供及时有效的客服，客服形式包括但不限于通过明确且合理的方式告知用户客服渠道、提供QQ/电话/微信/邮件等，并自行承担客服费用。
          </span>
        </p>
        <p>
          <span
            >2.4.6
            您应当在应用中向用户和相关权利人提供投诉途径，确保用户和权利人在认为您侵犯其合法权益时可以向您主张权利。
          </span>
        </p>
        <p>
          <span>2.4.7 </span
          ><strong
            >针对您的应用的具体要求，除了本协议约定外，您还应当遵守本平台的《应用审核规范》等规定。</strong
          >
        </p>
        <p>
          <span>2.5 应用运营</span>
        </p>
        <p>
          <span>2.5.1 开发者平台、您的地位</span>
        </p>
        <p>
          <span>您同意和理解：</span>
        </p>
        <p>
          <span
            >（1）开发者平台是一个中立的平台服务提供者，仅向开发者提供信息存储空间、链接等中立的网络服务以及相关中立的技术支持服务，以供开发者在中立的开发者平台上自主发布、运营、推广其应用；</span
          >
        </p>
        <p>
          <span
            >（2）开发者的应用由开发者自主开发、独立运营并独立承担全部责任。我们不会、也不可能参与开发者应用的研发、运营等任何活动，我们也不会对开发者的应用进行任何的修改、编辑或整理；</span
          >
        </p>
        <p>
          <span
            >（3）因开发者应用及服务产生的任何纠纷、责任以及开发者违反相关法律法规或本协议约定或本平台规则引发的任何后果，均由开发者独立承担责任、赔偿所造成的一切损失，与我们无关。</span
          ><span><strong>如侵害到我们或他人</strong></span
          ><strong
            >合法权益的，开发者须自行承担全部责任和赔偿所造成的一切损失。</strong
          >
        </p>
        <p>
          <span
            >2.5.2您应自行按照相关法律法规运营您的应用、履行相关义务并自行承担全部责任，包括但不限于：</span
          >
        </p>
        <p>
          <span
            >（1）建立全天候24小时不间断的技术支持机制，并保证技术支持渠道的通畅，确保应用在运营期间一旦出现技术故障能第一时间获得提供支持和解决；
          </span>
        </p>
        <p>
          <span
            >（2）依照相关法律法规的规定，保留相应的访问、使用等日志记录；
          </span>
        </p>
        <p>
          <span
            >（3）国家有权机关向您依法查询相关信息时，应积极配合提供；
          </span>
        </p>
        <p>
          <span>（4）主动履行其他您应依法履行的义务。</span>
        </p>
        <p>
          <span>2.5.3 您保证：</span>
        </p>
        <p>
          <span
            >（1）您的应用、提供给用户的相关服务及发布的相关信息、内容等，不违反相关法律、法规、政策等的规定及本协议和本平台规则等，也不会侵犯任何人的合法权益；
          </span>
        </p>
        <p>
          <span
            >（2）您自行对您应用中由用户使用应用服务产生的内容（包括但不限于留言、消息、评论、账号名称、账号头像、账号签名等）负责，保证其不违反相关法律、法规、政策的规定以及公序良俗等。否则，您应及时采取删除、断开连接或其他有效措施。</span
          >
        </p>
        <p>
          <strong
            >您在本平台上传应用程序并提供相关产品及服务等行为，除了遵守本协议约定外，还应当遵守本平台的<span
              class="goToBlank"
              @click="$goToBlank('register_threeConvention')"
              >《小天才/步步高应用商店/第三方应用程序提供方内容守则公约》</span
            >
            之规定，该公约为本协议之一部分，与本协议具有同等法律约束力</strong
          >
        </p>
        <p>
          <span
            >（3）应用设计上应当重视用户体验，尊重用户知情权、选择权，应用服务应当坚持诚信原则，不误导、欺诈、混淆用户，尊重用户的隐私，不骚扰用户，不制造垃圾信息；
          </span>
        </p>
        <p>
          <span
            >2.5.4您不得从事任何包括但不限于以下的违反法律法规的行为，也不得为以下违反法律法规的行为提供便利（包括但不限于为您应用的用户的行为提供便利等）：</span
          >
        </p>
        <p>
          <span>（1）反对宪法所确定的基本原则的行为；</span>
        </p>
        <p>
          <span
            >（2）危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的行为；
          </span>
        </p>
        <p>
          <span>（3）损害国家荣誉和利益的行为；</span>
        </p>
        <p>
          <span>（4）煽动民族仇恨、民族歧视、破坏民族团结的行为； </span>
        </p>
        <p>
          <span>（5）破坏国家宗教政策、宣扬邪教和封建迷信的行为； </span>
        </p>
        <p>
          <span>（6）散布谣言、扰乱社会秩序、破坏社会稳定的行为； </span>
        </p>
        <p>
          <span
            >（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的行为；
          </span>
        </p>
        <p>
          <span>（8）侮辱或者诽谤他人、侵害他人合法权益的行为； </span>
        </p>
        <p>
          <span>（9）侵害他人知识产权、商业秘密等合法权利的行为； </span>
        </p>
        <p>
          <span>（10）恶意虚构事实、隐瞒真相以误导、欺骗他人的行为； </span>
        </p>
        <p>
          <span>（11）发布、传送、传播广告信息及垃圾信息； </span>
        </p>
        <p>
          <span>（12）其他法律法规禁止的行为。</span>
        </p>
        <p>
          <span
            >2.5.5您不得从事以下行为，也不得为以下行为提供便利（包括但不限于为您的用户的行为提供便利等）：</span
          >
        </p>
        <p>
          <span
            >（1）删除、隐匿、改变开发者平台显示或其中包含的任何专利、著作权、商标或其他所有权声明；
          </span>
        </p>
        <p>
          <span
            >（2）对我们提供的基础环境进行修改和删除或以任何方式干扰或企图干扰我们任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等；
          </span>
        </p>
        <p>
          <span
            >（3）在未获得我们书面许可的情况下，以任何方式使用我们URL地址、技术接口等；
          </span>
        </p>
        <p>
          <span
            >（4）在未经过用户同意的情况下，向任何其他用户等第三方显示或以其他任何方式提供该用户的任何信息；
          </span>
        </p>
        <p>
          <span
            >（5）在没有获得用户明示同意的情况下直接联系用户或向用户发布任何商业广告及骚扰信息；
          </span>
        </p>
        <p>
          <span>（6）为任何用户登录到本平台提供代理身份验证凭据； </span>
        </p>
        <p>
          <span
            >（7）提供跟踪功能，包括但不限于识别其他用户在个人主页上查看、点击等操作行为；
          </span>
        </p>
        <p>
          <span>（8）自动将浏览器窗口定向到其他网页、接口等； </span>
        </p>
        <p>
          <span>（9）未经我们授权获取对我们的产品或服务的访问权； </span>
        </p>
        <p>
          <span
            >（10）应用中含有计算机病毒、木马或其他恶意程序等任何可能危害我们或用户权益和终端信息安全等的内容，或被发现存在漏洞可能会导致被植入木马或钓鱼网页等安全威胁；
          </span>
        </p>
        <p>
          <span>（12）未经过审批私自开放高危端口/服务；</span>
        </p>
        <p>
          <span
            >（13）公开表达或暗示您与我们之间存在合作关系，包括但不限于声称相互持股、商业往来或合作关系等或声称我们对您的认可；
          </span>
        </p>
        <p>
          <span
            >（14）利用开发者平台漏洞进行恶意行为，包括但不限于发布违法违规信息、恶意劫持用户信息、捆绑软件安装、欺诈用户等；
          </span>
        </p>
        <p>
          <span>（15）出现公网IP裸露情况或发生安全入侵类故障； </span>
        </p>
        <p>
          <span>（16）其他我们认为不应该、不适当的行为、内容等情形。 </span>
        </p>
        <p>
          <span
            >2.5.6本服务中可能会使用第三方软件或技术，若有使用，我们保证已经获得完整、合法授权，同时，我们有权决定按照相关法律法规或约定对相关的协议或其他文件进行展示。前述通过各种形式展示的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守该等协议或文件的要求。否则，因您违反该等要求所造成的的一切责任由您应自行承担。</span
          >
        </p>
        <p>
          <span>2.6 应用的下线规则</span>
        </p>
        <p>
          <span
            >2.6.1
            对于您主动申请您提供的应用或服务终止运营的，您应当在终止之前提前30日书面通知我们，经我们同意后方可终止运营。
          </span>
        </p>
        <p>
          <span
            >2.6.2
            若无论因为何种原因，您的网络游戏类应用或服务需要终止运营的，您应至少提前60日在应用页面及服务页面中向用户发布终止运营的公告，并关闭支付入口；若您主动申请您提供的非网络游戏类应用或相关服务终止运营的，您应依照法律法规以及按照我们的要求在应用页面及服务页面中向用户提前发布终止运营的公告。
          </span>
        </p>
        <p>
          <span
            >2.6.3
            您发布的关于应用或服务终止运营的公告应持续发布至应用或服务正式下线之日止。</span
          >
        </p>
        <p>
          <span>2.6.4 </span
          ><strong
            >应用因故终止运营的，用户在应用中享有的相关权益，您必须依法处理，该等处理包括但不限于应当予以相应退费或补偿。对于网络游戏类应用的用户尚未使用的网络游戏虚拟货币及尚未失效的游戏服务、道具等用户权益，您应当按用户购买时的比例以法定货币退还给用户或者以用户接受的其他方式进行退还。
          </strong>
        </p>
        <p>
          <span
            >2.6.5
            您可在公告通知中建议用户在下线公告期间行使应用或服务中的权利，包括鼓励用户在公告期限内消耗完毕游戏道具、服务等，但应依法保护用户的合法权益，不得强制用户消费，亦不得免除自身退款责任等义务。
          </span>
        </p>
        <p>
          <span
            >2.6.6
            无论因何种原因导致的应用或服务的终止，在终止之前，您都应该依照法律法规、合同约定及按照我们的要求妥善解决您与用户之间的相关事项，否则，由此产生的您与用户之间的纠纷等问题由您自行负责解决及承担全部责任。
          </span>
        </p>
        <p>
          <span
            >2.6.7
            您因运营需要须暂时限制、中止向用户提供应用或服务的，应经过我们的书面同意，并至少提前十个工作日通知用户，需要对用户进行赔偿或补偿的，您应当承担赔偿或补偿义务。
          </span>
        </p>
        <p>
          <span>2.7 关于网络游戏类应用</span>
        </p>
        <p>
          <span>2.7.1 若您的应用属于网络游戏类应用，应满足以下要求： </span>
        </p>
        <p>
          <span
            >根据相关法律法规的要求，在网络游戏类应用中，根据网络游戏类应用的内容、功能和适用人群，制定网络游戏类应用的用户指引和警示说明，并在网站和网络游戏的显著位置予以标明。
          </span>
        </p>
        <p>
          <span>2.7.2 若您的应用属于网络游戏类应用，则应用： </span>
        </p>
        <p>
          <span>（1）不得设置未经用户同意的强制对战；</span>
        </p>
        <p>
          <span
            >（2）不得以随机抽取等偶然方式诱导用户采取投入法定货币或者网络游戏虚拟货币方式获取应用的产品和服务；
          </span>
        </p>
        <p>
          <span
            >（3）在以未成年人为对象的网络游戏类应用中，不得含有诱发未成年人模仿的违反社会公德的行为和违法犯罪的行为和内容以及恐怖、残酷等妨害未成年人身心健康的内容；
          </span>
        </p>
        <p>
          <span
            >（4）如果游戏类应用用户要求以人民币的方式退还其已经购买但尚未使用的虚拟货币或/与尚未失效的服务的，由双方按比例向用户进行退还。
          </span>
        </p>
        <p>
          <span>2.8 关于用户个人信息的规则</span>
        </p>
        <p>
          <span
            >2.8.1
            您的应用或服务对于个人信息的收集、保存、使用等必须满足以下要求：
          </span>
        </p>
        <p>
          <span
            >（1）您的应用或服务需要收集用户任何数据的，必须事先获得用户的明确同意，且仅应当收集为应用程序运行及功能实现目的而必要的个人信息，同时应当告知用户相关数据收集的目的、范围及使用方式等，保障用户的知情权；
          </span>
        </p>
        <p>
          <span
            >（2）您收集个人信息后，必须采取必要的保护措施，防止个人信息被盗、泄漏等；
          </span>
        </p>
        <p>
          <span
            >（3）您在特定应用中收集的个人信息仅可以在该特定目的中使用，不得将其使用在该特定目的之外的其他任何目的，也不得以任何方式将其提供给他人；
          </span>
        </p>
        <p>
          <span
            >（4）您应向用户提供隐私政策。隐私政策须在应用界面上明显位置向用户展示；
          </span>
        </p>
        <p>
          <span
            >（5）您应当向用户提供修改、删除个人信息的方式，确保用户要求删除其个人信息时可通过该方式自行操作完成，并确保相关数据被完全删除。
          </span>
        </p>
        <p>
          <span
            >2.8.2
            您不得收集或要求用户提供您应用账号和密码之外的任何用户密码、用户关系链、好友列表数据、具有支付功能的账号及其密码等。
          </span>
        </p>
        <p>
          <span
            >2.8.3
            您不得使用开发者平台上用户的个人信息等数据向用户进行广告宣传。
          </span>
        </p>
        <p>
          <span
            >2.8.4
            如果我们认为您收集、使用个人信息的方式可能损害用户体验，我们有权要求您删除相关数据并不得再以该方式收集、使用个人信息。
          </span>
        </p>
        <p>
          <span
            >2.8.5
            我们有权限制或阻止您获取使用您应用的用户的个人信息及开发者平台上的用户个人信息等全部数据。
          </span>
        </p>
        <p>
          <span
            >2.8.6
            本平台的运营数据以及本平台上用户的个人信息等所有数据的全部权利均归属我们，且是我们的商业秘密，依法属于用户享有的个人信息权益等相关权利除外。未经我们事先书面同意，您不得为本协议约定之外的目的使用前述数据，亦不得以任何形式将前述数据提供给他人。
          </span>
        </p>
        <p>
          <span
            >2.8.7
            一旦开发者停止使用本平台，或我们基于任何原因终止您使用本服务，您必须立即删除全部从开发者平台中获得的数据（包括各种备份），且不得再以任何方式进行使用。
          </span>
        </p>
        <p>
          <span
            >2.8.8
            您应自行对因使用本服务而存储在我们服务器的各类数据采取合理、安全的技术措施和管理措施以确保其安全性，并对自己的行为（包括但不限于自行安装软件、采取加密措施或进行其他安全措施等）所引起的结果承担全部责任。
          </span>
        </p>
        <p>
          <span>2.8.9 </span
          ><strong
            >在您收集、使用等处理个人信息的活动中，您除了需要遵守本协议约定外，还应当遵守本平台的<span
              class="goToBlank"
              @click="$goToBlank('register_convention')"
              >《应用商店平台开发者合规公约（个人信息保护）》</span
            >之规定，该公约为本协议之一部分，与本协议具有同等法律约束力。</strong
          >
        </p>
        <p>
          <span>2.9 法律责任</span>
        </p>
        <p>
          <span
            >2.9.1
            您保证：您会按照本协议及相关协议、本平台规则等规则按时足额支付相关费用。否则，您理解并同意：每延期一日，您应当向我们支付所欠费用千分之一的违约金。同时，我们有权随时单方采取包括但不限于以下措施中的一种或多种，以维护自己的合法权益：</span
          >
        </p>
        <p>
          <span
            >（1）从其他我们应支付给您或您关联公司的任何费用中直接抵扣；
          </span>
        </p>
        <p>
          <span>（2）暂停向您或您关联公司结算或支付任何费用； </span>
        </p>
        <p>
          <span>（3）中止、终止您或您关联公司使用本服务； </span>
        </p>
        <p>
          <span>（4）中止、终止您或您关联公司账户的后台管理权限； </span>
        </p>
        <p>
          <span>（5）删除您或您关联公司在使用本服务中存储的任何数据； </span>
        </p>
        <p>
          <span>（6）禁止您今后将您的任何新应用接入本平台； </span>
        </p>
        <p>
          <span>（7）其他我们可以采取的为维护自己权益的措施。 </span>
        </p>
        <p>
          <span>2.9.2 </span
          ><span
            >您保证：<strong
              >您使用本服务及您的任何行为，不会侵犯任何人的合法权益等。因您违反本协议约定</strong
            ></span
          ><strong
            >或/和侵害他人合法权益所引起的纠纷、责任等一概由您自行负责，因此造成我们损失的（该等损失包括但不限于被主管机关罚款、赔偿第三方损失以及律师费、诉讼费、鉴定费、调查取证费、差旅费等），您也应在该等费用发生之日起5个工作日内赔偿我们全部损失。</strong
          >
        </p>
        <p>
          <span
            >2.9.3
            您理解并同意：若我们通过自行发现或根据相关部门的通报、用户或第三方投诉等方式发现您可能存在侵犯他人合法权益情形的，我们有权根据自己的独立判断以认定您是否存在侵犯他人合法权益情形。若我们经过判断认为您存在侵犯他人合法权益情形，且该等情形发生次数不超过2次的，我们有权随时单方面采取以下一项或多项措施：</span
          >
        </p>
        <p>
          <span
            >（1）要求您立即更换、修改侵犯他人合法权益的相关内容，在您采取更正行为前暂停向您结算应用收益，该等暂停结算不构成延期支付；
          </span>
        </p>
        <p>
          <span
            >（2）对存在侵犯他人合法权益的应用，采取封闭新用户入口、限制老用户登录等措施；
          </span>
        </p>
        <p>
          <span
            >（3）对存在侵犯他人合法权益的应用，我们有权按照本平台的<span
              class="goToBlank"
              @click="$goToBlank('check_measure')"
              >《开发者帐号与应用处置原因及措施》</span
            >对您的应用和开发者账号采取相应处置措施；
          </span>
        </p>
        <p>
          <span>（4）从拟支付的款项中扣减应由您承担的相应金额的违约金； </span>
        </p>
        <p>
          <span>（5）追究您的法律责任；</span>
        </p>
        <p>
          <span
            >（6）将您的行为对外予以公告，向主管机关或权利人提供您的有效信息；
          </span>
        </p>
        <p>
          <span>（7）采取其他我们认为适合的处理措施。</span>
        </p>
        <p>
          <span
            >对于侵犯他人合法权益的应用，如您对应用进行修改后不再侵犯他人的合法权益，您同意我们仍然有权对修改后的应用进行冻结、下架或删除等处理。同时，若有需要，您可以将修改后的不再侵犯他人合法权益的应用作为新应用按照开发者平台的流程、规范等接入开发者平台。</span
          >
        </p>
        <p>
          <span
            >2.9.4
            您理解并同意：若我们通过自行发现或根据相关部门的通报、用户或第三方投诉等方式发现您可能存在侵犯他人合法权益情形的，我们有权根据自己的独立判断以认定您是否存在侵犯他人合法权益情形。若我们经过判断认为您存在侵犯他人合法权益情形，且该等情形发生次数3次以上（含3次）的，我们有权随时单方采取以下一项或多项措施：</span
          >
        </p>
        <p>
          <span
            >（1）要求您立即更换、修改侵犯他人合法权益的相关内容，
            在您采取更正行为前暂停向您结算应用收益，该等暂停结算不构成延期支付；
          </span>
        </p>
        <p>
          <span
            >（2）对存在侵犯他人合法权益的应用或您名下的全部应用或任何一款应用采取封闭新用户入口、限制老用户登录等措施；
          </span>
        </p>
        <p>
          <span
            >（3）对存在侵犯他人合法权益的应用，我们有权按照本平台的<span
              class="goToBlank"
              @click="$goToBlank('check_measure')"
              >《开发者帐号与应用处置原因及措施》</span
            >对您的该应用和/或您名下的任何一款应用以及您的开发者账号采取相应处置措施；
          </span>
        </p>
        <p>
          <span>（4）从拟支付的款项中扣减应由您承担的相应金额的违约金； </span>
        </p>
        <p>
          <span>（5）禁止您今后将您的任何新应用接入开发者平台；</span>
        </p>
        <p>
          <span>（6）追究您的法律责任，同时单方终止本协议； </span>
        </p>
        <p>
          <span
            >（7）将您的行为对外予以公告，向主管机关或权利人提供您的有效信息；
          </span>
        </p>
        <p>
          <span>（8）采取其他我们认为适合的处理措施。</span>
        </p>
        <p>
          <span
            >2.9.5
            在我们告知您或您自行得知您存在侵犯他人合法权益情形后，您应在知悉之日起1个工作日内向我们提出反馈通知。但是，无论我们是否告知您、您是否提出反馈通知或反馈通知是否符合相关法律法规以及我们的要求，均不影响我们进行自己的独立判断和采取相应处置措施。</span
          >
        </p>
        <p>
          <span
            >2.9.6
            您保证：您使用本服务及您的任何行为不违反任何相关法律法规、本协议和相关协议以及本平台规则。否则，您理解并同意：若通过我们自行发现或根据相关部门的信息、用户或权利人的投诉等方式发现您可能存在违反前述保证情形的，我们有权独立判断以认定您是否存在违反前述保证情形，若我们经过判断认为您存在违反前述保证情形的，我们有权随时单方采取以下一项或多项措施：</span
          >
        </p>
        <p>
          <strong
            >（1）要求您立即更换、修改违反前述保证情形的相关内容，在您采取更正行为前暂停向您结算应用收益，该等暂停结算不构成延期支付；
          </strong>
        </p>
        <p>
          <span
            >（2）对存在违反前述保证情形的应用或您名下的全部应用或任何一款应用采取封闭新用户入口、限制老用户登录等措施；
          </span>
        </p>
        <p>
          <span><strong>（3）对存在违反前述保证情形的应用</strong></span
          ><strong
            >，我们有权按照本平台的<span
              class="goToBlank"
              @click="$goToBlank('check_measure')"
              >《开发者帐号与应用处置原因及措施》</span
            >/《应用违规处理规定》对您的该应用和/或您名下的任何一款应用以及您的开发者账号采取相应处置措施；
          </strong>
        </p>
        <p>
          <span>（4）从拟支付的款项中扣减应由您承担的相应金额的违约金； </span>
        </p>
        <p>
          <span>（5）禁止您今后将您的任何新应用接入开发者平台；</span>
        </p>
        <p>
          <span>（6）追究您的法律责任，同时单方终止本协议； </span>
        </p>
        <p>
          <span
            >（7）将您的行为对外予以公告，向主管机关或权利人提供您的有效信息；
          </span>
        </p>
        <p>
          <span>（8）采取其他我们认为适合的处理措施。</span>
        </p>
        <p>
          <span>2.9.7 </span
          ><strong
            >若我们按照上述条款、本协议的其他相关约定或因您违反相关法律法规的规定，对您或您的应用采取任何行为或措施所引起的纠纷、责任等一概由您自行负责。因您之行为造成您损失的，您应自行全部承担。因您之行为造成我们或他人损失的，您也应自行承担全部责任。</strong
          >
        </p>
        <p>
          <strong
            >若您有任何违反相关法律法规、本协议和相关协议以及本平台规则等行为的，我们有权暂停向您或/和您的关联公司结算和支付任何费用。若因为您的行为给我们造成任何损失的，我们有权将应支付给您或您关联公司的任何费用先直接抵扣我们的损失后再将余款支付给您或您关联公司，不足部分您应另行赔偿我们。</strong
          >
        </p>
        <p>
          <span>2.9.8 </span
          ><strong
            >若我们按照上述条款、本协议的其他相关约定或因您违反相关法律的规定，对您或您的应用采取任何行为或措施后，导致您没有使用到相应服务但已经缴纳相应费用的，对该部分费用，我们有权不予退还，而作为您违约行为的违约金予以没收。</strong
          >
        </p>
        <p>
          <span>2.9.9 </span
          ><strong
            >因您违约导致我们终止和/或解除本协议的，您的应用尚未结算的应用收益，我们有权不予结算，而作为您违约行为的违约金予以没收。</strong
          >
        </p>
        <p>
          <span>3. 本平台的权利义务</span>
        </p>
        <p>
          <span
            >3.1 我们会根据您选择的服务以及交纳费用的情况向您提供相应的服务。
          </span>
        </p>
        <p>
          <span
            >3.2
            保护您的信息安全是我们的一项基本原则，未经您的同意，我们不会向我们以外的任何公司、组织和个人披露、提供您的信息，但下列情形除外：
          </span>
        </p>
        <p>
          <span
            >（1）据本协议或其他相关协议以及本平台规则等规定可以提供的；</span
          >
        </p>
        <p>
          <span>（2）依据法律法规的规定可以提供的；</span>
        </p>
        <p>
          <span>（3）行政、司法等政府部门要求提供的；</span>
        </p>
        <p>
          <span>（4）您同意我们向第三方提供的；</span>
        </p>
        <p>
          <span
            >（5）为解决用户或第三方举报、投诉事件或者诉讼、仲裁、行政处罚等事宜所必需而需要提供的；
          </span>
        </p>
        <p>
          <span
            >（6）为防止违法行为或涉嫌犯罪行为发生而采取必要合理行为所必需提供的。
          </span>
        </p>
        <p>
          <strong
            >3.3
            尽管我们对您的信息安全做了最大努力，但不能保证在我们已经采取安全措施的情况下，您的信息仍存在因不可抗力或非我们的过错等原因而遭受泄漏、窃取等风险，由此给您造成损失的，您同意我们可以免责。</strong
          >
        </p>
        <p>
          <span
            >3.4
            我们不保证开发者平台上不会出现其他开发者提供的与您的应用在功能、界面、交互或者服务等方面具有竞争关系的应用，若您发现其他开发者存在侵犯您著作权等合法权益情形，您可以向我们举报或投诉。经我们核实属实后，我们将对该侵犯您合法权益的应用采取相应的处置措施。</span
          >
        </p>
        <p>
          <span
            >3.5
            我们有权在包括但不限于应用介绍页或安装页等页面向用户阐述该应用为您开发以及由您向用户提供客服等。
          </span>
        </p>
        <p>
          <span>3.6 </span
          ><strong
            >您理解并同意，我们可将本协议下的权利和义务的部分或全部转让给他人。我们将权利和义务转让给第三方之前会通过各种送达方式通知您。您同意，该等通知中的任一方式发送成功即视为该通知已经送达于您。如果您不同意该等转让，则有权停止使用本协议项下服务。否则，前述通知中的任一方式送达您之日起，若您继续使用本协议项下服务的，视为您同意并接受该等转让。
          </strong>
        </p>
        <p>
          <span>3.7 </span
          ><strong
            >除另有约定外，我们享有本协议项下的所有权益且无需向您支付其他任何费用。
          </strong>
        </p>
        <p>
          <span>3.8 </span
          ><strong
            >您理解并同意，我们有权采取以下行为向更多用户推广您的应用而无须再取得您的同意：</strong
          >
        </p>
        <p>
          <strong
            >（1）在本平台以及其他平台、网站等采取各种形式对应用进行宣传推广，在宣传推广中使用您的应用的名称、场景、商标、用户界面及其元素等任何部分；</strong
          >
        </p>
        <p>
          <strong
            >（2）我们可根据整体运营安排，自主选择向整个或部分全世界范围内的用户提供您的应用；</strong
          >
        </p>
        <p>
          <strong
            >（3）我们有权为本协议目的使用您应用的LOGO、标识、名称、图片、用户界面等任何元素。</strong
          >
        </p>
        <p>
          <strong
            >3.9我们保留对您的应用分类、产品介绍、截图、产品标题及副标题等信息修改编辑的权利，使之正确且符合本平台的业务推广规则。
          </strong>
        </p>
        <p>
          <strong>4. 关于免责（提示您重点阅读本协议该部分条款）</strong>
        </p>
        <p>
          <strong
            >4.1
            您理解并同意：鉴于网络服务的特殊性，我们有权在无需通知您的情况下根据本平台的整体运营情况或相关运营规范、规则以及本平台规则等，可以随时变更、中止或/终止部分或全部的服务。由此给您造成损失的，您同意我们可以免责。</strong
          >
        </p>
        <p>
          <span>4.2 </span
          ><strong
            >您理解并同意：为了向您提供更完善的服务，我们有权定期或不定期地对提供本平台或/和相关设备进行检修、维护、升级等，此类情况可能会造成本平台相关服务在一段时间内中断或暂停。由此给您造成损失的，您同意我们可以免责。</strong
          >
        </p>
        <p>
          <span>4.3 </span
          ><strong
            >您理解并同意：我们的服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力向您提供服务，确保服务的连贯性和安全性；但我们不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</strong
          >
        </p>
        <p>
          <strong
            >您同意：即使我们提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为我们违约。由此给您造成损失的，您同意我们可以免责。</strong
          >
        </p>
        <p>
          <span>4.4 </span><strong>您理解并同意：</strong
          ><span
            >在您使用本服务的过程中，我们提供的服务可能会遇到不可抗力（包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等）、第三方违约等风险因素使本服务发生中断。出现上述情况时，我们将努力在第一时间与相关单位配合，及时进行修复。</span
          ><strong>由此给您造成损失的，您同意我们可以免责。</strong>
        </p>
        <p>
          <span>4.5 </span
          ><strong
            >您理解并同意：若由于以下情形导致服务中断或受阻给您造成损失的，您同意我们可以免责：</strong
          >
        </p>
        <p>
          <strong
            >（1）本平台受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
          </strong>
        </p>
        <p>
          <strong
            >（2）您或我们的电脑软件、系统、硬件和通信线路出现故障；
          </strong>
        </p>
        <p><strong>（3）您操作不当；</strong></p>
        <p>
          <strong>（4）您通过非我们授权的方式使用本服务； </strong>
        </p>
        <p>
          <strong>（5）其他我们无法控制或无法合理预见的情形。 </strong>
        </p>
        <p>
          <span>5. 服务的中止/终止</span>
        </p>
        <p>
          <span
            >5.1
            如您书面通知我们不接受本协议或/及对其的修改、更新，我们有权随时中止或/及终止向您提供全部或部分本服务。
          </span>
        </p>
        <p>
          <span
            >5.2
            因不可抗力、第三方违约等风险因素导致您无法继续使用本服务或我们无法提供本服务的，任何一方均有权随时终止协议。
          </span>
        </p>
        <p>
          <span
            >5.3
            本协议约定的其他中止或终止条件发生或实现的，我们有权随时中止或终止向您提供全部或部分本服务。
          </span>
        </p>
        <p>
          <span
            >5.4
            如果一方及其债权人或任何其他合法方申请清算、破产、重组、和解或解除或一方资金无法维持公司正常运作，或一方无法赔偿任何债务，或一方的债权人接管一方的经营，则相对方可终止本协议。
          </span>
        </p>
        <p>
          <span
            >5.5
            由于您违反本协议约定以及本平台规则，我们依约终止向您提供本服务后，如您后续再直接或间接或以他人名义注册使用本服务的，我们有权直接单方面暂停或终止向您提供本服务。
          </span>
        </p>
        <p>
          <span>5.6 </span
          ><strong
            >在本服务因为任何原因终止时，对于您的账号中的全部数据或您因使用本服务而存储在我们服务器中的数据等任何信息，我们可将该等信息保留或删除。</strong
          >
        </p>
        <p>
          <strong
            >5.7
            在本服务因为任何原因终止时，您应自行提前进行数据备份以及处理好您与您的用户之间的纠纷等相关事项。因您之处理不当造成我们损失的，您应当承担全部赔偿责任。</strong
          >
        </p>
        <p>
          <span>6. 关于通知</span>
        </p>
        <p>
          <strong
            >6.1
            我们可能会以网页公告、网页提示、电子邮箱、手机短信、常规的信件传送、您注册的本服务账户的管理系统内发送站内信等方式中的一种或多种，向您送达关于本服务的各种通知、提示等信息，该等信息一经我们采取前述任何一种方式公布或发送即视为送达。若您不接受该送达方式的，请您书面通知我们，否则您使用本平台的任何行为即视为您已经接受和同意本条之约定。
          </strong>
        </p>
        <p>
          <strong
            >6.2
            您同意：若由于您提供的电子邮箱、手机号码、通讯地址等信息错误或您的该等信息已经变更但未及时书面告知我们，导致您未收到相关规则、通知、提示等信息的，仍然视为您已经收到相关信息并受其约束，一切后果及责任由您自行承担。
          </strong>
        </p>
        <p>
          <strong
            >6.3
            您同意我们或/和我们的合作伙伴可以向您的电子邮箱、手机号码等发送可能与本服务不相关的其他各类信息（包括但不限于商业广告等）。
          </strong>
        </p>
        <p>
          <span
            >6.4
            若您有事项需要通知我们的，应当按照步步高开发者平台网站上对外正式公布的联系方式书面通知步步高。
          </span>
        </p>
        <p>
          <span> </span>
        </p>
        <p>
          <span>7. 知识产权</span>
        </p>
        <p>
          <span
            >7.1
            本协议签订前，双方单独享有的商标权、著作权或者其他知识产权，均归各方单独享有，并不会因为双方签订或者履行本协议而转归对方享有，或者转归双方共同享有。但双方另有书面约定的除外
            。</span
          >
        </p>
        <p>
          <span
            >7.2
            我们在本服务中提供的信息内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归我们所有，依法属于他人所有的除外。除另有特别声明外，我们提供本服务时所依托软件的著作权、专利权及其他知识产权均归我们所有。我们在本服务中所使用的“步步高”/“小天才”/等商业标识，其著作权或商标权等合法权益归我们所有。上述及其他任何我们依法拥有的知识产权均受到法律保护，未经我们书面许可，您不得以任何形式进行使用或创作相关衍生作品。
          </span>
        </p>
        <p>
          <span
            >7.3
            您仅拥有依照本协议约定合法使用本服务的权利，与本服务相关著作权、专利权等全部合法权益归我们所有。未经我们书面许可，您不得违约或违法使用，不得向任何单位或个人出售、转让、转授权我们的代码、API接口或开发工具等。
          </span>
        </p>
        <p>
          <span>8. 其他</span>
        </p>
        <p>
          <span
            >8.1
            本协议内容同时包括《开发者服务协议》及我们可能不断发布的关于本服务的其他相关协议及其更新。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。</span
          >
        </p>
        <p>
          <strong
            >您除了应当遵守本《开发者服务协议》外，还应当遵守本平台的<span
              class="goToBlank"
              @click="$goToBlank('register_convention')"
              >《应用商店平台开发者合规公约》</span
            >，该等</strong
          ><u
            ><strong
              >公约属于《开发者服务协议》的一部分，与其具有同等法律效力。该等公约与《开发者服务协议》不一致的内容，以该等公约的规定为准。</strong
            ></u
          >
        </p>
        <p>
          <u
            ><strong
              >您除了应当遵守《开发者服务协议》外，还应当遵守本平台的<span
                class="goToBlank"
                @click="$goToBlank('check_rule')"
                >《应用审核规范》</span
              ><span class="goToBlank" @click="$goToBlank('check_quality')"
                >《应用资质审核规范》</span
              ><span class="goToBlank" @click="$goToBlank('check_measure')"
                >《开发者帐号与应用处置原因及措施》</span
              >之规定，</strong
            ></u
          ><strong>该等规范</strong
          ><u
            ><strong
              >属于《开发者服务协议》的一部分，与其具有同等法律效力。该等规范与《开发者服务协议》不一致的内容，以该等规范的规定为准。</strong
            ></u
          >
        </p>
        <p><strong> </strong></p>
        <p>
          <span
            >8.2
            未成年人特别保护：本平台特别重视对未成年人的保护，您在使用本平台服务的过程中以及在通过您的应用向未成年人用户提供服务的过程中，除了应当遵守本协议对未成年人保护的相关约定外，还应当遵守本平台的<span
              class="goToBlank"
              @click="$goToBlank('register_convention')"
              >《应用商店平台开发者合规公约（未成年人特别保护）》</span
            >之规定，该公约为本协议之一部分，与本协议具有同等法律约束力。</span
          >
        </p>
        <p>
          <span
            >8.3
            具有舆论属性或社会动员能力应用程序安全评估：本平台一直重视对具有舆论属性或社会动员能力的互联网信息服务和相关新技术新应用的安全管理，您除了应当遵守本协议相关条款外，还应当遵守本平台的<span
              class="goToBlank"
              @click="$goToBlank('register_convention')"
              >《应用商店平台开发者合规公约（具有舆论属性或社会动员能力应用程序安全评估）》</span
            >之规定，该公约为本协议之一部分，与本协议具有同等法律约束力。</span
          >
        </p>
        <p>
          <span>8.4 </span
          ><span>本协议签订地为中华人民共和国广东省东莞市长安镇。</span>
        </p>
        <p>
          <span
            >8.5
            本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不适用冲突法）。
          </span>
        </p>
        <p>
          <strong
            >8.6
            若您和我们之间因本协议之履行发生任何纠纷或争议，应友好协商解决；协商不成的，双方均同意提交至本协议签订地有管辖权的人民法院解决。</strong
          ><span> </span>
        </p>
        <p>
          <span
            >8.7
            本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议解释的依据。</span
          >
        </p>
        <p>
          <span>（正文完）</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isOverflow: {
      type: Number,
      default: 2
    }
  }
};
</script>

<style lang="less" scoped>
strong {
  font-weight: 600;
}
.isOverflow {
  height: 550px;
  overflow: auto;
}
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  padding-top: 30px;
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content {
    padding-right: 30px;
  }
  p {
    line-height: 160%;
    text-indent: 2em;
  }

  .content-big-title {
    margin-top: 30px;
    font-size: 18px;
    color: #333333;
    margin-bottom: 8px;
    text-indent: 0;
  }
}
</style>
