<template>
  <div class="content-right">
    <div class="content-title">应用版本更新</div>
    <div class="content">
      <p class="content-big-title">1.应用版本更新流程</p>
      <p class="content-small-title">
        <img src="@/assets/img/tj1.png" alt />
      </p>
      <p class="content-big-title">2.详细说明</p>
      <p class="content-small-title">
        1）在管理中心，点击编辑与更新
        <img src="@/assets/img/gxsm2.jpg" alt class="img" />
      </p>

      <p class="content-small-title">
        2）选择您要上传的应用apk文件
        ，上传后将自动解析包名，以及应用名称。如下图：
        <img src="@/assets/img/xx2.png" alt class="img" />
      </p>

      <p class="content-small-title">3）完善应用信息</p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span
        >icon：要求与安装包中图标一致。尺寸：要求长等于宽，不低于256*256，不超过512*512，角不超过48PX，小于50K；
      </p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span>
        截图：请上传3-5张截图（尺寸大小保持一致、横屏或竖屏保持一致），支持JPG、PNG格式。截图尺寸要求：尺寸为竖图480*800，格式为jpg/png，每张图片尺寸一致，单张图片不超过2MB。请去除截图中的顶部状态栏的通知图标，图片中不得使用其他品牌的手机作为边框或宣传图；
      </p>
      <p class="content-small-title">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="span3"></span
        >新版本说明：应用当前版本的更新说明，例如：修复若干bug，优化应用ui界面等
        <img src="@/assets/img/sm1.png" alt class="img" />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    .span2 {
      text-decoration: underline;
      color: skyblue;
    }
    .span3 {
      display: inline-block;
      margin-right: 20px;
      width: 10px;
      height: 10px;
      background-color: #000;
      border-radius: 50%;
    }
  }
}
</style>
