<template>
  <div class="content-area">
    <p class="content-title">找回密码</p>
    <div class="progress-box">
      <img
        v-show="firstProgress"
        src="@/assets/img/progress-1.png"
        alt="找回密码"
        class="progress-img"
      />
      <img
        v-show="!firstProgress"
        src="@/assets/img/progress-2.png"
        alt="找回密码"
        class="progress-img"
      />
      <img
        v-show="secondProgress"
        src="@/assets/img/progress-3.png"
        alt="找回密码"
        class="progress-img left-margin"
      />
      <img
        v-show="!secondProgress"
        src="@/assets/img/progress-4.png"
        alt="找回密码"
        class="progress-img left-margin"
      />
      <img
        v-show="thirdProgress"
        src="@/assets/img/progress-5.png"
        alt="找回密码"
        class="progress-img left-margin"
      />
      <img
        v-show="!thirdProgress"
        src="@/assets/img/progress-6.png"
        alt="找回密码"
        class="progress-img left-margin"
      />
    </div>
    <!-- 确认账号功能 -->
    <div v-show="firstBox" class="input-box first-box">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">确认账号</span>
      </div>
      <div class="second-line">
        请认真确认您需要找回的账号
      </div>
      <div class="account-box">
        <input
          v-model="account"
          v-focus="focusAccountState"
          type="text"
          placeholder="请输入您的注册邮箱"
          class="input input-long"
          maxlength="30"
          @keyup="accountInput('account')"
        />
        <img
          v-if="clearAccountShow"
          src="@/assets/img/delete-icon.png"
          alt="删除输入信息"
          class="clear-icon"
          @click="clearContent('account')"
        />
        <span v-if="errorAcount" class="error-desc">请输入正确的邮箱</span>
      </div>
      <div class="code-box">
        <input
          v-model="code"
          v-focus="focusCodeState"
          type="text"
          placeholder="请输入验证码"
          maxlength="6"
          class="input input-short"
          @keyup="accountInput('code')"
        />
        <img
          :src="codeSrc"
          alt="验证码"
          class="change-code-btn"
          @click="changeCode"
        />
        <img
          v-if="clearCodeShow"
          src="@/assets/img/delete-icon.png"
          alt="删除输入信息"
          class="clear-icon"
          @click="clearContent('code')"
        />
        <span v-if="errorCode" class="error-desc"> 请输入验证码</span>
      </div>
      <div class="next-step-btn" @click="submitAccount">
        下一步
      </div>
      <div v-show="firstToastShow" class="toast-box">
        <img
          src="@/assets/img/error-icon.png"
          alt="提示图标"
          class="toast-icon"
        />
        <span>邮箱或验证码不正确</span>
      </div>
    </div>
    <!-- 安全验证区域 -->
    <div v-show="secondBox" class="input-box second-box">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">安全验证</span>
      </div>
      <div class="second-line">
        为了保护您的账号安全，操作前请进行安全验证
      </div>
      <div class="third-line">
        <span>验证邮箱</span>
        <span class="account-show">{{ userMail }}</span>
      </div>
      <div class="code-box">
        <span>验证码</span>
        <input
          v-model="mailBoxCode"
          type="text"
          placeholder="验证码"
          maxlength="6"
          class="input input-short-1"
        />
        <span class="get-code-btn" @click="getCode">{{ defaultCode }}</span>
      </div>
      <div class="next-step-btn" @click="submitAccountAccess">
        下一步
      </div>
      <div v-show="toastShow" class="toast-box">
        <img
          src="@/assets/img/error-icon.png"
          alt="提示图标"
          class="toast-icon"
        />
        <span>验证码错误</span>
      </div>
    </div>
    <!-- 重置密码区域 -->
    <div v-show="thirdBox" class="input-box third-box">
      <div class="first-line">
        <img src="@/assets/img/vertical-line.png" class="vertical-line" />
        <span class="title-bold">重置密码</span>
      </div>
      <div class="psd-box">
        <span class="psd-label">新密码</span>
        <!-- <input type="password" placeholder="8-16位大小写字母、数字、符号组合" v-model="psd" class="input input-psd" maxlength="16"> -->
        <input
          v-model="psd"
          v-focus="focusPsdState"
          type="password"
          autocomplete="new-password"
          maxlength="16"
          style="width: 352px;margin-left:38px;"
          placeholder="8-16位大小写字母、数字、符号组合"
          class="input input-psd"
          :class="errorPsd ? 'red-line' : 'default-line'"
          @keyup="accountInput('psd')"
        />
        <img
          v-if="clearPsdShow"
          src="@/assets/img/delete-icon.png"
          alt="删除输入信息"
          class="clear-icon"
          @click="clearContent('psd')"
        />
        <div
          v-if="errorPsd"
          class="error-desc"
          style="position: absolute;margin-left:0;left:80px;font-size:12px;"
        >
          8-16位，必须是大写或小写字母、标点、数字至少三种的组合
        </div>
      </div>
      <div class="confirm-psd-box">
        <span class="psd-label">确认密码</span>
        <!-- <input type="password" placeholder="请再次输入新密码" maxlength="16" v-model="confirmPsd" class="input input-psd"> -->
        <input
          v-model="confirmPsd"
          v-focus="focusConfirmPsdState"
          type="password"
          autocomplete="new-password"
          maxlength="16"
          style="width: 352px;margin-left:24px;"
          placeholder="请再次输入新密码"
          class="input input-psd"
          :class="errorConfirmPsd ? 'red-line' : 'default-line'"
          @keyup="accountInput('confirmPsd')"
        />
        <img
          v-if="clearConfirmPsdShow"
          src="@/assets/img/delete-icon.png"
          alt="删除输入信息"
          class="clear-icon"
          @click="clearContent('confirmPsd')"
        />
        <!-- <span class="error-desc" v-if="errorConfirmPsd">请输入确认密码</span> -->
        <div
          v-if="errorConfirmPsd1"
          class="error-desc"
          style="position: absolute;margin-left:0;left:80px;font-size:12px;"
        >
          密码不一致
        </div>
      </div>
      <div
        class="next-step-btn"
        style="margin-left: 240px;margin-top: 40px;"
        @click="finishVerification"
      >
        完成
      </div>
    </div>
  </div>
</template>

<script>
import { Md5 } from '@/plugins/md5';
export default {
  name: 'Recover',
  data() {
    return {
      firstProgress: false,
      secondProgress: true,
      thirdProgress: true,
      account: '',
      code: '',
      codeSrc: '',
      firstBox: true,
      secondBox: false,
      thirdBox: false,
      mailBoxCode: '',
      toastShow: false,
      psd: '',
      confirmPsd: '',
      mailReg: null,
      redisKey: '',
      errorAcount: false,
      errorCode: false,
      errorPsd: false,
      errorConfirmPsd: false,
      clearAccountShow: false,
      clearPsdShow: false,
      clearCodeShow: false,
      clearConfirmPsdShow: false,
      focusAccountState: false,
      focusPsdState: false,
      focusCodeState: false,
      focusConfirmPsdState: false,
      defaultCode: '获取验证码',
      userMail: '',
      accountId: '',
      updateToke: '',
      errorConfirmPsd1: false,
      firstToastShow: false
    };
  },
  mounted() {
    this.mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    this.changeCode();
  },
  methods: {
    getCode() {
      if (this.defaultCode !== '获取验证码') {
        return;
      }
      this.defaultCode = '发送中';
      this.codeTimer();
      this.$axios
        .post(`userManage/sendMailCode`, {
          contactMail: this.userMail,
          verifyCodeType: 3
        })
        .then(() => {})
        .catch(() => {});
    },
    goRegister() {
      this.$router.push('/register');
    },
    changeCode() {
      this.redisKey = location.hostname + new Date().getTime();
      this.codeSrc = `${this.$axios.defaults.baseURL}userManage/getImageVerifyCode?redisKey=${this.redisKey}&verifyCodeType=3`;
    },
    submitAccount() {
      this.errorAcount = this.account.length ? false : true;
      this.errorCode = this.code.length ? false : true;
      if (this.errorAcount || this.errorCode) {
        return;
      }
      var vm = this;
      this.$axios
        .post(`userManage/verifyMailByPassword`, {
          contactMail: this.account,
          verifyCode: this.code,
          verifyCodeType: 3,
          redisKey: this.redisKey
        })
        .then(res => {
          console.log(res);
          var data = res.data;
          if (data.stateCode === '0') {
            this.firstBox = false;
            this.secondBox = true;
            this.firstProgress = true;
            this.secondProgress = false;
            this.userMail = data.data.email;
            this.accountId = data.data.accountId;
          } else {
            this.firstToastShow = true;
            this.changeCode();
            setTimeout(function() {
              vm.firstToastShow = false;
            }, 3000);
          }
        })
        .catch(() => {});
    },
    submitAccountAccess() {
      this.$axios
        .post(`userManage/securityVerify`, {
          contactMail: this.userMail,
          redisKey: '',
          verifyCode: this.mailBoxCode,
          verifyCodeType: 3
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            this.secondBox = false;
            this.thirdBox = true;
            this.secondProgress = true;
            this.thirdProgress = false;
            this.updateToke = data.data;
          } else {
            this.toastShow = true;
          }
        })
        .catch(() => {
          this.toastShow = true;
        });
    },
    finishVerification() {
      // this.errorPsd = this.psd ? false : true;
      // this.errorConfirmPsd = this.confirmPsd ? false : true;
      if (!this.psd.trim()) {
        this.errorPsd = true;
      }
      if (!this.confirmPsd.trim()) {
        this.errorConfirmPsd1 = true;
      }
      if (this.errorPsd || this.errorConfirmPsd1) {
        return;
      }
      this.$axios
        .post(`userManage/findPasswordUpdatePassword`, {
          password: Md5.hex_md5(this.psd),
          accountId: this.accountId,
          updateToke: this.updateToke,
          email: this.userMail
        })
        .then(res => {
          var data = res.data;
          if (data.stateCode === '0') {
            this.$router.push('/');
          }
        })
        .catch(() => {});
    },
    accountInput(type) {
      switch (type) {
        case 'account':
          this.focusCodeState = false;
          if (this.account.length && this.mailReg.test(this.account)) {
            this.clearAccountShow = true;
            this.errorAcount = false;
          } else {
            this.clearAccountShow = false;
            this.errorAcount = true;
          }
          break;
        case 'psd':
          var psdReg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/;
          if (this.psd.length && psdReg.test(this.psd.trim())) {
            this.clearPsdShow = true;
            this.errorPsd = false;
          } else {
            this.clearPsdShow = false;
            this.errorPsd = true;
          }
          break;
        case 'code':
          this.focusAccountState = false;
          if (this.code.length) {
            this.clearCodeShow = true;
            this.errorCode = false;
          } else {
            this.clearCodeShow = false;
            this.errorCode = true;
          }
          break;
        case 'confirmPsd':
          this.focusConfirmPsdState = false;
          if (
            this.confirmPsd.length &&
            this.confirmPsd.trim() === this.psd.trim()
          ) {
            this.clearConfirmPsdShow = true;
            this.errorConfirmPsd1 = false;
          } else {
            this.clearConfirmPsdShow = false;
            this.errorConfirmPsd1 = true;
          }
          break;
      }
    },
    clearContent(type) {
      switch (type) {
        case 'account':
          if (!this.account.length) return;
          this.account = '';
          this.clearAccountShow = false;
          this.focusAccountState = true;
          break;
        case 'psd':
          if (!this.psd.length) return;
          this.psd = '';
          this.clearPsdShow = false;
          this.focusPsdState = true;
          break;
        case 'code':
          if (!this.code.length) return;
          this.code = '';
          this.clearCodeShow = false;
          this.focusCodeState = true;
          break;
        case 'confirmPsd':
          if (!this.confirmPsd.length) return;
          this.confirmPsd = '';
          this.clearConfirmPsdShow = false;
          this.focusConfirmPsdState = true;
          break;
      }
    },
    codeTimer() {
      let count = 60;
      let timer = setInterval(() => {
        count--;
        if (count < 0) {
          clearInterval(timer);
          this.defaultCode = '获取验证码';
          return;
        }
        this.defaultCode = count + '秒';
      }, 1000);
    }
  }
};
</script>

<style lang="less" scoped>
.content-area {
  margin: 0 auto;
  margin-top: 50px;
  max-width: 777px;
  .content-title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .progress-box {
    margin-top: 37px;
    .left-margin {
      margin-left: 2px;
    }
  }
  .input-box {
    width: 100%;
    height: 447px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 23px;
    padding-top: 28px;
    padding-left: 28px;
    .account-box {
      position: relative;
      margin-left: 112px;
      .clear-icon {
        position: absolute;
        left: 60.9%;
        top: 48%;
        cursor: pointer;
      }
    }
    .psd-box,
    .confirm-psd-box {
      position: relative;
      margin-left: 160px;
      margin-bottom: 16px;
      .clear-icon {
        position: absolute;
        left: 70%;
        top: 48%;
        cursor: pointer;
      }
    }
    .code-box {
      position: relative;
      margin-left: 112px;
      .clear-icon {
        position: absolute;
        left: 33.5%;
        top: 47%;
        cursor: pointer;
      }
    }
    .first-line {
      border-bottom: 1px solid #c7c7c7;
      padding-bottom: 14px;
    }
    .second-line {
      color: #333333;
      font-size: 14px;
      text-align: center;
      margin-top: 50px;
    }
    .third-line {
      color: #666666;
      font-size: 14px;
      margin-top: 23px;
      margin-left: 210px;
      .account-show {
        margin-left: 24px;
      }
    }
    .title-bold {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      margin-left: 6px;
    }
    .vertical-line {
      margin-bottom: -2px;
    }
    .next-step-btn {
      background: #3cb1ff;
      width: 352px;
      height: 40px;
      line-height: 40px;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      margin-top: 26px;
      letter-spacing: 1px;
      cursor: pointer;
      border-radius: 4px;
      margin-left: 210px;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
      -moz-transition: background 0.5s;
      -o-transition: background 0.5s;
    }
    .next-step-btn:hover {
      background: #2ea5f5;
    }
    .input {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      height: 40px;
      width: 352px;
      outline: none;
      -webkit-appearance: none;
      border: 1px solid #c7c7c7;
      border-radius: 4px;
      margin-top: 16px;
      margin-bottom: 8px;
      color: #666666;
      font-size: 14px;
      padding: 10px 36px 10px 10px;
      overflow: hidden;
      &.input-short {
        width: 204px;
      }
      &.input-short-1 {
        width: 144px;
        margin-left: 24px;
      }
      &.input-psd {
        width: 270px;
      }
    }
    .change-code-btn {
      cursor: pointer;
      margin-left: 20px;
      width: 124px;
      height: 40px;
      vertical-align: middle;
      border-radius: 4px;
    }
    .get-code-btn {
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
      margin-left: 22px;
      width: 104px;
      height: 40px;
      line-height: 42px;
      display: inline-block;
      vertical-align: middle;
      background: #28da99;
      border-radius: 4px;
      text-align: center;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
      -moz-transition: background 0.5s;
      -o-transition: background 0.5s;
    }
    .get-code-btn:hover {
      background: #36c792;
    }
    .psd-label {
      font-size: 14px;
      color: #333333; // margin-right: 8px;
    }
    input:focus {
      border: 1px solid #3cb1ff;
    }
    .psd-box {
      margin-top: 64px;
    }
    .account-box,
    .code-box {
      margin-left: 210px;
    }
  }
}

.toast-box {
  font-size: 14px;
  color: #666666;
  text-align: center;
  border-radius: 4px;
  border: 1px solid red;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background: #ffeaeb;
  margin: 40px 232px 0;
  .toast-icon {
    margin-right: 10px;
    margin-bottom: -2px;
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}

.error-desc {
  margin-left: 14px;
  color: #ff7477;
  font-size: 14px;
}
</style>
