<template>
  <div class="content-right">
    <p class="content-title"><strong>APP备案说明</strong></p>
    <p class="content-big-title"><strong>1.为什么要进行APP备案？</strong></p>
    <p>
      根据《工业和信息化部关于开展移动互联网应用程序备案工作的通知》要求，APP主办者应当依照《中华人民共和国反电信网络诈骗法》第二十三条“设立移动互联网应用程序应当按照国家有关规定向电信主管部门办理许可或者备案手续”相关规定履行备案手续。若未履行备案手续，不得从事APP互联网信息服务。<br />相关规定请见：<br /><a
        class="goToBlank"
        href="https://www.miit.gov.cn/zwgk/zcwj/wjfb/tz/art/2023/art_920db564162e4312916a01bed6540ad8.html"
        target="_blank"
        >《工业和信息化部关于开展移动互联网应用程序备案工作的通知》</a
      ><br /><a
        class="goToBlank"
        href="https://www.miit.gov.cn/jgsj/zfs/fl/art/2022/art_d30139b442a141f48f05775d8c0b3cee.html"
        target="_blank"
        >《中华人民共和国反电信网络诈骗法》</a
      >第二十三条<br /><a
        class="goToBlank"
        href="https://www.miit.gov.cn/zwgk/zcwj/flfg/art/2020/art_4c6a91eb93c34a6e8adc5852f9b56fd1.html"
        target="_blank"
        >《互联网信息服务管理办法》</a
      >（国务院令第292号）
    </p>
    <p class="content-big-title"><strong>2. 备案对象</strong></p>
    <p>
      服务器放置在境内，需要联网接入的APP就需要备案，并不区分APP安装、使用的平台。若APP本身内容是单机，仅使用了其他供应商的广告服务、推广服务，且域名等不属于APP主办者，则主办者不用为不联网的单机APP进行备案。
    </p>
    <p class="content-big-title"><strong>3.APP备案要求</strong></p>
    <p>
      在中华人民共和国境内从事互联网信息服务的APP主办者，应当依照相关文件规定履行备案手续，未履行备案手续的，不得从事互联网信息服务。
    </p>
    <p>APP备案必须由APP的接入商提供备案能力，步步高开发者平台将确认：</p>
    <ul class="ulBox">
      <li>APP是否备案成功</li>
      <li>是否在应用内展示了备案号</li>
      <li>备案主体是否与上架主体一致</li>
    </ul>
    <p class="content-big-title"><strong>4. APP备案的时间节点？</strong></p>
    <ul class="ulBox">
      <li>2023年9月1日起，不再收录未备案的新增应用；</li>
      <li>2023年12月12日起，限制未备案的存量库内应用发起版本更新；</li>
      <li>
        2024年1月4日起，逐步清理在架未备案的存量应用，针对未备案的应用及开发者采取包括但不限于以下措施：限制未备案应用安装、下架未备案应用、冻结开发者账号等措施。
      </li>
    </ul>
    <p>特别提醒：存量APP即2023年9月1日前在架的APP</p>
    <p class="content-big-title">
      <strong>5. 已完成网站ICP备案，还需要APP备案吗？</strong>
    </p>
    <p>需要。即使已完成网站ICP备案，仍需要履行APP备案手续。</p>
    <p class="content-big-title">
      <strong>6. 名下A应用已完成备案，B应用还需要备案吗？</strong>
    </p>
    <p>需要。每一个APP都需要履行APP备案手续。</p>
    <p class="content-big-title">
      <strong>7. 应用转移后，需要重新完成APP备案吗？</strong>
    </p>
    <p>需要。应用转移后，由转移后的开发者主体重新进行APP备案。</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
strong {
  font-weight: 600;
}
.content-right {
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  p {
    font-size: 14px;
    color: rgb(84, 80, 80);

    line-height: 30px;
    text-indent: 2em;
  }
  .ulBox {
    padding-left: 30px;
    font-size: 14px;
    color: rgb(84, 80, 80);
    li {
      list-style-type: disc;
      line-height: 160%;
    }
  }
  .content {
    padding-right: 30px;
  }
  .content-title {
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  .content-big-title {
    margin-top: 30px;
    font-size: 18px;
    color: #000;
    margin-bottom: 8px;
    text-indent: 0;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-medium-title {
    // margin-top: 10px;
    font-size: 16px;
    line-height: 34px;

    span {
      color: #333333;
    }
    // margin-bottom: 8px;
    img {
      display: block;
      margin-top: 20px;
      width: 75%;
      height: 75%;
    }
  }
  .content-small-title {
    // margin-top: 12px;
    // font-size: 14px;

    line-height: 30px;
    text-indent: 2em;
    //  margin-bottom: 30px;
    .img {
      width: 75%;
      height: 75%;
    }
    img {
      display: block;
      margin-top: 12px;
    }
    span {
      font-size: 14px;
      color: rgb(84, 80, 80);
    }
  }
  .end {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .goToBlank {
    text-decoration: none;
  }
}
</style>
